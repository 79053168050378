import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CustomDialogTitle from "components/custom-dialog-title/CustomDialogTitle.component";
import BlueButton from "components/blue-button/BlueButton.component";
import GrayButton from "components/gray-button/GrayButton.component";
import Divider from "@mui/material/Divider";
import ArticleForm from "./Form.component";
import List from "./List.component";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import { useEffect, useState } from "react";
import { uiActions } from "store/ui/ui.state";
import {
  deleteArticleThunk,
  getArticlesThunk,
} from "store/article/article.thunk";

export default function Article() {
  const dispatch = useAppDispatch();
  const { deleteArticleWarningOpen, idOfItemToDelete } = useAppSelector(
    (state) => state.ui
  );
  const _article = useAppSelector((state) => state.articles)

  const [onUpdate, setOnUpdate] = useState<any>(null)
  const [onDelete, setOnDelete] = useState<any>(false)

  //useEffect(() => {setOnUpdate(!onUpdate)}, [_article])

  const closeDeleteArticleWarning = () => {
    dispatch(uiActions.setDeleteArticleWarningOpen({ set: false, id: "" }));
  };
  useEffect(() => {
    dispatch(getArticlesThunk());
  }, [deleteArticleWarningOpen]);

  return (
    <div style={{ padding: "40px 40px 0 40px " }}>
      <ArticleForm onSubmit={() => {
        if (!onUpdate) {
          setOnUpdate(true)
        }
        else {
          setOnUpdate(!onUpdate)
        }

      }} />
      <Divider sx={{ marginBottom: "20px" }} />
      <List didUpdate={onUpdate} />

      <Dialog
        open={deleteArticleWarningOpen}
        onClose={closeDeleteArticleWarning}
        sx={{ "& .MuiPaper-root": { width: "500px" } }}
      >
        <CustomDialogTitle onClose={closeDeleteArticleWarning} id="">
          Suppression
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          Voulez-vous vraiment supprimer cette option ?
        </DialogContent>
        <DialogActions>
          <BlueButton
            onClick={() => {
              dispatch(deleteArticleThunk(idOfItemToDelete)).then(() => { setOnDelete(!onDelete); dispatch(getArticlesThunk()) });
              dispatch(
                uiActions.setDeleteArticleWarningOpen({ set: false, id: "" })
              );
            }}
          >
            Oui
          </BlueButton>
          <GrayButton onClick={closeDeleteArticleWarning}>No</GrayButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
