import { useAppSelector } from "hooks/redux.hook";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";

type UsersListProps = {
  handleClose: () => void;
};

export default function UsersList(props: UsersListProps) {
  const users = useAppSelector((state) => state.users.list);

  return (
    <List>
      {users.map((user) => {
        const { firstName, lastName } = user;
        const fullName = `${lastName} ${firstName}`;
        return (
          <ListItem key={user.id}>
            <ListItemButton>
              <ListItemText primary={fullName} />
            </ListItemButton>
          </ListItem>
        );
      })}
      {users.length === 0 && (
        <ListItem >
          <ListItemButton onClick={props.handleClose}>
            <ListItemText
              primary="Il n'y a pas d'utilisateurs"
              primaryTypographyProps={{ fontWeight: "bold" }}
            />
          </ListItemButton>
        </ListItem>
      )}
    </List>
  );
}