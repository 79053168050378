import { Permissions } from "store/roles/roles.state";

type RoleFormState = {
  name: string;
  description: string;
  userManagment: Permissions;
  supplierManagment: Permissions;
  roleManagment: Permissions;
  managmentRules: Permissions;
  billManagment: Permissions;
  orderManagment: Permissions;
  APIConnection: Permissions;
};

// returns true if state are equal, false otherwise
export function compareRoleFormStates( initial: RoleFormState, current: RoleFormState ) {
  const sameName = initial.name === current.name;
  const sameDescription = initial.description === current.description;
//   i am using length to compare arrays because they can only have 4 strings as declared by Permissions type 
  const sameUserManagment = initial.userManagment.length === current.userManagment.length;
  const sameSupplierManagment = initial.supplierManagment.length === current.supplierManagment.length;
  const sameRoleManagment = initial.roleManagment.length === current.roleManagment.length;
  const sameManagmentRules = initial.managmentRules.length === current.managmentRules.length;
  const sameBillManagment = initial.billManagment.length === current.billManagment.length;
  const sameOrderManagment = initial.orderManagment.length === current.orderManagment.length;
  const sameAPIConnection = initial.APIConnection.length === current.APIConnection.length;

  return (
    sameName &&
    sameDescription &&
    sameUserManagment &&
    sameSupplierManagment &&
    sameRoleManagment &&
    sameManagmentRules &&
    sameBillManagment &&
    sameOrderManagment &&
    sameAPIConnection
  );
}
