import React from "react";
import { useAppSelector, useAppDispatch } from "hooks/redux.hook";
import { Role, rolesActions } from "store/roles/roles.state";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import { uiActions } from "store/ui/ui.state";
import Avatar from "@mui/material/Avatar";

import search from "assets/Search.svg";

export default function RolesFilter() {
  const dispatch = useAppDispatch();
  const filterValue = useAppSelector(
    (state) => state.roles.roleNameFilterValue
  );
  const list = useAppSelector((state) => state.roles.list);
  const authenticatedUser = useAppSelector((state) => state.auth.user);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      rolesActions.setRoleNameFilterValueFilterValue(e.currentTarget.value)
    );
  };
  return (
    <Grid container gap="64px" alignItems="center" marginBottom="51px">
      <Grid item>
        <Typography
          fontWeight="500"
          fontSize="20px"
          lineHeight="23px"
          sx={{ color: "#181C32" }}
        >
          Rôles ({list.length})
        </Typography>
      </Grid>
      <Grid item flex="1">
        <TextField
          type="search"
          placeholder="Rechercher"
          variant="filled"
          autoComplete="off"
          value={filterValue}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {/* <SearchIcon sx={{width: "10.6px", height: "11.3px"}}/> */}
                <Avatar
                  src={search}
                  sx={{ width: "10.6px", height: "11.3px" }}
                />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          sx={{
            width: "136px",
            input: {
              padding: "0px",
              fontWeight: "700",
              fontSize: "12px",
              lineHeight: "14px",
              color: "#A1A5B7",
              "&::placeholder": {
                fontWeight: "700",
                fontSize: "12px",
                lineHeight: "14px",
                color: "#A1A5B7",
                opacity: "1",
              },
            },
            "& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-filled.MuiInputAdornment-sizeMedium":
              {
                marginTop: 0,
              },
            "& .MuiInputBase-root": {
              background: "white",
              padding: "10px 14px",
              borderRadius: "6px",
              "&:hover": {
                background: "white",
              },
              "&.Mui-focused": {
                background: "white",
              },
            },
          }}
        />
      </Grid>
      <Grid item>
        <Button
          sx={{
            boxShadow: "none",
            backgroundColor: "#1DC894",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "#1DC894",
            },
          }}
          variant="contained"
          onClick={() => dispatch(uiActions.setAddRoleFormOpen(true))}
          disabled={
            !(authenticatedUser!.role as Role).roleManagment.includes("create")
          }
          title="Ajouter un rôle"
        >
          +
        </Button>
      </Grid>
    </Grid>
  );
}
