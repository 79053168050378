import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CustomDialogTitle from "components/custom-dialog-title/CustomDialogTitle.component";
import BlueButton from "components/blue-button/BlueButton.component";
import GrayButton from "components/gray-button/GrayButton.component";
import Divider from "@mui/material/Divider";

import { useEffect } from "react";
import Form from "./Form.component";
import List from "./List.component";
import { getPerimetersThunk, deletePerimeterThunk } from "store/perimeter/perimeters.thunk";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import { uiActions } from "store/ui/ui.state";

export default function Perimeters() {
  const dispatch = useAppDispatch();
  const { deletePerimeterWarningOpen, idOfItemToDelete } = useAppSelector(
    (state) => state.ui
  );

  const closeDeletePerimetereWarning = () => {
    dispatch(uiActions.setDeletePerimeterWarningOpen({ set: false, id: "" }));
  };

  useEffect(() => {
    dispatch(getPerimetersThunk());
  }, []);

  return (
    <>
      <Form />
      <Divider sx={{ marginBottom: "20px" }} />
      <List />

      <Dialog
        open={deletePerimeterWarningOpen}
        onClose={closeDeletePerimetereWarning}
        sx={{ "& .MuiPaper-root": { width: "500px" } }}
      >
        <CustomDialogTitle onClose={closeDeletePerimetereWarning} id="">
          Suppression
        </CustomDialogTitle>
        <Divider />
        <DialogContent>Voulez-vous vraiment supprimer cette Produit?</DialogContent>
        <DialogActions>
          <BlueButton
            onClick={() => {
              dispatch(deletePerimeterThunk(idOfItemToDelete));
              dispatch(
                uiActions.setDeletePerimeterWarningOpen({ set: false, id: "" })
              );
            }}
          >
            Oui
          </BlueButton>
          <GrayButton onClick={closeDeletePerimetereWarning}>No</GrayButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
