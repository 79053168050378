import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CustomDialogTitle from "components/custom-dialog-title/CustomDialogTitle.component";
import BlueButton from "components/blue-button/BlueButton.component";
import GrayButton from "components/gray-button/GrayButton.component";
import Divider from "@mui/material/Divider";
import Form from "./Form.component";
import List from "./List.component";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import { useEffect } from "react";
import { uiActions } from "store/ui/ui.state";
import {
  deletePaymentDateThunk,
  getPaymentDatesThunk,
} from "store/payment-dates/paymentDates.thunk";

export default function PaymentDate() {
  const dispatch = useAppDispatch();
  const { deletePaymentDateWarningOpen, idOfItemToDelete } = useAppSelector(
    (state) => state.ui
  );

  const closeDeletePaymentDateWarning = () => {
    dispatch(uiActions.setDeletePaymentDateWarningOpen({ set: false, id: "" }));
  };

  useEffect(() => {
    dispatch(getPaymentDatesThunk());
  });

  return (
    <>
      <Form />
      <Divider sx={{ marginBottom: "20px" }} />
      <List />

      <Dialog
        open={deletePaymentDateWarningOpen}
        onClose={closeDeletePaymentDateWarning}
        sx={{ "& .MuiPaper-root": { width: "500px" } }}
      >
        <CustomDialogTitle onClose={closeDeletePaymentDateWarning} id="">
          Suppression
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          Voulez-vous vraiment supprimer cette option ?
        </DialogContent>
        <DialogActions>
          <BlueButton
            onClick={() => {
              dispatch(deletePaymentDateThunk(idOfItemToDelete));
              dispatch(
                uiActions.setDeletePaymentDateWarningOpen({
                  set: false,
                  id: "",
                })
              );
            }}
          >
            Oui
          </BlueButton>
          <GrayButton onClick={closeDeletePaymentDateWarning}>No</GrayButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
