import { axiosInstance } from "api/axiosInstance";
import { AppDispatch } from "store/store.state";
import { Pole, poleActions } from "./pole.state";
import { feedbackMessageUIActions } from "store/feedback-message-ui/feedbackMessageUI.state";

type GetPoleResponse = {
  data: Pole[];
};

export function getPoleThunk() {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.get<GetPoleResponse>("/poles", {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      });
      const poles: Pole[] = response.data.data;
      dispatch(poleActions.setList(poles));
    } catch (e) {
      console.log(e);
    }
  };
}
export function getCostsThunk() {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.get<GetPoleResponse>("/poles/costCenter", {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      });
      const costs: Pole[] = response.data.data;
      dispatch(poleActions.setCostCenterList(costs));
    } catch (e) {
      console.log(e);
    }
  };
}

type AddPoleResponse = {
  data: Pole;
};

export function addCostThunk(cost) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.post<any>('/poles/costCenter', cost, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      })
      const dataRec = response.data.data
      console.log(dataRec)
      dispatch(poleActions.addCostCenter(dataRec))
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Centre de cout ajouté avec succés",
        })
      );
    } catch (err) { console.log(err) }
  }
}
export function editCostThunk(cost: { _id: string, name: string }) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.put<any>(`/poles/costCenter/${cost._id}`, cost, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      })
      const editedCost = response.data.data
      dispatch(poleActions.editCost({ id: cost._id, cost: editedCost }));
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Centre de cout a été mis a jour avec succés",
        })
      );
    } catch (err) { console.log(err) }
  }
}

export function deleteCostThunk(id: string) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.delete<any>(`/poles/costCenter/${id}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      })
      if (response.data.success) {
        dispatch(poleActions.deleteCostCenter(id))
        dispatch(
          feedbackMessageUIActions.setState({
            open: true,
            message: "Le centre du côut a été supprimé avec succés",
          })
        );
        dispatch(getCostsThunk())
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function addPoleThunk(pole: Omit<Pole, "id">) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.post<AddPoleResponse>(
        "/poles",
        pole,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
          },
        }
      );
      const completePole = response.data.data;
      dispatch(poleActions.addPole(completePole));
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Pole ajouté avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message:
            "Impossible d'ajouter un pole s'il vous plaît réessayer plus tard",
        })
      );
    }
  };
}

export function deletePoleThunk(id: string) {
  return async (dispatch: AppDispatch) => {
    try {
      await axiosInstance.delete(`/poles/${id}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      });
      dispatch(poleActions.deletePole(id));
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Pole supprimée avec succès",
        })
      );
    } catch (e: any) {
      if (e.response.status === 409) {
        dispatch(
          feedbackMessageUIActions.setState({
            open: true,
            message:
              "Cette donnée est utilisée, vous ne pouvez pas la supprimer",
          })
        );
      } else {
        dispatch(
          feedbackMessageUIActions.setState({
            open: true,
            message:
              "Impossible de supprimer un pole s'il vous plaît réessayer plus tard",
          })
        );
      }

    }
  };
}

type EditPoleResponse = {
  data: Pole;
};

export function editPoleThunk(obj: { id: string; pole: Omit<Pole, "id"> }) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.put<EditPoleResponse>(
        `/poles/${obj.id}`,
        obj.pole,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
          },
        }
      );
      const editedPole = response.data.data;
      dispatch(poleActions.editPole({ id: obj.id, pole: editedPole }));
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "pole mis à jour avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message:
            "Impossible de mettre à jour un pole s'il vous plaît réessayer plus tard",
        })
      );
    }
  };
}
