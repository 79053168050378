import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { USER_ROLES } from "utils/roles";
import { users as usersFakeData } from "utils/fakeData";
import { Civilite } from "store/civilite/civilite.state";
import { Pole } from "store/pole/pole.state";
import { Poste } from "store/postes/postes.state";
import { Role } from "store/roles/roles.state";

export type User = {
  id: string;
  avatarUrl: string;
  // fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  // role: string;
  role: Role;
  tel: string;
  // civilite: string;
  // position: string;
  civilite: Civilite;
  position: Poste;
  joinDate: string;
  lastLogin: string;
  pole: Pole;
};

export type Users = {
  selectedUserToEdit: User | null;
  list: User[];
};

// a test initial state
let initialState: Users = {
  selectedUserToEdit: null,
  list: [],
};

const usersSlice = createSlice({
  name: "users",
  initialState: initialState,
  reducers: {
    setList(state, action: PayloadAction<User[]>) {
      state.list = action.payload;
    },
    addUser(state, action: PayloadAction<User>) {
      state.list.push(action.payload);
    },
    deleteUser(state, action: PayloadAction<string>) {
      // pauload: user id
      state.list = state.list.filter((user) => user.id !== action.payload);
    },
    editUser(state, action: PayloadAction<{ id: string; user: User }>) {
      state.list = state.list.map((user) => {
        if (user.id === action.payload.id) return action.payload.user;
        return user;
      });
    },
    selectUsertoEdit(state, action: PayloadAction<string>) {
      // payload: user id
      // NOTE: object spread doesnt deep copy objects
      state.selectedUserToEdit = {
        ...state.list.find((user) => user.id === action.payload)!,
      };
    },
    clearSelectedUserToEdit(state) {
      state.selectedUserToEdit = null;
    },
  },
});

export const usersActions = usersSlice.actions;
export const usersReducer = usersSlice.reducer;
