import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Pole } from "store/pole/pole.state";
import { Supplier } from "store/suppliers/suppliers.state";
import { IInvoice } from "store/invoices/invoice.state";
import { IPaymentTerm } from "store/payment-terms/paymentTerms.state";
import { Session } from "store/session/session.state";
import { Project } from "store/project/project.state";
import { Perimeter } from "store/perimeter/perimeters.state";
import { TypeDepense } from "store/typedepenses/typedepenses.state";

export type Order = {
  id: string;
  _id: string;
  designation: string;
  BDC_NUM: string;
  userRef: string;
  paymentTerm: IPaymentTerm;
  session: Session;
  project: Project;
  perimeter: Perimeter;
  pole: Pole;
  typeDepense: TypeDepense;
  GTTC: number;
  GHT: number;
  MTVA: number;
  missionInformation: string;
  startMission: string;
  endMission: string;
  description: string;
  reste: number;
  documentDate: string;
  // pole: Pole;
  invoices?: IInvoice[];
  articles: any[];
  raisonSocial: string;
  FRN: Supplier;
  status: OrderStatusEnum;
  prestationOuAchat?: string;
  startDate?: string,
  endDate?: string,
};

export enum OrderStatusEnum {
  Actif = 'Actif',
  Annulé = 'Annulé',
  Clôturé = 'Clôturé',
}

export type Orders = {
  selectedOrderToEdit: Order | null;
  pdf: string,
  list: Order[];
};

// a test initial state
const initialState: Orders = {
  selectedOrderToEdit: null,
  pdf: "",
  list: [],
};

const ordersSlice = createSlice({
  name: "orders",
  initialState: initialState,
  reducers: {
    setList(state, action: PayloadAction<Order[]>) {
      state.list = action.payload;
    },
    addOrder(state, action: PayloadAction<Order>) {
      state.list.push(action.payload);
    },
    deleteOrder(state, action: PayloadAction<string>) {
      // pauload: user id
      state.list = state.list.filter((order) => order.id !== action.payload);
    },
    pdfOrder(state, action: PayloadAction<Order>) {
      state.selectedOrderToEdit = action.payload
    },
    editOrder(state, action: PayloadAction<{ id: string; order: Order }>) {
      state.list = state.list.map((order) => {
        if (order.id === action.payload.id) return action.payload.order;
        return order;
      });
    },
    selectOrdertoEdit(state, action: PayloadAction<Order>) {
      // payload: order id
      // NOTE: object spread doesnt deep copy objects
      state.selectedOrderToEdit = action.payload
    },
    clearSelectedOrderToEdit(state) {
      state.selectedOrderToEdit = null;
    },
  },
});

export const ordersActions = ordersSlice.actions;
export const ordersReducer = ordersSlice.reducer;
