import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Civilite = {
  id: string;
  name: string;
  description: string;
  _id?: string; //<--- using this in edit/add user form 
};

type InitialState = {
  selectedCiviliteToEdit: Civilite | null;
  list: Civilite[];
};

const initialState: InitialState = {
  selectedCiviliteToEdit: null,
  list: [],
};

const civiliteSlice = createSlice({
  name: "civilite",
  initialState,
  reducers: {
    setList(state, action: PayloadAction<Civilite[]>) {
      state.list = action.payload;
    },
    addCivilite(state, action: PayloadAction<Civilite>) {
      state.list.push(action.payload);
    },
    deleteCivilite(state, action: PayloadAction<string>) {
      // pauload: user id
      state.list = state.list.filter((civilite) => civilite.id !== action.payload);
    },
    editCivilite(state, action: PayloadAction<{ id: string; civilite: Civilite }>) {
      state.list = state.list.map((civilite) => {
        if (civilite.id === action.payload.id) return action.payload.civilite;
        return civilite;
      });
    },
    selectCivilitetoEdit(state, action: PayloadAction<string>) {
      // payload: user id
      // NOTE: object spread doesnt deep copy objects
      state.selectedCiviliteToEdit = {
        ...state.list.find((civilite) => civilite.id === action.payload)!,
      };
    },
    clearSelectedCiviliteToEdit(state) {
      state.selectedCiviliteToEdit = null;
    },
  },
});

export const civiliteActions = civiliteSlice.actions;
export const civiliteReducer = civiliteSlice.reducer;
