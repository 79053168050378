import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import { useAppSelector, useAppDispatch } from "hooks/redux.hook";
import { Poste, postesActions } from "store/postes/postes.state";
import { deletePosteThunk } from "store/postes/postes.thunk";
import { uiActions } from "store/ui/ui.state";
import { Role } from "store/roles/roles.state";
import BlueButton from "components/blue-button/BlueButton.component";
import RedButton from "components/red-button/RedButton";

export default function List() {
  const dispatch = useAppDispatch();
  const list = useAppSelector((state) => state.postes.list);

  const authenticatedUser = useAppSelector((state) => state.auth.user);

  const handleDelete = (id: string) => {
    // dispatch(postesActions.deletePoste(id));
    // dispatch(deletePosteThunk(id));
    dispatch(uiActions.setDeletePosteWarningOpen({ set: true, id: id }));
  };

  const handleEdit = (id: string) => {
    dispatch(postesActions.selectPostetoEdit(id));
  };

  return (
    <>
      {list.length === 0 && "Il n'y a pas de postes"}
      <Grid container spacing="20px" alignItems="stretch">
        {list.map((poste) => (
          <Grid key={poste.id} item xs={12} md={6} lg={4} xl={3}>
            <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }} elevation={0}>
              <CardContent sx={{flex: 1}}>
                <Typography variant="h5" component="div" sx={{color: "#3F4254"}}>
                  {poste.name}
                </Typography>
                <Typography variant="body2" sx={{color: "#7E8299"}}>{poste.description}</Typography>
              </CardContent>
              <CardActions>
                <BlueButton
                  onClick={() => handleEdit(poste.id)}
                  size="small"
                  variant="contained"
                  sx={{
                    marginRight: "10px",
                    background: "#00A3FF",
                    boxShadow: "none",
                    "&:hover": {
                      background: "#00A3FF",
                      boxShadow: "none",
                    },
                  }}
                  disabled={
                    !(authenticatedUser!.role as Role).managmentRules.includes(
                      "update"
                    )
                  }
                >
                  Edition
                </BlueButton>
                <RedButton
                  onClick={() => handleDelete(poste.id)}
                  size="small"
                  variant="contained"
                  sx={{
                    background: "#FE7373",
                    boxShadow: "none",
                    "&:hover": {
                      background: "#FE7373",
                      boxShadow: "none",
                    },
                  }}
                  disabled={
                    !(authenticatedUser!.role as Role).managmentRules.includes(
                      "delete"
                    )
                  }
                >
                  Suppression
                </RedButton>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
