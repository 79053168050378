import styled from "@emotion/styled";
import { drawerWidth } from "./variables";
import { OpenProp } from "./variables";

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})<OpenProp>(({ theme, open }) => ({
  // minHeight: "80vh",
  flexGrow: 1,
  // padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

export default Main;
