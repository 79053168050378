import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Field, FormikProvider, useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import * as Yup from "yup";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import React, { useRef, useState } from "react";
import {
  editInvoiceThunk,
  getInvoicesByVendorIdThunk,
  getInvoicesThunk,
} from "store/invoices/invoice.thunk";
import GrayButton from "components/gray-button/GrayButton.component";
import BlueButton from "components/blue-button/BlueButton.component";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
import { IInvoice } from "store/invoices/invoice.state";
import { ITva } from "store/tva/tva.state";
import { validDate } from "utils/globalVariables";
import { Order } from "store/orders/orders.state";

interface IEditInvoiceFormProps {
  handleCloseEditDialog: () => void;
}

type ErrorProp = boolean | undefined;

const EditInvoice: React.FC<IEditInvoiceFormProps> = ({
  handleCloseEditDialog,
}) => {
  const dispatch = useAppDispatch();
  const suppList = useAppSelector((state) => state.suppliers.list)!;
  const orderList = useAppSelector((state) => state.orders.list)!;
  const tvaList = useAppSelector((state) => state.tvas.list)!;

  const authenticatedUser: any = useAppSelector((state) => state.auth.user)!;
  const accountType = useAppSelector((state) => state.auth.accountType)!;
  const selectedInvoice: any = useAppSelector(
    (state) => state.invoices.selectedInvoice
  )!;
  const [invoiceInputValue, setInvoiceInputValue] = useState<any>(
    (selectedInvoice as IInvoice).invoiceDocUrl
  );

  const invoiceInputRef = useRef<HTMLInputElement>(null);
  const [showRestError, setShowRestError] = useState<boolean>(false);

  const calculateTTC = () => {
    const TTC =
      (formik.values.amount as number) +
      (0 *
        (formik.values.amount as number)) /
      100;
    formik.values.TTC = TTC;
    return Math.round((TTC + Number.EPSILON) * 100) / 100;
  };
  const calculateReste = () => {
    const MBDC = orderList.filter(
      (item: any) =>
        item._id === formik.values.purchaseOrder
    )[0]?.GTTC ?? 0
    const reste = orderList.filter(
      (item: any) =>
        item._id === formik.values.purchaseOrder
    )[0]?.reste ?? 0

    if (reste !== 0) {
      const resteCalculted = reste - calculateTTC() + selectedInvoice.amount
      formik.values.invoiceRest = resteCalculted
      return Math.round((resteCalculted + Number.EPSILON) * 100) / 100;;
    } else {
      const resteCalculted = MBDC - calculateTTC() + selectedInvoice.amount
      formik.values.invoiceRest = resteCalculted
      return Math.round((resteCalculted + Number.EPSILON) * 100) / 100;;
    }
  };

  const formik = useFormik({
    initialValues: {
      invoiceDate: `${validDate(selectedInvoice?.invoiceDate)}`,
      purchaseOrder: selectedInvoice.purchaseOrder?._id,
      fournisseur: selectedInvoice.fournisseur?.id,
      amount: selectedInvoice.amount,
      status: selectedInvoice.status,
      invoiceName: selectedInvoice.invoiceName,
      invoiceRest: 0,
      // TVA: selectedInvoice.TVA?._id,
      TTC: selectedInvoice.TTC,
    },
    validationSchema: Yup.object({
      invoiceDate: Yup.string().required("Date obligatoire"),
      purchaseOrder: Yup.string().required("Order obligatoire"),
      fournisseur: Yup.string().required("Fournisseur obligatoire"),
      amount: Yup.number().min(1, 'Montant doit être supérieur à zéro')
        .required("Montant obligatoire"),
      // TVA: Yup.string().required("Montant obligatoire"),
      status: Yup.string().required("Status obligatoire"),
      invoiceName: Yup.string().required("Nom obligatoire"),
    }),
    // enableReinitialize: true,
    onSubmit: async (values) => {
      if (values.status === "Créée") {
        if (values.invoiceRest < 0) {
          setShowRestError(true);
          return;
        } else {
          setShowRestError(false);
        }
        await dispatch(
          editInvoiceThunk({
            id: selectedInvoice?._id,
            invoice: values as any,
            file: invoiceInputRef.current!.files![0],
          })
        );
        handleCloseEditDialog();
        if (accountType === "vendor") {
          await dispatch(getInvoicesByVendorIdThunk(authenticatedUser.id));
        } else {
          await dispatch(getInvoicesThunk());
        }
      }
    },
  });

  const showStatusError = (formik.errors.status &&
    formik.touched.status) as ErrorProp;
  // const showTVAError = (formik.errors.TVA && formik.touched.TVA) as ErrorProp;
  const showAmountError = (formik.errors.amount &&
    formik.touched.amount) as ErrorProp;
  const showFournisseurError = (formik.errors.fournisseur &&
    formik.touched.fournisseur) as ErrorProp;
  const showInvoiceDateError = (formik.errors.invoiceDate &&
    formik.touched.invoiceDate) as ErrorProp;
  const showInvoiceNameError = (formik.errors.invoiceName &&
    formik.touched.invoiceName) as ErrorProp;
  const showPurchaseOrderError = (formik.errors.purchaseOrder &&
    formik.touched.purchaseOrder) as ErrorProp;

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormikProvider value={formik}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={4}
          style={{ width: "40vw", margin: "3% auto" }}
        >
          <Grid item xs={12}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={12}>
                <Typography component="span" fontWeight="bold">
                  Nom du document{" "}
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  {...formik.getFieldProps("invoiceName")}
                  type="text"
                  error={showInvoiceNameError}
                  style={{
                    width: "37vw",
                    height: "42px",
                    color: "#5E6278",
                    fontSize: "15px",
                    fontWeight: "500",
                    padding: "0 3%",
                    backgroundColor: "#F5F8FA",
                    border: "none",
                  }}
                // InputLabelProps={{
                //   shrink: true,
                // }}
                />
                <FormHelperText error>
                  {showInvoiceNameError &&
                    formik.errors.invoiceName?.toString()}{" "}
                </FormHelperText>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ width: "38vw" }}
            >
              <Grid item xs={6}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  style={{ width: "18vw" }}
                >
                  <Grid item xs={12}>
                    <Typography component="span" fontWeight="bold">
                      Date de facture{" "}
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      {...formik.getFieldProps("invoiceDate")}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      error={showInvoiceDateError}
                      style={{
                        width: "18vw",
                        padding: "3%",
                        height: "42px",
                        color: "#5E6278",
                        backgroundColor: "#F5F8FA",
                      }}
                      size="small"
                      type="date"
                    />{" "}
                    <FormHelperText error>
                      {showInvoiceDateError &&
                        formik.errors.invoiceDate?.toString()}{" "}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  style={{ width: "18vw" }}
                >
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                    >
                      <Grid item xs={12}>
                        <Typography component="span" fontWeight="bold">
                          Fournisseur{" "}
                          {/* <Typography component="span" color="error">
                            *
                          </Typography> */}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {accountType === "vendor" ? (
                          <>
                            <Field
                              render={() => {
                                <TextField
                                  fullWidth
                                  label="Fournisseur"
                                  style={{ width: "18vw", height: "42px" }}
                                  {...formik.getFieldProps("fournisseur")}
                                  type="text"
                                />;
                              }}
                            />
                            <TextField
                              {...formik.getFieldProps("fournisseur")}
                              placeholder="Fournisseur"
                              disabled
                              style={{ width: "18vw", height: "42px" }}
                              value={`${authenticatedUser.nom} ${authenticatedUser.nom}`}
                              size="small"
                              type="text"
                            />
                          </>
                        ) : (
                          <>
                            <FormControl
                              size="small"
                              sx={{
                                "& .MuiSelect-select": { padding: "10px" },
                              }}
                            >
                              <Select
                                {...formik.getFieldProps("fournisseur")}
                                onChange={formik.handleChange}
                                // label="civilitesss"
                                error={showFournisseurError}
                                variant="filled"
                                sx={{
                                  color: "#5E6278",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  width: "18vw",

                                  background: "#F5F8FA",
                                  "&:before": {
                                    border: "none",
                                  },
                                  "&:after": {
                                    border: "none",
                                  },
                                }}
                              >
                                {suppList.map((supp) => (
                                  <MenuItem key={supp.id} value={supp.id}>
                                    {supp.nom} {supp.prenom}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormHelperText error>
                              {formik.errors.fournisseur?.toString()}
                            </FormHelperText>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ width: "38vw" }}
            >
              <Grid item xs={6}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Grid item xs={12}>
                    <Typography component="span" fontWeight="bold">
                      Numéro de bon de commande associé {" "}
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl size="small">
                      <Select
                        {...formik.getFieldProps("purchaseOrder")}
                        onChange={formik.handleChange}
                        variant="standard"
                        error={showPurchaseOrderError}
                        sx={{
                          width: "18vw",
                          height: "40px",
                          fontWeight: "500",
                          padding: "4%",
                          borderRadius: "6px",
                          fontSize: "14px",
                          color: "#5E6278",
                          background: "#F5F8FA",
                          "&:before": {
                            border: "none",
                          },
                          "&:after": {
                            border: "none",
                          },
                        }}
                      >
                        {orderList
                          .filter((item) => item.status === "Actif")
                          .map((order) => (
                            <MenuItem key={order._id} value={order._id}>
                              {order.BDC_NUM}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText error>
                        {formik.errors.purchaseOrder?.toString()}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid>
                  <Grid item xs={12}>
                    <Typography component="span" fontWeight="bold">
                      Montant{" "}
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      type="number"
                      error={showAmountError}
                      style={{
                        width: "18vw",
                        height: "42px",
                        color: "#5E6278",
                        backgroundColor: "#F5F8FA",
                        border: "none",
                        paddingLeft: "4%",
                      }}
                      {...formik.getFieldProps("amount")}
                    />
                    <FormHelperText error>
                      {showAmountError && formik.errors.amount?.toString()}{" "}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ width: "38vw" }}
            >
              <Grid item xs={6}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  style={{ width: "38vw" }}
                >
                  <Grid item xs={6}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item xs={12}>
                        <Typography component="span" fontWeight="bold">
                          Téléverser la Facture{" "}
                          <Typography component="span" color="error">
                            *
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Button variant="contained" component="label">
                          <KeyboardArrowDownIcon />
                          <input
                            hidden
                            ref={invoiceInputRef}
                            type="file"
                            onChange={() =>
                              setInvoiceInputValue({ ...invoiceInputRef })
                            }
                          />
                        </Button>
                        <br />
                        <span>
                          {invoiceInputValue.current?.value.split("\\")[
                            invoiceInputValue.current?.value.split("\\").length - 1
                          ] ??
                            selectedInvoice.invoiceDocUrl.split("/")[
                            selectedInvoice.invoiceDocUrl.split("/").length - 1
                            ]}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={6}>
                <Grid>
                  <Grid item xs={12}>
                    <Typography component="span" fontWeight="bold">
                      TVA{" "}
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl size="small">
                      <Select
                        {...formik.getFieldProps("TVA")}
                        onChange={formik.handleChange}
                        variant="standard"
                        error={showTVAError}
                        sx={{
                          width: "18vw",
                          height: "40px",
                          fontWeight: "500",
                          padding: "4%",
                          borderRadius: "6px",
                          fontSize: "14px",
                          color: "#5E6278",
                          background: "#F5F8FA",
                          "&:before": {
                            border: "none",
                          },
                          "&:after": {
                            border: "none",
                          },
                        }}
                      >
                        {tvaList.map((tva) => (
                          <MenuItem key={tva._id} value={tva._id}>
                            {tva.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormHelperText error>
                      {showTVAError && formik.errors.TVA?.toString()}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </Grid> */}
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid>
                  <Grid item xs={12}>
                    <Typography component="span" fontWeight="bold">
                      Montant BDC (en € TTC){" "}
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      type="number"
                      value={
                        orderList.filter(
                          (item: any) =>
                            item._id === formik.values.purchaseOrder
                        )[0]?.GTTC ?? 0
                      }
                      style={{
                        width: "18vw",
                        height: "42px",
                        color: "#5E6278",
                        backgroundColor: "#F5F8FA",
                        border: "none",
                        paddingLeft: "4%",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} >
              </Grid>
              {/* empty elements serves to adjust */}
              <div style={{ margin: '10px 0' }}>&#x9;</div>
              <Grid item xs={6} ></Grid>
              <Grid item xs={6} ></Grid>
              <Grid item xs={6}>
                <Grid>
                  <Grid item xs={12}>
                    <Typography component="span" fontWeight="bold">
                      Reste à facturer{" "}
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      type="number"
                      value={calculateReste() ?? 0}
                      // value={
                      //   orderList.filter(
                      //     (item: any) =>
                      //       item._id === formik.values.purchaseOrder
                      //   )[0]?.reste ?? 0
                      // }
                      style={{
                        width: "18vw",
                        height: "42px",
                        color: "#5E6278",
                        backgroundColor: "#F5F8FA",
                        border: "none",
                        paddingLeft: "4%",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              style={{ width: "37vw" }}
            >
              <Grid item xs={6}>
                <Grid>
                  <Grid item xs={12}>
                    <Typography component="span" fontWeight="bold">
                      Montant TTC{" "}
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type="number"
                      size="small"
                      {...formik.getFieldProps("TTC")}
                      value={calculateTTC() ?? 0}
                      style={{
                        width: "18vw",
                        height: "42px",
                        color: "#5E6278",
                        border: "none",
                        paddingLeft: "4%",
                      }}
                    />
                    <FormHelperText error>
                      {showRestError && (
                        <span>
                          {" "}
                          Le Montant TTC ne peut pas être supérieur à “Reste à
                          facturer”{" "}
                        </span>
                      )}{" "}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}

          {/* <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              style={{ width: "37vw" }}
            >
              <GrayButton onClick={handleCloseEditDialog}>Annuler</GrayButton>

              <BlueButton type="submit">Soumettre</BlueButton>
            </Grid>
          </Grid> */}
          <div style={{ position: "absolute", bottom: "0", left: "0", background: "white", right: "0", display: "flex", padding: "10px", justifyContent: "center", alignItems: "center" }} >
            <GrayButton style={{ marginRight: "40px" }} onClick={handleCloseEditDialog}>Annuler</GrayButton>

            <BlueButton style={{ marginLeft: "40px" }} variant="contained" sx={{ textTransform: "none" }} type="submit">Soumettre</BlueButton>
          </div>
        </Grid>
      </FormikProvider>
    </form>
  );
};

export default EditInvoice;
