import styled from "@emotion/styled";
import Badge, { BadgeProps } from "@mui/material/Badge";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const ProfileAvatarBadge = styled(Badge)<BadgeProps>((props) => ({
  "& .MuiBadge-badge": {
    right: "0px",
    top: 160-27,
    backgroundColor: true ? "#50CD89" : "transparent",
    // border: `2px solid ${props.theme.palette.background.paper}`,
    padding: "8px",
    borderRadius: "50%",
  },
}));

export default ProfileAvatarBadge;
