import { useAppSelector, useAppDispatch } from "hooks/redux.hook";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { Role } from "store/roles/roles.state";
import { uiActions } from "store/ui/ui.state";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";
import { IInvoice, InvoiceStatusEnum } from "store/invoices/invoice.state";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
interface ICustomToolbarProps {
  exportXLSX?: any;
  exportCSV?: any;
  invoicesData?: any;
}

export const CustomToolbar: React.FC<ICustomToolbarProps> = ({
  exportXLSX,
  invoicesData,
  exportCSV,
}) => {
  const invoices = useAppSelector((state) => state.invoices.list);
  const authenticatedUser = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing="20px"
      mt={3}
      mb={3}
    >
      <Grid item>
        <Typography
          component="span"
          fontWeight="500"
          fontSize="20px"
          lineHeight="23px"
          sx={{ color: "#181C32" }}
        >
          Factures ({invoices.length})
        </Typography>
      </Grid>
      {(authenticatedUser?.role as Role)?.name === "Administrateur" && (
        <Grid item>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              backgroundColor: "white",
              paddingTop: "1.5%",
              paddingBottom: "1.5%",
              borderRadius: "6px",
            }}
            pl={4}
          >
            <Typography
              variant="button"
              style={{ textTransform: "capitalize" }}
              mr={4}
            >
              Créée :{" "}
              {
                invoices.filter(
                  (invoice: IInvoice) =>
                    invoice.status === InvoiceStatusEnum.Créée
                ).length
              }
            </Typography>
            <Typography
              variant="button"
              style={{ textTransform: "capitalize" }}
              mr={4}
            >
              Validée :{" "}
              {
                invoices.filter(
                  (invoice: IInvoice) =>
                    invoice.status === InvoiceStatusEnum.Validee
                ).length
              }
            </Typography>
            <Typography
              variant="button"
              style={{ textTransform: "capitalize" }}
              mr={4}
            >
              Réceptionnée :{" "}
              {
                invoices.filter(
                  (invoice: IInvoice) =>
                    invoice.status === InvoiceStatusEnum.Réceptionnée
                ).length
              }
            </Typography>
            <Typography
              variant="button"
              style={{ textTransform: "capitalize" }}
              mr={4}
            >
              En paiement :{" "}
              {
                invoices.filter(
                  (invoice: IInvoice) =>
                    invoice.status === InvoiceStatusEnum.Enpaiement
                ).length
              }
            </Typography>
            <Typography
              variant="button"
              style={{ textTransform: "capitalize" }}
              mr={4}
            >
              Payée :{" "}
              {
                invoices.filter(
                  (invoice: IInvoice) =>
                    invoice.status === InvoiceStatusEnum.Payée
                ).length
              }
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid item>
        <Button
          sx={{
            boxShadow: "none",
            backgroundColor: "#1DC894",
            textTransform: "none",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "#1DC894",
            },
          }}
          variant="contained"
          title="Ajouter une facture"
          onClick={() => dispatch(uiActions.setAddInvoiceFormOpen(true))}
          //</Grid>disabled={
          //    !(authenticatedUser!.role as Role).userManagment.includes(
          //</Grid>      "create"
          //     )
          //   }
        >
          Ajouter une facture
        </Button>

        <Button
          sx={{
            marginLeft: "5px",
          }}
          variant="outlined"
          // title="Ajouter une facture"
          onClick={() => exportXLSX(invoicesData)}
        >
          xlsx
          {/* <FileDownloadIcon /> */}
        </Button>
        <Button
          sx={{
            marginLeft: "5px",
          }}
          variant="outlined"
          // title="Ajouter une facture"
          onClick={exportCSV}
        >
          csv
        </Button>
      </Grid>
    </Grid>
  );
};
