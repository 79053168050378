import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Project = {
  id: string;
  name: string;
  _id?: string; //<--- using this in edit/add user form
};

type InitialState = {
  selectedProjectToEdit: Project | null;
  projectList: Project[];
};

const initialState: InitialState = {
  selectedProjectToEdit: null,
  projectList: []
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProjectList(state, action: PayloadAction<Project[]>) {
      state.projectList = action.payload;
    },
    addProject(state, action: PayloadAction<Project>) {
      state.projectList.push(action.payload);
    },
    deleteProject(state, action: PayloadAction<string>) {
      // pauload: user id
      state.projectList = state.projectList.filter((project) => project.id !== action.payload);
    },
    editProject(state, action: PayloadAction<{ id: string; project: any }>) {
      state.projectList = state.projectList.map((project) => {
        if (project._id === action.payload.id) return action.payload.project;
        return project;
      });
    },
    selectProjectToEdit(state, action: PayloadAction<string>) {
      // payload: user id
      // NOTE: object spread doesnt deep copy objects
      state.selectedProjectToEdit = {
        ...state.projectList.find((project) => project._id === action.payload)!,
      };
    },
    clearSelectedProjectToEdit(state) {
      state.selectedProjectToEdit = null;
    },
  },
});

export const projectActions = projectSlice.actions;
export const projectReducer = projectSlice.reducer;
