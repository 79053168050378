import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "store/users/users.state";
import { USER_ROLES } from "utils/roles";

export type Permissions = ("read" | "update" | "create" | "delete")[];

export type Role = {
  id: string;
  name: string;
  description: string;
  userManagment: Permissions;
  supplierManagment: Permissions;
  roleManagment: Permissions;
  managmentRules: Permissions;
  billManagment: Permissions;
  orderManagment: Permissions;
  APIConnection: Permissions;
  assignedUsers: User[];
  // assignedUsersObjects: User[];
  _id?: string;
  // contentManagment: Permissions;
  // financialManagment: Permissions;
  // reporting: Permissions;
  // payroll: Permissions;
  // disputesManagment: Permissions;
  // APIControls: Permissions;
  // databaseManagment: Permissions;
  // repositoryManagment: Permissions;
};
type Roles = {
  userNameFilterValue: string;
  roleNameFilterValue: string;
  selectedRoleToEdit: Role | null;
  selectedRoleToDelete: Role | null;
  list: Role[];
  assignedUsersObjects: User[];
};

const initialState: Roles = {
  userNameFilterValue: "",
  roleNameFilterValue: "",
  selectedRoleToEdit: {
    id: "",
    name: "",
    description: "",
    userManagment: [],
    supplierManagment: [],
    roleManagment: [],
    managmentRules: [],
    billManagment: [],
    orderManagment: [],
    APIConnection: [],
    assignedUsers: [],
  },
  selectedRoleToDelete: null,
  list: [],
  assignedUsersObjects: [],
};

const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    setList(state, action: PayloadAction<Role[]>) {
      state.list = action.payload;
    },
    setAssignedUsersOfARole(
      state,
      action: PayloadAction<{ roleId: string; users: User[] }>
    ) {
      state.list = state.list.map((role) => {
        if (role.id !== action.payload.roleId) return role;
        else
          return {
            ...role,
            assignedUsersObjects: action.payload.users,
          };
      });
      state.assignedUsersObjects = action.payload.users;
    },
    addRole(state, action: PayloadAction<Role>) {
      state.list.push(action.payload);
    },
    editRole(state, action: PayloadAction<{ id: string; role: Role }>) {
      state.list = state.list.map((role) => {
        if (role.id === action.payload.id) return action.payload.role;
        return role;
      });
    },
    deleteRole(state, action: PayloadAction<string>) {
      // payload: id
      state.list = state.list.filter((role) => role.id !== action.payload);
    },
    setRoleNameFilterValueFilterValue(state, action: PayloadAction<string>) {
      state.roleNameFilterValue = action.payload;
    },
    selectRoleToEdit(state, action: PayloadAction<string>) {
      state.selectedRoleToEdit = state.list.find(
        (role) => role.id === action.payload
      )!;
    },
    selectRoleToDelete(state, action: PayloadAction<string>) {
      state.selectedRoleToDelete = state.list.find(
        (role) => role.id === action.payload
      )!;
    },
    setUserNameFilterValue(state, action: PayloadAction<string>) {
      state.userNameFilterValue = action.payload;
    },
    // removeAssignedUser(
    //   state,
    //   action: PayloadAction<{ roleId: string; userId: string }>
    // ) {
    //   let role = state.list.find((role) => role.id === action.payload.roleId)!;
    //   role.assignedUsers = role.assignedUsers.filter(
    //     (user) => user.id !== action.payload.userId
    //   );
    // },
    removeAssignedUser(state, action: PayloadAction<string>) {
      state.assignedUsersObjects = state.assignedUsersObjects.filter(
        (user) => user.id !== action.payload
      );
    },
  },
});

export const rolesActions = rolesSlice.actions;
export const rolesReducer = rolesSlice.reducer;
