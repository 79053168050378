import { axiosInstance } from "api/axiosInstance";
import { feedbackMessageUIActions } from "store/feedback-message-ui/feedbackMessageUI.state";
import { AppDispatch } from "store/store.state";
import { IPaymentDate, paymentDatesActions } from "./paymentDates.state";


export function getPaymentDatesThunk() {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axiosInstance.get(
                "/paymentdates",
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                    },
                }
            );
            const paymentDates: IPaymentDate[] = response.data.data;
            dispatch(paymentDatesActions.setList(paymentDates));
        } catch (e) {
            console.log(e);
        }
    };
}

export function addpaymentDateThunk(paymentDate: Omit<IPaymentDate, "id">) {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axiosInstance.post(
                "/paymentdates",
                paymentDate,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                    },
                }
            );
            const completePaymentDate = response.data.data;
            dispatch(paymentDatesActions.addPaymentDate(completePaymentDate));
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message: "Date de paiement ajoutée avec succès",
                })
            );
        } catch (e) {
            console.log(e);
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message:
                        "Impossible d'ajouter Date de paiement s'il vous plaît réessayer plus tard",
                })
            );
        }
    };
}

export function editPaymentDateThunk(obj: { id: string; paymentDate: Omit<IPaymentDate, "id"> }) {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axiosInstance.put(
                `/paymentdates/${obj.id}`,
                obj.paymentDate,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                    },
                }
            );
            const editedPaymentDate = response.data.data;
            dispatch(paymentDatesActions.editPaymentDate({ id: obj.id, paymentDate: editedPaymentDate }));
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message: "Date de paiement mis à jour avec succès",
                })
            );
        } catch (e) {
            console.log(e);
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message:
                        "Impossible de mettre à jour Date de paiement s'il vous plaît réessayer plus tard",
                })
            );
        }
    };
}


export function deletePaymentDateThunk(id: string) {
    return async (dispatch: AppDispatch) => {
        try {
            const response: any = await axiosInstance.delete(`/paymentdates/${id}`, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                },
            });

            dispatch(paymentDatesActions.deletePaymentDate(id));
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message: "Date de paiement supprimée avec succès",
                })
            );
        } catch (e: any) {
            if (e.response.status === 409) {
                dispatch(
                    feedbackMessageUIActions.setState({
                        open: true,
                        message:
                            "Cette donnée est utilisée, vous ne pouvez pas la supprimer",
                    })
                );
            } else {
                dispatch(
                    feedbackMessageUIActions.setState({
                        open: true,
                        message:
                            "Impossible de supprimer Date de paiement s'il vous plaît réessayer plus tard",
                    })
                );
            }

        }
    };
}