import { useMemo } from "react";
import {
  GridColumns,
  GridValueFormatterParams,
  GridActionsCellItem,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

import DeleteIcon from "@mui/icons-material/Delete";

import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import type { User } from "store/users/users.state";
import { useParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { uiActions } from "store/ui/ui.state";
import { Role } from "store/roles/roles.state";
import { removeRoleFromUserThunk } from "store/users/users.thunk";

export default function useColumns() {
  const roleId = useParams().id!;
  const dispatch = useAppDispatch();
  const authenticatedUser = useAppSelector((state) => state.auth.user);

  return useMemo<GridColumns<User>>(
    () => [
      {
        // type: "actions",
        field: "actions",
        align: "center",
        headerName: "",
        filterable: false,
        // flex: 1,
        // headerClassName: "gray-bold actions-p-r",
        // cellClassName: "align-right",
        width: 50,
        // headerAlign: "right",
        renderCell: (params) => (
          <Grid container>
            <Grid item>
              <IconButton
                size="small"
                title="Supprimer le rôle pour cet utilisateur"
                sx={{ color: "#FE7373" }}
                disabled={
                  params.row.id === authenticatedUser!.id ||
                  !(authenticatedUser!.role as Role).userManagment.includes(
                    "update"
                  )
                }
                onClick={() => {
                  dispatch(removeRoleFromUserThunk(params.row.id, roleId));
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ),
      },
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        width: 200,
      },

      {
        type: "string",
        field: "UTILISATEUR",
        headerAlign: "left",
        width: 400,
        disableExport: true,
        filterable: false,
        headerClassName: "gray-bold",
        valueGetter: (params) =>
          params.row.lastName + " " + params.row.firstName, //this is for sorting
        renderCell: (params) => (
          <Grid
            container
            alignItems="center"
            gap="5px"
            flexWrap="nowrap"
            overflow="hidden"
          >
            <Grid item marginRight="5px">
              <Avatar
                src={params.row.avatarUrl}
                sx={{ width: "45px", height: "45px" }}
              />
            </Grid>
            <Grid>
              <Typography
                fontWeight="700"
                fontSize="15px"
                lineHeight="18px"
                sx={{ color: "#3F4254", marginBottom: "5px" }}
              >
                {params.row.lastName + " " + params.row.firstName}
              </Typography>
              <Typography
                fontWeight="500"
                fontSize="13px"
                lineHeight="15px"
                sx={{ color: "#B5B5C3" }}
              >
                {params.row.email}
              </Typography>
            </Grid>
          </Grid>
        ),
      },
      {
        field: "firstName",
        headerName: "Prénom",
        hideable: false,
      },
      {
        field: "lastName",
        headerName: "Nom",
        hideable: false,
      },
      {
        field: "email",
        headerName: "E-mail",
        hideable: false,
      },
      {
        type: "date",
        field: "joinDate",
        headerName: "DATE D'ADHÉSION",
        headerClassName: "gray-bold",
        cellClassName: "cell",
        width: 200,
        headerAlign: "left",
        valueGetter: (params) => (params.value ? new Date(params.value) : ""),
        valueFormatter: (params: GridValueFormatterParams<Date>) => {
          const { value } = params;
          const month = Intl.DateTimeFormat("en-US", { month: "short" }).format(
            value
          );
          const date = value.getDate();
          const year = value.getFullYear();
          return date + " " + month + " " + year;
        },
      },
    ],
    []
  );
}
