import { useAppSelector, useAppDispatch } from "hooks/redux.hook";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import CustomDialogTitle from "components/custom-dialog-title/CustomDialogTitle.component";

import { uiActions } from "store/ui/ui.state";
import { useNavigate, useParams } from "react-router-dom";
import DialogActions from "@mui/material/DialogActions";
import BlueButton from "components/blue-button/BlueButton.component";
import GrayButton from "components/gray-button/GrayButton.component";
import { deleteRoleThunk } from "store/roles/roles.thunk";
import { Role } from "store/roles/roles.state";
import WhiteButton from "components/white-button/WhiteButton.component";



export default function DeleteRoleDialog() {
    const navigate = useNavigate();
    const { id } = useParams();
    const selectedRoleToDelete = useAppSelector(state => state.roles.selectedRoleToDelete)
    const dispatch = useAppDispatch();
    const {
      deleteRoleWarningOpen,
      idOfItemToDelete,
    } = useAppSelector((state) => state.ui);

    const closeDeleteRoleWarning = () => {
        dispatch(uiActions.setDeleteRoleWarningOpen({ set: false, id: "" }));
      };

    return (
        <Dialog
        open={deleteRoleWarningOpen}
        onClose={closeDeleteRoleWarning}
        sx={{ "& .MuiPaper-root": { width: "500px" } }}
      >
        <CustomDialogTitle onClose={closeDeleteRoleWarning} id="">
          Suppression du rôle ({(selectedRoleToDelete as Role)?.name})
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          Attention, la suppression de ce rôle est définitive. Voulez-vous
          continuer ?
        </DialogContent>
        <DialogActions>
          <BlueButton
            onClick={() => {
              // id ? navigate : undefined: means if there is id param in url ==> in single role view ==> pass a navigate function
              dispatch(
                deleteRoleThunk(idOfItemToDelete, id ? navigate : undefined)
              );
              dispatch(
                uiActions.setDeleteRoleWarningOpen({ set: false, id: "" })
              );
            }}
          >
            Valider
          </BlueButton>
          <WhiteButton onClick={closeDeleteRoleWarning}>Annuler</WhiteButton>
        </DialogActions>
      </Dialog>
    )
}