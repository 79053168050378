import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { usersReducer } from "./users/users.state";
import { uiReducer } from "./ui/ui.state";
import { suppliersReducer } from "./suppliers/suppliers.state";
import { rolesReducer } from "./roles/roles.state";
import { poleReducer } from "./pole/pole.state";
import { projectReducer } from "./project/project.state";
import { authReducer } from "./auth/auth.state";
import { postesReducer } from "./postes/postes.state";
import { perimetersReducer } from "./perimeter/perimeters.state";
import { civiliteReducer } from "./civilite/civilite.state";
import { feedbackMessageUIReducer } from "./feedback-message-ui/feedbackMessageUI.state";
import { ordersReducer } from "./orders/orders.state";
import { invoicesReducer } from "./invoices/invoice.state";
import { tvasReducer } from "./tva/tva.state";
import { paymentTermsReducer } from "./payment-terms/paymentTerms.state";
import { articlesReducer } from "./article/article.state";
import { paymentDatesReducer } from "./payment-dates/paymentDates.state";
import { DataGridStatesReducer } from "./datagrid-state/dataGridState.state";
import { notificationsReducer } from "./notifications/notifications.state";
import { depensesReducer } from "./typedepenses/typedepenses.state";
import { sessionReducer } from './session/session.state'

export const store = configureStore({
  reducer: {
    users: usersReducer,
    tvas: tvasReducer,
    paymentTerms: paymentTermsReducer,
    paymentDates: paymentDatesReducer,
    orders: ordersReducer,
    suppliers: suppliersReducer,
    articles: articlesReducer,
    ui: uiReducer,
    notifications: notificationsReducer,
    dataGridStates: DataGridStatesReducer,
    roles: rolesReducer,
    auth: authReducer,
    postes: postesReducer,
    perimeters: perimetersReducer,
    invoices: invoicesReducer,
    civilite: civiliteReducer,
    pole: poleReducer,
    project: projectReducer,
    feedbackMessageUI: feedbackMessageUIReducer,
    typedepenses: depensesReducer,
    session: sessionReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
