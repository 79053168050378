import Grid from "@mui/material/Grid";

import CompleteRoleCard from "../complete-role-card/CompleteRoleCard.component";
import UsersFilter from "../users-filter/UsersFilter.component";
import UDataGrid from "../users-data-grid/UDataGrid.component";
import { useAppSelector } from "hooks/redux.hook";
import { useParams } from "react-router-dom";
import Header from "./Header.component";


export default function SingleRoleView() {
  const params = useParams();
  const role = useAppSelector((state) => state.roles.list).find(
    (role) => role.id === params.id
  )!;
  return (
    <div style={{padding: "41px 57px"}}>
      <Header/>
      <UsersFilter />
      <Grid container alignItems="flex-start" spacing="20px" marginTop="25px" paddingLeft={{xs: "0", md: "30px"}} >
        <Grid item width={{xs: "100%", lg: "40%", xl: "30%"}}>
          <CompleteRoleCard role={role} />
        </Grid>
        <Grid item width={{xs: "100%", lg: "60%", xl: "70%"}}>
          <UDataGrid />
        </Grid>
      </Grid>
    </div>
  );
}
