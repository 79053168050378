import AddFormDialog from "./components/add-form-dialog/AddFormDialog.component";
import EditFormDialog from "./components/edit-form-dialog/EditFormDialog.component";
import DeleteRoleDialog from "./components/delete-role-dialog/DeleteRoleDialog.component";

import { Outlet } from "react-router-dom";

export default function Roles() {
  return (
    <>
      <Outlet />

      <AddFormDialog/>
      <EditFormDialog/>
      <DeleteRoleDialog/>
    </>
  );
}
