import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import BlueButton from "components/blue-button/BlueButton.component";
import WhiteButton from "components/white-button/WhiteButton.component";
import { FormikProvider, useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import React from "react";
import { OrderStatusEnum } from "store/orders/orders.state";
import {
  editStatusOrderThunk,
  getOrdersByVendorIdThunk,
  getOrdersThunk,
} from "store/orders/orders.thunk";
import { Role } from "store/roles/roles.state";
import { uiActions } from "store/ui/ui.state";
import * as Yup from "yup";

interface IOrderStatusProps {
  handleCloseEditStatus: any;
}
type ErrorProp = boolean | undefined;

const OrderStatus: React.FC<IOrderStatusProps> = ({
  handleCloseEditStatus,
}) => {
  const dispatch = useAppDispatch();
  const selectedInOrder = useAppSelector(
    (state) => state.orders.selectedOrderToEdit
  )!;
  const accountType = useAppSelector((state) => state.auth.accountType)!;
  const authUser = useAppSelector((state) => state.auth.user)!;
  const formik = useFormik({
    initialValues: {
      status: selectedInOrder.status,
    },
    validationSchema: Yup.object({
      status: Yup.string().required("Statut obligatoire"),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await dispatch(
          editStatusOrderThunk({
            id: selectedInOrder?._id,
            status: values.status as OrderStatusEnum,
          })
          // editInvoiceThunk({ id: selectedInvoice?._id, invoice: values as any })
        );
        dispatch(uiActions.setEditOrderStatusOpen(false));
        if ((authUser.role as Role).name === "Administrateur") {
          await dispatch(getOrdersThunk());
        } else if (accountType === "vendor") {
          await dispatch(getOrdersByVendorIdThunk(authUser.id));
        }
      } catch (e) {
        console.log(e);
      }
    },
  });
  const status = OrderStatusEnum;
  const showStatusError = (formik.errors.status &&
    formik.touched.status) as ErrorProp;

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormikProvider value={formik}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Typography component="span" fontWeight="bold">
              Statut de la bon de commande{" "}
              <Typography component="span" color="error">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Select
                error={showStatusError}
                {...formik.getFieldProps("status")}
              >
                {Object.values(status)
                  .filter((item: any) => item !== selectedInOrder.status)
                  .map((item: any, index:number) => {
                    return <MenuItem key={index} value={item}> {item} </MenuItem>;
                  })}
                  <MenuItem selected value={selectedInOrder.status}> {selectedInOrder.status} </MenuItem>;
              </Select>
            </FormControl>
            <FormHelperText>
              {showStatusError && formik.errors.status}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={5}>
          <BlueButton type="submit">Soumettre</BlueButton>
          <WhiteButton type="reset" onClick={handleCloseEditStatus}>
            Annuler
          </WhiteButton>
        </Grid>
      </FormikProvider>
    </form>
  );
};

export default OrderStatus;
