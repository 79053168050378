import Button from "@mui/material/Button";
import styled from "@emotion/styled";

type Props = {
    isActive : boolean
}

const TabButton = styled(Button)<Props>((props) => ({
  textTransform: "none",
  background: "transparent",
  color: props.isActive ? "#00A3FF" : "#A1A5B7",
  borderRadius: 0,
  boxShadow: "none",
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "19px",
  paddingBottom: "14px",
  position: "relative",
  "&:hover": {
    background: "transparent",
  },
  "&:after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: "12.5%",
    width: "75%",
    height: "2px",
    borderRadius: "3px",
    background: props.isActive ? "#00A3FF" : "transparent",
  }
  
}));


export default TabButton