import Typography from "@mui/material/Typography"


export default function FormHeading() {
    return (
        <Typography
        sx={{ marginBottom: "30px" }}
        variant="h4"
        fontWeight="700"
        align="center"
      >
        Veuillez vous enregistrer
      </Typography>
    )
}