import { createSlice, PayloadAction } from "@reduxjs/toolkit";


export interface INotification {
    vendorId: string;
    seen: boolean;
    content: string;
    createdAt: string
}

export interface INotifications {
    list: INotification[];
};

const initialState: INotifications = {
    list: [],
};

const notificationsSlice = createSlice({
    name: "notifications",
    initialState: initialState,
    reducers: {
        setList(state, action: PayloadAction<INotification[]>) {
            state.list = action.payload;
        },
        addOrder(state, action: PayloadAction<INotification>) {
            state.list.push(action.payload);
        },
    },
});

export const notificationsActions = notificationsSlice.actions;
export const notificationsReducer = notificationsSlice.reducer;
