import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import pattern1 from "assets/pattern1.svg";
import rlb from "assets/rectangle-left-bottom.svg";
import rrt from "assets/rectangle-right-top.svg";
import crb from "assets/circle-right-bottom.svg";
import clt from "assets/circle-left-top.svg";

import LogoPaper from "components/logo-paper/LogoPaper.component";
import LoginForm from "./components/form/Form.component";

export default function Login() {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: `
              url(${clt}) left 10% no-repeat,
              url(${rlb}) left bottom no-repeat, 
              url(${rrt}) 50% top no-repeat,
              url(${crb}) 50% bottom no-repeat
              `,
        // backgroundSize: "7% auto, 80%, 10% auto, 20% auto, 15% auto",
        backgroundSize: "7% ,10% , 20% , 15% ",
        backgroundColor: "#000619",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          zIndex: 0,
          top: 0,
          left: 0,
          width: "100%",
          minHeight: "100vh",
          background: `
                url(${pattern1})  no-repeat
                `,
          // backgroundPosition: {xs: "0 80px", xl: "0 0"},
          backgroundSize: "cover",
          backgroundPosition: "top left",
          backgroundColor: "transparent",
          // "@media only screen and (min-width: 0px)": {
          //   backgroundSize: "100%",
          //   backgroundPosition: "0 80px",
          // },
          // "@media only screen and (min-width: 700px)": {
          //   backgroundSize: "80%",
          // },
          // "@media only screen and (min-width: 1000px)": {
          //   backgroundSize: "70%",
          // },
          // "@media only screen and (min-width: 1300px)": {
          //   backgroundSize: "60%",
          // },
          // "@media only screen and (min-width: 1400px)": {
          //   backgroundSize: "70% 60%",
          // },
          // "@media only screen and (min-width: 1640px)": {
          //   backgroundSize: "80% 70%",
          //   backgroundPosition: "0 -30px",
          // },
          // "@media only screen and (min-width: 1900px)": {
          //   backgroundSize: "80% 70%",
          //   backgroundPosition: "0 -30px",
          // },
        }}
      />
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        gap="179px"
        sx={{ zIndex: 1 }}
      >
        <Grid
          item
          sx={{
            width: "300px",
            height: "400px",
            "@media only screen and (max-width: 878px)": {
              display: "none",
            },
          }}
        >
          <LogoPaper />
        </Grid>
        <Grid
          item
          sx={{
            width: "400px",
          }}
        >
          <LoginForm />
        </Grid>
      </Grid>
    </Box>
  );
}
