/* eslint-disable @typescript-eslint/no-unused-vars */
import { AppDispatch, AppThunk } from "store/store.state";
import { feedbackMessageUIActions } from "store/feedback-message-ui/feedbackMessageUI.state";
import { axiosInstance } from "api/axiosInstance";
import { sessionActions } from "./session.state";


export function getSessionThunk() {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.get<any>("/sessions", {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      })
      const sessions = response.data.data
      //dispatch(civiliteActions.setList(civilite))
      dispatch(sessionActions.setList(sessions))
    } catch (e) {

    }
  }
}
export function addSessionThunk(name: any) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.post<any>("/sessions", name, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      })
      const session = response.data.data
      dispatch(sessionActions.addSession(session));
      dispatch(feedbackMessageUIActions.setState({ open: true, message: "Périmètre du BDC ajouté avec succès" }))
      dispatch(getSessionThunk())
    } catch (e) {

      dispatch(feedbackMessageUIActions.setState({ open: true, message: "Impossible d'ajouter session s'il vous plaît réessayer plus tard" }))
    }
  };
}
export function deleteSessionThunk(id: string) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.delete(`/sessions/${id}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      })
      dispatch(sessionActions.deleteSession(id));
      dispatch(feedbackMessageUIActions.setState({ open: true, message: "Périmètre du BDC supprimée avec succès" }))
    } catch (e: any) {
      if (e.response.status === 409) {
        dispatch(
          feedbackMessageUIActions.setState({
            open: true,
            message:
              "Cette donnée est utilisée, vous ne pouvez pas la supprimer",
          })
        );
      } else {
        dispatch(feedbackMessageUIActions.setState({ open: true, message: "Impossible de supprimer session s'il vous plaît réessayer plus tard" }))

      }
    }
  }
}
export function editSessionThunk(obj: { id: string; session: any }) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.put<any>(`/sessions/${obj.id}`, obj.session, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      })
      const editedSession = response.data.data
      dispatch(sessionActions.editSession({ id: obj.id, session: editedSession }));
      dispatch(feedbackMessageUIActions.setState({ open: true, message: "Périmètre du BDC mis à jour avec succès" }))
      dispatch(getSessionThunk())
    } catch (e) {
      dispatch(feedbackMessageUIActions.setState({ open: true, message: "Impossible de mettre à jour session s'il vous plaît réessayer plus tard" }))
    }
  }
}