import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as ROUTES from "utils/routes";
import { useAppDispatch } from "hooks/redux.hook";
import { useFormik } from "formik";
import * as Yup from "yup";
import BlueButton from "components/blue-button/BlueButton.component";
import Container from "@mui/material/Container";
import { forgotPasswordThunk as forgotPasswordThunkSupplier } from "store/suppliers/suppliers.thunk";
import { forgotPasswordThunk as forgotPasswordThunkUser } from "store/users/users.thunk";

import pattern1 from "assets/pattern1.svg";
import rlb from "assets/rectangle-left-bottom.svg";
import rrt from "assets/rectangle-right-top.svg";
import crb from "assets/circle-right-bottom.svg";
import clt from "assets/circle-left-top.svg";

export default function ForgotPassword() {
  const { type } = useParams();
  const [showError, setShowError] = useState(false);
  // const [openLoading, setOpenLoading] = useState(false);
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState("");
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Mauvais format d'e-mail")
        .required("E-mail obligatoire"),
    }),
    onSubmit: (values: any, helpers: any) => {
      // console.log(values);
      const accountType = type!.toLowerCase();
      if (accountType === "utilisateur") {
        dispatch(forgotPasswordThunkUser(values.email, setShowError));

        setTimeout(() => {
          navigate(`/connexion/utilisateur`);
        }, 1500);

      } else {
        dispatch(forgotPasswordThunkSupplier(values.email, setShowError));

        setTimeout(() => {
          navigate(`/connexion/fournisseur`);
        }, 1500);

      }
      helpers.resetForm();
    },
  });

  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          background: `
              url(${clt}) left 10% no-repeat,
              url(${rlb}) left bottom no-repeat, 
              url(${rrt}) 50% top no-repeat,
              url(${crb}) 50% bottom no-repeat,
              url(${pattern1}) top left  no-repeat
              `,
          // backgroundSize: "7% auto, 80%, 10% auto, 20% auto, 15% auto",
          backgroundSize: "7% ,10% , 20% , 15%, cover ",
          backgroundColor: "#000619",
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Container maxWidth="sm">
          <Paper
            sx={{
              padding: "40px",
              borderRadius: "25px 5px",
              marginBottom: "15px",
            }}
          >
            <Typography
              sx={{ marginBottom: showError ? 0 : "50px" }}
              variant="h4"
              //   fontWeight="700"
              align="center"
            >
              Veuillez entrer votre e-mail
            </Typography>

            {showError && (
              <Typography variant="body2" align="center" color="error">
                Error {errorText && errorText}
              </Typography>
            )}

            <form onSubmit={formik.handleSubmit}>
              <TextField
                {...formik.getFieldProps("email")}
                error={
                  (formik.errors.email && formik.touched.email) as
                  | boolean
                  | undefined
                }
                helperText={
                  formik.errors.email &&
                  formik.touched.email &&
                  formik.errors.email
                }
                sx={{
                  marginBottom: "30px",
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#000619",
                  },
                  "& .MuiInputBase-root:after": {
                    border: "none",
                  },
                }}
                label="E-mail"
                variant="standard"
                placeholder="example@mail.com"
                fullWidth
              />

              <BlueButton type="submit" sx={{ padding: "12px 20px" }}>Envoyer</BlueButton>
            </form>
          </Paper>

          <Typography
            variant="body2"
            //   fontWeight="700"
            align="center"
          >
            Vous recevrez un email pour changer votre mot de passe.
          </Typography>
        </Container>
      </Box>
    </>
  );
}
