import { useFormik } from "formik";
import * as Yup from "yup";

import { useAppSelector, useAppDispatch } from "hooks/redux.hook";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import CustomDialogTitle from "components/custom-dialog-title/CustomDialogTitle.component";
import AddForm from "./AddForm.component";
import CloseAddRoleConfirmation from "../close-confiramtion-dialogs/CloseAddRoleConfirmation.component";

import { addRoleThunk } from "store/roles/roles.thunk";
import { addRelatedPermissions } from "utils/addRelatedPermissions"
import { uiActions } from "store/ui/ui.state";
import { useEffect } from "react";
import { compareRoleFormStates } from "utils/compareRoleFormStates"

export default function AddFormDialog() {
  const dispatch = useAppDispatch();
  const { addRoleFormOpen } = useAppSelector((state) => state.ui);
    const formik = useFormik({
    initialValues: {
      // id: "",
      name: "",
      description: "",
      userManagment: [],  
      supplierManagment: [],
      roleManagment : [],
      managmentRules: [],
      billManagment : [],
      orderManagment : [],
      APIConnection : [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Obligatoire"),
      description: Yup.string(),
    }),
    onSubmit: (values, helpers) => {
      // console.log(values);
      const modifiedValues = addRelatedPermissions(values)
      dispatch(addRoleThunk(modifiedValues as any));
      dispatch(uiActions.setAddRoleFormOpen(false));
      helpers.resetForm()
    },
  });

  const closeAddRoleForm = () => {
    const userDidNotChangeForm = compareRoleFormStates(formik.initialValues, formik.values)
    if(userDidNotChangeForm) {
      dispatch(uiActions.setAddRoleFormOpen(false))
    }else{
      dispatch(uiActions.setCloseAddRoleConfirmationOpen(true));
    }
  };

  useEffect(() => {
    // reset form when ever open state changes
    formik.resetForm()
  }, [addRoleFormOpen])

  return (
    <Dialog open={addRoleFormOpen} onClose={closeAddRoleForm} maxWidth={false}>
      <CustomDialogTitle onClose={closeAddRoleForm} id="">
        Ajouter un rôle
      </CustomDialogTitle>
      <Divider />
      <DialogContent>
        <AddForm formik={formik} handleAnnuler={closeAddRoleForm}/>
        <CloseAddRoleConfirmation />
      </DialogContent>
    </Dialog>
  );
}
