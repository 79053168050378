import Grid from "@mui/material/Grid";

import { useAppSelector } from "hooks/redux.hook";

import RoleCard from "../role-card/RoleCard.component";
import AddNewRoleCard from "../add-new-role-card/AddNewRoleCard.component";
import RolesFilter from "../roles-filter/RolesFilter.component";
import type { Role } from "store/roles/roles.state";

export default function RolesListView() {
  const roles = useAppSelector((state) => state.roles.list);
  const filterValue = useAppSelector((state) => state.roles.roleNameFilterValue);

  let filteredRoles: Role[] = [];
  if (filterValue === "") filteredRoles = roles;
  else {
    const regExp = new RegExp(filterValue, "i");
    filteredRoles = roles.filter((role) => role.name.search(regExp) > -1);
  }

  return (
    <div style={{padding: "41px 57px"}}>
    <RolesFilter/>
    <Grid container paddingLeft="20px" alignItems="stretch" spacing="20px">
      {filteredRoles.map((role) => (
        <Grid key={role.id} item xs={12} md={8} xl={4} >
          <RoleCard role={role} />
        </Grid>
      ))}
      <Grid item xs={12} md={8} xl={4}>
        <AddNewRoleCard/>
      </Grid>
    </Grid>
    </div>
  );
}
