import { Routes, Route, Navigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import Welcome from "pages/welcome/Welcome.component";
import Login from "pages/login/Login.component";
import Signup from "pages/signup/Signup.component";
import Users from "pages/users/Users.component";
import Suppliers from "pages/suppliers/Suppliers.component";
import Roles from "pages/roles/Roles.component";
import RolesList from "pages/roles/components/roles-list-view/RolesListView.component";
import SingleRoleView from "pages/roles/components/single-role-view/SingleRoleView.component";
import DashboardNavigation from "components/dashboard-navigation/DashboardNavigation.component";
import SupplierInvite from "pages/supplier-invite/SupplierInvite.component";

import DashboardAccessController from "routes-access-controllers/DashboardAccessController.component";

import * as ROUTES from "utils/routes";
import UsersAccessController from "routes-access-controllers/UsersAccessController.component";
import SuppliersAccessController from "routes-access-controllers/SuppliersAccessController.component";
import RolesAccessController from "routes-access-controllers/RolesAccessController.component";
import Home from "pages/home/Home.component";
import ManagmentRules from "pages/managment-rules/ManagmentRules.component";
import Postes from "pages/managment-rules/components/postes/Postes.component";
import Perimeters from "pages/managment-rules/components/Perimeter/Perimeter.component";
import Civilite from "pages/managment-rules/components/civilite/Civilite.component";
import Tva from "pages/managment-rules/components/tva/Tva.component";
import SDataGrid from "pages/managment-rules/components/settings-data-grid/SDataGrid.component";
import UserProfile from "pages/user-profile/UserProfile.component";
import SupplierProfile from "pages/supplier-profile/SupplierProfile.component";
import ForgotPassword from "pages/forgot-password/ForgotPassword.component";
import ResetPassword from "pages/forgot-password/ResetPassword.component";
import TempFbMsg from "components/temporary-feedback-message/TempFbMsg.component";
import ManagmentRulesAccessController from "routes-access-controllers/ManagmentRulesAccessController.component";
import Pole from "pages/managment-rules/components/Pole/pole.component";
import Article from "pages/article/Article.component";
import OrdersAccessController from "./routes-access-controllers/OrdersAccessController.component";
import PurshaseOrders from "pages/purshase-orders/purshaseOrders.component";
import Invoices from "pages/invoices/Invoices.component";
import PaymentTerm from "pages/managment-rules/components/payment-term/PaymentTerm.component";
import PaymentDate from "pages/managment-rules/components/payment-date/PaymentDate.component";
import TypeDepense from "pages/managment-rules/components/type-depense/typedepense.component";
import Session from "pages/managment-rules/session/session.component";
import Project from "pages/managment-rules/components/projects/Project.component";
import React from "react";


function App() {
  const [isLogedIn, setIslogedIn] = React.useState(false)
  const token = useAppSelector((state) => state.auth.token);
  React.useEffect(() => {
    token ? setIslogedIn(true) : setIslogedIn(false)
  }, [])

  return (
    <>
      {/* temporary feedback message that can be opened by thunks to display success/error messages acroos the app */}
      <TempFbMsg />
      {token ? (
        <>
          <Routes>
            <Route path={ROUTES.WELCOME} element={<Welcome />} />
            <Route path="profile/Fournisseur/edit/:token" element={<SupplierInvite />} />
            <Route path={ROUTES.DASHBOARD} element={<DashboardAccessController />}>
              <Route path="" element={<DashboardNavigation />}>
                <Route index element={<h4>Dashboard</h4>} />

                <Route path={`${ROUTES.USER_PROFILE}`} element={<UserProfile />} />
                <Route
                  path={`${ROUTES.SUPPLIER_PROFILE}`}
                  element={<SupplierProfile />}
                />

                <Route
                  path={`${ROUTES.PARAMETERS}/${ROUTES.USERS}`}
                  element={<UsersAccessController />}
                >
                  <Route index element={<Users />} />
                </Route>

                <Route
                  path={`${ROUTES.PARAMETERS}/${ROUTES.ROLES}`}
                  element={<RolesAccessController />}
                >
                  <Route path="" element={<Roles />}>
                    <Route index element={<RolesList />} />
                    <Route path=":id" element={<SingleRoleView />} />
                  </Route>
                </Route>
                <Route
                  path={`${ROUTES.PARAMETERS}/${ROUTES.ARTICLE}`}
                  element={<Article />}
                >
                  <Route path={`${ROUTES.ARTICLE}`} element={<Article />} />
                </Route>

                <Route
                  path={`${ROUTES.PARAMETERS}/${ROUTES.MANAGMENT_RULES}`}
                  element={<ManagmentRulesAccessController />}
                >
                  <Route path="" element={<ManagmentRules />}>
                    <Route index element={<SDataGrid />} />
                    <Route path={`${ROUTES.POSTES}`} element={<Postes />} />
                    <Route path={`${ROUTES.PERIMETER}`} element={<Perimeters />} />
                    <Route path={`${ROUTES.CIVILITE}`} element={<Civilite />} />
                    <Route path={`${ROUTES.POLE}`} element={<Pole />} />
                    <Route path={`${ROUTES.TVA}`} element={<Tva />} />
                    <Route path={`${ROUTES.ARTICLE}`} element={<Article />} />
                    <Route path={`${ROUTES.TYPE_DEPENSE}`} element={<TypeDepense />} />
                    <Route path={`${ROUTES.SESSION}`} element={<Session />} />
                    <Route path={`${ROUTES.PROJECT}`} element={<Project />} />
                    <Route
                      path={`${ROUTES.PAYMENTDATE}`}
                      element={<PaymentDate />}
                    />
                    <Route
                      path={`${ROUTES.PAYMENTTERM}`}
                      element={<PaymentTerm />}
                    />
                  </Route>
                </Route>

                <Route
                  path={`${ROUTES.PARAMETERS}/${ROUTES.API_CONNECTION}`}
                  element={<h4>API connection</h4>}
                />

                <Route
                  path={`${ROUTES.PURCHASE_ORDERS}/${ROUTES.PURCHASE_ORDERS_LIST}`}
                  element={<OrdersAccessController />}
                >
                  <Route index element={<PurshaseOrders />} />
                </Route>

                <Route
                  path={`${ROUTES.BILLS}/${ROUTES.BILLS_LIST}`}
                  element={<Invoices />}
                />

                <Route
                  path={`${ROUTES.SUPPLIERS}/${ROUTES.SUPPLIERS_LIST}`}
                  element={<SuppliersAccessController />}
                >
                  <Route index element={<Suppliers />} />
                </Route>
              </Route>
            </Route>
            <Route path="role" element={<>qsdqsd</>} />
            <Route path="*" element={<Navigate to={ROUTES.DASHBOARD} replace />} />
          </Routes>
        </>
      ) :
        (
          <Routes>
            <Route index element={<Home />} />

            <Route path="profile/Fournisseur/edit/:token" element={<SupplierInvite />} />

            <Route path={`/${ROUTES.HOME}`} element={<Home />} />

            <Route path={`/${ROUTES.LOGIN}/:type`} element={<Login />} />

            <Route
              path={`/${ROUTES.FORGOT_PASSWORD}/:type`}
              element={<ForgotPassword />}
            />
            <Route
              path={`${ROUTES.RESET_PASSWORD}/:type/:token`}
              element={<ResetPassword />}
            />

            <Route path={ROUTES.SIGNUP} element={<Signup />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        )

      }
    </>
  );
}

export default App;
