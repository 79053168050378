import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPaymentDate {
    id: string;
    _id: string;
    value: number,
};


export interface IPaymentDates {
    selectedPaymentDate: IPaymentDate | null;
    list: IPaymentDate[];
};


const initialState: IPaymentDates = {
    selectedPaymentDate: null,
    list: [],
};

const paymentDatesSlice = createSlice({
    name: "paymentdates",
    initialState: initialState,
    reducers: {
        setList(state, action: PayloadAction<IPaymentDate[]>) {
            state.list = action.payload;
        },
        addPaymentDate(state, action: PayloadAction<IPaymentDate>) {
            state.list.push(action.payload);
        },
        deletePaymentDate(state, action: PayloadAction<string>) {
            state.list = state.list.filter((paymentDate) => paymentDate.id !== action.payload);
        },
        editPaymentDate(state, action: PayloadAction<{ id: string; paymentDate: IPaymentDate }>) {
            state.list = state.list.map((paymentDate) => {
                if (paymentDate._id === action.payload.id) return action.payload.paymentDate;
                return paymentDate;
            });
        },
        setSelectPaymentDate(state, action: PayloadAction<IPaymentDate>) {
            state.selectedPaymentDate = action.payload
        },
        clearSelectedPaymentDate(state) {
            state.selectedPaymentDate = null;
        },
    },
});

export const paymentDatesActions = paymentDatesSlice.actions;
export const paymentDatesReducer = paymentDatesSlice.reducer;