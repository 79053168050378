import { ChangeEvent, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";

import { uiActions } from "store/ui/ui.state";
import { CustomTextField } from "components/custom-textfield/CustomTextField.component";
import CheckBoxGroup from "../checkbox-group/CheckBoxGroup.component";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import { editRoleThunk } from "store/roles/roles.thunk";
import { addRelatedPermissions } from "utils/addRelatedPermissions";
import CustomCheckbox from "components/custom-checkbox/CutomCheckbox.component";
import BlueButton from "components/blue-button/BlueButton.component";
import GrayButton from "components/gray-button/GrayButton.component";

type ErrorProp = boolean | undefined;

type EditFormProps = {
  formik: any;
  handleAnnuler: () => void;
};

export default function EditForm(props: EditFormProps) {
  const dispatch = useAppDispatch();
  // const selectedRoleToEdit = useAppSelector(
  //   (state) => state.roles.selectedRoleToEdit
  // )!;
  const [allChecked, setAllChecked] = useState(false);
  const allPermissions = ["read", "update", "create", "delete"];
  const { formik } = props;
  // const formik = useFormik({
  //   initialValues: selectedRoleToEdit,
  //   validationSchema: Yup.object({
  //     name: Yup.string().required("Obligatoire"),
  //     description: Yup.string(),
  //   }),
  //   onSubmit: (values) => {
  //     console.log(values);
  //     const modifiedValues = addRelatedPermissions(values)
  //     dispatch(editRoleThunk({ id: selectedRoleToEdit.id, role: modifiedValues }));
  //     dispatch(uiActions.setEditRoleFormOpen(false));
  //   },
  // });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAllChecked(e.currentTarget.checked);
    formik.setFieldValue(
      "userManagment",
      e.currentTarget.checked ? allPermissions : []
    );
    formik.setFieldValue(
      "supplierManagment",
      e.currentTarget.checked ? allPermissions : []
    );
    formik.setFieldValue(
      "roleManagment",
      e.currentTarget.checked ? allPermissions : []
    );
    formik.setFieldValue(
      "managmentRules",
      e.currentTarget.checked ? allPermissions : []
    );
    formik.setFieldValue(
      "billManagment",
      e.currentTarget.checked ? allPermissions : []
    );
    formik.setFieldValue(
      "orderManagment",
      e.currentTarget.checked ? allPermissions : []
    );
    formik.setFieldValue(
      "APIConnection",
      e.currentTarget.checked ? allPermissions : []
    );
  };

  const showNameError = (formik.errors.name &&
    formik.touched.name) as ErrorProp;
  const showDescriptionError = (formik.errors.description &&
    formik.touched.description) as ErrorProp;

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="body2" fontWeight="bold" color="text.secondary">
        Nom du Rôle
      </Typography>
      <CustomTextField
        {...formik.getFieldProps("name")}
        error={showNameError}
        helperText={showNameError && formik.errors.name}
        placeholder="Entrer le nom du rôle"
        sx={{ marginBottom: "20px" }}
      />
      <Typography variant="body2" fontWeight="bold" color="text.secondary">
        Description
      </Typography>
      <CustomTextField
        {...formik.getFieldProps("description")}
        error={showDescriptionError}
        helperText={showDescriptionError && formik.errors.description}
        placeholder="Description..."
        sx={{ marginBottom: "20px" }}
      />
      <Typography
        variant="body2"
        fontWeight="bold"
        sx={{ marginTop: "10px" }}
        color="text.secondary"
      >
        Autorisations:
      </Typography>
      <Grid container alignItems="center">
        <Grid item sx={{ marginRight: "20px" }}>
          <Typography variant="body2" color="text.secondary">
            Accès administrateur
          </Typography>
        </Grid>
        <Grid item>
          <FormControlLabel
            componentsProps={{ typography: { color: "text.secondary" } }}
            control={
              <CustomCheckbox
                disableRipple
                checked={allChecked}
                onChange={handleChange}
              />
            }
            label="Tout sélectionner"
          />
        </Grid>
      </Grid>
      <Divider />
      <CheckBoxGroup
        label="Utilisateurs"
        {...formik.getFieldProps("userManagment")}
      />
      <Divider />
      <CheckBoxGroup
        label="Fournisseurs"
        {...formik.getFieldProps("supplierManagment")}
      />
      <Divider />
      <CheckBoxGroup label="Rôles" {...formik.getFieldProps("roleManagment")} />
      <Divider />
      <CheckBoxGroup
        label="Règles de gestion"
        {...formik.getFieldProps("managmentRules")}
      />
      <Divider />
      <CheckBoxGroup
        label="Factures"
        {...formik.getFieldProps("billManagment")}
      />
      <Divider />
      <CheckBoxGroup
        label="Bon de commande"
        {...formik.getFieldProps("orderManagment")}
      />
      <Divider />
      <CheckBoxGroup
        label="Connexion API"
        {...formik.getFieldProps("APIConnection")}
      />
      <Divider />

      <Box display="flex" justifyContent="center" gap="10px" marginTop="20px">
        <GrayButton onClick={props.handleAnnuler}>Annuler</GrayButton>
        <BlueButton type="submit">Soumettre</BlueButton>
      </Box>
    </form>
  );
}
