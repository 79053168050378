import { createTheme } from "@mui/material";
import { Theme as MuiTheme } from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";

declare module "@mui/material/styles" {
  interface Theme {
    customTypography: {
      bulletPoint: {
        fontSize: string;
        lineHeight: string;
        letterSpacing: string;
        fontWeight: number;
      };
      bulletPointBody: {
        fontSize: string;
        lineHeight: string;
        letterSpacing: string;
        fontWeight: number;
      };
    };
  }
  interface Palette {
    custom: {
      pink: string;
      green: string;
      violet: string;
      orange: string;
    };
  }

  interface ThemeOptions {
    customTypography?: {
      bulletPoint?: {
        fontSize?: string;
        lineHeight?: string;
        letterSpacing?: string;
        fontWeight?: number;
      };
      bulletPointBody?: {
        fontSize?: string;
        lineHeight?: string;
        letterSpacing?: string;
        fontWeight?: number;
      };
    };
  }
  interface PaletteOptions {
    custom?: {
      pink?: string;
      green?: string;
      violet?: string;
      orange?: string;
    };
  }
  interface TypeText {
    third?: string;
    forth?: string;
  }
}

// make emotion aware of your Theme interface
declare module "@emotion/react" {
  export interface Theme extends MuiTheme {}
}

// just to use it in the declaration of theme
let theme1 = createTheme();

const theme = createTheme({
  palette: {
    text: {
      primary: "#000619",
      secondary: "rgba(0,6,25,0.6)",
      third: "rgba(0,6,25,0.2)",
      forth: "rgba(0,6,25,0.05)",
    },
    custom: {
      pink: "#FE7373",
      green: "#73DEB4",
      violet: "#FF78FF",
      orange: "#FF7750",
    },
    background: {
      // default: "#F5F5F5",
      default: "#f2f2f2",
    },
  },
  typography: {
    h1: {
      fontSize: "45px",
      lineHeight: "60px",
      letterSpacing: "2%",
      fontWeight: 500,
      [theme1.breakpoints.down("md")]: {
        fontSize: "30px",
        lineHeight: "45px",
      },
    },
    h2: {
      fontSize: "35px",
      lineHeight: "50px",
      letterSpacing: "2%",
      fontWeight: 500,
      [theme1.breakpoints.down("md")]: {
        fontSize: "30px",
        lineHeight: "35px",
      },
    },
    h3: {
      fontSize: "30px",
      lineHeight: "40px",
      letterSpacing: "2%",
      fontWeight: 500,
      [theme1.breakpoints.down("md")]: {
        fontSize: "20px",
        lineHeight: "25px",
      },
    },
    h4: {
      fontSize: "20px",
      lineHeight: "30px",
      letterSpacing: "2%",
      fontWeight: 500,
    },
    body1: {
      fontSize: "15px",
      lineHeight: "25px",
      letterSpacing: "2%",
      fontWeight: 400,
    },
  },
  customTypography: {
    bulletPoint: {
      fontSize: "18px",
      lineHeight: "30px",
      letterSpacing: "2%",
      fontWeight: 400,
    },
    bulletPointBody: {
      fontSize: "18px",
      lineHeight: "30px",
      letterSpacing: "5%",
      fontWeight: 400,
    },
  },
});

export const PoopinsFontFamilyTheme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif;",
    fontSize: 12,
    lineHeight: 1.5,
    display: "block",
    fontWeight: "bold"
  }
} as any)

export default theme;
