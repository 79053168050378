import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import { CustomToolbar } from "./CutomToolbar.component";
import { Order, ordersActions } from "store/orders/orders.state";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Typography,
  Box
} from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SettingsIcon from "@mui/icons-material/Settings";
import * as XLSX from "xlsx";
import { uiActions } from "store/ui/ui.state";
import { feedbackMessageUIActions } from "../../../../store/feedback-message-ui/feedbackMessageUI.state";
import {
  addOrderThunk,
  deleteOrderThunk,
  getOrdersByVendorIdThunk,
  getOrdersThunk,
} from "store/orders/orders.thunk";
import OrderInfos from "../order-infos/OrderInfos";
import { Supplier } from "store/suppliers/suppliers.state";
import { User } from "store/users/users.state";
import OrderPdf from "../order-pdf/OrderPdf";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { Role } from "store/roles/roles.state";
import { IInvoice } from "store/invoices/invoice.state";
import { errorMonitor } from "events";
import CustomSideBar from "components/custom-sidebar/CustomSideBar";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import {
  DataGridStateEnum,
  DataGridStatesActions,
  IDataGridState,
} from "store/datagrid-state/dataGridState.state";
import {
  getDatagridStateByUserIdThunk,
  getDatagridStatesThunk,
} from "store/datagrid-state/dataGridState.thunk";
import CustomDialogTitle from "components/custom-dialog-title/CustomDialogTitle.component";
import AddView from "../add-view/AddView";
import ViewSettings from "../views-settings/ViewSettings";
import WhiteButton from "components/white-button/WhiteButton.component";
import { AG_GRID_LOCALE_FR } from "utils/constants";

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

export default function ODataGrid() {
  const dispatch = useAppDispatch();
  const gridRef = useRef<AgGridReact>(null);

  const { addViewFormOpen, viewSettingsOpen } = useAppSelector(
    (state) => state.ui
  );
  const accountType = useAppSelector((state) => state.auth.accountType)!;
  const authenticatedUser = useAppSelector((state) => state.auth.user)!;
  const ordersList = useAppSelector((state) => state.orders.list);
  const [gridColumnApi, setGridColumnApi] = useState<any>();
  const [gridApi, setGridApi] = useState<any>();
  const [customSideBarOpen, setCustomSideBarOpen] = useState<boolean>(false);
  const statesList = useAppSelector((state) => state.dataGridStates.list);
  const ordersNumber = useAppSelector((state) => state.orders.list.length);

  const selectedOrderView: IDataGridState = useAppSelector(
    (state) => state.dataGridStates.selectedDataGridStateOrder
  )!;
  const [selectedView, setSelectedView] =
    useState<IDataGridState>(selectedOrderView);

  const defaultColDef = useMemo(() => {
    return {
      minWidth: 100,
      // allow every column to be aggregated
      // enableValue: true,
      // allow every column to be grouped
      // enableRowGroup: true,
      // allow every column to be pivoted
      // enablePivot: true,
      resizable: true,
      sortable: true,
      filter: true,
    };
  }, []);

  const FormatDataForAgGrid = (rows: any) => ({
    id: rows?._id,
    fullName: rows?.userRef.fullName,
    BDC_NUM: rows?.BDC_NUM,
    designation: rows?.designation,
    typeDepense: rows?.typeDepense,
    pole: rows?.pole,
    perimeter: rows?.perimeter?.name,
    project: rows?.project?._id,
    missionInformation: rows?.missionInformation,
    session: rows?.session,
    raisonSocial: rows?.raisonSocial,
    documentDate: rows?.documentDate,
    endDate: rows?.endDate,
    startDate: rows?.startDate,
    endMission: rows?.endMission,
    startMission: rows?.startMission,
    GTTC: rows?.GTTC,
    MTVA: rows?.MTVA,
    FRN_address: rows?.FRN?.address,
    FRN_city: rows?.FRN?.city,
    FRN_pays: rows?.FRN?.pays,
    FRN_postalCode: rows?.FRN?.postalCode,
    FRN_email: rows?.FRN?.email,
    FRN_tel: rows?.FRN?.tel
  })
  const rows = useAppSelector((state) => state.orders.list)!;
  const rowss = rows.map((row: any) => (FormatDataForAgGrid(row)))
  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };


  useEffect(() => {
    if (accountType === "vendor") {
      dispatch(getOrdersByVendorIdThunk(authenticatedUser.id));
    } else {
      dispatch(getOrdersThunk());
    }
    getDatagridStateByUserIdThunk(authenticatedUser.id);
  }, []);

  const closeAddViewform = () => {
    dispatch(uiActions.setAddViewFormOpen(false));
  };
  const closeSettingsViewform = () => {
    dispatch(uiActions.setViewSettingsOpen(false));
  };

  useEffect(() => {
    setSelectedView({ ...selectedOrderView });

    if (selectedOrderView?.colState.length === 0) {
      setSelectedView({ ...defaultView });
      resetState();
      selectView(gridRef?.current?.columnApi?.getColumnState());
    } else {
      gridRef?.current?.columnApi?.applyColumnState({
        state: selectedOrderView?.colState,
        applyOrder: true,
      });
    }

    // const favorite = statesList
    //   .filter(
    //     (view: IDataGridState) =>
    //       view.dataGridName === DataGridStateEnum.Invoice
    //   )
    //   .every((view: IDataGridState) => view.favorite === false);

    // if (favorite) {
    //   setSelectedView({ ...defaultView });
    //   gridRef?.current?.columnApi?.resetColumnState();
    //   selectView(gridRef?.current?.columnApi?.getColumnState());
    // }
  }, [selectedOrderView]);

  const defaultView = {
    _id: "0",
    id: "0",
    dataGridName: DataGridStateEnum.Order,
    favorite: false,
    viewName: "Par défaut",
    user: authenticatedUser,
    colState: gridRef?.current?.columnApi?.getColumnState() as any,
  };
  // const downloadExcel = (data: any) => {
  //   const dd: any =  gridRef.current?.columnApi?.getColumns()
  //   const dd2: any =  gridRef.current?.columnApi?.getColumns()
  //   dd.forEach((col: any) => {
  //     // console.log('🚀 ~ dd.forEach ~ col', col)
  //     // console.log('🚀 ~ dd.forEach ~ col', col.colDef.headerName)

  //   })
  //   let newData: any[] = [];
  //   data.forEach((row: any, index: number) => {
  //     newData[index] = { ...row };
  //   });
  //   newData.map((elem: any) => {
  //     elem.FRN = elem?.FRN?._id;
  //     elem.articles = elem?.articles.length;
  //     elem.invoices = elem?.invoices.length;
  //     elem.invoices = elem?.invoices.toString();
  //     elem.paymentTerm = elem?.paymentTerm._id;
  //     elem.userRef = elem?.userRef?._id;
  //     elem.perimeter = elem?.perimeter?.name;
  //     elem.pole = elem?.pole?.description;
  //     elem.project = elem?.project?.name;
  //     elem.session = elem?.session?.name;
  //     elem.typeDepense = elem?.typeDepense?.name;
  //     elem.missionInformation = elem?.missionInformation;
  //     delete elem._v;
  //     delete elem.centreCout;
  //   });

  //   const worksheet = XLSX.utils.json_to_sheet(newData);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   XLSX.writeFile(workbook, "DataSheet.xlsx", { compression: true });
  // };
  const downloadExcel = (data: any) => {
    const cols: any = []
    const colswithHeader: any = []
    const datas: any[] = []
    const columnDefs: any = gridRef.current?.columnApi?.getColumns()

    columnDefs?.forEach((col: any) => {
      if (col?.visible) {
        cols.push(col?.colId)
        colswithHeader.push({
          field: col?.colId,
          headerName: col?.userProvidedColDef?.headerName,
          type: col?.userProvidedColDef?.filter === 'agDateColumnFilter' ? 'date' : 'notDate',
        })
      }
    })
    const rowNode: any = gridRef.current?.api
    const rowNode2: any = Object.values(rowNode?.rowModel?.nodeManager?.allNodesMap)

    for (let i = 0; i <= rowNode2?.length; i++) {
      const rowNode3: any = rowNode2[i]
      const dt: any = {}
      cols?.forEach((cl: any) => {
        // console.log('🚀 ~ cols?.forEach ~ cl', cl)
        if (cl !== "id") {
          const fl = colswithHeader.find((elm: any) => elm.field === cl)
          if (cl === "userRef.fullName") {
            dt[fl.headerName] = rowNode3?.data?.userRef?.fullName
          } else if (cl === "FRN.ddress") {
            dt[fl.headerName] = rowNode3?.data?.FRN?.ddress
          } else if (cl === "FRN.city") {
            dt[fl.headerName] = rowNode3?.data?.FRN?.city
          } else if (cl === "FRN.pays") {
            dt[fl.headerName] = rowNode3?.data?.FRN?.pays
          } else if (cl === "FRN.postalCode") {
            dt[fl.headerName] = rowNode3?.data?.FRN?.postalCode
          } else if (cl === "FRN.email") {
            dt[fl.headerName] = rowNode3?.data?.FRN?.email
          } else if (cl === "FRN.mobile") {
            dt[fl.headerName] = rowNode3?.data?.FRN?.mobile
          } else if (cl === "perimeter.name") {
            dt[fl.headerName] = rowNode3?.data?.perimeter?.name
          } else if (cl === "session.name") {
            dt[fl.headerName] = rowNode3?.data?.session?.name
          } else if (cl === "typeDepense.name") {
            dt[fl.headerName] = rowNode3?.data?.typeDepense?.name
          } else if (cl === "pole.description") {
            dt[fl.headerName] = rowNode3?.data?.pole?.description
          } else if (cl === "project.name") {
            dt[fl.headerName] = rowNode3?.data?.project?.name
          } else dt[fl.headerName] = rowNode3?.data[cl]

        }
      })
      datas.push(dt)
    }
    const ws = XLSX.utils.json_to_sheet(datas)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "DataSheet.xlsx", { compression: true });
  };
  const selectView = (colState: any) => {
    gridRef?.current?.columnApi?.applyColumnState({
      state: colState,
      applyOrder: true,
    });
  };

  useEffect(() => {
    if (!addViewFormOpen) {
      selectView(selectedOrderView?.colState ?? defaultView.colState);
    }
  }, [])
  

  const resetState = () => {
    gridRef?.current?.columnApi.resetColumnState();
  };

  const handleImport = async (e: any) => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);

    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData: any = XLSX.utils.sheet_to_json(worksheet);
    jsonData.map((item: any) => {
      delete item._id;
      delete item.BDC_NUM;
      delete item.articles;
      delete item.invoices;
    });

    try {
      await dispatch(addOrderThunk(jsonData));
      await dispatch(getOrdersThunk());
    } catch (error) {
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: `${error}`,
        })
      );
    }
  };

  const deleteRow = (id: string) => {
    dispatch(
      uiActions.setDeleteOrderWarningOpen({
        set: true,
        id: id,
      })
    );
  };
  const updateRow = (values: Order) => {
    dispatch(ordersActions.selectOrdertoEdit(values));
    dispatch(uiActions.setEditOrderFormOpen(true));
  };

  // const orderInfo = (data: Order) => {
  //   dispatch(ordersActions.selectOrdertoEdit(data));
  //   dispatch(uiActions.setInfoOrderOpen(true));
  // };
  const orderPdf = async (data: Order) => {
    dispatch(ordersActions.selectOrdertoEdit(data));
    dispatch(uiActions.setOrderPdfWarningOpen(true));
  };
  // display each row grouping in group rows
  const groupDisplayType = "groupRows";

  const changeStatus = (data: any) => {
    if (
      (authenticatedUser?.role as Role)?.name === "Administrateur" &&
      (authenticatedUser?.role as Role)?.billManagment.includes("update")
    ) {
      dispatch(ordersActions.selectOrdertoEdit(data));
      dispatch(uiActions.setEditOrderStatusOpen(true));
    }
  };

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([
    {
      resizable: false,
      suppressMenu: true,
      menuTabs: [],
      headerName: "Actions",
      field: "id",
      cellRenderer: (params: any) => (
        <Grid>
          {accountType === "user" && (
            <>
              <IconButton
                title="Supprimer"
                onClick={() => deleteRow(params.data._id)}
                disabled={
                  !(authenticatedUser!.role as Role).orderManagment.includes(
                    "delete"
                  ) || params.data.status === "Clôturé" || params.data.status === "Annulé"
                }
                color="error"
                size="small"
              >
                <Delete />
              </IconButton>
              {""}
              <IconButton
                title="Editer"
                size="small"
                disabled={
                  !(authenticatedUser!.role as Role).orderManagment.includes(
                    "update"
                  )|| params.data.status === "Clôturé" || params.data.status === "Annulé"
                }
                sx={{ color: "#00A3FF" }}
                onClick={() => updateRow(params.data)}
              >
                <EditIcon />
              </IconButton>
            </>
          )}
          <IconButton
            title="Version PDF"
            sx={{ color: "#f24446" }}
            size="small"
            disabled={
              !(
                (authenticatedUser!.role as Role)?.orderManagment?.includes(
                  "read"
                ) || accountType === "vendor"
              )
            }
            onClick={() => orderPdf(params.data)}
          >
            <PictureAsPdfIcon />
          </IconButton>

          {/* <IconButton
            title="Infos"
            size="small"
            color="info"
            disabled={
              !(
                (authenticatedUser!.role as Role)?.orderManagment?.includes(
                  "read"
                ) || accountType === "vendor"
              )
            }
            onClick={() => orderInfo(params.data)}
          >
            <InfoIcon />
          </IconButton> */}
        </Grid>
      ),
    },
    {
      floatingFilter: true,
      field: "userRef.fullName",
      filter: "agTextColumnFilter",
      sortable: true,
      headerName: "Utilisateur",
      width: 150,
      filterParams: {
        buttons: ["apply", "clear", "cancel", "reset"],
      },
      // rowGroup: true,
    },
    {
      floatingFilter: true,
      field: "BDC_NUM",
      filter: "agTextColumnFilter",
      sortable: true,
      width: 160,
      headerName: "Numéro du BDC",
      filterParams: {
        buttons: ["apply", "clear", "cancel", "reset"],
      },
      // rowGroup: true,
    },
    {
      field: "status",
      headerName: "Status",
      // rowGroup: true,
      cellRenderer: (params: any) => (
        <Box
          sx={{
            width: "100%",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => changeStatus(params.data)}
            // variant={params.data.status === "Actif" ? "outlined" : "contained"} 
            variant="outlined"
            size="small"
            style={{ textTransform: "capitalize" }}
            sx={{
              color: params.data.status === "Actif" ? "#42D9C7" : params.data.status === "Clôturé" ? "blue" : "red",
            }}
          >
            {" "}
            {params.data.status}{" "}
          </Button>
        </Box>
      ),
    },
    {
      floatingFilter: true,
      field: "raisonSocial",
      filter: "agTextColumnFilter",
      sortable: true,
      width: 150,
      headerName: "Raison Sociale",
      filterParams: {
        buttons: ["apply", "clear", "cancel", "reset"],
      },
      // rowGroup: true,
    },
    {
      floatingFilter: true,
      field: "documentDate",
      filter: "agDateColumnFilter",
      sortable: true,
      width: 180,
      headerName: "Date du document",
      filterParams: {
        buttons: ["apply", "clear", "cancel", "reset"],
      },
      // rowGroup: true,
    },
    {
      floatingFilter: true,
      field: "GTTC",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 150,
      headerName: "Total",
      filterParams: {
        buttons: ["apply", "clear", "cancel", "reset"],
      },
      // rowGroup: true,
      cellRenderer: (params: any) => <span>{params.data.GTTC} €</span>,
    },
    {
      floatingFilter: true,
      field: "MTVA",
      filter: "agNumberColumnFilter",
      sortable: true,
      width: 150,
      headerName: "Montant TVA",
      filterParams: {
        buttons: ["apply", "clear", "cancel", "reset"],
      },
      // rowGroup: true,
      cellRenderer: (params: any) => <span>{params.data.MTVA} €</span>,
    },
    {
      floatingFilter: true,
      field: "FRN.ddress",
      filter: "agTextColumnFilter",
      sortable: true,
      width: 150,
      headerName: "Adresse",
      filterParams: {
        buttons: ["apply", "clear", "cancel", "reset"],
      },
      // rowGroup: true,
    },
    {
      floatingFilter: true,
      field: "FRN.city",
      width: 150,
      filter: "agTextColumnFilter",
      sortable: true,
      headerName: "Ville",
      filterParams: {
        buttons: ["apply", "clear", "cancel", "reset"],
      },
      // rowGroup: true,
    },
    {
      floatingFilter: true,
      field: "FRN.pays",
      filter: "agTextColumnFilter",
      sortable: true,
      width: 150,
      headerName: "Pays",
      filterParams: {
        buttons: ["apply", "clear", "cancel", "reset"],
      },
      // rowGroup: true,
    },
    {
      floatingFilter: true,
      field: "FRN.postalCode",
      filter: "agTextColumnFilter",
      sortable: true,
      headerName: "Code Postal",
      width: 150,
      filterParams: {
        buttons: ["apply", "clear", "cancel", "reset"],
      },
      // rowGroup: true,
    },
    {
      floatingFilter: true,
      field: "FRN.email",
      width: 180,
      filter: "agTextColumnFilter",
      sortable: true,
      headerName: "E-mail",
      filterParams: {
        buttons: ["apply", "clear", "cancel", "reset"],
      },
      // rowGroup: true,
    },
    {
      floatingFilter: true,
      field: "FRN.mobile",
      width: 150,
      filter: "agTextColumnFilter",
      sortable: true,
      headerName: "Numéro Mobile",
      filterParams: {
        buttons: ["apply", "clear", "cancel", "reset"],
      },
      // rowGroup: true,
    },
    {
      floatingFilter: true,
      field: "perimeter.name",
      width: 150,
      filter: "agTextColumnFilter",
      sortable: true,
      headerName: "Produit",
      filterParams: {
        buttons: ["apply", "clear", "cancel", "reset"],
      },
      // rowGroup: true,
    },
    {
      floatingFilter: true,
      field: "session.name",
      width: 150,
      filter: "agTextColumnFilter",
      sortable: true,
      headerName: "Périmètre du BDC",
      filterParams: {
        buttons: ["apply", "clear", "cancel", "reset"],
      },
      // rowGroup: true,
    },
    {
      floatingFilter: true,
      field: "typeDepense.name",
      width: 150,
      filter: "agTextColumnFilter",
      sortable: true,
      headerName: "Type de dépense",
      filterParams: {
        buttons: ["apply", "clear", "cancel", "reset"],
      },
      // rowGroup: true,
    },
    {
      floatingFilter: true,
      field: "pole.description",
      width: 150,
      filter: "agTextColumnFilter",
      sortable: true,
      headerName: "Pôle / Centre de coûts",
      filterParams: {
        buttons: ["apply", "clear", "cancel", "reset"],
      },
      // rowGroup: true,
    },
    {
      floatingFilter: true,
      field: "project.name",
      width: 150,
      filter: "agTextColumnFilter",
      sortable: true,
      headerName: "Projet",
      filterParams: {
        buttons: ["apply", "clear", "cancel", "reset"],
      },
      // rowGroup: true,
    },
    {
      floatingFilter: true,
      field: "missionInformation",
      width: 150,
      filter: "agTextColumnFilter",
      sortable: true,
      headerName: "Informations de la mission",
      filterParams: {
        buttons: ["apply", "clear", "cancel", "reset"],
      },
      // rowGroup: true,
    },
    {
      floatingFilter: true,
      field: "startDate",
      filter: "agDateColumnFilter",
      sortable: true,
      width: 180,
      headerName: "Date début BDC",
      filterParams: {
        buttons: ["apply", "clear", "cancel", "reset"],
      },
      // rowGroup: true,
    },
    {
      floatingFilter: true,
      field: "endDate",
      filter: "agDateColumnFilter",
      sortable: true,
      width: 180,
      headerName: "Date fin BDC",
      filterParams: {
        buttons: ["apply", "clear", "cancel", "reset"],
      },
      // rowGroup: true,
    },
  ]);

  const rowStyle: any = {
    fontFamily: "Roboto",
    fontWeigth: 700,
    fontSize: "14px",
    lineHeigth: "17.58px",
    color: "#3F4254",
  };

  const cellClickedListener = useCallback((event: any) => {
    dispatch(ordersActions.selectOrdertoEdit(event.data));
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <Grid container direction="row" alignItems="stretch">
        <Grid style={{ marginBottom: "40px" }} item xs={12}>
          <Typography
            component="span"
            fontWeight="500"
            fontSize="20px"
            lineHeight="23px"
            sx={{ color: "#181C32" }}
          >
            Bons de commande ({ordersNumber})
          </Typography>
        </Grid>
        <Grid item xs={6}>
        </Grid>
        <Grid item xs={6} >
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <FormControl
              style={{
                width: "30%",
                background: "#e6f4ff",
                borderRadius: "5px"
              }}
            >
              <InputLabel
                style={{
                  background: "#e6f4ff",
                  padding: "1px",
                  color: "black"
                }}
                id="demo-simple-select-label"
              >
                Sélectionner une vue
              </InputLabel>
              <Select
                // disableUnderline={true}
                placeholder="Sélectionner une vue"
                sx={{
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: "0 #e3d3f2", }
                }}
                value={selectedOrderView?._id ?? defaultView._id}
                style={{
                  height: "30px",
                  margin: "6px",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
              >
                <MenuItem
                  key={defaultView._id}
                  value={defaultView._id}
                  style={{ height: "40px" }}
                  onClick={() => {
                    resetState();
                    dispatch(
                      DataGridStatesActions.clearSelectedDataGridStateOrder()
                    );
                  }}
                >
                  {" "}
                  <Checkbox
                    checked={defaultView.favorite}
                    icon={<FavoriteBorder />}
                    checkedIcon={<Favorite />}
                  />
                  {defaultView.viewName}{" "}
                </MenuItem>
                {statesList
                  .filter(
                    (view: IDataGridState) =>
                      view.dataGridName === DataGridStateEnum.Order
                  )
                  .map((view: IDataGridState) => {
                    return (
                      <MenuItem
                        key={view._id}
                        value={view._id}
                        style={{ height: "40px" }}
                        onClick={() => {
                          selectView(view.colState);
                          dispatch(
                            DataGridStatesActions.setSelectDataGridStateOrder(
                              view
                            )
                          );
                        }}
                      >
                        {" "}
                        <Checkbox
                          key={view._id}
                          checked={view.favorite}
                          icon={<FavoriteBorder />}
                          checkedIcon={<Favorite />}
                        />
                        {view.viewName}{" "}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <IconButton onClick={() => {
              dispatch(uiActions.setViewSettingsOpen(true));
            }} aria-label="settings" color="default">
              <SettingsIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                dispatch(uiActions.setAddViewFormOpen(true));
              }}
              aria-label="sauvegarder"
              color="primary"
            >
              <SaveIcon />
            </IconButton>
            <CustomToolbar downloadExcel={downloadExcel} />
            <Button
              sx={{
                margin: "0 0 0 10px",
                boxShadow: "none",
                backgroundColor: "#1DC894",
                textTransform: "none",
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: "#1DC894",
                },
              }}
              variant="contained"
              endIcon={<FileDownloadIcon />}
              onClick={() => downloadExcel(ordersList)}
            >
              Excel
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          xs={!customSideBarOpen ? 11.5 : 10}
          justifyContent="center"
          alignItems="center"
          className="ag-theme-alpine"
          // spacing={0}
          mt={1}
          style={{ height: "75vh" }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rows}
            localeText={AG_GRID_LOCALE_FR}
            onGridReady={onGridReady}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            groupDisplayType={groupDisplayType}
            animateRows={true}
            onCellClicked={cellClickedListener}
            rowStyle={rowStyle}
            onRowClicked={(e: any) => {
              dispatch(ordersActions.selectOrdertoEdit(e.data));
            }}
            enableCellTextSelection
          />
        </Grid>
        <Grid style={{ height: "75vh", overflow: "hidden" }} item xs={!customSideBarOpen ? 0.5 : 2} mt={1}>
          <CustomSideBar
            gridColumnApi={gridColumnApi}
            setCustomSideBarOpen={setCustomSideBarOpen}
          />
        </Grid>
      </Grid>

      {/* add view dialog */}
      <Dialog
        fullScreen
        open={addViewFormOpen}
        onClose={closeAddViewform}
        sx={{ "& .MuiPaper-root": { height: "25vh", width: "40vw" } }}
        maxWidth="lg"
      >
        <CustomDialogTitle onClose={closeAddViewform} id="">
          Sauvgarder la vue
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          <AddView gridRef={gridRef} closeAddViewform={closeAddViewform} />
        </DialogContent>
      </Dialog>

      {/* settings view dialog */}
      <Dialog
        fullScreen
        open={viewSettingsOpen}
        onClose={closeSettingsViewform}
        sx={{ "& .MuiPaper-root": { height: "85vh", width: "30vw" } }}
        maxWidth="lg"
      >
        <CustomDialogTitle onClose={closeSettingsViewform} id="">
          Liste des vues disponible
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          <ViewSettings
            views={statesList.filter(
              (view: IDataGridState) =>
                view.dataGridName === DataGridStateEnum.Order
            )}
          />
        </DialogContent>
        <DialogActions>
          <WhiteButton onClick={closeSettingsViewform}>Annuler</WhiteButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
