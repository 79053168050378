import { useFormik } from "formik";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";

// import TextField, { TextFieldProps } from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
// import {FilledInputProps} from "@mui/material";
import { uiActions } from "store/ui/ui.state";
import {
  addAndSendEmailSupplierThunk,
  addOnlySupplierThunk,
} from "store/suppliers/suppliers.thunk";
// import type {Supplier} from "store/suppliers/suppliers.state"
import { CustomTextField as ITextField } from "components/custom-textfield/CustomTextField.component";
import { margin } from "../variables";
import BlueButton from "components/blue-button/BlueButton.component";
import { useState } from "react";
import GrayButton from "components/gray-button/GrayButton.component";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ClearIcon from "@mui/icons-material/Clear";
import FormHelperText from "@mui/material/FormHelperText";
import EditIcon from "@mui/icons-material/Edit";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { CustomTextField } from "components/custom-textfield/CustomTextField.component";
import Checkbox from "@mui/material/Checkbox";

type ErrorProp = boolean | undefined;

export default function InviteForm() {
  const [sendEmail, setSendEmail] = useState(false);
  const [hideTva, setHideTva] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const civiliteList = useAppSelector((state) => state.civilite.list);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Mauvais format d'e-mail")
        .required("Obligatoire"),
    }),
    onSubmit: (values) => {
      dispatch(addAndSendEmailSupplierThunk(values));

      // console.log("sendEmail ", sendEmail);
      dispatch(uiActions.setInviteSupplierFormOpen(false));
    },
  });
  const showEmailError = (formik.errors.email &&
    formik.touched.email) as ErrorProp;

  const handleTvaChange = (value: string) => {
    // console.log(value);
    if (value == "false") {
      setHideTva(true);
      formik.setFieldValue("tva", 0);
    } else {
      setHideTva(false);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography component="span" fontWeight="bold">
        E-mail{" "}
        <Typography component="span" color="error">
          *
        </Typography>
      </Typography>
      <ITextField
        {...formik.getFieldProps("email")}
        placeholder=" Email"
        error={showEmailError}
        helperText={showEmailError && formik.errors.email}
        sx={{ marginBottom: margin }}
      />

      <Box display="flex" justifyContent="center" gap="10px" marginTop="20px">
        <GrayButton
          onClick={() => dispatch(uiActions.setInviteSupplierFormOpen(false))}
        >
          Annuler
        </GrayButton>
        <BlueButton
          variant="contained"
          type="submit"
          sx={{ textTransform: "none" }}
          onClick={() => setSendEmail(true)}
          disabled={!formik.isValid}
        >
          Inviter
        </BlueButton>
      </Box>
    </form>
  );
}
