import Snackbar from "@mui/material/Snackbar";
import { feedbackMessageUIActions } from "store/feedback-message-ui/feedbackMessageUI.state";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";

export default function TempFbMsg() {
  const dispatch = useAppDispatch();
  const feedbackMessageUI = useAppSelector((state) => state.feedbackMessageUI);
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(feedbackMessageUIActions.setState({ open: false, message: "" }));
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={feedbackMessageUI.open}
      autoHideDuration={5000}
      onClose={handleClose}
      message={feedbackMessageUI.message}
    />
  );
}
