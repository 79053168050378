import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import IconTextChip from "components/icon-text-chip/IconTextChip.component";
import Box from "@mui/material/Box";
import TabButton from "components/tab-button/TabButton.component";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import { User } from "store/users/users.state";
import Duotone from "assets/Duotone.svg";
import verified from "assets/verified.svg";
import { uiActions } from "store/ui/ui.state";
import ProfileAvatarBadge from "components/profile-avatar-badge/ProfileAvatarBadge.component";


export default function ProfileCard() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user) as User;
  // console.log('🚀 ~ ProfileCard ~ user', user)
  const suppliersNumber = useAppSelector(
    (state) => state.suppliers.list.length
  );

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          padding: "30px 30px 0 30px",
          marginBottom: "30px",
          borderRadius: "12px",
        }}
      >
        <Grid container gap="20px">
          <Grid item marginRight="10px">
            <ProfileAvatarBadge variant="dot">
              <Avatar
                title="Changer l'image du profil"
                sx={{
                  height: "160px",
                  width: "160px",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                src={user.avatarUrl}
                onClick={() =>
                  dispatch(uiActions.setUploadAvatarFormOpen(true))
                }
              >
                {user.lastName + " " + user.firstName}
              </Avatar>
            </ProfileAvatarBadge>
          </Grid>
          <Grid
            item
            flex="1"
            minWidth="327px"
            // paddingLeft="50px"
            // paddingTop="50px"
          >
            <Typography
              fontWeight={700}
              fontSize="19px"
              lineHeight="22px"
              sx={{
                color: "#3F4254",
                display: "flex",
                alignItems: "center",
                gap: "7px",
              }}
            >
              {user.lastName.toUpperCase() + " " + user.firstName}
              <Avatar src={verified} sx={{ height: "21px", width: "21px" }} />
            </Typography>
            <Box>
              <IconTextChip
                sx={{ marginRight: "36px" }}
                avatarSrc={Duotone}
                label={user.role.name}
              />
              {/*        <IconTextChip
               sx={{ marginRight: "36px" }}
                avatarSrc={Position}
                label="Paris, 75"
              />
              <IconTextChip avatarSrc={at} label="artlineinstitut" />*/}
            </Box>
            {/* <NumberTextChip
              primaryText="$6000"
              secondaryText="Factures"
              sx={{ marginRight: "20px" }}
            />
            <NumberTextChip
              primaryText={suppliersNumber.toString()}
              secondaryText="Fournisseurs"
            /> */}
          </Grid>
          <Grid item>
            <Grid
              container
              justifyContent="space-between"
              height="100%"
              minHeight="100px"
              width="320px"
              // spacing="12px"
            >
              <Grid item>
                {/* <GrayButton sx={{ padding: "12px 20px" }}>
                  Ajouter un Bon
                </GrayButton> */}
              </Grid>
              <Grid item>
                {/* <BlueButton sx={{ padding: "12px 20px" }}>
                  Lier une facture
                </BlueButton> */}
              </Grid>
              <Grid item xs={12} marginLeft="12px" alignSelf="flex-end">
                {/* <CustomProgress text="Complétion Profil" value={100} /> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ margin: "20px 0 17px 0" }} />
        <TabButton isActive={true} sx={{ marginRight: "20px" }}>
          Vue d'ensemble
        </TabButton>
        {/* <TabButton isActive={false}>Documents</TabButton> */}
      </Paper>
    </>
  );
}
