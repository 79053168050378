import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import { useAppSelector, useAppDispatch } from "hooks/redux.hook";
import { Civilite, civiliteActions } from "store/civilite/civilite.state";
import { deleteCiviliteThunk } from "store/civilite/civilite.thunk";
import { uiActions } from "store/ui/ui.state";
import { Role } from "store/roles/roles.state";
import BlueButton from "components/blue-button/BlueButton.component";
import RedButton from "components/red-button/RedButton";
import { IArticle, articlesActions } from "store/article/article.state";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import { getArticlesThunk } from "store/article/article.thunk";

export default function List(props) {
  const dispatch = useAppDispatch();
  const list = useAppSelector((state) => state.articles.list);

  const authenticatedUser = useAppSelector((state) => state.auth.user);

  const suppList = useAppSelector((state) => state.suppliers.list)!;

  const handleDelete = (id: string) => {
    // console.log('HANDLE DELTE')
    dispatch(uiActions.setDeleteArticleWarningOpen({ set: true, id: id }));
  };

  const handleEdit = (data: IArticle) => {
    // console.log(data);
    dispatch(articlesActions.setSelectArticle(data));
  };
  useEffect(() => {
    dispatch(getArticlesThunk());
  }, [props.didUpdate, props.onDelete]);
  return (
    <>
      <Grid
        container
        direction="row"
        spacing={2}
        width="100%"
        margin="0px"
        >
        {list.length === 0 ? (
          <Typography> Il n'y a pas d'articles</Typography>
        ) : (
          <table style={{ width: "100%" }}>
            <thead style={{ backgroundColor: "white" }}>
              <tr
                style={{ borderBottom: "1px solid black", fontSize: "20px" }}
              >
                <th>Nom de l'article</th>
                <th>Fournisseur</th>
                <th>Coût unitaire HT</th>
                <th>TVA</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {list.map((article: IArticle, index: number) => {
                return (
                  <tr key={index}>
                    <td style={{ fontSize: "15px" }}>{article.nomArticle}</td>
                    <td style={{ fontSize: "15px" }}>
                      {suppList?.find((t) => t.id === article.fournisseur)?.nom} {suppList?.find((t) => t.id === article.fournisseur)?.prenom}
                    </td>
                    <td style={{ fontSize: "15px" }}> {article.HT} €</td>
                    <td style={{ fontSize: "15px" }}> {article.TVA} %</td>
                    <td>
                      <Button
                        onClick={() => handleEdit(article)}
                        color="info"
                        disabled={
                          !(
                            authenticatedUser!.role as Role
                          ).managmentRules.includes("update")
                        }
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        color="error"
                        onClick={() => {
                          handleDelete(article._id);

                        }}
                        disabled={
                          !(
                            authenticatedUser!.role as Role
                          ).managmentRules.includes("delete")
                        }
                      >
                        <DeleteIcon />
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </Grid>
      {/* {list.map((tva) => (
          <Grid key={tva.id} item xs={12} md={6} lg={4} xl={3}>
            <Card
              sx={{ height: "100%", display: "flex", flexDirection: "column" }}
              elevation={0}
            >
              <CardContent sx={{ flex: "1" }}>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ color: "#3F4254" }}
                >
                  {tva.value}
                </Typography>
              </CardContent>
              <CardActions>
                <BlueButton
                  onClick={() => handleEdit(tva)}
                  // sx={{ marginRight: "20px", padding: "4px 10px" }}
                  disabled={
                    !(authenticatedUser!.role as Role).managmentRules.includes(
                      "update"
                    )
                  }
                >
                  Edition
                </BlueButton>
                <RedButton
                  onClick={() => {
                    handleDelete(tva._id);
                  }}
                  disabled={
                    !(authenticatedUser!.role as Role).managmentRules.includes(
                      "delete"
                    )
                  }
                >
                  Suppression
                </RedButton>
              </CardActions>
            </Card>
          </Grid>
        ))} */}
    </>
  );
}
