import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import { useFormik } from "formik";
import * as Yup from "yup";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import BlueButton from "components/blue-button/BlueButton.component";

// import { uiActions } from "store/ui/ui.state";
import { editMySupplierProfileThunk, editSupplierThunk } from "store/suppliers/suppliers.thunk";
import { CustomTextField as ITextField } from "components/custom-textfield/CustomTextField.component";
import { Supplier } from "store/suppliers/suppliers.state";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import React, { useRef, useState } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AttachmentIcon from "@mui/icons-material/Attachment";
import ClearIcon from "@mui/icons-material/Clear";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// import {margin} from "../variables"

const margin = "20px";

type ErrorProp = boolean | undefined;

export default function EditForm() {
  const [pays, setPays] = useState<any>([])
  const [paysValue, setPaysValue] = useState<any>("France")
  const dispatch = useAppDispatch();
  const proofActRef = useRef<any>(null)!;
  const [ribFile, setRibFile] = useState<any>();
  const ribRef = useRef<any>(null)!;
  const [proofActivityFiles, setProofActivityFiles] = useState<any[]>([]);
  const [hideTva, setHideTva] = useState<boolean>(false);
  const [showData, setShowdata] = useState<boolean>(false);
  const [countryState, setCountryState] = useState<any>({
    loading: false,
    countries: [],
    errorMessage: "",
  });
  const civiliteList = useAppSelector((state) => state.civilite.list);

  // const supplier = useAppSelector(state => state.suppliers.supplier)!
  const supplier = useAppSelector((state) => state.auth.user) as Supplier;
  const civilite: any = supplier.civilite;
  const formik = useFormik({
    // initialValues: supplier,
    initialValues: {
      nom: supplier.nom,
      prenom: supplier.prenom,
      civilite: civilite._id,
      email: supplier.email,
      socialReason: supplier.socialReason,
      city: supplier.city,
      postalCode: supplier.postalCode,
      address: supplier.address,
      siret: supplier.siret,
      pays: supplier.pays,
      siren: supplier.siren,
      tva: supplier.tva,
      tel: supplier.tel,
      BIC: supplier.BIC,
      IBAN: supplier.IBAN,
      mobile: supplier.mobile.split(" ")[1],
    },
    validationSchema: Yup.object({
      civilite: Yup.string().required("Civilité obligatoire"),
      prenom: Yup.string()
        .min(3, "Too Short!")
        .max(30, "Too Long!")
        .required("Prenom obligatoire"),
      nom: Yup.string()
        .min(3, "Too Short!")
        .max(30, "Too Long!")
        .required("Nom obligatoire"),
      email: Yup.string()
        .email("Mauvais format d'e-mail")
        .required("Obligatoire"),
      socialReason: Yup.string(),
      BIC: Yup.string(),
      IBAN: Yup.string(),
      city: Yup.string().required("Obligatoire"),
      siret: Yup.string().length(14, "14 required"),
      address: Yup.string().required("Obligatoire"),
      postalCode: Yup.string().required("Obligatoire"),
      pays: Yup.string().required("Obligatoire"),
      siren: Yup.number(),
      tva: Yup.string().required("Obligatoire"),
      tel: Yup.number()
        .typeError("Cela ne ressemble pas à un numéro de téléphone")
        .positive("Un numéro de téléphone ne peut pas commencer par un moins")
        .integer("Un numéro de téléphone ne peut pas inclure de point décimal")
        .min(8)
        .required("Telephone obligatoire"),
      mobile: Yup.number()
        .typeError("Cela ne ressemble pas à un numéro de mobile")
        .positive("Un numéro de téléphone ne peut pas commencer par un moins")
        .integer("Un numéro de téléphone ne peut pas inclure de point décimal")
        .min(8)
        .required("Mobile obligatoire"),
    }),
    onSubmit: (values) => {
      // console.log(values);
      // dispatch(
      //   editSupplierThunk({ id: supplier.id, supplier: values })
      // );
      const country = countryState.countries.filter(
        (c: any) => c?.name.common === formik.values.pays
      )[0];

      const rib = ribRef.current!.files![0];
      const mob = formik.values.mobile;
      formik.values.mobile = `${country?.idd?.root}${country?.idd?.suffixes} ${mob}`;
      dispatch(
        editSupplierThunk({
          id: supplier.id,
          supplier: values,
          RIB: rib,
          ProofActivity: proofActRef.current!.files,
        })
      );
      // dispatch(uiActions.setEditSupplierFormOpen(false))
    },
  });

  const showCiviliteError = (formik.errors.civilite &&
    formik.touched.civilite) as ErrorProp;
  const showEmailError = (formik.errors.email &&
    formik.touched.email) as ErrorProp;
  const showSocialReasonError = (formik.errors.socialReason &&
    formik.touched.socialReason) as ErrorProp;
  const showCityError = (formik.errors.city &&
    formik.touched.city) as ErrorProp;
  const showSiretError = (formik.errors.siret &&
    formik.touched.siret) as ErrorProp;
  const showAddressError = (formik.errors.address &&
    formik.touched.address) as ErrorProp;
  const showPostalCodeError = (formik.errors.postalCode &&
    formik.touched.postalCode) as ErrorProp;
  const showPaysError = (formik.errors.pays &&
    formik.touched.pays) as ErrorProp;
  const showSirenError = (formik.errors.siren &&
    formik.touched.siren) as ErrorProp;
  const showTvaError = (formik.errors.tva && formik.touched.tva) as ErrorProp;
  const showPrenomError = (formik.errors.prenom &&
    formik.touched.prenom) as ErrorProp;
  const showNomError = (formik.errors.nom && formik.touched.nom) as ErrorProp;
  const showTelError = (formik.errors.tel && formik.touched.tel) as ErrorProp;
  const showMobileError = (formik.errors.mobile &&
    formik.touched.mobile) as ErrorProp;

  const handleTvaChange = (value: string) => {
    // console.log(value);
    if (value == "false") {
      setHideTva(true);
      formik.setFieldValue("tva", 0);
    } else {
      setHideTva(false);
    }
  };

  // React.useEffect(() => {

  //   handleTvaChange(supplier.tva)
  // }, []);
  const getCountries = async () => {
    try {
      setCountryState({
        ...countryState,
        loading: true,
      });

      // fetch data
      const dataUrl = "https://restcountries.com/v3.1/all";
      const response = await axios.get(dataUrl);
      const payListOptions: any = response.data?.map((e: any) => ({ name: e.name?.common }))
      setPays(payListOptions.sort((a: any, b: any) => (a?.name > b?.name) ? 1 : ((b?.name > a?.name) ? -1 : 0)))
      setCountryState({
        ...countryState,
        countries: response.data,
        loading: false,
      });

      // const country = countryState.countries.map(
      //   (c: any) => c.name.common === supplier.pays
      // )[0];
      // setSelectedCountry({ ...country });
    } catch (error) {
      setCountryState({
        ...countryState,
        loading: false,
        errorMessage: "Sorry Something went wrong!",
      });
    }
  };
  React.useEffect(() => {
    if (supplier) {
      setShowdata(true)
      const dt: any = []
      supplier?.ProofActivity?.forEach((elm: any) => {
        dt.push({
          filepdf: elm,
          name: elm.split('/uploads/')[1]
        })
      })
      setProofActivityFiles(dt)
    }
    getCountries()
    // if (supplier.pays) {
    //   const selected = countryState.countries.filter(
    //     (country: any) => country.name.common === formik.values.pays
    //   )[0];
    //   // setSelectedCountry(selected);
    // }
  }, [supplier]);

  return (
    <Paper elevation={0} sx={{ marginBottom: "30px", borderRadius: "12px", border: '1px solid red', }}>
      <Typography
        fontWeight={700}
        fontSize="19px"
        sx={{ color: "#3F4254" }}
        lineHeight="23px"
        padding="22px 0 19px 30px"
      >
        Informations de base
      </Typography>
      <Divider />
      {showData && (
        <form
          onSubmit={formik.handleSubmit}
          style={{ padding: "30px 30px 20px 30px" }}
        >
          <Grid container>
            <Grid item flex="1">
              <Typography
                fontWeight="500"
                fontSize="15px"
                lineHeight="18px"
                sx={{ color: "#3F4254" }}
              >
                Raison Social
              </Typography>
            </Grid>
            <Grid item width="450px">
              <ITextField
                InputProps={{ readOnly: true, disableUnderline: true }}
                {...formik.getFieldProps("socialReason")}
                error={showSocialReasonError}
                helperText={showSocialReasonError && formik.errors.socialReason}
                sx={{ marginBottom: margin }}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item flex="1">
              <Typography
                component="span"
                fontWeight="500"
                fontSize="15px"
                lineHeight="18px"
                sx={{ color: "#3F4254" }}
              >
                Civilité{" "}
                {/* <Typography component="span" color="error">
                *
              </Typography> */}
              </Typography>
            </Grid>
            <Grid item width="450px">
              <FormControl
                fullWidth
                size="small"
                error={showCiviliteError}
                sx={{
                  marginBottom: margin,
                  "& .MuiSelect-select": { padding: "13px 16px" },
                }}
              >
                <Select
                  {...formik.getFieldProps("civilite")}
                  // label="civilitesss"
                  // renderValue={(o) => o.id}
                  variant="filled"
                  sx={{
                    color: "#5E6278",
                    fontWeight: "500",
                    fontSize: "14px",
                    background: "#F5F8FA",
                    "&:before": {
                      border: "none",
                    },
                    "&:after": {
                      border: "none",
                    },
                  }}
                >
                  <MenuItem key="civilie" value="civilie" disabled>
                    Civilite
                  </MenuItem>
                  {civiliteList.map((c) => (
                    <MenuItem key={c.id} value={c.id}>
                      {c?.name}
                    </MenuItem>
                  ))}
                </Select>
                {/* <FormHelperText error>
                {showCiviliteError && formik.errors.civilite}
              </FormHelperText> */}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item flex="1">
              <Typography
                fontWeight="500"
                fontSize="15px"
                lineHeight="18px"
                sx={{ color: "#3F4254" }}
              >
                Nom
              </Typography>
            </Grid>
            <Grid item width="450px">
              <ITextField
                {...formik.getFieldProps("nom")}
                inputProps={{ style: { color: 'black' } }}
                error={showNomError}
                helperText={showNomError && formik.errors.nom}
                sx={{ marginBottom: margin }}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item flex="1">
              <Typography
                fontWeight="500"
                fontSize="15px"
                lineHeight="18px"
                sx={{ color: "#3F4254" }}
              >
                Prenom
              </Typography>
            </Grid>
            <Grid item width="450px">
              <ITextField
                {...formik.getFieldProps("prenom")}
                inputProps={{ style: { color: 'black' } }}
                error={showPrenomError}
                helperText={showPrenomError && formik.errors.prenom}
                sx={{ marginBottom: margin }}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item flex="1">
              <Typography
                fontWeight="500"
                fontSize="15px"
                lineHeight="18px"
                sx={{ color: "#3F4254" }}
              >
                BIC
              </Typography>
            </Grid>
            <Grid item width="450px">
              <ITextField
                inputProps={{ style: { color: 'black' } }}
                {...formik.getFieldProps("BIC")}
                // error={showPrenomError}
                // helperText={showPrenomError && formik.errors.prenom}
                sx={{ marginBottom: margin }}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item flex="1">
              <Typography
                fontWeight="500"
                fontSize="15px"
                lineHeight="18px"
                sx={{ color: "#3F4254" }}
              >
                IBAN
              </Typography>
            </Grid>
            <Grid item width="450px">
              <ITextField
                {...formik.getFieldProps("IBAN")}
                style={{ color: 'black' }}
                inputProps={{ style: { color: 'black' } }}
                // error={showPrenomError}
                // helperText={showPrenomError && formik.errors.prenom}
                sx={{ marginBottom: margin }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item flex="1">
              <Typography
                fontWeight="500"
                fontSize="15px"
                lineHeight="18px"
                sx={{ color: "#3F4254" }}
              >
                Pays
              </Typography>
            </Grid>
            <Grid item width="450px">
              {/* <ITextField
                {...formik.getFieldProps("pays")} 
                error={showPaysError}
                inputProps={{ style: { color: 'black' } }}
                helperText={showPaysError && formik.errors.pays}
                sx={{ marginBottom: margin }}
              /> */}
              <FormControl
                fullWidth
                size="small"
                error={showPaysError}
                sx={{
                  marginBottom: margin,
                  "& .MuiSelect-select": { padding: "10px" },
                }}
              >
                {/* <select
                  {...formik.getFieldProps("pays")}
                  // onClick={() => handleSelectedCountry(formik.values.pays)}
                  style={{
                    color: "#5E6278",
                    fontWeight: "500",
                    border: "none",
                    padding: "2.5%",
                    fontSize: "14px",
                    background: "#F5F8FA",
                  }}
                >
                  {countryState.countries.map((c: any, index: number) => (
                    <option key={index} value={c.name.common}>
                      {c.name.common}
                    </option>
                  ))}
                </select> */}
                <Autocomplete
                  {...formik.getFieldProps("pays")}
                  fullWidth
                  // sx={{
                  //   marginBottom: margin,
                  //   "& .MuiSelect-select": { padding: "10px" },
                  // }}
                  onChange={(event, newValue: any) => {
                    formik.values.pays = newValue.name
                    setPaysValue(newValue.name)
                  }}
                  getOptionLabel={(supp) => {
                    return supp?.name ? supp?.name : supp
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return option.name === value
                  }}
                  style={{
                    color: "#5E6278",
                    fontWeight: "500",
                    border: "none",
                    fontSize: "14px",
                    background: "#F5F8FA",
                  }}
                  // size='small'
                  value={paysValue}
                  options={pays}
                  renderInput={(params) => {
                    return (
                      <TextField  {...params} />
                    )
                  }}
                />
                <FormHelperText error>
                  {showPaysError && formik.errors.pays}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item flex="1">
              <Typography
                fontWeight="500"
                fontSize="15px"
                lineHeight="18px"
                sx={{ color: "#3F4254" }}
              >
                Siret
              </Typography>
            </Grid>
            <Grid item width="450px">
              <ITextField
                InputProps={{ readOnly: true, disableUnderline: true }}
                {...formik.getFieldProps("siret")}
                error={showSiretError}
                helperText={showSiretError && formik.errors.siret}
                sx={{ marginBottom: margin }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item flex="1">
              <Typography
                fontWeight="500"
                fontSize="15px"
                lineHeight="18px"
                sx={{ color: "#3F4254" }}
              >
                Siren
              </Typography>
            </Grid>
            <Grid item width="450px">
              <ITextField
                InputProps={{ readOnly: true, disableUnderline: true }}
                {...formik.getFieldProps("siren")}
                error={showSirenError}
                helperText={showSirenError && formik.errors.siren}
                sx={{ marginBottom: margin }}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item flex="1">
              <Typography
                fontWeight="500"
                fontSize="15px"
                lineHeight="18px"
                sx={{ color: "#3F4254" }}
              >
                Address
              </Typography>
            </Grid>
            <Grid item width="450px">
              <ITextField
                inputProps={{ style: { color: 'black' } }}
                {...formik.getFieldProps("address")}
                error={showAddressError}
                helperText={showAddressError && formik.errors.address}
                sx={{ marginBottom: margin }}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item flex="1">
              <Typography
                fontWeight="500"
                fontSize="15px"
                lineHeight="18px"
                sx={{ color: "#3F4254" }}
              >
                Code postal
              </Typography>
            </Grid>
            <Grid item width="450px">
              <ITextField
                {...formik.getFieldProps("postalCode")}
                error={showPostalCodeError}
                inputProps={{ style: { color: 'black' } }}
                helperText={showPostalCodeError && formik.errors.postalCode}
                sx={{ marginBottom: margin }}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item flex="1">
              <Typography
                fontWeight="500"
                fontSize="15px"
                lineHeight="18px"
                sx={{ color: "#3F4254" }}
              >
                Ville
              </Typography>
            </Grid>
            <Grid item width="450px">
              <ITextField
                {...formik.getFieldProps("city")}
                error={showCityError}
                inputProps={{ style: { color: 'black' } }}
                helperText={showCityError && formik.errors.city}
                sx={{ marginBottom: margin }}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item flex="1">
              <Typography
                fontWeight="500"
                fontSize="15px"
                lineHeight="18px"
                sx={{ color: "#3F4254" }}
              >
                Telephone
              </Typography>
            </Grid>
            <Grid item width="450px">
              <ITextField
                {...formik.getFieldProps("tel")}
                error={showTelError}
                inputProps={{ style: { color: 'black' } }}
                helperText={showTelError && formik.errors.tel}
                sx={{ marginBottom: margin }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item flex="1">
              <Typography
                fontWeight="500"
                fontSize="15px"
                lineHeight="18px"
                sx={{ color: "#3F4254" }}
              >
                Mobile
              </Typography>
            </Grid>
            <Grid item width="450px">
              <ITextField
                {...formik.getFieldProps("mobile")}
                error={showMobileError}
                inputProps={{ style: { color: 'black' } }}
                helperText={showMobileError && formik.errors.mobile}
                sx={{ marginBottom: margin }}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item flex="1">
              <Typography
                fontWeight="500"
                fontSize="15px"
                lineHeight="18px"
                sx={{ color: "#3F4254" }}
              >
                E-mail
              </Typography>
            </Grid>
            <Grid item width="450px">
              <ITextField
                {...formik.getFieldProps("email")}
                error={showEmailError}
                inputProps={{ style: { color: 'black' } }}
                helperText={showEmailError && formik.errors.email}
                sx={{ marginBottom: margin }}
              />
            </Grid>
          </Grid>
          {!hideTva && (
            <Grid container>
              <Grid item flex="1">
                <Typography
                  fontWeight="500"
                  fontSize="15px"
                  lineHeight="18px"
                  sx={{ color: "#3F4254" }}
                >
                  Numéro de TVA
                </Typography>
              </Grid>
              <Grid item width="450px">
                <ITextField
                  {...formik.getFieldProps("tva")}
                  error={showTvaError}
                  inputProps={{ style: { color: 'black' } }}
                  helperText={showTvaError && formik.errors.tva}
                  sx={{ marginBottom: margin }}
                />
              </Grid>
            </Grid>
          )}

          <Grid container style={{ marginBottom: "15px" }}>
            <Grid item flex="1">
              <Typography
                fontWeight="500"
                fontSize="15px"
                lineHeight="18px"
                sx={{ color: "#3F4254" }}
              >                RIB{" "}
              </Typography>
            </Grid>
            <Grid item width="450px">
              <Button
                variant="outlined"
                fullWidth
                startIcon={<AttachFileIcon />}
                style={{ marginBottom: "5px" }}
              >
                Upload
                <input
                  ref={ribRef}
                  type="file"
                  onChange={() => {
                    const file = ribRef.current?.files[0];
                    setRibFile(file);
                  }}
                  style={{
                    opacity: 0,
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </Button>
              {ribFile?.name && (
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  mb={2}
                >
                  {ribFile?.name}
                  {/* <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => {
                      // const files = proofActivityFiles;
                      // files.splice(index, 1);
                      // setProofActivityFiles([...files]);
                      setRibFile({})
                    }}
                    // startIcon={<ClearIcon />}
                    startIcon={<AttachFileIcon />}
                    style={{ marginBottom: "15px" }}
                  >

                  </Button> */}
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid container>
            <Grid item flex="1">
              <Typography
                fontWeight="500"
                fontSize="15px"
                lineHeight="18px"
                sx={{ color: "#3F4254" }}
              >                Justificatif d’activité{" "}
              </Typography>
            </Grid>
            <Grid item width="450px">
              <Button
                startIcon={<AttachFileIcon />}
                variant="outlined"
                style={{ marginBottom: "10px" }}
                fullWidth
              >
                Upload
                <input
                  ref={proofActRef}
                  type="file"
                  multiple
                  onChange={() => {
                    let files: any[] = []
                    proofActivityFiles?.forEach((elm: any) => files.push(elm))
                    // files.splice(index, 1);
                    // setProofActivityFiles([...files]);


                    // const files = proofActivityFiles;
                    // console.log(Array.from(proofActRef.current!.files));

                    Array.from(proofActRef.current!.files).map((file: any) => {
                      return files.push(file)
                    })
                    setProofActivityFiles([...files]);
                  }}
                  style={{
                    opacity: 0,
                    position: "absolute",
                    width: "50%",
                    height: "100%",
                  }}
                />
              </Button>
              {proofActivityFiles && proofActivityFiles?.length &&
                (
                  <div style={{ margin: '5px 0 20px 0' }}>
                    {proofActivityFiles.map((file: any, index: number) => {
                      return (
                        <ListItem key={index}>
                          <ListItemIcon onClick={() => window.open(file.filepdf)}>
                            <AttachmentIcon style={{ color: '#23659f', cursor: 'pointer' }} />
                          </ListItemIcon>
                          <ListItemText>{file?.name}</ListItemText>
                          <ListItemIcon
                            onClick={() => {
                              let files: any[] = []
                              proofActivityFiles?.forEach((elm: any) => files.push(elm))
                              files.splice(index, 1);
                              setProofActivityFiles([...files]);
                            }}
                          >
                            <ClearIcon style={{ color: '#9e1e3e', cursor: 'pointer' }} />
                          </ListItemIcon>
                        </ListItem>
                      )
                    })}
                  </div>
                )
              }
            </Grid>
          </Grid>
          <Box
            display="flex"
            justifyContent="flex-end"
            gap="10px"
            marginTop="20px"
          >
            {/* <Button
          sx={{
            textTransform: "none",
            color: (theme) => theme.palette.text.secondary,
          }}
          onClick={() => dispatch(uiActions.setEditSupplierFormOpen(false))}
        >
          Annuler
        </Button> */}
            <BlueButton
              variant="contained"
              type="submit"
              sx={{ textTransform: "none", padding: "12px 20px" }}
            >
              Soumettre
            </BlueButton>
          </Box>
        </form>
      )}
    </Paper>
  );
}
