import Button, { ButtonProps } from "@mui/material/Button";
import styled from "@emotion/styled";

// const RedButton = styled(Button)((props) => ({
//   textTransform: "none",
//   background: "#FE7373",
//   color: "#fff",
//   boxShadow: "none",
//   fontSize: "15px",
//   fontWeight: "700",
//   lineHeight: "18px",
//   "&:hover": {
//     background: "#FE7373",
//     boxShadow: "none",
//   },
// }));

const RedButton = ({sx, ...props}: ButtonProps) => (
  <Button
  {...props}
  sx={{
    textTransform: "none",
    background: "#FE7373",
    color: "#fff",
    boxShadow: "none",
    fontSize: "15px",
    fontWeight: "700",
    lineHeight: "18px",
    padding: "12px 20px",
    "&:hover": {
      background: "#FE7373",
      boxShadow: "none",
    },
    ...sx
  }}
  />
)


export default RedButton