import { Grid, Typography } from "@mui/material";
import { CustomTextField } from "components/custom-textfield/CustomTextField.component";
import GreenButton from "components/green-button/GreenButton";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import * as Yup from "yup";
import { Role } from "store/roles/roles.state";
import { projectActions } from "store/project/project.state";
import { addProjectThunk, editProjectThunk } from "store/project/project.thunk";
import BlueButton from "components/blue-button/BlueButton.component";

const Form = () => {
    const authenticatedUser = useAppSelector((state) => state.auth.user);
    const dispatch = useAppDispatch();
    const selectedProjectToEdit = useAppSelector(
        (state) => state.project.selectedProjectToEdit
      );
    const isEditMode = selectedProjectToEdit !== null;
    const formik = useFormik({
        initialValues: {
          name: isEditMode && selectedProjectToEdit.name ? selectedProjectToEdit.name : "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
          name: Yup.string().required("Nom obligatoire"),
        }),
        onSubmit: (values, helpers) => {
          
          if (isEditMode) {
            dispatch(editProjectThunk({_id: selectedProjectToEdit._id || selectedProjectToEdit.id, name: values.name}))
            dispatch(projectActions.clearSelectedProjectToEdit());
          }
          else{
            dispatch(addProjectThunk(values))
          }
          helpers.resetForm();
        },
      });
    return (<form onSubmit={formik.handleSubmit}>
        <Grid container spacing="12px" marginBottom="20px">
        <Grid item xs={12} md={4}>
          <Typography
            component="span"
            fontWeight="bold"
            sx={{ color: "#3F4254" }}
          >
            Projet{" "}
            <Typography component="span" color="error">
              *
            </Typography>
          </Typography>
          <CustomTextField
            {...formik.getFieldProps("name")}
            //error={showNameError}
            //helperText={showNameError && formik.errors.name}
            placeholder="Nom du paramètre..."
            //ref={costForm}
            sx={{
              "& .MuiFilledInput-root": {
                background: "#fff",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
              <GreenButton
                variant="contained"
                sx={{
                  marginTop: "25px",
                  display: isEditMode ? "none" : "inline",
                }}
                type="submit"
                disabled={(!(authenticatedUser!.role as Role).managmentRules.includes("create")) || formik.values.name.trim().length === 0}
              >
                Ajouter
              </GreenButton>
              <BlueButton
            sx={{ display: !isEditMode ? "none" : "inline", marginTop: "25px" }}
            variant="contained"
            type="submit"
            disabled={
              !(authenticatedUser!.role as Role).managmentRules.includes(
                "update"
              )
            }
          >
            Editer
          </BlueButton>
              </Grid>
            </Grid>
    </form>)
}

export default Form