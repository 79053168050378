import React, { useState } from "react";
import { Field, FormikProvider, useFormik } from "formik";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { InvoiceStatusEnum } from "store/invoices/invoice.state";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import BlueButton from "components/blue-button/BlueButton.component";
import WhiteButton from "components/white-button/WhiteButton.component";
import {
  editInvoiceThunk,
  editStatusInvoiceThunk,
  getInvoicesThunk,
} from "store/invoices/invoice.thunk";
import { uiActions } from "store/ui/ui.state";
import { defaultTodayDate, validDate } from "utils/globalVariables";
interface IInvoiceStatusProps {
  handleCloseEditStatus: any;
}
type ErrorProp = boolean | undefined;

const InvoiceStatus: React.FC<IInvoiceStatusProps> = ({
  handleCloseEditStatus,
}) => {
  const dispatch = useAppDispatch();
  const selectedInvoice = useAppSelector(
    (state) => state.invoices.selectedInvoice
  )!;
  const formik = useFormik({
    initialValues: {
      status: selectedInvoice.status,
      dateDePaiementPrevisionnel: selectedInvoice.status === 'En paiement' ? validDate(selectedInvoice?.dateDePaiementPrevisionnel) : "",
      dateDePaiementReel: selectedInvoice.status === 'Payée' ? validDate(selectedInvoice?.dateDePaiementReel) : ""
    },
    validationSchema: Yup.object({
      status: Yup.string().required("Statut obligatoire"),
      dateDePaiementPrevisionnel: Yup.string().when('status', {
        is: (val: any) => ((val === "En paiement") ? true : false),
        then: Yup.string().required("Date de paiement prévisionnel obligatoire"),
      }),
      dateDePaiementReel: Yup.string().when('status', {
        is: (val: any) => ((val === "Payée") ? true : false),
        then: Yup.string().required("Date de paiement réel obligatoire"),
      }),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      // console.log('🚀 ~ onSubmit: ~ values:', values)
      try {
        await dispatch(
          editStatusInvoiceThunk({
            id: selectedInvoice?._id,
            status: values.status as InvoiceStatusEnum,
            Date: (values?.status === "Réceptionnée" || values?.status === "Validée" || values?.status === "Créée") ? defaultTodayDate as string : (values?.status === "En paiement") ? values.dateDePaiementPrevisionnel as string : values.dateDePaiementReel as string,
          })
          // editInvoiceThunk({ id: selectedInvoice?._id, invoice: values as any })
        );
        dispatch(uiActions.setEditInvoiceStatusOpen(false));
        await dispatch(getInvoicesThunk());
      } catch (e) {
        console.log(e);
      }
    },
  });
  const status = InvoiceStatusEnum;

  const showStatusError = (formik.errors.status &&
    formik.touched.status) as ErrorProp;
  const showdateDePaiementPrevisionnelError = (formik.errors.dateDePaiementPrevisionnel &&
    formik.touched.dateDePaiementPrevisionnel) as ErrorProp;
  const showdateDePaiementReelError = (formik.errors.dateDePaiementReel &&
    formik.touched.dateDePaiementReel) as ErrorProp;
  return (
    <form onSubmit={formik.handleSubmit}>
      <FormikProvider value={formik}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Typography component="span" fontWeight="bold">
              Statut de la facture{" "}
              <Typography component="span" color="error">
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Select
                error={showStatusError}
                {...formik.getFieldProps("status")}
              >
                {Object.values(status)
                  .filter((item: any) => item !== selectedInvoice.status)
                  .map((item: any, index: number) => {
                    if (selectedInvoice.status === "Validée") {
                      if (item !== "Créée" && item !== "En paiement" && item !== "Payée") {
                        return <MenuItem key={index} value={item}> {item} </MenuItem>;
                      }
                    }
                    if (selectedInvoice.status === "Réceptionnée") {
                      if (item !== "Créée" && item !== "Validée" && item !== "Payée") {
                        return <MenuItem key={index} value={item}> {item} </MenuItem>;
                      }
                    }
                    if (selectedInvoice.status === "En paiement") {
                      if (item !== "Créée" && item !== "Validée" && item !== "Réceptionnée") {
                        return <MenuItem key={index} value={item}> {item} </MenuItem>;
                      }
                    }
                    if (selectedInvoice.status === "Payée") {
                      if (item !== "Créée" && item !== "Validée" && item !== "Réceptionnée" && item !== "En paiement") {
                        return <MenuItem key={index} value={item}> {item} </MenuItem>;
                      }
                    }
                    if (selectedInvoice.status === "Créée") {
                      if (item !== "Réceptionnée" && item !== "En paiement" && item !== "Payée") {
                        return <MenuItem key={index} value={item}> {item} </MenuItem>;
                      }
                    }
                  })}
                <MenuItem selected value={selectedInvoice.status}> {selectedInvoice.status} </MenuItem>;
              </Select>
            </FormControl>
            <FormHelperText>
              {showStatusError && formik.errors.status}
            </FormHelperText>
          </Grid>
          {(formik.values.status === 'En paiement') && (
            <>
              <Grid item xs={12} mt={5}>
                <Typography component="span" fontWeight="bold">
                  Date de paiement prévisionnel{" "}
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  {...formik.getFieldProps("dateDePaiementPrevisionnel")}
                  variant="standard"
                  error={showdateDePaiementPrevisionnelError}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  style={{
                    padding: "1%",
                    height: "42px",
                    color: "#5E6278",
                    backgroundColor: "#F5F8FA",
                  }}
                  type="date"
                />{" "}
                <FormHelperText error>
                  {formik.errors.dateDePaiementPrevisionnel}
                </FormHelperText>
              </Grid></>
          )}
          {(formik.values.status === 'Payée') && (
            <>
              <Grid item xs={12} mt={5}>
                <Typography component="span" fontWeight="bold">
                  Date de paiement réel{" "}
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  {...formik.getFieldProps("dateDePaiementReel")}
                  variant="standard"
                  error={showdateDePaiementReelError}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  style={{
                    padding: "1%",
                    height: "42px",
                    color: "#5E6278",
                    backgroundColor: "#F5F8FA",
                  }}
                  type="date"
                />{" "}
                <FormHelperText error>
                  {formik.errors.dateDePaiementReel}
                </FormHelperText>
              </Grid></>
          )}
        </Grid>
        <Grid item xs={12} mt={5}>
          <BlueButton type="submit">Soumettre</BlueButton>
          <WhiteButton type="reset" onClick={handleCloseEditStatus}>
            Annuler
          </WhiteButton>
        </Grid>
      </FormikProvider>
    </form>
  );
};

export default InvoiceStatus;
