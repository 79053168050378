import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Perimeter = {
  id: string;
  name: string;
  description: string;
  _id?: string; //<--- using this in edit/add user form
};

type InitialState = {
  selectedPerimeterToEdit: Perimeter | null;
  list: Perimeter[];
};

const initialState: InitialState = {
  selectedPerimeterToEdit: null,
  list: [],
};

const perimetersSlice = createSlice({
  name: "perimeters",
  initialState,
  reducers: {
    setList(state, action: PayloadAction<Perimeter[]>) {
      state.list = action.payload;
    },
    addPerimeter(state, action: PayloadAction<Perimeter>) {
      state.list.push(action.payload);
    },
    deletePerimeter(state, action: PayloadAction<string>) {
      // pauload: user id
      state.list = state.list.filter((perimeter) => perimeter.id !== action.payload);
    },
    editPerimeter(state, action: PayloadAction<{ id: string; perimeter: Perimeter }>) {
      state.list = state.list.map((perimeter) => {
        if (perimeter.id === action.payload.id) return action.payload.perimeter;
        return perimeter;
      });
    },
    selectPerimetertoEdit(state, action: PayloadAction<string>) {
      // payload: user id
      // NOTE: object spread doesnt deep copy objects
      state.selectedPerimeterToEdit = {
        ...state.list.find((perimeter) => perimeter.id === action.payload)!,
      };
    },
    clearSelectedPerimeterToEdit(state) {
      state.selectedPerimeterToEdit = null;
    },
  },
});

export const perimetersActions = perimetersSlice.actions;
export const perimetersReducer = perimetersSlice.reducer;
