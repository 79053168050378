import Typography from "@mui/material/Typography";

import { DataGrid, frFR } from "@mui/x-data-grid";
import useColumns from "pages/managment-rules/hooks/useColumns.hook";
import { rows } from "./rows";

export default function SDataGrid() {
  const columns = useColumns();
  return (
    <>
      <Typography variant="h3" sx={{ color: "#3F4254", marginBottom: "41px" }}>
        Règles de gestion
      </Typography>
      <div style={{ display: "flex" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={11}
          hideFooter
          autoHeight
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide columns email & fullName, the other columns will remain visible
                email: false,
                firstName: false,
                lastName: false,
              },
            },
          }}
          components={
            {
              // Toolbar: CustomToolbar,
              // Pagination: CustomPagination,
            }
          }
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          sx={{
            background: "white",
            border: "none",
            padding: "20px",
            "& .gray-bold": {
              color: "#B5B5C3",
              fontWeight: "bold",
            },
            "& .actions-p-r": {
              paddingRight: "25px",
            },
            "& .cell": {
              color: "#3F4254",
              fontWeight: 700,
            },
            "& .roles-actions": {
              justifyContent: "right",
            },
            "& .MuiInput-underline:before": {
              display: "none",
            },
            "& .MuiInput-root:after": {
              display: "none",
            },
            "& .MuiInput-root": {
              background: "rgba(0, 0, 0, 0.06)",
              paddingLeft: "10px",
              width: "190px",
              borderRadius: "5px",
              color: "gray",
            },
            "& .MuiDataGrid-columnSeparator--sideRight": {
              display: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "1px dashed rgba(224, 224, 224, 1)",
            },
            "& .MuiDataGrid-columnHeaders": {
              borderBottom: "1px dashed rgba(224, 224, 224, 1)",
            },
            "& .MuiDataGrid-footerContainer": {
              border: "none",
            },
          }}
        />
      </div>
    </>
  );
}
