import { useAppSelector, useAppDispatch } from "hooks/redux.hook";
import { uiActions } from "store/ui/ui.state";
import { useFormik } from "formik";
import * as Yup from "yup";
import { editRoleThunk } from "store/roles/roles.thunk";
import { addRelatedPermissions } from "utils/addRelatedPermissions"

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import CustomDialogTitle from "components/custom-dialog-title/CustomDialogTitle.component";
import EditForm from "./EditForm.component";
import CloseEditRoleConfirmation from "../close-confiramtion-dialogs/CloseEditRoleConfirmation.component";
import { useEffect } from "react";
import { compareRoleFormStates } from "utils/compareRoleFormStates"




export default function EditFormDialog() {
    const dispatch = useAppDispatch();
    const {
      editRoleFormOpen,
    } = useAppSelector((state) => state.ui);
    const selectedRoleToEdit = useAppSelector(
      (state) => state.roles.selectedRoleToEdit
    )!;
    const formik = useFormik({
      initialValues: selectedRoleToEdit,
      validationSchema: Yup.object({
        name: Yup.string().required("Obligatoire"),
        description: Yup.string(),
      }),
      onSubmit: (values) => {
        // console.log(values);
        const modifiedValues = addRelatedPermissions(values)
        dispatch(editRoleThunk({ id: selectedRoleToEdit.id, role: modifiedValues }));
        dispatch(uiActions.setEditRoleFormOpen(false));
      },
    });


    const closeEditRoleForm = () => {
        const userDidNotChangeForm = compareRoleFormStates(formik.initialValues, formik.values)
        if(userDidNotChangeForm) {
          dispatch(uiActions.setEditRoleFormOpen(false))
        } else {
          dispatch(uiActions.setCloseEditRoleConfirmationOpen(true));
        }
      };


      useEffect(() => {
        // reset form when ever selectedRoleToEdit changes
        formik.resetForm({values: selectedRoleToEdit})
      }, [selectedRoleToEdit, editRoleFormOpen])
    return(
        <Dialog open={editRoleFormOpen} onClose={closeEditRoleForm} maxWidth={false}>
        <CustomDialogTitle onClose={closeEditRoleForm} id="">
          Modifier un rôle
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          <EditForm formik={formik} handleAnnuler={closeEditRoleForm}/>
          <CloseEditRoleConfirmation />
        </DialogContent>
      </Dialog>
    )
}