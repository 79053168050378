import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { CustomTextField } from "components/custom-textfield/CustomTextField.component";
import { useAppSelector, useAppDispatch } from "hooks/redux.hook";
import { useFormik } from "formik";
import * as Yup from "yup";
import { poleActions } from "store/pole/pole.state";
// import { v4 as uuidv4 } from "uuid";
import { addPoleThunk, editPoleThunk } from "store/pole/pole.thunk";
import { Role } from "store/roles/roles.state";
import BlueButton from "components/blue-button/BlueButton.component";
import GreenButton from "components/green-button/GreenButton";

type ErrorProp = boolean | undefined;

export default function Form() {
  const dispatch = useAppDispatch();
  const selectedPoleToEdit = useAppSelector(
    (state) => state.pole.selectedPoleToEdit
  );
  const isEditMode = selectedPoleToEdit !== null;

  const authenticatedUser = useAppSelector((state) => state.auth.user);

  const formik = useFormik({
    initialValues: {
      name:
        isEditMode && selectedPoleToEdit.name ? selectedPoleToEdit.name : "",
      description:
        isEditMode && selectedPoleToEdit.description
          ? selectedPoleToEdit.description
          : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Nom obligatoire"),
      description: Yup.string(),
    }),
    onSubmit: (values, helpers) => {
      // console.log(values);
      if (isEditMode) {
        // dispatch(
        //   civiliteActions.editCivilite({
        //     id: selectedCiviliteToEdit.id,
        //     civilite: values as any,
        //   })
        // );
        dispatch(editPoleThunk({ id: selectedPoleToEdit.id, pole: values }));
        dispatch(poleActions.clearSelectedPoleToEdit());
      } else {
        // dispatch(civiliteActions.addCivilite({ id: uuidv4(), ...values }));
        dispatch(addPoleThunk(values));
      }
      helpers.resetForm();
    },
  });

  const showNameError = (formik.errors.name &&
    formik.touched.name) as ErrorProp;
  const showDescriptionError = (formik.errors.description &&
    formik.touched.description) as ErrorProp;
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing="12px" marginBottom="20px">
        <Grid item xs={12} md={4}>
          <Typography
            component="span"
            fontWeight="bold"
            sx={{ color: "#3F4254" }}
          >
            Nom du pôle{" "}
            <Typography component="span" color="error">
              *
            </Typography>
          </Typography>
          <CustomTextField
            {...formik.getFieldProps("name")}
            error={showNameError}
            helperText={showNameError && formik.errors.name}
            placeholder="Nom du paramètre..."
            sx={{
              "& .MuiFilledInput-root": {
                background: "#fff",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            component="span"
            fontWeight="bold"
            sx={{ color: "#3F4254" }}
          >
            Description{" "}
          </Typography>
          <CustomTextField
            {...formik.getFieldProps("description")}
            error={showDescriptionError}
            helperText={showDescriptionError && formik.errors.description}
            placeholder="Description..."
            multiline
            maxRows={4}
            minRows={4}
            sx={{
              "& .MuiFilledInput-root": {
                background: "#fff",
                color: "#5E6278",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <GreenButton
            variant="contained"
            sx={{
              marginTop: "25px",
              display: isEditMode ? "none" : "inline",
            }}
            type="submit"
            disabled={
              !(authenticatedUser!.role as Role).managmentRules.includes(
                "create"
              )
            }
          >
            Ajouter
          </GreenButton>
          <BlueButton
            sx={{ display: !isEditMode ? "none" : "inline", marginTop: "25px" }}
            variant="contained"
            type="submit"
            disabled={
              !(authenticatedUser!.role as Role).managmentRules.includes(
                "update"
              )
            }
          >
            Editer
          </BlueButton>
        </Grid>
      </Grid>
    </form>
  );
}
