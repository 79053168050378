import { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import * as Yup from "yup";

import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ClearIcon from "@mui/icons-material/Clear";
import FormHelperText from "@mui/material/FormHelperText";
import EditIcon from "@mui/icons-material/Edit";

import { CustomTextField } from "components/custom-textfield/CustomTextField.component";
import BlueButton from "components/blue-button/BlueButton.component";
// import RadioLabel from "../radio-label/RadioLabel.component";
import RadioLabel from "components/radio-label/RadioLabel.component";
import { USER_ROLES } from "utils/roles";
import { editMyUserProfileThunk } from "store/users/users.thunk";
// import { margin } from "../variables";
// import { DashedDivider } from "../dashed-divider/DottedDivider.component";
import { DashedDivider } from "components/dashed-divider/DottedDivider.component";
import useImageBase64Encoder from "hooks/useImageBase64Ecoder";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { User } from "store/users/users.state";
import Divider from "@mui/material/Divider";
import { getRolesThunk } from "store/roles/roles.thunk";
import CustomRadioButton from "components/custom-radio-button/CutomRadioButton.component";

type ErrorProp = boolean | undefined;

const margin = "20px";

export default function EditForm() {
  const dispatch = useAppDispatch();
  //   const selectedUsertoEdit = useAppSelector(
  //     (state) => state.users.selectedUserToEdit!
  //   );
  const user = useAppSelector((state) => state.auth.user) as User;
  const postesList = useAppSelector((state) => state.postes.list);
  const civiliteList = useAppSelector((state) => state.civilite.list);
  const poleList = useAppSelector((state) => state.pole.list);
  const rolesList = useAppSelector((state) => state.roles.list);
  const phoneRegExp = new RegExp(/^[1-9]\d*$/);
  const formik = useFormik({
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      role: user.role._id,
      tel: user.tel,
      civilite: user.civilite._id,
      pole: user.pole._id,
      position: user.position._id,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Prenom obligatoire"),
      lastName: Yup.string().required("Nom obligatoire"),
      email: Yup.string()
        .email("Mauvais format d'e-mail")
        .required("E-mail obligatoire"),

      tel: Yup.string().length(10),
      civilite: Yup.string().required("Civilité obligatoire"),
      pole: Yup.string().required("Pole obligatoire"),
      position: Yup.string().required("Poste obligatoire"),
    }),
    onSubmit: (values) => {
      // console.log(values);
      dispatch(
        editMyUserProfileThunk({
          id: user.id,
          user: values as any,
        })
      );
      //   props.handleCloseEditDialog();
    },
  });

  const showFirstNameError = (formik.errors.firstName &&
    formik.touched.firstName) as ErrorProp;
  const showLastNameError = (formik.errors.lastName &&
    formik.touched.lastName) as ErrorProp;
  const showEmailError = (formik.errors.email &&
    formik.touched.email) as ErrorProp;

  const showTelError = (formik.errors.tel && formik.touched.tel) as ErrorProp;
  const showCiviliteError = (formik.errors.civilite &&
    formik.touched.civilite) as ErrorProp;
  const showPoleError = (formik.errors.pole &&
    formik.touched.pole) as ErrorProp;
  const showPositionError = (formik.errors.position &&
    formik.touched.position) as ErrorProp;

  useEffect(() => {
    dispatch(getRolesThunk());
  }, []);

  return (
    <Paper elevation={0} sx={{ marginBottom: "30px", borderRadius: "12px" }}>
      <Typography
        fontWeight={700}
        fontSize="20px"
        sx={{ color: "#3F4254" }}
        lineHeight="23px"
        padding="22px 0 19px 30px"
      >
        Informations de base
      </Typography>
      <Divider />
      <form
        onSubmit={formik.handleSubmit}
        style={{ padding: "30px 30px 20px 30px" }}
      >
        <Grid container>
          <Grid item flex="1">
            <Typography
              component="span"
              fontWeight="500"
              fontSize="15px"
              lineHeight="18px"
              sx={{ color: "#3F4254" }}
            >
              Civilité{" "}
              {/* <Typography component="span" color="error">
                *
              </Typography> */}
            </Typography>
          </Grid>
          <Grid item width="473px">
            <FormControl
              fullWidth
              size="small"
              error={showCiviliteError}
              sx={{
                marginBottom: margin,
                "& .MuiSelect-select": { padding: "13px 16px" },
              }}
            >
              <Select
                {...formik.getFieldProps("civilite")}
                // label="civilitesss"
                variant="filled"
                sx={{
                  color: "#5E6278",
                  fontWeight: "500",
                  fontSize: "14px",
                  background: "#F5F8FA",
                  "&:before": {
                    border: "none",
                  },
                  "&:after": {
                    border: "none",
                  },
                }}
              >
                <MenuItem key="civilie" value="civilie" disabled selected>
                  Civilite
                </MenuItem>
                {civiliteList.map((c) => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>
                {showCiviliteError && formik.errors.civilite}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item flex="1">
            <Typography
              component="span"
              fontWeight="500"
              fontSize="15px"
              lineHeight="18px"
              sx={{ color: "#3F4254" }}
            >
              Nom complet{" "}
              {/* <Typography component="span" color="error">
                *
              </Typography> */}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container width="493px" spacing="20px">
              <Grid item xs={6}>
                <CustomTextField
                  {...formik.getFieldProps("lastName")}
                  error={showLastNameError}
                  helperText={showLastNameError && formik.errors.lastName}
                  placeholder="Nom"
                  // fullWidth={false}
                  size="small"
                  sx={{ marginBottom: margin, marginRight: "10px" }}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  {...formik.getFieldProps("firstName")}
                  error={showFirstNameError}
                  helperText={showFirstNameError && formik.errors.firstName}
                  placeholder="Prénom"
                  // fullWidth={false}
                  size="small"
                  sx={{ marginBottom: margin }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item flex="1">
            <Typography
              component="span"
              fontWeight="500"
              fontSize="15px"
              lineHeight="18px"
              sx={{ color: "#3F4254" }}
            >
              E-mail{" "}
              {/* <Typography component="span" color="error">
                *
              </Typography> */}
            </Typography>
          </Grid>
          <Grid item width="473px">
            <CustomTextField
              {...formik.getFieldProps("email")}
              error={showEmailError}
              helperText={showEmailError && formik.errors.email}
              placeholder="E-mail"
              fullWidth
              size="small"
              sx={{ marginBottom: margin }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item flex="1">
            <Typography
              component="span"
              fontWeight="500"
              fontSize="15px"
              lineHeight="18px"
              sx={{ color: "#3F4254" }}
            >
              Téléphone{" "}
              {/* <Typography component="span" color="error">
                *
              </Typography> */}
            </Typography>
          </Grid>
          <Grid item width="473px">
            <CustomTextField
              {...formik.getFieldProps("tel")}
              error={showTelError}
              helperText={showTelError && formik.errors.tel}
              placeholder="Numéro de téléphone"
              fullWidth
              size="small"
              sx={{ marginBottom: margin }}
              inputProps={{ maxLength: 10 }}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, "");
                formik.setValues({
                  ...formik.values,
                  tel: value,
                });
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item flex="1">
            <Typography
              component="span"
              fontWeight="500"
              fontSize="15px"
              lineHeight="18px"
              sx={{ color: "#3F4254" }}
            >
              Pôle{" "}
              {/* <Typography component="span" color="error">
                *
              </Typography> */}
            </Typography>
          </Grid>
          <Grid item width="473px">
            <FormControl
              fullWidth
              size="small"
              error={showPoleError}
              sx={{
                marginBottom: margin,
                "& .MuiSelect-select": { padding: "13px 16px" },
              }}
            >
              <Select
                {...formik.getFieldProps("pole")}
                // label="civilitesss"
                variant="filled"
                sx={{
                  color: "#5E6278",
                  fontWeight: "500",
                  fontSize: "14px",
                  background: "#F5F8FA",
                  "&:before": {
                    border: "none",
                  },
                  "&:after": {
                    border: "none",
                  },
                }}
              >
                <MenuItem key="pole" value="pole" disabled selected>
                  Pole
                </MenuItem>
                {poleList.map((pole) => (
                  <MenuItem key={pole.id} value={pole.id}>
                    {pole.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>
                {showPoleError && formik.errors.pole}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item flex="1">
            <Typography
              component="span"
              fontWeight="500"
              fontSize="15px"
              lineHeight="18px"
              sx={{ color: "#3F4254" }}
            >
              Poste{" "}
              {/* <Typography component="span" color="error">
                *
              </Typography> */}
            </Typography>
          </Grid>
          <Grid item width="473px">
            <FormControl
              disabled
              fullWidth
              size="small"
              sx={{
                marginBottom: margin,
                "& .MuiSelect-select": { padding: "13px 16px" },
              }}
            >
              <Select
                {...formik.getFieldProps("position")}
                // label="civilitesss"
                variant="filled"
                sx={{
                  color: "#5E6278",
                  fontWeight: "500",
                  fontSize: "14px",
                  background: "#F5F8FA",
                }}
              >
                {postesList.map((c) => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>
                {showPositionError && formik.errors.position}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        {/* Role --------------------- */}

        <Box display="flex" justifyContent="flex-end" gap="10px">
          {/* <Button
            sx={{
              textTransform: "none",
              color: (theme) => theme.palette.text.secondary,
            }}
            //   onClick={props.handleCloseEditDialog}
          >
            Annuler
          </Button> */}
          <BlueButton
            variant="contained"
            type="submit"
            sx={{ textTransform: "none", padding: "12px 20px" }}
          >
            Enregistrer
          </BlueButton>
        </Box>
      </form>
    </Paper>
  );
}
