import React from 'react'
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import logo from "assets/logo.svg";

export default function LogoPaper() {
  const [access, setAccess] = React.useState('');
  React.useEffect(() => {
    if (window.location.href.includes('utilisateur')) {
      setAccess('Accès Institut Artline')
    } else {
      setAccess('Accès Partenaires')
    }
  }, [])
  return (
    <Paper
      sx={{
        background: "#000619",
        color: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "30px",
        borderRadius: "500px",
        height: "100%",
      }}
    >
      <img src={logo} alt="Artline" height="150" />
      <Typography align="center" variant="h4" fontWeight={700}>
        GAFA
        <Typography align="center" variant="h4" fontWeight={700}>
          {access}
        </Typography>
      </Typography>

    </Paper>
  );
}
