import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Order } from "store/orders/orders.state";
import { Supplier } from "store/suppliers/suppliers.state";
import { ITva } from "store/tva/tva.state";

export interface IInvoice {
    id: string;
    _id: string;
    FAC_NUM: any;
    invoiceDate: string;
    dateDeControle: string;
    dateDeReception: string;
    dateDePaiementPrevisionnel: string;
    dateDePaiementReel: string;
    invoiceDocUrl: any,
    fournisseur: Supplier;
    purchaseOrder: Order,
    status: InvoiceStatusEnum,
    amount: number,
    invoiceRest: number,
    TTC: number,
    invoiceName: string,
    TVA: string,
};

export enum InvoiceStatusEnum {
    Validee = 'Validée',
    Créée = 'Créée',
    Réceptionnée = 'Réceptionnée',
    Enpaiement = 'En paiement',
    Payée = 'Payée'
}

export interface IInvoices {
    selectedInvoice: IInvoice | null;
    pdf: string,
    list: IInvoice[];
};


const initialState: IInvoices = {
    selectedInvoice: null,
    pdf: "",
    list: [],
};

const invoicesSlice = createSlice({
    name: "invoices",
    initialState: initialState,
    reducers: {
        setList(state, action: PayloadAction<IInvoice[]>) {
            state.list = action.payload;
        },
        addInvoice(state, action: PayloadAction<IInvoice>) {
            state.list.push(action.payload);
        },
        deleteInvoice(state, action: PayloadAction<string>) {
            // pauload: user id
            state.list = state.list.filter((invoice) => invoice.id !== action.payload);
        },
        //   pdfOrder(state, action: PayloadAction<Order>) {
        //     state.selectedOrderToEdit = action.payload
        //   },
        editInvoice(state, action: PayloadAction<{ id: string; invoice: IInvoice }>) {
            state.list = state.list.map((invoice) => {
                if (invoice.id === action.payload.id) return action.payload.invoice;
                return invoice;
            });
        },
        setSelectInvoice(state, action: PayloadAction<IInvoice>) {
            state.selectedInvoice = action.payload
        },
        clearSelectedInvoice(state) {
            state.selectedInvoice = null;
        },
    },
});

export const invoicesActions = invoicesSlice.actions;
export const invoicesReducer = invoicesSlice.reducer;