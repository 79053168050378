import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "store/store.state";
import App from "App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@emotion/react";
import theme from "theme/theme";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import 'react-phone-input-2/lib/style.css'
import "./style/globalStyle.css"

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  // <React.StrictMode> no strict mode
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
