import Button, { ButtonProps } from "@mui/material/Button";
import styled from "@emotion/styled";

// const WhiteButton = styled(Button)((props) => ({
//   textTransform: "none",
//   background: "transparent",
//   color: "#7E8299",
//   boxShadow: "none",
//   fontSize: "15px",
//   fontWeight: "700",
//   lineHeight: "18px",
//   "&:hover": {
//     background: "transparent",
//     boxShadow: "none",
//   },
// }));

const WhiteButton = ({sx, ...props}: ButtonProps) => (
  <Button
  {...props}
  sx={{
    textTransform: "none",
    background: "transparent",
    color: "#7E8299",
    boxShadow: "none",
    fontSize: "15px",
    fontWeight: "700",
    lineHeight: "18px",
    padding: "12px 20px",
    "&:hover": {
      background: "transparent",
      boxShadow: "none",
    },
    ...sx
  }}
  />
)


export default WhiteButton