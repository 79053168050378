import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ListItemButton,
  Link,
} from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import WorkIcon from "@mui/icons-material/Work";
import HomeIcon from "@mui/icons-material/Home";
import NumbersIcon from "@mui/icons-material/Numbers";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import PublicIcon from "@mui/icons-material/Public";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import { useAppSelector } from "hooks/redux.hook";
import AttachmentIcon from "@mui/icons-material/Attachment";
import React from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";


interface IInvoiceInfosProps {
  handleCloseEditDialog?: Function;
  selectedInvoice?: any;
  idComponent?: any;
  exportInvoicePdf?: any;
  idComponentFinal?: any;
}

function InvoiceInfosAll({ selectedInvoice, idComponent, idComponentFinal, exportInvoicePdf }: IInvoiceInfosProps) {
  const articles = useAppSelector((state) => state.articles.list)!;
  React.useEffect(() => {
    if (idComponentFinal === idComponent) {
      exportInvoicePdf()
    }
  }, [articles]);

  return (
    <div id={idComponent} style={{ opacity: 1 }}>
      <Grid
        item
        textAlign="center"
        xs={12}
        mt={5}
        style={{
          backgroundColor: "#0a0b31",
          color: "whitesmoke",
          padding: "1%",
        }}
      >
        <Typography variant="h2">
          {" "}
          Nom: {selectedInvoice?.invoiceName}{" "}
        </Typography>
        <Typography variant="subtitle1">
          {" "}
          Date: {selectedInvoice?.invoiceDate}{" "}
        </Typography>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignContent="center"
        spacing={1}
        mt={5}
      >
        <Grid item xs={6} style={{ marginTop: "5%" }}>
          <Typography variant="h3" style={{ fontFamily: "Roboto" }}>
            Fournisseur
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText>
                {selectedInvoice?.fournisseur?.nom}{" "}
                {selectedInvoice?.fournisseur?.prenom}
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <WorkIcon />
              </ListItemIcon>
              <ListItemText>
                Raison sociale: {selectedInvoice?.fournisseur?.socialReason}
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText>
                {selectedInvoice?.fournisseur?.address}{" "}
                {selectedInvoice?.fournisseur?.city}
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <NumbersIcon />
              </ListItemIcon>
              <ListItemText>
                SIRET: {selectedInvoice?.fournisseur?.siret}
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <NumbersIcon />
              </ListItemIcon>
              <ListItemText>
                SIREN: {selectedInvoice?.fournisseur?.siren}
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <LocalPhoneIcon />
              </ListItemIcon>
              <ListItemText>
                Tel: {selectedInvoice?.fournisseur?.tel}
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <PhoneIphoneIcon />
              </ListItemIcon>
              <ListItemText>
                Mobile: {selectedInvoice?.fournisseur?.mobile}
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <PublicIcon />
              </ListItemIcon>
              <ListItemText>{selectedInvoice?.fournisseur?.pays}</ListItemText>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={5} style={{ marginTop: "5%" }}>
          <Typography variant="h3">Bon de comande</Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <NumbersIcon />
              </ListItemIcon>
              <ListItemText>
                {selectedInvoice?.purchaseOrder?.BDC_NUM}{" "}
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <FingerprintIcon />
              </ListItemIcon>
              <ListItemText>
                Order ID: {selectedInvoice?.purchaseOrder?._id.slice(0, 15)}
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <CalendarMonthIcon />
              </ListItemIcon>
              <ListItemText>
                {selectedInvoice?.purchaseOrder?.documentDate}
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <WorkIcon />
              </ListItemIcon>
              <ListItemText>
                Raison sociale: {selectedInvoice?.purchaseOrder?.raisonSocial}
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <NumbersIcon />
              </ListItemIcon>
              <ListItemText>
                Num Articles: {selectedInvoice?.purchaseOrder?.articles?.length}
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{
          margin: "5% auto",
          border: "3px solid rgba(0,0,0,0.07)",
          borderRadius: "5px",
          padding: "1%",
        }}
      >
        <Grid item xs={3}>
          <Typography variant="subtitle1" style={{ padding: "1%" }}>
            <b>BDC Total:</b> {selectedInvoice.purchaseOrder?.GTTC}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1" style={{ padding: "1%" }}>
            <b> Facture Amount:</b> {selectedInvoice?.amount}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1" style={{ padding: "1%" }}>
            <b>Reste:</b> {selectedInvoice?.purchaseOrder?.reste}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <List>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <AttachmentIcon />
                </ListItemIcon>
                <ListItemText>
                  <Link
                    href={`${selectedInvoice?.invoiceDocUrl}`}
                    underline="hover"
                    target="_blank"
                  >
                    {"facture jointe"}
                  </Link>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" mt={5}>
        <Grid item xs={8}>
          <Typography variant="h3" mb={5} style={{ fontFamily: "Roboto" }}>
            Les Articles de Bon de Commande
          </Typography>
          <TableContainer
            component={Paper}
            style={{ maxHeight: 400, maxWidth: "40vw" }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right">Nom de l’article</TableCell>
                  <TableCell align="right">Coût unitaire HT</TableCell>
                  <TableCell align="right">Quantité</TableCell>
                  <TableCell align="right">TVA</TableCell>
                  <TableCell align="right">TTC</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedInvoice?.purchaseOrder?.articles?.map(
                  (row: any, idx: number) => (
                    <TableRow
                      key={idx}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="right">
                        {
                          articles.filter(
                            (art: any) =>
                              art._id ===
                              selectedInvoice.purchaseOrder?.articles[idx]
                                .idArticle
                          )[0]?.nomArticle
                        }
                      </TableCell>
                      <TableCell align="right">
                        {
                          articles.filter(
                            (art: any) =>
                              art._id ===
                              selectedInvoice.purchaseOrder?.articles[idx]
                                .idArticle
                          )[0]?.HT
                        }
                      </TableCell>
                      <TableCell align="right">{row?.qty}</TableCell>
                      <TableCell align="right">
                        {
                          articles.filter(
                            (art: any) =>
                              art._id ===
                              selectedInvoice.purchaseOrder?.articles[idx]
                                .idArticle
                          )[0]?.TVA
                        }
                      </TableCell>
                      <TableCell align="right">{row?.TTC}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid
        container
        textAlign="center"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={6} style={{ marginTop: "5%" }}>
          <Typography variant="body1">
            INSTITUT ARTLINE – L’ÉCOLE EN LIGNE DE LA CRÉATION NUMÉRIQUE 117 rue
            de Charenton, 75012 PARIS | institutartline.com | +33 (0)1 86 95 23
            31 | admission@institutartline.com Ét. d’enseignement supérieur
            privé à distance, déclaré au Rectorat de l’académie de Paris, soumis
            au contrôle pédagogique de l’État | N° formation continue : 11 75
            51293 75 - Sarl au capital de 11 000 € - RCS Paris 793 501 750 - APE
            8559BD
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default InvoiceInfosAll;
