import { axiosInstance } from "api/axiosInstance";
import { AppDispatch } from "store/store.state";
import { Perimeter, perimetersActions } from "./perimeters.state";
import { feedbackMessageUIActions } from "store/feedback-message-ui/feedbackMessageUI.state"


type GetPerimetersResponse = {
  data: Perimeter[]
}

export function getPerimetersThunk() {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.get<GetPerimetersResponse>("/perimeters", {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      })
      const perimeters = response.data.data
      dispatch(perimetersActions.setList(perimeters))
    } catch (e) {
      console.log(e)
    }
  }
}

type AddPerimeterResponse = {
  data: Perimeter
}

export function addPerimeterThunk(perimeter: Omit<Perimeter, "id">) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.post<AddPerimeterResponse>("/perimeters", perimeter, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      })
      const completePerimeter = response.data.data
      dispatch(perimetersActions.addPerimeter(completePerimeter));
      dispatch(feedbackMessageUIActions.setState({ open: true, message: "Produit ajouté avec succès" }))
    } catch (e) {
      console.log(e)
      dispatch(feedbackMessageUIActions.setState({ open: true, message: "Impossible d'ajouter Produit, veuillez réessayer plus tard" }))
    }
  };
}

export function deletePerimeterThunk(id: string) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.delete(`/perimeters/${id}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      })
      dispatch(perimetersActions.deletePerimeter(id));
      dispatch(feedbackMessageUIActions.setState({ open: true, message: "Produit supprimé avec succès" }))
    } catch (e: any) {
      if (e.response.status === 409) {
        dispatch(
          feedbackMessageUIActions.setState({
            open: true,
            message:
              "Cette donnée est utilisée, vous ne pouvez pas la supprimer",
          })
        );
      } else {
        dispatch(feedbackMessageUIActions.setState({ open: true, message: "Impossible de supprimer Produit, veuillez réessayer plus tard" }))

      }
    }
  };
}

type EditPerimeterResponse = {
  data: Perimeter
}

export function editPerimeterThunk(obj: { id: string; perimeter: Omit<Perimeter, "id"> }) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.put<EditPerimeterResponse>(`/perimeters/${obj.id}`, obj.perimeter)
      const editedPerimeter = response.data.data
      dispatch(perimetersActions.editPerimeter({ id: obj.id, perimeter: editedPerimeter }));
      dispatch(feedbackMessageUIActions.setState({ open: true, message: "Produit mise à jour avec succès" }))
    } catch (e) {
      console.log(e)
      dispatch(feedbackMessageUIActions.setState({ open: true, message: "Impossible de mettre à jour Produit, veuillez réessayer plus tard" }))
    }
  };
}
