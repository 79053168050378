import Paper  from "@mui/material/Paper";
import FormHeading from "./FormHeading.component";
import Form from "./Form.component";

export default function FormContainer() {
  return (
    <Paper
      sx={{
        padding: "40px",
        margin: "15px",
        height: "700px",
        overflow: "auto",
        borderRadius: "25px 5px",
      }}
    >
      <FormHeading />
      <Form />
    </Paper>
  );
}
