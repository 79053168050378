import React from "react";
import { useAppSelector, useAppDispatch } from "hooks/redux.hook";
import { rolesActions } from "store/roles/roles.state";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { useParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";

import search from "assets/Search.svg";

export default function UsersFilter() {
  const params = useParams();
  const dispatch = useAppDispatch();
  const assignedUsersNumber = useAppSelector(
    (state) => state.roles.assignedUsersObjects.length
  );
  const userNameFilterValue = useAppSelector(
    (state) => state.roles.userNameFilterValue
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(rolesActions.setUserNameFilterValue(e.currentTarget.value));
  };
  return (
    <Grid container alignItems="center" marginBottom="80px">
      <Grid item marginRight="12px">
        <Typography
          fontWeight="500"
          fontSize="20px"
          lineHeight="23px"
          sx={{ color: "#181C32" }}
        >
          Utilisateurs ({assignedUsersNumber})
        </Typography>
      </Grid>
      <Grid item>
        <TextField
          type="search"
          placeholder="Rechercher"
          variant="filled"
          autoComplete="off"
          value={userNameFilterValue}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {/* <SearchIcon /> */}
                <Avatar
                  src={search}
                  sx={{ width: "10.6px", height: "11.3px" }}
                />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          sx={{
            width: "136px",
            input: {
              padding: "0px",
              fontWeight: "700",
              fontSize: "12px",
              lineHeight: "14px",
              color: "#A1A5B7",
              "&::placeholder": {
                fontWeight: "700",
                fontSize: "12px",
                lineHeight: "14px",
                color: "#A1A5B7",
                opacity: "1",
              },
            },
            "& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-filled.MuiInputAdornment-sizeMedium":
              {
                marginTop: 0,
              },
            "& .MuiInputBase-root": {
              background: "white",
              padding: "10px 14px",
              borderRadius: "6px",
              "&:hover": {
                background: "white",
              },
              "&.Mui-focused": {
                background: "white",
              },
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
