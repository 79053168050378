import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import EastIcon from "@mui/icons-material/East";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { useFormik } from "formik";
import * as Yup from "yup";

import { Link, useNavigate } from "react-router-dom";

import * as ROUTES from "utils/routes";

import { axiosInstance } from "api/axiosInstance";
import { useParams } from "react-router-dom";
import { useState } from "react";

import { setUserAndTokenThunk } from "store/auth/auth.thunk";
import { useAppDispatch } from "hooks/redux.hook";

export default function Form() {
  const [showError, setShowError] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Mauvais format d'e-mail")
        .required("E-mail obligatoire"),
      password: Yup.string().required("Mot de passe obligatoire"),
    }),
    onSubmit: (values) => {
      let url = "";
      const type = params.type!.toLowerCase();
      if (type === "utilisateur") url = "/auth/user/login";
      else if (type === "fournisseur") {
        url = "/auth/vendor/login";
      }
      setOpenLoading(true);
      axiosInstance
        .post(url, {
          email: values.email,
          password: values.password,
        })
        .then((res) => {
          // console.log(res);
          setShowError(false);
          setErrorText("");
          const accountType = url.includes("vendor")
            ? "vendor"
            : url.includes("user")
            ? "user"
            : "";
          dispatch(
            setUserAndTokenThunk(
              res.data.user as any,
              res.data.token,
              accountType
            )
          );
          navigate(`../${ROUTES.DASHBOARD}`);
        })
        .catch((err) => {
          // console.log(err);
          setErrorText(err.response.data.data);
          setShowError(true);
        })
        .finally(() => {
          setOpenLoading(false);
        });
    },
  });

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoading}
        // onClick={() => setOpenLoading(false)}
      >
        <CircularProgress />
      </Backdrop>
      <Paper
        sx={{
          padding: "40px",
          borderRadius: "25px 5px",
          margin: "15px",
        }}
      >
        <Typography
          sx={{ marginBottom: showError ? 0 : "50px" }}
          variant="h4"
          //   fontWeight="700"
          align="center"
        >
          Veuillez entrer vos identifiants
        </Typography>

        {showError && (
          <Typography variant="body2" align="center" color="error">
            Error: {errorText}
          </Typography>
        )}

        <form onSubmit={formik.handleSubmit}>
          <TextField
            {...formik.getFieldProps("email")}
            error={
              (formik.errors.email && formik.touched.email) as
                | boolean
                | undefined
            }
            helperText={
              formik.errors.email && formik.touched.email && formik.errors.email
            }
            sx={{
              marginBottom: "10px",
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#000619",
              },
              "& .MuiInputBase-root:after": {
                border: "none",
              },
            }}
            label="E-mail"
            variant="standard"
            placeholder="example@mail.com"
            fullWidth
          />
          <TextField
            type="password"
            {...formik.getFieldProps("password")}
            error={
              (formik.errors.password && formik.touched.password) as
                | boolean
                | undefined
            }
            helperText={
              formik.errors.password &&
              formik.touched.password &&
              formik.errors.password
            }
            sx={{
              marginBottom: "10px",
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#000619",
              },
              "& .MuiInputBase-root:after": {
                border: "none",
              },
            }}
            label="Mot de passe"
            variant="standard"
            placeholder="Mot de passe"
            fullWidth
          />

          <Typography
            fontWeight="400"
            fontSize="14px"
            sx={{ color: "#B5B5C3", marginBottom: "30px", display: "block" }}
            component={Link}
            to={
              params.type!.toLowerCase() === "utilisateur"
                ? `/${ROUTES.FORGOT_PASSWORD}/utilisateur`
                : `/${ROUTES.FORGOT_PASSWORD}/fournisseur`
            }
          >
            Mot de passe oublié?
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                cursor: "pointer",
                "&:hover .MuiSvgIcon-root": {
                  marginLeft: "5px",
                },
                border: "none",
                background: "transparent",
              }}
              component="button"
              type="submit"
            >
              <Avatar sx={{ backgroundColor: "#FE7373" }}>
                <EastIcon fontSize="small" sx={{ transition: "margin .2s" }} />
              </Avatar>
              <Typography
                sx={{
                  borderBottom: 2,
                }}
              >
                Je me connecte
              </Typography>
            </Box>
          </Box>
        </form>
      </Paper>
    </>
  );
}
