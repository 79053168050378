import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPaymentTerm {
    _id: string;
    id: string;
    value: string,
};


export interface IPaymentTerms {
    selectedPaymentTerm: IPaymentTerm | null;
    list: IPaymentTerm[];
};


const initialState: IPaymentTerms = {
    selectedPaymentTerm: null,
    list: [],
};

const paymentTermsSlice = createSlice({
    name: "paymentTerms",
    initialState: initialState,
    reducers: {
        setList(state, action: PayloadAction<IPaymentTerm[]>) {
            state.list = action.payload;
        },
        addPaymentTerm(state, action: PayloadAction<IPaymentTerm>) {
            state.list.push(action.payload);
        },
        deletePaymentTerm(state, action: PayloadAction<string>) {
            state.list = state.list.filter((pt) => pt._id !== action.payload);
        },
        editPaymentTerm(state, action: PayloadAction<{ id: string; pt: IPaymentTerm }>) {
            state.list = state.list.map((pt) => {
                if (pt._id === action.payload.id) return action.payload.pt;
                return pt;
            });
        },
        setSelectPaymentTerm(state, action: PayloadAction<IPaymentTerm>) {
            state.selectedPaymentTerm = action.payload
        },
        clearSelectedPaymentTerm(state) {
            state.selectedPaymentTerm = null;
        },
    },
});

export const paymentTermsActions = paymentTermsSlice.actions;
export const paymentTermsReducer = paymentTermsSlice.reducer;