import { AppDispatch, AppThunk } from "store/store.state";
import { suppliersActions } from "./suppliers.state";
import type { Supplier } from "./suppliers.state";
import { axiosInstance } from "api/axiosInstance";
import { NavigateFunction } from "react-router-dom";
import { Dispatch } from "react";
import { setUserAndTokenThunk } from "store/auth/auth.thunk";
import * as ROUTES from "utils/routes";
import { feedbackMessageUIActions } from "store/feedback-message-ui/feedbackMessageUI.state";
import { authActions } from "store/auth/auth.state";

type GetSuppliersResponse = {
  data: Supplier[];
};

export function getSuppliersThunk() {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.get<GetSuppliersResponse>(
        "/vendors",
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
          },
        }
      );
      const suppliers = response.data.data;
      dispatch(suppliersActions.setList(suppliers));
    } catch (e) {
      console.log(e);
    }
  };
}

type AddSupplierParams = {
  email: string;
};

type AddSupplierResponse = {
  data: Supplier;
};

// Add and send invitation email
export function addAndSendEmailSupplierThunk(supplier: AddSupplierParams) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.post<AddSupplierResponse>(
        "/auth/vendor/register",
        supplier,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
          },
        }
      );
      const completeSupplier = response.data.data;
      dispatch(suppliersActions.addSupplier(completeSupplier));
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Fournisseur ajouté et invité avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message:
            "Impossible d'ajouter un fournisseur, veuillez réessayer plus tard",
        })
      );
    }
  };
}


// Add and dont send invitation email
export function addOnlySupplierThunk(supplier: Supplier, RIB: File, ProofActivity: File[]) {
  return async (dispatch: AppDispatch) => {
    try {
      const formData = new FormData();
      formData.set("socialReason", supplier.socialReason);
      formData.set("nom", supplier.nom);
      formData.set("prenom", supplier.prenom);
      formData.set("email", supplier.email);
      formData.set("tel", supplier.tel);
      formData.set("civilite", supplier.civilite as any);
      formData.set("RIB", RIB);
      formData.set("BIC", supplier.BIC);
      formData.set("IBAN", supplier.IBAN);
      // formData.set("ProofActivity", ProofActivity);
      const keys: any = Object.keys(ProofActivity)
      for (const key of keys) {
        formData.append('ProofActivity', ProofActivity[key])
      }
      formData.set("address", supplier.address);
      formData.set("city", supplier.city);
      formData.set("mobile", supplier.mobile);
      formData.set("pays", supplier.pays);
      formData.set("siren", supplier.siren);
      formData.set("siret", supplier.siret);
      formData.set("postalCode", supplier.postalCode);
      formData.set("tva", supplier.tva as any);
      // formData.set("purchaseOredrs", supplier.purchaseOredrs as any);
      formData.set("role", supplier.role as any);
      const response = await axiosInstance.post(
        "/auth/vendor/add",
        formData,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
          },
        }
      );
      const completeSupplier = response.data.data;
      dispatch(suppliersActions.addSupplier(completeSupplier));
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Fournisseur ajouté avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message:
            "Impossible d'ajouter un fournisseur, veuillez réessayer plus tard",
        })
      );
    }
  };
}

// send invitation email
export function sendInvitationEmailThunk(id: string) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.post("/auth/vendor/invite", {
        vendorId: id,
      }, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      });
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Fournisseur invité avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message:
            "Impossible d'inviter le fournisseur veuillez réessayer plus tard",
        })
      );
    }
  };
}

export function deleteSupplierThunk(id: string) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.delete(`/vendors/${id}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      });
      dispatch(suppliersActions.deleteSupplier(id));
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Fournisseur supprimé avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message:
            "Impossible de supprimer le fournisseur veuillez réessayer plus tard",
        })
      );
    }
  };
}

type EditSupplierParams = Omit<Supplier, "id" | "role" | "email">;

type EditSupplierResponse = {
  data: Supplier;
};

// edit a supplier from dashboard
export function editSupplierThunk(
  obj: {
    id: string;
    supplier: any;
    RIB?: File;
    ProofActivity?: File[]
  },
  navigate?: NavigateFunction,
  setOpenLoading?: Dispatch<React.SetStateAction<boolean>>
) {
  return async (dispatch: AppDispatch) => {
    try {
      const formData = new FormData();
      formData.set("socialReason", obj.supplier.socialReason);
      formData.set("nom", obj.supplier.nom);
      formData.set("prenom", obj.supplier.prenom);
      formData.set("email", obj.supplier.email);
      formData.set("tel", obj.supplier.tel);
      formData.set("civilite", obj.supplier.civilite as any);
      formData.set("IBAN", obj.supplier.IBAN);
      // formData.set("ProofActivity", ProofActivity);
      if (obj.RIB) {
        formData.set("RIB", obj.RIB);
      }
      if (obj.ProofActivity) {
        const keys: any = Object.keys(obj.ProofActivity)
        for (const key of keys) {
          formData.append('ProofActivity', obj.ProofActivity[key])
        }
      }
      formData.set("BIC", obj.supplier.BIC);
      formData.set("address", obj.supplier.address);
      formData.set("city", obj.supplier.city);
      formData.set("mobile", obj.supplier.mobile);
      formData.set("pays", obj.supplier.pays);
      formData.set("siren", obj.supplier.siren);
      formData.set("siret", obj.supplier.siret);
      formData.set("postalCode", obj.supplier.postalCode);
      formData.set("tva", obj.supplier.tva as any);
      // formData.set("purchaseOredrs", obj.supplier.purchaseOredrs as any);
      formData.set("role", obj.supplier.role as any);
      const response = await axiosInstance.put<EditSupplierResponse>(
        `/vendors/${obj.id}`,
        formData,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
          },
        }
      );
      const editedSupplier = response.data.data;
      dispatch(
        suppliersActions.editSupplier({ id: obj.id, supplier: editedSupplier })
      );
      // dispatch(setUserAndTokenThunk(editedSupplier, ))
      if (navigate) navigate(`/${ROUTES.LOGIN_SUPPLIER}`);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Fournisseur mis à jour avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message:
            "Impossible de mettre à jour le fournisseur, veuillez réessayer plus tard",
        })
      );
    } finally {
      if (setOpenLoading) setOpenLoading(false);
    }
  };
}

// edit my self from profile page
export function editMySupplierProfileThunk(
  obj: {
    id: string;
    supplier: EditSupplierParams;
  },
  navigate?: NavigateFunction,
  setOpenLoading?: Dispatch<React.SetStateAction<boolean>>
) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.put<EditSupplierResponse>(
        `/vendors/${obj.id}`,
        obj.supplier,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
          },
        }
      );
      const editedSupplier = response.data.data;
      // edit supplier in auth store
      localStorage.setItem("user", JSON.stringify(editedSupplier));
      dispatch(authActions.setUser(editedSupplier as any));
      // edit supplier in suppliers store
      dispatch(
        suppliersActions.editSupplier({ id: obj.id, supplier: editedSupplier })
      );
      // dispatch(setUserAndTokenThunk(editedSupplier, ))
      if (navigate) navigate(`/${ROUTES.LOGIN_SUPPLIER}`);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Fournisseur mis à jour avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message:
            "Impossible de mettre à jour le fournisseur, veuillez réessayer plus tard",
        })
      );
    } finally {
      if (setOpenLoading) setOpenLoading(false);
    }
  };
}

export function updateSupplierStatusThunk(
  id: string,
  status: "À confirmer" | "Confirmé"
): AppThunk {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.put<EditSupplierResponse>(
        `/vendors/changestatus/${id}`,
        {
          status,
        },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
          },
        }
      );
      const editedSupplier = response.data.data;
      dispatch(suppliersActions.editSupplierStatus({ id, status: status }));
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Statut du fournisseur mis à jour avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message:
            "Impossible de mettre à jour le statut du fournisseur, veuillez réessayer plus tard",
        })
      );
    }
  };
}

// forgot password ==================================================================
// send email to reset password
export function forgotPasswordThunk(
  email: string,
  setShowError: React.Dispatch<React.SetStateAction<boolean>>
): AppThunk {
  return async (dispatch) => {
    try {
      await axiosInstance.post("/auth/vendor/forgotpassword", {
        email,
      },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
          },
        });
      setShowError(false);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "L'e-mail a été envoyé avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      setShowError(true);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message:
            "Impossible d'envoyer un e-mail, veuillez réessayer plus tard",
        })
      );
    }
  };
}

export function resetPasswordThunk(
  password: string,
  confirmPassword: string,
  token: string,
  setShowError: React.Dispatch<React.SetStateAction<boolean>>,
  navigate: NavigateFunction
): AppThunk {
  return async (dispatch, getState) => {
    try {
      // const token = getState().auth.token
      await axiosInstance.put(`/auth/vendor/resetpassword/${token}`, {
        password,
        confirmPassword,
      },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
          },
        });
      setShowError(false);
      navigate(`/${ROUTES.LOGIN_SUPPLIER}`);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Le mot de passe a été réinitialisé avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      setShowError(true);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message:
            "Impossible de réinitialiser le mot de passe, veuillez réessayer plus tard",
        })
      );
    }
  };
}
// forgot password ==================================================================

// reset password from profile=======================================================
export function resetPasswordFromProfileThunk(
  oldPassword: string,
  newPassword: string,
  confirmNewPassword: string
): AppThunk {
  return async (dispatch, getState) => {
    try {
      const vendorId = getState().auth.user!.id;
      await axiosInstance.put(`/vendors/changepassword/${vendorId}`, {
        oldPassword,
        newPassword,
        confirmNewPassword,
      },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
          },
        });
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Le mot de passe a été réinitialisé avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message:
            "Impossible de réinitialiser le mot de passe, veuillez réessayer plus tard",
        })
      );
    }
  };
}
// reset password from profile=======================================================
