import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import * as ROUTES from "utils/routes";

export default function Home() {
  return (
    <Grid
      container
      justifyContent="center"
      spacing="10px"
      height="100vh"
      alignItems="center"
    >
      <Grid item>
        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          component={Link}
          to={`/${ROUTES.LOGIN_SUPPLIER}`}
        >
          Connexion Fournisseur
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          component={Link}
          to={`/${ROUTES.LOGIN_USER}`}
        >
          Connexion Utilisateur
        </Button>
      </Grid>
    </Grid>
  );
}
