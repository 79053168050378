import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import FormControlLabel from "@mui/material/FormControlLabel"
import MuiCheckbox from "@mui/material/Checkbox"
import Typography from "@mui/material/Typography"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import FormHelperText from "@mui/material/FormHelperText"
import EastIcon from "@mui/icons-material/East";
import styled from "@emotion/styled";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "utils/routes"

const textFieldStyle = {
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#000619",
  },
  "& .MuiInputBase-root:after": {
    border: "none",
  },
};

const Checkbox = styled(MuiCheckbox)((props) => ({
  "&.Mui-checked": {
    color: "#FE7373",
  },
}));

type ErrorProp = boolean | undefined;


// =================================================

export default function Form() {
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      confirmPassword: "",
      acceptTermsAndConditions: false,
      acceptInfoEmail: false,
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Prenom obligatoire"),
      lastname: Yup.string().required("Nom obligatoire"),
      email: Yup.string()
        .required("E-mail obligatoire")
        .email("Mauvais format d'e-mail"),
      password: Yup.string().required("Mot de passe obligatoire").min(10, "Trop court"),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Les mots de passe doivent correspondre"
      ),
      acceptTermsAndConditions: Yup.boolean().oneOf(
        [true],
        "Ce champ doit être coché"
      ),
      acceptInfoEmail: Yup.boolean(),
    }),
    onSubmit: (values) => {
      // alert(JSON.stringify(values))
      // console.log(values)
      navigate(`../${ROUTES.WELCOME}`)
    },
  });

  const showFirstnameError = (formik.errors.firstname &&
    formik.touched.firstname) as ErrorProp;
  const showLastnameError = (formik.errors.lastname &&
    formik.touched.lastname) as ErrorProp;
  const showEmailError = (formik.errors.email &&
    formik.touched.email) as ErrorProp;
  const showPasswordError = (formik.errors.password &&
    formik.touched.password) as ErrorProp;
  const showConfirmPasswordError = (formik.errors.confirmPassword &&
    formik.touched.confirmPassword) as ErrorProp;
  const showUseTermsError = (formik.errors.acceptTermsAndConditions &&
    formik.touched.acceptTermsAndConditions) as ErrorProp;

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            sx={{
              marginBottom: "10px",
              ...textFieldStyle,
            }}
            label="Nom"
            variant="standard"
            placeholder="Colino"
            {...formik.getFieldProps("lastname")}
            error={showLastnameError}
            helperText={showLastnameError && formik.errors.lastname}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            sx={{
              marginBottom: "10px",
              ...textFieldStyle,
            }}
            label="Prénom"
            variant="standard"
            placeholder="Killian"
            {...formik.getFieldProps("firstname")}
            error={showFirstnameError}
            helperText={showFirstnameError && formik.errors.firstname}
          />
        </Grid>
      </Grid>

      <TextField
        sx={{
          marginBottom: "10px",
          ...textFieldStyle,
        }}
        label="Confirmer votre mail"
        variant="standard"
        placeholder="behappy@syconvoia.com"
        fullWidth
        {...formik.getFieldProps("email")}
        error={showEmailError}
        helperText={showEmailError && formik.errors.email}
      />
      <TextField
        sx={{
          marginBottom: "10px",
          ...textFieldStyle,
        }}
        label="Mot de passe"
        variant="standard"
        placeholder="Mot de passe"
        fullWidth
        {...formik.getFieldProps("password")}
        error={showPasswordError}
        helperText={showPasswordError && formik.errors.password}
        type="password"
      />
      <TextField
        sx={{
          marginBottom: "30px",
          ...textFieldStyle,
        }}
        label="Répéter votre mot de passe"
        variant="standard"
        placeholder="Mot de passe"
        fullWidth
        {...formik.getFieldProps("confirmPassword")}
        error={showConfirmPasswordError}
        helperText={showConfirmPasswordError && formik.errors.confirmPassword}
        type="password"
      />

      <FormControlLabel
        control={
          <Checkbox {...formik.getFieldProps("acceptTermsAndConditions")} />
        }
        label="J'accepte les conditions générales d'utilisations"
        componentsProps={{
          typography: {
            variant: "caption",
          },
        }}
      />
      {showUseTermsError && (
        <FormHelperText variant="outlined" error>
          {formik.errors.acceptTermsAndConditions}
        </FormHelperText>
      )}

      <FormControlLabel
        control={<Checkbox {...formik.getFieldProps("acceptInfoEmail")} />}
        label="J'accepte de recevoir des informations et des offres promotionnelles à propos de l'institut Artline."
        componentsProps={{
          typography: {
            variant: "caption",
          },
        }}
        sx={{
          marginBottom: "10px",
        }}
      />

      <Box
        sx={{
          display: "inline-flex",
          alignItems: "center",
          gap: "5px",
          cursor: "pointer",
          marginBottom: "10px",
          "&:hover .MuiSvgIcon-root": {
            marginLeft: "5px",
          },
          border: "none",
          background: "transparent",
        }}
        component="button"
        type="submit"
      >
        <Avatar sx={{ backgroundColor: "#FE7373" }}>
          <EastIcon fontSize="small" sx={{ transition: "margin .2s" }} />
        </Avatar>
        <Typography
          sx={{
            borderBottom: 2,
          }}
        >
          Je finalise mon enregistrement
        </Typography>
      </Box>
    </form>
  );
}
