import { axiosInstance } from "api/axiosInstance";
import { feedbackMessageUIActions } from "store/feedback-message-ui/feedbackMessageUI.state";
import { AppDispatch } from "store/store.state";
import { IInvoice, invoicesActions, InvoiceStatusEnum } from "./invoice.state";


export function getInvoicesThunk() {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axiosInstance.get(
                "/invoices",
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                    },
                },
            );
            const invoices: IInvoice[] = response.data.data;
            dispatch(invoicesActions.setList(invoices));
        } catch (e) {
            console.log(e);
        }
    };
}

export function getInvoicesByVendorIdThunk(vendorId: string) {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axiosInstance.get(
                `/invoices/vendor/${vendorId}`,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                    },
                },
            );
            const invoices: IInvoice[] = response.data.data;
            dispatch(invoicesActions.setList(invoices));
        } catch (e) {
            console.log(e);
        }
    };
}

export function addInvoiceThunk(invoice: Omit<IInvoice, "id">, file: File) {
    return async (dispatch: AppDispatch) => {
        try {
            const formData = new FormData();
            formData.set("fournisseur", invoice.fournisseur as any);
            formData.set("amount", invoice.amount as any);
            formData.set("invoiceDate", invoice.invoiceDate);
            formData.set("invoiceName", invoice.invoiceName);
            formData.set("purchaseOrder", invoice.purchaseOrder as any);
            formData.set("status", invoice.status);
            formData.set("TTC", invoice.TTC as any);
            formData.set("invoiceRest", invoice.invoiceRest as any);

            formData.set("invoiceDoc", file);
            const response = await axiosInstance.post(
                "/invoices",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                    },
                }

            );

            const completeInvoice = response.data.data;
            dispatch(invoicesActions.addInvoice(completeInvoice));
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message: "Facture ajoutée avec succès",
                })
            );
        } catch (e) {
            console.log(e);
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message:
                        "Impossible d'ajouter une facture s'il vous plaît réessayer plus tard",
                })
            );
        }
    };
}

export function editInvoiceThunk(obj: { id: string; invoice: Omit<IInvoice, "id">, file?: File }) {
    return async (dispatch: AppDispatch) => {
        const formData = new FormData();
        formData.set("fournisseur", obj.invoice.fournisseur as any);
        formData.set("amount", obj.invoice.amount as any);
        formData.set("invoiceDate", obj.invoice.invoiceDate);
        formData.set("invoiceName", obj.invoice.invoiceName);
        formData.set("purchaseOrder", obj.invoice.purchaseOrder as any);
        formData.set("status", obj.invoice.status);
        formData.set("TTC", obj.invoice.TTC as any);
        formData.set("TVA", obj.invoice.TVA);

        obj.file && formData.set("invoiceDoc", obj.file);
        try {
            const response = await axiosInstance.put(
                `/invoices/${obj.id}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                    },
                }
            );
            const editedInvoice = response.data.data;
            dispatch(invoicesActions.editInvoice({ id: obj.id, invoice: editedInvoice }));
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message: "facture mis à jour avec succès",
                })
            );
        } catch (e) {
            console.log(e);
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message:
                        "Impossible de mettre à jour une facture s'il vous plaît réessayer plus tard",
                })
            );
        }
    };
}


export function editStatusInvoiceThunk(obj: { id: string; status: InvoiceStatusEnum, Date: string }) {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axiosInstance.put(
                `/invoices/status/${obj.id}`,
                { status: obj.status, modificationDate: obj.Date },
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                    },
                }

            );
            const editedInvoice = response.data.data;
            dispatch(invoicesActions.editInvoice({ id: obj.id, invoice: editedInvoice }));
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message: "Statut de facture mis à jour avec succès",
                })
            );
        } catch (e) {
            console.log(e);
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message:
                        "Impossible de mettre à jour une facture s'il vous plaît réessayer plus tard",
                })
            );
        }
    };
}


export function deleteInvoiceThunk(id: string) {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axiosInstance.delete(`/invoices/${id}`, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                },
            });
            dispatch(invoicesActions.deleteInvoice(id));
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message: "facture supprimée avec succès",
                })
            );
        } catch (e) {
            console.log(e);
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message:
                        "Impossible de supprimer facture s'il vous plaît réessayer plus tard",
                })
            );
        }
    };
}