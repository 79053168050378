import { axiosInstance } from "api/axiosInstance";
import { AppDispatch } from "store/store.state";
import { Project, projectActions } from "./project.state";
import { feedbackMessageUIActions } from "store/feedback-message-ui/feedbackMessageUI.state";

type GetProjectResponse = {
  data: Project[];
};

export function getProjectsThunk() {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.get<GetProjectResponse>("/projects", {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      });
      const projects: Project[] = response.data.data;
      dispatch(projectActions.setProjectList(projects));
    } catch (e) {
      console.log(e);
    }
  };
}

export function addProjectThunk(project) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.post<any>('/projects', project, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      })
      const dataRec = response.data.data
      dispatch(projectActions.addProject(dataRec))
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Projet ajouté avec succés",
        })
      );
    } catch (err) { console.log(err) }
  }
}
export function editProjectThunk(project: { _id: string, name: string }) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.put<any>(`/projects/${project._id}`, project, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      })
      const editedProject = response.data.data
      dispatch(projectActions.editProject({ id: project._id, project: editedProject }));
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Le projet a été mis a jour avec succés",
        })
      );
    } catch (err) { console.log(err) }
  }
}

export function deleteProjectThunk(id: string) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.delete<any>(`/projects/${id}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      })
      if (response.data.success) {
        dispatch(projectActions.deleteProject(id))
        dispatch(
          feedbackMessageUIActions.setState({
            open: true,
            message: "Le Projet a été supprimé avec succés",
          })
        );
        dispatch(getProjectsThunk())
      }
    } catch (err) {
      console.log(err)
    }
  }
}
