import { useMemo } from "react";
import { GridColumns } from "@mui/x-data-grid";
import { useNavigate, Link } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SettingsIcon from "@mui/icons-material/Settings";

import * as ROUTES from "utils/routes";

type Row = {
  id: string;
  name: string;
  description: string;
};

export default function useColumns() {
  return useMemo<GridColumns>(
    () => [
      {
        field: "actions",
        headerName: "",
        sortable: false,
        width: 50,
        disableColumnMenu: true,
        renderCell: (params) => {
          const link =
            params.row.name === "Poste"
              ? ROUTES.POSTES
              : params.row.name === "Civilité"
              ? ROUTES.CIVILITE
              : params.row.name === "Pôle"
              ? ROUTES.POLE
              : params.row.name === "TVA"
              ? ROUTES.TVA
              : params.row.name === "Condition de paiement"
              ? ROUTES.PAYMENTTERM
              : params.row.name === "Articles"
              ? ROUTES.ARTICLE
              : params.row.name === "Date de paiement"
              ? ROUTES.PAYMENTDATE
              : params.row.name === "Type de dépense"
              ? ROUTES.TYPE_DEPENSE
              : params.row.name === "Périmètre du BDC"
              ? ROUTES.SESSION
              : params.row.name === "Projets"
              ? ROUTES.PROJECT
              : params.row.name === "Produit"
              ? ROUTES.PERIMETER
              : "error-error";
          return (
            <IconButton size="small" component={Link} to={link}>
              <SettingsIcon />
            </IconButton>
          );
        },
      },
      {
        field: "name",
        headerName: "Nom",
        headerClassName: "gray-bold",
        width: 200,
        cellClassName: "cell",
      },
      {
        field: "description",
        headerName: "Description",
        headerClassName: "gray-bold",
        cellClassName: "cell",
        flex: 1,
      },
    ],
    []
  );
}
