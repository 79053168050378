import {
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import React, { useEffect, useRef, useState } from "react";
import { DashedDivider } from "pages/users/components/dashed-divider/DottedDivider.component";

interface ICustomSiedBarProps {
  gridColumnApi: any;
  setCustomSideBarOpen: any;
}

const CustomSideBar: React.FC<ICustomSiedBarProps> = ({
  gridColumnApi,
  setCustomSideBarOpen,
}) => {
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(false);
  const [defaultCheckboxValue, setDefaultCheckboxValue] =
    useState<boolean>(true);
  const [defaultCheckboxValues, setDefaultCheckboxValues] = useState<any>(
    gridColumnApi?.getColumns()?.map((col: any) => {
      const columnId = col.colId;
      const visible = col.visible;
      const obj: any = {};
      obj[columnId] = visible;
      return obj;
    })
  );
  useEffect(() => {
    const values: any = {};
    gridColumnApi?.getColumns()?.map((col: any) => {
      const columnId = col.colId;
      const visible = col.visible;
      values[columnId] = visible;
    });
    setDefaultCheckboxValues({ ...values });
  }, []);

  const width = sideBarOpen ? "100%" : "45px";

  return (
    <div style={{ width: "100%" }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        style={{
          width: `${width}`,
          // textAlign: "center",
        }}
      >
        <header
          style={{
            border: "1px solid #dde2eb",
            backgroundColor: "white",
            height: "5.3vh",
            textAlign: "center",
          }}
        >
          {!sideBarOpen ? (
            <IconButton onClick={() => {
              setSideBarOpen(true);
              setCustomSideBarOpen(true);
            }} color="primary" aria-label="add to shopping cart">
              <ViewColumnIcon />
            </IconButton>
          ) : (
            <>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <IconButton onClick={() => {
                  setSideBarOpen(false);
                  setCustomSideBarOpen(false);
                }} color="primary" aria-label="add to shopping cart">
                  <KeyboardDoubleArrowLeftIcon
                    color="error" />
                </IconButton>
                <Typography variant="button"> Masquer</Typography>
                <IconButton color="primary" aria-label="add to shopping cart">
                  <ViewColumnIcon />
                </IconButton>
              </Grid>
            </>
          )}
        </header>
        <>
          {sideBarOpen ? (
            <Grid
              container
              style={{ height: "75vh", backgroundColor: "white" }}
            >
              <List
                sx={{
                  width: "100%",
                  overflow: "scroll",
                  backgroundColor: "white",
                  height: "72vh",
                  padding: "0",
                }}
              >
                <ListItem style={{ width: "100%", padding: "0" }}>
                  <ListItemIcon>
                    <Checkbox
                      disableRipple
                      defaultChecked={false}
                      checked={defaultCheckboxValue}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const values: any = {};

                        Object.keys(defaultCheckboxValues).map((key: any) => {
                          values[key] = event.target.checked;
                        });
                        setDefaultCheckboxValues({ ...values });
                        setDefaultCheckboxValue(event.target.checked);
                        gridColumnApi.setColumnsVisible(
                          gridColumnApi.getColumns(),
                          event.target.checked
                        );
                      }}
                      inputProps={{ "aria-labelledby": "tout" }}
                    />
                  </ListItemIcon>
                  <ListItemText id={"tout"} primary={`Sélectionner tout`} />
                </ListItem>
                <DashedDivider />
                {gridColumnApi.getColumns().map((col: any, index: number) => {
                  const labelId = `checkbox-list-label-${col.colId}`;
                  return (
                    <ListItem
                      key={index}
                      style={{ width: "100%", padding: "0" }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          key={index}
                          disableRipple
                          defaultChecked={true}
                          checked={
                            gridColumnApi
                              .getColumns()
                              .filter(
                                (column: any) => column.colId === col.colId
                              )[0]?.visible
                          }
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const values = defaultCheckboxValues;
                            values[col.colId] = event.target.checked;
                            setDefaultCheckboxValues({ ...values });
                            gridColumnApi.setColumnVisible(
                              col.colId,
                              event.target.checked
                            );
                            const defaultValue = Object.values(
                              defaultCheckboxValues
                            ).every((value: any) => value === true);
                            setDefaultCheckboxValue(defaultValue);
                          }}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        key={index}
                        id={labelId}
                        primary={`${col.colDef.headerName}`}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          ) : (
            <div
              style={{
                border: "1px solid #dde2eb",
                height: "75vh",
                margin: "auto",
                backgroundColor: "white",
              }}
            >
              <Typography style={{ transform: "rotate(90deg)", margin: "25%" }}>
                Colonnes
              </Typography>
            </div>
          )}
        </>
      </Grid>
    </div>
  );
};

export default CustomSideBar;
