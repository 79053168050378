import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { uiActions } from "store/ui/ui.state";
import { Role } from "store/roles/roles.state";

const UserToolbar = () => {
  const users = useAppSelector((state) => state.users.list);
  const authenticatedUser = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={0}
      mb={1}
    >
      <Typography variant="h4" color="initial">
        Utilisateurs ({users.length})
      </Typography>

    </Grid>
  );
};

export default UserToolbar;
