import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Pole = {
  id: string;
  name: string;
  description: string;
  _id?: string; //<--- using this in edit/add user form
};
export type CostCenter = {
  id: string;
  name: string;
  _id?: string; //<--- using this in edit/add user form
};

type InitialState = {
  selectedPoleToEdit: Pole | null;
  selectedCostToEdit: CostCenter | null;
  list: Pole[];
  costCenterList: CostCenter[];
};

const initialState: InitialState = {
  selectedPoleToEdit: null,
  selectedCostToEdit: null,
  list: [],
  costCenterList: []
};

const poleSlice = createSlice({
  name: "pole",
  initialState,
  reducers: {
    setList(state, action: PayloadAction<Pole[]>) {
      state.list = action.payload;
    },
    setCostCenterList(state, action: PayloadAction<CostCenter[]>) {
      state.costCenterList = action.payload;
    },
    addPole(state, action: PayloadAction<Pole>) {
      state.list.push(action.payload);
    },
    addCostCenter(state, action: PayloadAction<CostCenter>) {
      state.costCenterList.push(action.payload);
    },
    deletePole(state, action: PayloadAction<string>) {
      // pauload: user id
      state.list = state.list.filter((pole) => pole.id !== action.payload);
    },
    deleteCostCenter(state, action: PayloadAction<string>) {
      // pauload: user id
      state.costCenterList = state.costCenterList.filter((pole) => pole.id !== action.payload);
    },
    editPole(state, action: PayloadAction<{ id: string; pole: Pole }>) {
      state.list = state.list.map((pole) => {
        if (pole.id === action.payload.id) return action.payload.pole;
        return pole;
      });
    },
    editCost(state, action: PayloadAction<{ id: string; cost: any }>) {
      state.costCenterList = state.costCenterList.map((cost) => {
        if (cost._id === action.payload.id) return action.payload.cost;
        return cost;
      });
    },
    selectPoletoEdit(state, action: PayloadAction<string>) {
      // payload: user id
      // NOTE: object spread doesnt deep copy objects
      state.selectedPoleToEdit = {
        ...state.list.find((pole) => pole.id === action.payload)!,
      };
    },
    selectCostToEdit(state, action: PayloadAction<string>) {
      // payload: user id
      // NOTE: object spread doesnt deep copy objects
      state.selectedCostToEdit = {
        ...state.costCenterList.find((cost) => cost._id === action.payload)!,
      };
    },
    clearSelectedPoleToEdit(state) {
      state.selectedPoleToEdit = null;
    },
    clearSelectedCostToEdit(state) {
      state.selectedCostToEdit = null;
    },
  },
});

export const poleActions = poleSlice.actions;
export const poleReducer = poleSlice.reducer;
