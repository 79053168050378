import { DataGrid, frFR } from "@mui/x-data-grid";
import { useAppSelector, useAppDispatch } from "hooks/redux.hook";
import useColumns from "pages/roles/hooks/useColumns.hook";
import CustomPagination from "components/custom-pagination/CustomPagination.component";
import CustomToolbar from "./CutomToolbar.component";
import { useParams } from "react-router-dom";
import { User } from "store/users/users.state";
import { useEffect } from "react";
import { getUsersAssignedToARole } from "store/users/users.thunk";


export default function UDataGrid() {
  const columns = useColumns();
  const params = useParams();
  const dispatch = useAppDispatch()
  const userNameFilterValue = useAppSelector(state => state.roles.userNameFilterValue)
  const assignedUsersObjects = useAppSelector(state => state.roles.assignedUsersObjects);


    useEffect(() => {
      dispatch(getUsersAssignedToARole(params.id!))
    }, [])


  let filteredAssignedUsers: User[] = [];
  if(userNameFilterValue === "") filteredAssignedUsers = assignedUsersObjects;
  else {
    const regExp = new RegExp(userNameFilterValue, "i")
    filteredAssignedUsers = assignedUsersObjects.filter(user => {
      const fullName = user.lastName+" "+user.firstName
      return fullName.search(regExp) > -1
    })
  }

  return (
    <div style={{ display: "flex", height: "80vh" }}>
      <DataGrid
        rows={filteredAssignedUsers}
        // rows={[]}
        columns={columns}
        pageSize={5}
        density="comfortable"
        checkboxSelection
        initialState={{
          columns: {
            columnVisibilityModel: {
              // Hide columns email & fullName, the other columns will remain visible
              email: false,
              firstName: false,
              lastName: false,
            },
          },
        }}
        components={{
          Toolbar: CustomToolbar,
          Pagination: CustomPagination,
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        sx={{
          background: "white",
          border: "none",
          borderRadius: "6px",
          padding: "20px",
          "& .gray-bold": {
            color: "#B5B5C3",
            fontWeight: "700",
            fontSize: "15px",
            lineHeight: "23px",
          },
          "& .actions-p-r": {
            paddingRight: "25px",
          },
          "& .role": {
            color: (theme) => theme.palette.text.secondary,
          },
          "& .cell": {
            color: "#B5B5C3",
            fontWeight: "700",
            fontSize: "15px",
            lineHeight: "23px",
          },
          "& .MuiDataGrid-virtualScroller": {
            // overflow: "scroll",
          },
          "& .roles-actions": {
            justifyContent: "right",
          },
          "& .MuiInput-underline:before": {
            display: "none",
          },
          "& .MuiInput-root:after": {
            display: "none",
          },
          "& .MuiInput-root": {
            background: "rgba(0, 0, 0, 0.06)",
            paddingLeft: "10px",
            width: "190px",
            borderRadius: "5px",
            color: "gray"
          },
          "& .MuiDataGrid-columnSeparator--sideRight": {
            display: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px dashed rgba(224, 224, 224, 1)",
          },
          "& .MuiDataGrid-columnHeaders": {
            borderBottom: "1px dashed rgba(224, 224, 224, 1)",
          },
          "& .MuiDataGrid-footerContainer": {
            border: "none",
          },
        }}
      />
    </div>
  );
}
