import { axiosInstance } from "api/axiosInstance";
import { feedbackMessageUIActions } from "store/feedback-message-ui/feedbackMessageUI.state";
import { AppDispatch } from "store/store.state";
import { DataGridStateEnum, DataGridStatesActions, IDataGridState } from "./dataGridState.state";


export function getDatagridStatesThunk() {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axiosInstance.get(
                "/datagridstates",
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                    },
                },
            );
            const datagridstates: IDataGridState[] = response.data.data;
            dispatch(DataGridStatesActions.setList(datagridstates));
            const DVI: IDataGridState = datagridstates.filter(
                (view: IDataGridState) => view.favorite === true && view.dataGridName === DataGridStateEnum.Invoice
            )[0];
            const DVO: IDataGridState = datagridstates.filter(
                (view: IDataGridState) => view.favorite === true && view.dataGridName === DataGridStateEnum.Order
            )[0];
            const DVS: IDataGridState = datagridstates.filter(
                (view: IDataGridState) => view.favorite === true && view.dataGridName === DataGridStateEnum.Supplier
            )[0];
            const DVU: IDataGridState = datagridstates.filter(
                (view: IDataGridState) => view.favorite === true && view.dataGridName === DataGridStateEnum.User
            )[0];
            dispatch(DataGridStatesActions.setSelectDataGridStateInvoice(DVI))
            dispatch(DataGridStatesActions.setSelectDataGridStateOrder(DVO))
            dispatch(DataGridStatesActions.setSelectDataGridStateSupplier(DVS))
            dispatch(DataGridStatesActions.setSelectDataGridStateUser(DVU))
            dispatch(DataGridStatesActions.setList(datagridstates));
        } catch (e) {
            console.log(e);
        }
    };
}

export function getDatagridStateByUserIdThunk(id: string) {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axiosInstance.get(
                `/datagridstates/users/${id}`,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                    },
                },
            );
            const views: IDataGridState[] = response.data.data;
            dispatch(DataGridStatesActions.setList(views));
            const DVI: IDataGridState = views.filter(
                (view: IDataGridState) => view.favorite === true && view.dataGridName === DataGridStateEnum.Invoice
            )[0];
            const DVO: IDataGridState = views.filter(
                (view: IDataGridState) => view.favorite === true && view.dataGridName === DataGridStateEnum.Order
            )[0];
            const DVS: IDataGridState = views.filter(
                (view: IDataGridState) => view.favorite === true && view.dataGridName === DataGridStateEnum.Supplier
            )[0];
            const DVU: IDataGridState = views.filter(
                (view: IDataGridState) => view.favorite === true && view.dataGridName === DataGridStateEnum.User
            )[0];
            dispatch(DataGridStatesActions.setSelectDataGridStateInvoice(DVI))
            dispatch(DataGridStatesActions.setSelectDataGridStateOrder(DVO))
            dispatch(DataGridStatesActions.setSelectDataGridStateSupplier(DVS))
            dispatch(DataGridStatesActions.setSelectDataGridStateUser(DVU))

            dispatch(DataGridStatesActions.setSelectDataGridStateInvoiceFavorite(DVI))
            dispatch(DataGridStatesActions.setSelectDataGridStateOrderFavorite(DVO))
            dispatch(DataGridStatesActions.setSelectDataGridStateSupplierFavorite(DVS))
            dispatch(DataGridStatesActions.setSelectDataGridStateUserFavorite(DVU))

        } catch (e) {
            console.log(e);
        }
    };
}

export function getDatagridStateByNameThunk(name: string, user: string) {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axiosInstance.get(
                `/datagridstates/view/${name}/${user}`,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                    },
                },
            );
            const view: IDataGridState = response.data.data;
            switch (view.dataGridName) {
                case DataGridStateEnum.Invoice:
                    dispatch(DataGridStatesActions.setSelectDataGridStateInvoice(view));
                    break;
                case DataGridStateEnum.Order:
                    dispatch(DataGridStatesActions.setSelectDataGridStateOrder(view));
                    break;
                case DataGridStateEnum.Supplier:
                    dispatch(DataGridStatesActions.setSelectDataGridStateSupplier(view));
                    break;
                case DataGridStateEnum.User:
                    dispatch(DataGridStatesActions.setSelectDataGridStateUser(view));
                    break;
                default:
                    break;
            }
        } catch (e) {
            console.log(e);
        }
    };
}

export function addDatagridStateThunk(datagridstate: Omit<IDataGridState, "id">) {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axiosInstance.post(
                "/datagridstates",
                datagridstate,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                    },
                },
            );
            const completeDatagridstate: IDataGridState = response.data.data;

            dispatch(DataGridStatesActions.addDataGridState(completeDatagridstate));


            switch (completeDatagridstate.dataGridName) {
                case DataGridStateEnum.Invoice:
                    dispatch(DataGridStatesActions.setSelectDataGridStateInvoice(completeDatagridstate));
                    break;
                case DataGridStateEnum.Order:
                    dispatch(DataGridStatesActions.setSelectDataGridStateOrder(completeDatagridstate));
                    break;
                case DataGridStateEnum.Supplier:
                    dispatch(DataGridStatesActions.setSelectDataGridStateSupplier(completeDatagridstate));
                    break;
                case DataGridStateEnum.User:
                    dispatch(DataGridStatesActions.setSelectDataGridStateUser(completeDatagridstate));
                    break;
                default:
                    break;
            }
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message: "State ajoutée avec succès",
                })
            );
        } catch (e) {
            console.log(e);
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message:
                        "Impossible d'ajouter State s'il vous plaît réessayer plus tard",
                })
            );
        }
    };
}

export function editDataGridStateThunk(obj: { id: string; datagridstate: Omit<IDataGridState, "id"> }) {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axiosInstance.put(
                `/datagridstates/${obj.id}`,
                obj.datagridstate,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                    },
                },
            );
            const editedDatagridstates = response.data.data;
            dispatch(DataGridStatesActions.editDataGridState({ id: obj.id, dataGridState: editedDatagridstates }));
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message: "State mis à jour avec succès",
                })
            );
        } catch (e) {
            console.log(e);
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message:
                        "Impossible de mettre à jour State s'il vous plaît réessayer plus tard",
                })
            );
        }
    };
}


export function deleteDatagridStateThunk(id: string) {
    return async (dispatch: AppDispatch) => {
        try {
            const response: any = await axiosInstance.delete(`/datagridstates/${id}`, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                },
            },);

            dispatch(DataGridStatesActions.deleteDataGridState(id));
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message: "State supprimée avec succès",
                })
            );
        } catch (e: any) {

            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message:
                        "Impossible de supprimer State s'il vous plaît réessayer plus tard",
                })
            );

        }
    };
}

export function favoriteDatagridStateThunk(id: string) {
    return async (dispatch: AppDispatch) => {
        try {
            const response: any = await axiosInstance.put(`/datagridstates/favorite/${id}`);

        } catch (e: any) {

            console.log(e);


        }
    };
}