import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import { useFormik } from "formik";
import * as Yup from "yup";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";

import { uiActions } from "store/ui/ui.state";
import { editSupplierThunk } from "store/suppliers/suppliers.thunk";
import { CustomTextField as ITextField } from "components/custom-textfield/CustomTextField.component";
import GrayButton from "components/gray-button/GrayButton.component";
import Typography from "@mui/material/Typography";
import BlueButton from "components/blue-button/BlueButton.component";
import { margin } from "../variables";
import { useEffect, useRef, useState } from "react";
import AttachmentIcon from "@mui/icons-material/Attachment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import PhoneInput from 'react-phone-input-2'
import { CustomTextField } from "components/custom-textfield/CustomTextField.component";
import {
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Autocomplete
} from "@mui/material";
import axios from "axios";

type ErrorProp = boolean | undefined;

export default function EditForm() {
  const [hideTva, setHideTva] = useState<boolean>(false);
  const civiliteList = useAppSelector((state) => state.civilite.list);
  const [countryState, setCountryState] = useState<any>({
    loading: false,
    countries: [],
    errorMessage: "",
  });
  const [pays, setPays] = useState<any>([])
  const [selectedCountry, setSelectedCountry] = useState<any>();
  const [proofActivityFiles, setProofActivityFiles] = useState<any[]>([]);
  const [ribFile, setRibFile] = useState<any>();
  const ribRef = useRef<any>(null)!;
  const proofActRef = useRef<any>(null)!;
  const [showProofActivityFilesError, setShowProofActivityFilesError] = useState<any>(false);
  const dispatch = useAppDispatch();
  const selectedSupplierToEdit = useAppSelector(
    (state) => state.suppliers.selectedSupplierToEdit
  )!;
  const [paysValue, setPaysValue] = useState<any>(selectedSupplierToEdit?.pays)
  const civilite: any = selectedSupplierToEdit.civilite;
  const phoneRegExp = new RegExp(/^[1-9]\d*$/);

  const formik = useFormik({
    initialValues: {
      nom: selectedSupplierToEdit.nom,
      prenom: selectedSupplierToEdit.prenom,
      civilite: civilite._id,
      email: selectedSupplierToEdit.email,
      socialReason: selectedSupplierToEdit.socialReason,
      city: selectedSupplierToEdit.city,
      postalCode: selectedSupplierToEdit.postalCode,
      address: selectedSupplierToEdit.address,
      siret: selectedSupplierToEdit.siret,
      pays: selectedSupplierToEdit.pays,
      BIC: selectedSupplierToEdit.BIC,
      IBAN: selectedSupplierToEdit.IBAN,
      siren: selectedSupplierToEdit.siren,
      tva: selectedSupplierToEdit.tva,
      tel: selectedSupplierToEdit.tel,
      mobile: selectedSupplierToEdit.mobile?.split(" ")[1],
    },
    validationSchema: Yup.object({
      civilite: Yup.string().required("Civilité obligatoire"),
      prenom: Yup.string()
        .min(3, "Too Short!")
        .max(30, "Too Long!")
        .required("Prénom obligatoire"),
      nom: Yup.string()
        .min(3, "Too Short!")
        .max(30, "Too Long!")
        .required("Nom obligatoire"),
      email: Yup.string()
        .email("Mauvais format d'e-mail")
        .required("E-mail obligatoire"),
      BIC: Yup.string().length(11, "11 caractères requis").required("BIC obligatoire"),
      IBAN: Yup.string()
        .matches(/[FR]\d*/, "doit commencer par FR")
        .length(27, "27 caractères requis").required("IBAN obligatoire"),
      socialReason: Yup.string().required("Raison social obligatoire"),
      city: Yup.string().required("Ville obligatoire"),
      siret: Yup.string().when('pays',
      {
        is: (val:any) => (val === "France" ? true : false),
        then: Yup.string().required("SIRET  obligatoire")
        .length(14, "14 caractères requis")
      }
      ),
      address: Yup.string().required("Adresse  obligatoire"),
      postalCode: Yup.string().required("Code postal obligatoire"),
      pays: Yup.string(),
      siren: Yup.string().when('pays',
      {
        is: (val:any) => (val === "France" ? true : false),
        then: Yup.string().required("SIREN obligatoire")
      }
      ),
      tva: Yup.string().required("Numéro de TVA intracommunautaire obligatoire"),
      tel: Yup.string(),
      mobile: Yup.string().matches(phoneRegExp, "Le numéro de téléphone n'est pas valide").required("Numéro Mobile obligatoire"),
    }),
    onSubmit: (values) => {
      if (values.pays === "France") {
        if (proofActivityFiles?.length) {
          const country = countryState.countries.filter(
            (c: any) => c?.name.common === formik.values.pays
          )[0];
  
          const rib = ribRef.current!.files![0];
          const mob = formik.values.mobile;
          formik.values.mobile = `${country?.idd?.root}${country?.idd?.suffixes} ${mob}`;
          dispatch(
            editSupplierThunk({
              id: selectedSupplierToEdit.id,
              supplier: values,
              RIB: rib,
              ProofActivity: proofActRef.current!.files,
            })
          );
          dispatch(uiActions.setEditSupplierFormOpen(false));
        } else {
          setShowProofActivityFilesError(true)
        }
      } else {
        const country = countryState.countries.filter(
          (c: any) => c?.name.common === formik.values.pays
        )[0];

        const rib: any = "";
        const mob = formik.values.mobile;
        formik.values.mobile = `${country?.idd?.root}${country?.idd?.suffixes} ${mob}`;
        dispatch(
          editSupplierThunk({
            id: selectedSupplierToEdit.id,
            supplier: values,
            RIB: rib,
            ProofActivity: [],
          })
        );
        dispatch(uiActions.setEditSupplierFormOpen(false));
      }
    },
  });

  const showCiviliteError = (formik.errors.civilite &&
    formik.touched.civilite) as ErrorProp;
  const showEmailError = (formik.errors.email &&
    formik.touched.email) as ErrorProp;
  const showSocialReasonError = (formik.errors.socialReason &&
    formik.touched.socialReason) as ErrorProp;
  const showCityError = (formik.errors.city &&
    formik.touched.city) as ErrorProp;
  const showSiretError = (formik.errors.siret &&
    formik.touched.siret) as ErrorProp;
  const showAddressError = (formik.errors.address &&
    formik.touched.address) as ErrorProp;
  const showPostalCodeError = (formik.errors.postalCode &&
    formik.touched.postalCode) as ErrorProp;
  const showPaysError = (formik.errors.pays &&
    formik.touched.pays) as ErrorProp;
  const showSirenError = (formik.errors.siren &&
    formik.touched.siren) as ErrorProp;
  const showBICError = (formik.errors.BIC && formik.touched.BIC) as ErrorProp;
  const showIBANError = (formik.errors.IBAN &&
    formik.touched.IBAN) as ErrorProp;
  const showTvaError = (formik.errors.tva && formik.touched.tva) as ErrorProp;
  const showPrenomError = (formik.errors.prenom &&
    formik.touched.prenom) as ErrorProp;
  const showNomError = (formik.errors.nom && formik.touched.nom) as ErrorProp;
  const showTelError = (formik.errors.tel && formik.touched.tel) as ErrorProp;
  const showMobileError = (formik.errors.mobile &&
    formik.touched.mobile) as ErrorProp;
  const handleTvaChange = (value: string) => {
    if (value == "false") {
      setHideTva(true);
      formik.setFieldValue("tva", 0);
    } else {
      setHideTva(false);
    }
  };

  useEffect(() => {
    const getCountries = async () => {
      try {
        setCountryState({
          ...countryState,
          loading: true,
        });

        // fetch data
        const dataUrl = "https://restcountries.com/v3.1/all";
        const response = await axios.get(dataUrl);
        const payListOptions: any = response.data?.map((e: any) => ({ name: e.name?.common }))
        setPays(payListOptions.sort((a: any, b: any) => (a?.name > b?.name) ? 1 : ((b?.name > a?.name) ? -1 : 0)))
        setCountryState({
          ...countryState,
          countries: response.data,
          loading: false,
        });

        const country = countryState.countries.map(
          (c: any) => c?.name.common === selectedSupplierToEdit.pays
        )[0];
        setSelectedCountry({ ...country });
      } catch (error) {
        setCountryState({
          ...countryState,
          loading: false,
          errorMessage: "Sorry Something went wrong!",
        });
      }
    };

    getCountries();

    // GET selected country - first rendering
    if (selectedSupplierToEdit.pays) {
      const selected = countryState.countries.filter(
        (country: any) => country?.name.common === formik.values.pays
      )[0];
      setSelectedCountry(selected);
    }
  }, []);

  useEffect(() => {
    const pays = countryState.countries.filter(
      (c: any) => c?.name.common === formik.values.pays
    )[0];
    setSelectedCountry({ ...pays });
  }, [formik.values.pays]);

  useEffect(() => {
    if (selectedSupplierToEdit) {
      const dt: any = []
      selectedSupplierToEdit.ProofActivity?.forEach((elm: any) => {
        dt.push({
          filepdf: elm,
          name: elm?.split('/uploads/')[1]
        })
      })
      setProofActivityFiles(dt)
    }
  }, [selectedSupplierToEdit]);

  const handleSelectedCountry = (country: any) => {
    const selected = countryState.countries.filter(
      (c: any) => c?.name.common === country
    );

    setSelectedCountry({ ...selected[0] });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography component="span" fontWeight="bold">
        E-mail{" "}
        <Typography component="span" color="error">
          *
        </Typography>
      </Typography>
      <ITextField
        {...formik.getFieldProps("email")}
        placeholder=" Email"
        error={showEmailError}
        helperText={showEmailError && formik.errors.email}
        sx={{ marginBottom: margin }}
      />
      <Typography component="span" fontWeight="bold">
        Raison Social{" "}
        <Typography component="span" color="error">
          *
        </Typography>
      </Typography>
      <ITextField
        {...formik.getFieldProps("socialReason")}
        error={showSocialReasonError}
        placeholder="Raison Social"
        helperText={showSocialReasonError && formik.errors.socialReason}
        sx={{ marginBottom: margin }}
        inputProps={{ style: { textTransform: "uppercase" } }}
      />

      <Typography component="span" fontWeight="bold">
        Civilité{" "}
        <Typography component="span" color="error">
          *
        </Typography>
      </Typography>

      <FormControl
        fullWidth
        size="small"
        error={showCiviliteError}
        sx={{
          marginBottom: margin,
          "& .MuiSelect-select": { padding: "10px" },
        }}
      >
        <Select
          {...formik.getFieldProps("civilite")}
          variant="filled"
          sx={{
            color: "#5E6278",
            fontWeight: "500",
            fontSize: "14px",
            background: "#F5F8FA",
            "&:before": {
              border: "none",
            },
            "&:after": {
              border: "none",
            },
          }}
        >
          {civiliteList.map((c) => (
            <MenuItem key={c.id} value={c.id}>
              {c?.name}
            </MenuItem>
          ))}
        </Select>
        {/* <FormHelperText error>
          {showCiviliteError && formik.errors.civilite?.name}
        </FormHelperText> */}
      </FormControl>

      <Typography component="span" fontWeight="bold">
        Nom{" "}
        <Typography component="span" color="error">
          *
        </Typography>
      </Typography>
      <CustomTextField
        {...formik.getFieldProps("nom")}
        error={showNomError}
        helperText={showNomError && formik.errors.nom}
        placeholder="Nom"
        fullWidth
        size="small"
        sx={{ marginBottom: margin }}
        inputProps={{ style: { textTransform: "uppercase" } }}
      />

      <Typography component="span" fontWeight="bold">
        Prénom{" "}
        <Typography component="span" color="error">
          *
        </Typography>
      </Typography>
      <CustomTextField
        inputProps={{ style: { textTransform: "capitalize" } }}
        {...formik.getFieldProps("prenom")}
        error={showPrenomError}
        helperText={showPrenomError && formik.errors.prenom}
        placeholder="Prénom"
        fullWidth
        size="small"
        sx={{ marginBottom: margin }}
      />

      <Typography component="span" fontWeight="bold">
        BIC{" "}
        <Typography component="span" color="error">
          *
        </Typography>
      </Typography>
      <ITextField
        {...formik.getFieldProps("BIC")}
        placeholder="BIC"
        error={showBICError}
        helperText={showBICError && formik.errors.BIC}
        sx={{ marginBottom: margin }}
      />
      <Typography component="span" fontWeight="bold">
        IBAN{" "}
        <Typography component="span" color="error">
          *
        </Typography>
      </Typography>
      <ITextField
        {...formik.getFieldProps("IBAN")}
        placeholder="IBAN"
        error={showIBANError}
        helperText={showIBANError && formik.errors.IBAN}
        sx={{ marginBottom: margin }}
      />
      <Typography component="span" fontWeight="bold">
        Pays{" "}
        <Typography component="span" color="error">
          *
        </Typography>
      </Typography>
      <FormControl
        fullWidth
        size="small"
        error={showPaysError}
        sx={{
          marginBottom: margin,
          "& .MuiSelect-select": { padding: "10px" },
        }}
      >
        <Autocomplete
          {...formik.getFieldProps("pays")}
          fullWidth
          sx={{
            marginBottom: margin,
            "& .MuiSelect-select": { padding: "10px" },
          }}
          onChange={(event, newValue: any) => {
            formik.values.pays = newValue.name
            setPaysValue(newValue.name)
            setSelectedCountry(newValue.name)
          }}
          getOptionLabel={(supp) => {
            return supp?.name ? supp?.name : supp
          }}
          isOptionEqualToValue={(option, value) => {
            return option.name === value
          }}
          style={{
            color: "#5E6278",
            fontWeight: "500",
            border: "none",
            fontSize: "14px",
            background: "#F5F8FA",
          }}
          size='small'
          // value={paysValue}
          options={pays}
          renderInput={(params) => {
            return (
              <TextField  {...params} />
            )
          }}
        />
        <FormHelperText error>
          {showPaysError && formik.errors.pays}
        </FormHelperText>
      </FormControl>
      {formik.values.pays === "France" && (
        <>
          <Typography component="span" fontWeight="bold">
            SIRET{" "}
            <Typography component="span" color="error">
              *
            </Typography>
          </Typography>
          <ITextField
            {...formik.getFieldProps("siret")}
            placeholder=" Siret"
            error={showSiretError}
            helperText={showSiretError && formik.errors.siret}
            sx={{ marginBottom: margin }}
            inputProps={{ maxLength: 14 }}
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, "");
              formik.setValues({
                ...formik.values,
                siret: value,
                siren: value.slice(0, 9),
              });
            }}
          />

          <Typography component="span" fontWeight="bold">
            SIREN{" "}
            <Typography component="span" color="error">
              *
            </Typography>
          </Typography>
          <ITextField
            {...formik.getFieldProps("siren")}
            placeholder=" Siren"
            disabled
            error={showSirenError}
            helperText={showSirenError && formik.errors.siren}
            sx={{ marginBottom: margin }}
          />
        </>
      )}

      <Typography component="span" fontWeight="bold">
        Adresse{" "}
        <Typography component="span" color="error">
          *
        </Typography>
      </Typography>
      <ITextField
        {...formik.getFieldProps("address")}
        placeholder=" Adresse"
        error={showAddressError}
        helperText={showAddressError && formik.errors.address}
        sx={{ marginBottom: margin }}
      />
      <Typography component="span" fontWeight="bold">
        Code postal{" "}
        <Typography component="span" color="error">
          *
        </Typography>
      </Typography>
      <ITextField
        {...formik.getFieldProps("postalCode")}
        placeholder=" Code Postal"
        error={showPostalCodeError}
        helperText={showPostalCodeError && formik.errors.postalCode}
        sx={{ marginBottom: margin }}
        type="number"
      />
      <Typography component="span" fontWeight="bold">
        Ville{" "}
        <Typography component="span" color="error">
          *{" "}
        </Typography>
      </Typography>
      <ITextField
        {...formik.getFieldProps("city")}
        placeholder=" Ville"
        error={showCityError}
        helperText={showCityError && formik.errors.city}
        sx={{ marginBottom: margin }}
        inputProps={{ style: { textTransform: "uppercase" } }}
      />
      {/* <FormControl
        fullWidth
        size="small"
        error={showPaysError}
      // sx={{
      //   marginBottom: margin,
      //   "& .MuiSelect-select": { padding: "10px" },
      // }}
      >
        <Select
          {...formik.getFieldProps("pays")}
          onClick={() => handleSelectedCountry(formik.values.pays)}
          value={formik.values.pays}
        // style={{
        //   color: "#5E6278",
        //   fontWeight: "500",
        //   border: "none",
        //   padding: "2.5%",
        //   fontSize: "14px",
        //   background: "#F5F8FA",
        // }}
        >
          {countryState.countries.sort((a: any, b: any) => (a?.name.common > b?.name.common) ? 1 : ((b?.name.common > a?.name.common) ? -1 : 0)).map((c: any, index: number) => (
            <MenuItem key={index} value={c?.name.common}>
              {c?.name.common}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>
          {showPaysError && formik.errors.pays}
        </FormHelperText>
      </FormControl> */}
      {/* <ITextField
        {...formik.getFieldProps("pays")}
        error={showPaysError}
        placeholder=" Pays"
        helperText={showPaysError && formik.errors.pays}
        sx={{ marginBottom: margin }}
      /> */}

      <Typography component="span" fontWeight="bold">
        Numéro Tel{" "}
      </Typography>

      {/* <CustomTextField
        {...formik.getFieldProps("tel")}
        error={showTelError}
        helperText={showTelError && formik.errors.tel}
        placeholder="Numéro de téléphone"
        fullWidth
        // size="small"
        sx={{ marginBottom: margin }}
        inputProps={{ maxLength: 10 }}
        onChange={(e) => {
          const value = e.target.value.replace(/\D/g, "");
          formik.setValues({
            ...formik.values,
            tel: value,
          });
        }}
      /> */}
      <PhoneInput
        {...formik.getFieldProps("tel")}
        country={'fr'}
        onChange={phone => formik.setFieldValue('tel', phone)}
        inputStyle={{ width: '100%' }}
      />
      <Box
        sx={formik.values.pays !== "France" ? { marginBottom: "90px" } : { marginBottom: margin }}
      >
        <Typography component="div" fontWeight="bold" sx={{ marginTop: margin }}>
          Numéro Mobile{" "}
          <Typography component="span" color="error">
            *
          </Typography>
        </Typography>
        {/* <TextField
        {...formik.getFieldProps("mobile")}
        fullWidth
        sx={formik.values.pays !== "France" ? { marginBottom: "90px" } : { marginBottom: margin }}
        helperText={showMobileError && formik.errors.mobile}
        error={showMobileError}
        id="outlined-start-adornment"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{ width: "10%" }}>
              <img
                style={{ margin: "10px" }}
                src={
                  countryState.countries.filter(
                    (c: any) => c?.name.common === formik.values.pays
                  )[0]?.flags?.png
                }
                width="35px"
                alt="flag"
              />

              {
                countryState.countries.filter(
                  (c: any) => c?.name.common === formik.values.pays
                )[0]?.idd?.root
              }
              {
                countryState.countries.filter(
                  (c: any) => c?.name.common === formik.values.pays
                )[0]?.idd?.suffixes
              }
            </InputAdornment>
          ),
        }}
      /> */}
        <PhoneInput
          {...formik.getFieldProps("mobile")}
          country={'fr'}
          //value={this.state.phone}
          onChange={phone => formik.setFieldValue('mobile', phone)}
          inputStyle={{ width: '100%' }}
        />
        {showMobileError && (
          <Typography component="p" sx={{ fontWeight: 400, fontSize: '0.75rem', lineHeight: 1.66, letterSpacing: ' 0.03333em', textAlign: 'left', margin: "3px 14px 0 14px", color: "#d32f2f" }}>{showMobileError && formik.errors.mobile}</Typography>
        )}
      </Box>
      {/* <CustomTextField
        {...formik.getFieldProps("mobile")}
        error={showMobileError}
        helperText={showMobileError && formik.errors.mobile}
        placeholder="Numéro de mobile"
        fullWidth
        size="small"
        sx={{ marginBottom: margin }}
        inputProps={{ maxLength: 10 }}
        onChange={(e) => {
          const value = e.target.value.replace(/\D/g, "");
          formik.setValues({
            ...formik.values,
            mobile: value,
          });
        }}
      /> */}
      {formik.values.pays === "France" && (
        <div style={{ marginBottom: "90px" }}>
          <div>
            <Typography component="div" fontWeight="bold" sx={{ marginTop: margin }}>
              Assujetti à la TVA{" "}
              <Typography component="span" color="error">
                *
              </Typography>
            </Typography>
          </div>

          <FormControl>
            <RadioGroup
              defaultValue="true"
              onChange={(_, value) => handleTvaChange(value)}
              row
            >
              <FormControlLabel value="true" control={<Radio />} label="Oui" />
              <FormControlLabel value="false" control={<Radio />} label="Non" />
            </RadioGroup>
          </FormControl>
          {!hideTva && (
            <Grid>
              <div>
                <Typography component="div" fontWeight="bold" sx={{ marginTop: margin }}>
                  Numéro de TVA intracommunautaire{" "}
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
              </div>
              <ITextField
                {...formik.getFieldProps("tva")}
                placeholder="Numéro de TVA intracommunautaire"
                error={showTvaError}
                helperText={showTvaError && formik.errors.tva}
                sx={{ marginBottom: margin }}
                type="string"
              />
            </Grid>
          )}
          <Typography component="span" fontWeight="bold" mt={1}>
            RIB{" "}
          </Typography>
          <Button
            variant="outlined"
            fullWidth
            startIcon={<AttachFileIcon />}
            style={{ marginBottom: "15px" }}
          >
            Upload
            <input
              ref={ribRef}
              type="file"
              onChange={() => {
                const file = ribRef.current?.files[0];
                setRibFile(file);
              }}
              style={{
                opacity: 0,
                position: "absolute",
                width: "100%",
                height: "100%",
              }}
            // onChange={(e) => {
            //   console.log(e.target.files![0]);
            //   console.log(URL.createObjectURL(e.target.files![0]));

            //   // handleUpload();
            //   // setMediaUrl(URL.createObjectURL(e.target.files![0]));
            // }}
            />
          </Button>
          {ribFile?.name && (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              mb={2}
            >
              {/* <AttachFileIcon />
          <Typography style={{ width: "80%" }}>{ribFile?.name}</Typography> */}
              <ListItem>
                <ListItemIcon>
                  <AttachmentIcon />
                </ListItemIcon>
                <ListItemText>{ribFile?.name}</ListItemText>
                <ListItemIcon
                  onClick={() => {
                    // const files = proofActivityFiles;
                    // files.splice(index, 1);
                    // setProofActivityFiles([...files]);
                    setRibFile({})
                  }}
                >
                  <ClearIcon />
                </ListItemIcon>
              </ListItem>
            </Grid>
          )}
          <Typography component="span" fontWeight="bold" mt={2}>
            Justificatif d’activité de l’entreprise (Extrait KBIS, avis au répertoire SIRENE ou autre){" "}
            <Typography component="span" color="error">
              *
            </Typography>
          </Typography>
          {/* <Button
          variant={
            proofActRef.current?.files?.length > 0 ? "contained" : "outlined"
          }
          fullWidth
          startIcon={<AttachFileIcon />}
          style={{ marginBottom: "10px" }}
        >
          Upload
          <input
            ref={proofActRef}
            type="file"
            multiple
            style={{
              opacity: 0,
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
          />
        </Button>
        {proofActRef?.current?.files && (
          <List>
            {Array.from(proofActRef?.current?.files)?.map(
              (file: any, index: number) => {
                return (
                  <ListItem>
                    <ListItemIcon>
                      <AttachmentIcon />
                    </ListItemIcon>
                    <ListItemText key={index}>{file?.name}</ListItemText>
                  </ListItem>
                );
              }
            )}
          </List>
        )} */}
          {/* <FieldArray
          name="ProofActivity"
          render={(arrayHelpers) => (
            <div>
              <Button
                startIcon={<Add />}
                style={{ margin: "5% 0" }}
                variant="outlined"
                onClick={() => {
                  arrayHelpers.push(null);
                }}
              ></Button>
              {formik.values.ProofActivity?.map((friend, index) => (
                <div key={index}>
                  <Button
                    startIcon={<AttachFileIcon />}
                    style={{ marginBottom: "10px" }}
                  >
                    Upload
                    <input
                      ref={proofActRef}
                      type="file"
                      multiple
                      onChange={() => {
                        const files = proofActivityFiles;
                        console.log(Array.from(proofActRef.current!.files));

                        Array.from(proofActRef.current!.files).map(
                          (file: any) => files.push(file)
                        );
                        setProofActivityFiles([...files]);
                      }}
                      style={{
                        opacity: 0,
                        position: "absolute",
                        width: "50%",
                        height: "100%",
                      }}
                    />
                  </Button>
                  <button
                    type="button"
                    onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                  >
                    -
                  </button>
                </div>
              ))}
            </div>
          )}
        /> */}
          <Button
            startIcon={<AttachFileIcon />}
            variant="outlined"
            style={{ marginBottom: "10px" }}
            fullWidth
          >
            Upload
            <input
              ref={proofActRef}
              type="file"
              multiple
              onChange={() => {
                let files: any[] = []
                proofActivityFiles?.forEach((elm: any) => files.push(elm))
                // files.splice(index, 1);
                // setProofActivityFiles([...files]);


                // const files = proofActivityFiles;
                // console.log(Array.from(proofActRef.current!.files));

                Array.from(proofActRef.current!.files).map((file: any) => {
                  return files.push(file)
                })
                setProofActivityFiles([...files]);
              }}
              style={{
                opacity: 0,
                position: "absolute",
                width: "50%",
                height: "100%",
              }}
            />
          </Button>
          {showProofActivityFilesError && (
            <Typography component="p" sx={{ fontWeight: 400, fontSize: '0.75rem', lineHeight: 1.66, letterSpacing: ' 0.03333em', textAlign: 'left', margin: "3px 14px 0 14px", color: "#d32f2f" }}>Justificatif d’activité de l’entreprise (Extrait KBIS, avis au répertoire SIRENE ou autre) obligatoire</Typography>
          )}
          {proofActivityFiles && proofActivityFiles?.length ?
            (
              <div style={{ margin: '5px 0 90px 0' }}>
                {proofActivityFiles.map((file: any, index: number) => {
                  return (
                    <ListItem key={index}>
                      <ListItemIcon onClick={() => window.open(file.filepdf)}>
                        <AttachmentIcon style={{ color: '#23659f', cursor: 'pointer' }} />
                      </ListItemIcon>
                      <ListItemText>{file?.name}</ListItemText>
                      <ListItemIcon
                        onClick={() => {
                          let files: any[] = []
                          proofActivityFiles?.forEach((elm: any) => files.push(elm))
                          files.splice(index, 1);
                          setProofActivityFiles([...files]);
                        }}
                      >
                        <ClearIcon style={{ color: '#9e1e3e', cursor: 'pointer' }} />
                      </ListItemIcon>
                    </ListItem>
                  )
                })}
              </div>
            ) : null
          }
        </div>
      )}
      <div style={{ position: "absolute", bottom: "0", left: "0", background: "white", right: "0", display: "flex", padding: "10px", justifyContent: "center", alignItems: "center" }} >
        <GrayButton
          style={{ marginRight: "10px" }}
          onClick={() => dispatch(uiActions.setEditSupplierFormOpen(false))}
        >
          Annuler
        </GrayButton>
        <BlueButton style={{ marginLeft: "10px" }} type="submit" >Soumettre</BlueButton>
      </div>
    </form>
  );
}
