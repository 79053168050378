import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "hooks/redux.hook";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CustomDialogTitle from "components/custom-dialog-title/CustomDialogTitle.component";
import BlueButton from "components/blue-button/BlueButton.component";
import GrayButton from "components/gray-button/GrayButton.component";
import Divider from "@mui/material/Divider";

import UDataGrid from "./components/data-grid/ODataGrid.component";
import EditUserForm from "./components/edit-order-form/EditOrderForm.component";

import { uiActions } from "store/ui/ui.state";
import { getUsersThunk } from "store/users/users.thunk";
import { useNavigate } from "react-router-dom";
import { USER_ROLES } from "utils/roles";
import { getPostesThunk } from "store/postes/postes.thunk";
import { getCiviliteThunk } from "store/civilite/civilite.thunk";
import { getPoleThunk } from "store/pole/pole.thunk";
import {
  deleteUserThunk,
  generateNewPasswordForUser,
} from "store/users/users.thunk";
import WhiteButton from "components/white-button/WhiteButton.component";
import {
  deleteOrderThunk,
  getOrdersByVendorIdThunk,
  getOrdersThunk,
} from "store/orders/orders.thunk";
import EditOrderForm from "./components/edit-order-form/EditOrderForm.component";
import AddOrder from "./components/add-order-form/AddOrder";
import OrderInfos from "./components/order-infos/OrderInfos";
import OrderPdf from "./components/order-pdf/OrderPdf";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "./PurchaseOrder.css";
import EditOrderForm2 from "./components/edit-order-form/EditOrderForm2.component";
import OrderStatus from "./components/order-status/OrderStatus";

export default function PurshaseOrders() {
  const dispatch = useAppDispatch();
  const selectedOrder = useAppSelector(
    (state) => state.orders.selectedOrderToEdit
  )!;

  const authUser = useAppSelector((state) => state.auth.user)!;

  const accountType = useAppSelector((state) => state.auth.accountType)!;
  const {
    addOrderFormOpen,
    editOrderFormOpen,
    orderPdfWarningOpen,
    infoOrderOpen,
    deleteOrderWarningOpen,
    idOfItemToDelete,
    editOrderStatusOpen,
  } = useAppSelector((state) => state.ui);

  useEffect(() => {
    if (accountType === "vendor") {
      dispatch(getOrdersByVendorIdThunk(authUser.id));
    } else {
      dispatch(getOrdersThunk());
    }
  }, [
    addOrderFormOpen,
    editOrderFormOpen,
    orderPdfWarningOpen,
    infoOrderOpen,
    deleteOrderWarningOpen,
  ]);

  const openAddOrderForm = () => {
    dispatch(uiActions.setAddOrderFormOpen(true));
  };
  const closeAddOrderForm = () => {
    dispatch(uiActions.setAddOrderFormOpen(false));
  };
  const openEditOrderForm = () => {
    dispatch(uiActions.setEditOrderFormOpen(true));
  };
  const closeEditOrderStatusForm = () => {
    dispatch(uiActions.setEditOrderStatusOpen(false));
  };
  const closeEditOrderForm = () => {
    dispatch(uiActions.setEditOrderFormOpen(false));
  };
  const closePdfOrderWarning = () => {
    dispatch(uiActions.setOrderPdfWarningOpen(false));
  };
  const closeInfoOrder = () => {
    dispatch(uiActions.setInfoOrderOpen(false));
  };
  const closeDeleteOrderWarning = () => {
    dispatch(uiActions.setDeleteOrderWarningOpen({ set: false, id: "" }));
  };
  const exportOrderPdf = () => {
    const input: any = document.getElementById("orderInfos");
    html2canvas(input, { logging: true, useCORS: true }).then((canvas) => {
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL("img/png");
      const pdf = new jsPDF("portrait", "mm", "a4");
      pdf.addImage(imgData, "PNG", 0, 25, imgWidth, imgHeight);
      pdf.save(
        `${selectedOrder.BDC_NUM}-${selectedOrder.documentDate
        }-${new Date().getSeconds()}.pdf`
      );
    });
  };

  return (
    <div style={{ padding: "40px 40px 0 40px " }}>
      <UDataGrid />
      <Dialog
        fullScreen
        open={addOrderFormOpen}
        onClose={closeAddOrderForm}
        sx={{ "& .MuiPaper-root": { height: "90vh", width: "60vw" } }}
        maxWidth="lg"
      >
        <CustomDialogTitle onClose={closeAddOrderForm} id="">
          Ajouter un bon de commande
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          <AddOrder handleCloseAddDialog={closeAddOrderForm} />
          {/* <AddOrderForm handleCloseAddDialog={closeAddOrderForm} /> */}
        </DialogContent>
      </Dialog>

      {/* status dialog */}
      <Dialog
        fullScreen
        open={editOrderStatusOpen}
        onClose={closeEditOrderStatusForm}
        sx={{ "& .MuiPaper-root": { height: "45vh", width: "40vw" } }}
        maxWidth="lg"
      >
        <CustomDialogTitle onClose={closeEditOrderStatusForm} id="">
          Statut de bon de commande
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          <OrderStatus handleCloseEditStatus={closeEditOrderStatusForm} />
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen
        open={editOrderFormOpen}
        onClose={closeEditOrderForm}
        sx={{ "& .MuiPaper-root": { height: "90vh", width: "60vw" } }}
      >
        <CustomDialogTitle onClose={closeEditOrderForm} id="">
          Modifier un bon de commande
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          {/* <EditUserForm handleCloseEditDialog={closeEditOrderForm} /> */}
          <EditOrderForm2 handleCloseEditDialog={closeEditOrderForm} />
        </DialogContent>
      </Dialog>

      {/* version pdf */}
      <Dialog
        fullScreen
        open={orderPdfWarningOpen}
        onClose={closePdfOrderWarning}
        sx={{ "& .MuiPaper-root": { height: "95vh", width: "90vw" } }}
      >
        <CustomDialogTitle onClose={closePdfOrderWarning} id="">
          Téléchargement
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          <OrderPdf />
        </DialogContent>
        <Divider />
        <DialogActions>
          <BlueButton
            onClick={() => {
              exportOrderPdf();
              dispatch(uiActions.setOrderPdfWarningOpen(false));
            }}
          >
            Télécharger
          </BlueButton>
          <WhiteButton onClick={closePdfOrderWarning}>Annuler</WhiteButton>
        </DialogActions>
      </Dialog>

      {/* Suppression */}
      <Dialog
        open={deleteOrderWarningOpen}
        onClose={closeDeleteOrderWarning}
        sx={{ "& .MuiPaper-root": { width: "500px" } }}
      >
        <CustomDialogTitle onClose={closeDeleteOrderWarning} id="">
          Suppression
        </CustomDialogTitle>
        <Divider />
        <DialogContent>Voulez-vous vraiment supprimer ce bon?</DialogContent>
        <Divider />
        <DialogActions>
          <BlueButton
            onClick={async () => {
              try {
                await dispatch(deleteOrderThunk(idOfItemToDelete));
                if (accountType === "vendor") {
                  dispatch(
                    uiActions.setDeleteOrderWarningOpen({ set: false, id: "" })
                  );
                  await dispatch(getOrdersByVendorIdThunk(authUser.id));
                } else {
                  dispatch(
                    uiActions.setDeleteOrderWarningOpen({ set: false, id: "" })
                  );
                  await dispatch(getOrdersThunk());
                }
              } catch (error) {
                console.log(error);
              }
            }}
          >
            Valider
          </BlueButton>
          <WhiteButton onClick={closeDeleteOrderWarning}>Annuler</WhiteButton>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen
        open={infoOrderOpen}
        onClose={closeInfoOrder}
        sx={{ "& .MuiPaper-root": { height: "95vh", width: "90vw" } }}
      >
        <CustomDialogTitle onClose={closeInfoOrder} id="">
          Bon de commande
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          <OrderInfos />
        </DialogContent>
        <Divider />
        <DialogActions>
          <WhiteButton onClick={closeInfoOrder}>Annuler</WhiteButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
