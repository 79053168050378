import * as React from "react";
import Box from "@mui/material/Box";
import { Outlet, useNavigate } from "react-router-dom";

import TopNavigation from "./TopNavigation.component";
import SideNavigation from "./SideNavigation.component";
import MainContentContainer from "./MainContentContainer.component";
import { useAppSelector, useAppDispatch } from "hooks/redux.hook";
import { getCiviliteThunk } from "store/civilite/civilite.thunk";
import { getPoleThunk } from "store/pole/pole.thunk";
import { getPostesThunk } from "store/postes/postes.thunk";
import { getUsersThunk } from "store/users/users.thunk";
import { getSuppliersThunk } from "store/suppliers/suppliers.thunk";
import { getRolesThunk } from "store/roles/roles.thunk";
import {
  getOrdersByVendorIdThunk,
  getOrdersThunk,
} from "store/orders/orders.thunk";
import {
  getInvoicesByVendorIdThunk,
  getInvoicesThunk,
} from "store/invoices/invoice.thunk";
import { getTvasThunk } from "store/tva/tva.thunk";
import { getPaymentTermssThunk } from "store/payment-terms/paymentTerms.thunk";
import { getCostsThunk } from "store/pole/pole.thunk";
import { getSessionThunk } from "store/session/session.thunk";
import { getPerimetersThunk } from "store/perimeter/perimeters.thunk";
import { getProjectsThunk } from "store/project/project.thunk";
import { getDepenseThunk } from "store/typedepenses/typedepenses.thunk";
import { getArticlesThunk } from "store/article/article.thunk";
import {
  getDatagridStateByUserIdThunk,
  getDatagridStatesThunk,
} from "store/datagrid-state/dataGridState.thunk";
import { getNotificationsThunk } from "store/notifications/notifications.thunk";

export default function DashboardNavigation() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const accountType = useAppSelector((state) => state.auth.accountType)!;
  const user = useAppSelector((state) => state.auth.user)!;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    dispatch(getDatagridStateByUserIdThunk(user.id));
    dispatch(getCiviliteThunk());
    dispatch(getPoleThunk());
    dispatch(getPostesThunk());
    dispatch(getSuppliersThunk());
    dispatch(getUsersThunk());
    dispatch(getRolesThunk());
    dispatch(getArticlesThunk());
    dispatch(getNotificationsThunk());
    dispatch(getDepenseThunk());
    dispatch(getCostsThunk());
    dispatch(getSessionThunk());
    dispatch(getProjectsThunk());
    dispatch(getPerimetersThunk());
    dispatch(getPaymentTermssThunk());
    if (accountType === "vendor") {
      dispatch(getOrdersByVendorIdThunk(user?.id));
      dispatch(getInvoicesByVendorIdThunk(user?.id));
    } else {
      dispatch(getOrdersThunk());
      dispatch(getInvoicesThunk());
    }
    dispatch(getTvasThunk());
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <TopNavigation open={open} handleDrawerOpen={handleDrawerOpen} />

      <SideNavigation open={open} handleDrawerClose={handleDrawerClose} />

      <MainContentContainer open={open}>
        <Outlet />
        {/* or props.children */}
      </MainContentContainer>
    </Box>
  );
}
