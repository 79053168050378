import {Permissions} from "store/roles/roles.state"

export function makeFirstLetterCapital(s: string) {
  if (s === "") {
    return "";
  } else {
    const firstLetter = s.charAt(0).toUpperCase();
    const rest = s.slice(1)
    return firstLetter + rest
  }
}

export function translatePermissions(t: Permissions) {

  let resultString = ""
  t.forEach(p => {
    const permission = p.toLowerCase()
    if(permission==="") return "";
    else if(permission==="read") resultString += "Lecture, ";
    else if(permission==="update") resultString += "Écriture, ";
    else if(permission==="create") resultString += "Creation, "
    else if(permission==="delete") resultString += "Suppression, "
  })
  return resultString
}
