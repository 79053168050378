import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuIcon from "@mui/icons-material/Menu";
import Divider from "@mui/material/Divider";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import ClearIcon from "@mui/icons-material/Clear";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import {
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  TextField,
} from "@mui/material";
import Layout4Blocks from "./assets/layout-4-blocks.svg";
import Search from "./assets/search.svg";
import Equalizer from "./assets/equalizer.svg";
import Flag from "./assets/flag.svg";

import AppBar from "./AppBar.component";
import Button from "@mui/material/Button";
import { logoutThunk } from "store/auth/auth.thunk";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import { makeFirstLetterCapital } from "utils/stringOperations";
import { User } from "store/users/users.state";
import { Supplier } from "store/suppliers/suppliers.state";
import { Link } from "react-router-dom";
import * as ROUTES from "utils/routes";
import { Role } from "store/roles/roles.state";
import { useEffect, useState } from "react";
import {
  deleteNotificationsThunk,
  getNotificationsSeenThunk,
  getNotificationsThunk,
  getVendorNotificationsThunk
} from "store/notifications/notifications.thunk";
import { INotification } from "store/notifications/notifications.state";

type TopNavigationProps = {
  open: boolean;
  handleDrawerOpen: () => void;
};
// (state) => state.auth.user
function selectUser(state: any) {
  return state.auth.user;
}

export default function TopNavigation(props: TopNavigationProps) {
  const dispatch = useAppDispatch();
  const [notifOpen, setNotifOpen] = useState<boolean>(false);
  const account = useAppSelector(selectUser);
  const accountType = useAppSelector((state) => state.auth.accountType)!;
  const authUser = useAppSelector((state) => state.auth.user)!;
  const notifications = useAppSelector((state) => state.notifications.list)!;
  const [notSeen, setNotSeen] = useState<INotification[]>([]);

  // console.log(notifications);
  // console.log(notSeen);

  const handleLogout = () => {
    dispatch(logoutThunk());
  };
  const handleNotifClick = () => {
    setNotifOpen(!notifOpen);
    if (notifOpen) {
      dispatch(getNotificationsSeenThunk());
    }
  };

  const handleDeleteClick = async (notification: any) => {
    await dispatch(deleteNotificationsThunk(notification._id));
    await dispatch(getNotificationsThunk());
    // await dispatch(getVendorNotificationsThunk(account._id))
  };

  useEffect(() => {
    const notSeenNotifs = notifications.filter(
      (notif: INotification) => notif.seen === false
    );
    setNotSeen([...notSeenNotifs]);
  }, []);

  useEffect(() => {
    if (notifOpen) {
      dispatch(getNotificationsThunk());
      // dispatch(getVendorNotificationsThunk(account._id))
    }
  }, [notifOpen]);

  return (
    <AppBar
      position="fixed"
      open={props.open}
      sx={{ backgroundColor: "white", color: "black", boxShadow: "none" }}
    >
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(props.open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
        </Box>

        {/*  <IconButton>
          <Avatar
            sx={{
              height: "24px",
              width: "24px",
            }}
            src={Search}
          />
        </IconButton>
        <IconButton sx={{ marginRight: "30px" }}>
          <Avatar
            sx={{
              height: "24px",
              width: "24px",
            }}
            src={Equalizer}
          />
        </IconButton>
        <IconButton>
          <Avatar
            sx={{
              height: "24px",
              width: "24px",
            }}
            src={Layout4Blocks}
          />
        </IconButton>*/}
        <IconButton>
          <Avatar
            variant="square"
            sx={{
              height: "16px",
              width: "20px",
            }}
            src={Flag}
          />
        </IconButton>

        <Typography
          sx={{ color: "#6C7293", textDecoration: "none" }}
          fontWeight={500}
          fontSize="11px"
          lineHeight="16px"
          marginRight="10px"
          component={Link}
          to={
            accountType === "user"
              ? ROUTES.USER_PROFILE
              : ROUTES.SUPPLIER_PROFILE
          }
        >
          <span style={{ color: "#959CB6" }}>Hi, </span>
          {accountType === "user"
            ? makeFirstLetterCapital((account as any).firstName)
            : accountType === "vendor"
              ? (account as any).socialReason
              : ""}
        </Typography>

        <Avatar
          variant="square"
          sx={{
            height: "35px",
            width: "36px",
            marginRight: "20px",
            borderRadius: "4px",
          }}
          src={accountType === "user" ? (account as User).avatarUrl : ""}
        >
          {/*
          {accountType === "user"
            ? (account as User).firstName[0]?.toUpperCase()
            : accountType === "vendor"
            ? (account as Supplier).companyName[0]?.toUpperCase()
            : ""}*/}
        </Avatar>
        {/* <Button
          variant="outlined"
          color="error"
          size="small"
          sx={{ padding: 0 }}
          onClick={handleLogout}
        >
          <LogoutIcon/>
        </Button> */}
        {(authUser?.role as Role).name === "Administrateur" ? (
          <div>
            <IconButton onClick={handleNotifClick}>
              {notifications.every(
                (notif: INotification) => {
                    if (notif.seen === true) {
                      return true
                    }else {
                      return false
                    }
                }
              ) ? (
                <NotificationsIcon />
              ) : (
                <NotificationsActiveIcon style={{ color: "red", fontSize: "30px" }} />
              )}
            </IconButton>
            <List
              style={{
                position: "absolute",
                borderTop: "4px solid red",
                display: notifOpen ? "block" : "none",
                marginLeft: "-20%",
                overflow: "auto",
                backgroundColor: "white",
                boxShadow: "0px 1px 29px -5px #000000",
                height: "500px",
              }}
            >
              {notifications.map((notification: any, index: number) => {
                if (notification.content === null) {
                  return (
                    <div
                      key={index}
                    >
                      <ListItem
                        style={{
                          backgroundColor:
                            notification.seen == true ? "white" : "#eee",
                          cursor: "pointer",
                        }}
                      >
                        <ListItemIcon>
                          <CircleNotificationsIcon
                            style={{
                              color: notification.seen == true ? "" : "red",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText>
                          {notification?.vendorId?.nom}{" "}
                          {notification?.vendorId?.prenom} a changé ses
                          informations <br />
                          {notification?.vendorId?.email}
                        </ListItemText>
                        <ListItemIcon
                          onClick={() => handleDeleteClick(notification)}
                        >
                          <ClearIcon
                            style={{
                              color: "red",
                            }}
                          />
                        </ListItemIcon>
                      </ListItem>
                      <Divider />
                    </div>
                  );
                }
                if (notification.content === 'RIB-EDITED') {
                  return (
                    <div
                      key={index}
                    >
                      <ListItem
                        style={{
                          backgroundColor:
                            notification.seen == true ? "white" : "#eee",
                          cursor: "pointer",
                        }}
                      >
                        <ListItemIcon>
                          <CircleNotificationsIcon
                            style={{
                              color: notification.seen == true ? "" : "red",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText>
                          {notification?.vendorId?.nom}{" "}
                          {notification?.vendorId?.prenom} a changé son
                          RIB <br />
                          {notification?.vendorId?.email}
                        </ListItemText>
                        <ListItemIcon
                          onClick={() => handleDeleteClick(notification)}
                        >
                          <ClearIcon
                            style={{
                              color: "red",
                            }}
                          />
                        </ListItemIcon>
                      </ListItem>
                      <Divider />
                    </div>
                  );
                }
              })}
            </List>
          </div>
        ) :
          (
            <div>
              <IconButton onClick={handleNotifClick}>
                {notifications.every(
                  (notif: INotification) => {
                    if (notif.seen === true) {
                      return true
                    }
                  }
                ) ? (
                  <NotificationsIcon />
                ) : (
                  <NotificationsActiveIcon style={{ color: "red", fontSize: "30px" }} />
                )}
              </IconButton>
              <List
                style={{
                  position: "absolute",
                  borderTop: "4px solid red",
                  display: notifOpen ? "block" : "none",
                  marginLeft: "-20%",
                  overflow: "auto",
                  backgroundColor: "white",
                  boxShadow: "0px 1px 29px -5px #000000",
                  height: "500px",
                }}
              >
                {notifications.map((notification: any, index: number) => {
                  if (notification?.vendorId !== null) {
                    if (notification?.vendorId?._id === account?._id && notification?.content === 'FRN-BDC-CANCELED') {
                      return (
                        <div
                          key={index}
                        >
                          <ListItem
                            style={{
                              backgroundColor:
                                notification?.seen == true ? "white" : "#eee",
                              cursor: "pointer",
                            }}
                          >
                            <ListItemIcon>
                              <CircleNotificationsIcon
                                style={{
                                  color: notification?.seen == true ? "" : "red",
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText>
                              Le bon de commande {notification?.bdcNum} est annulé
                            </ListItemText>
                            <ListItemIcon
                              onClick={() => handleDeleteClick(notification)}
                            >
                              <ClearIcon
                                style={{
                                  color: "red",
                                }}
                              />
                            </ListItemIcon>
                          </ListItem>
                          <Divider />
                        </div>
                      );
                    }
                    if (notification?.vendorId?._id === account?._id && notification?.content === 'FRN-BDC-INVOICED') {
                      return (
                        <div
                          key={index}
                        >
                          <ListItem
                            style={{
                              backgroundColor:
                                notification?.seen == true ? "white" : "#eee",
                              cursor: "pointer",
                            }}
                          >
                            <ListItemIcon>
                              <CircleNotificationsIcon
                                style={{
                                  color: notification?.seen == true ? "" : "red",
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText>
                              Le bon de commande {notification?.bdcNum} est Clôturé
                            </ListItemText>
                            <ListItemIcon
                              onClick={() => handleDeleteClick(notification)}
                            >
                              <ClearIcon
                                style={{
                                  color: "red",
                                }}
                              />
                            </ListItemIcon>
                          </ListItem>
                          <Divider />
                        </div>
                      );
                    }
                    if (notification?.vendorId?._id === account?._id && notification?.content === 'FRN-BDC-EDIT') {
                      return (
                        <div
                          key={index}
                        >
                          <ListItem
                            style={{
                              backgroundColor:
                                notification?.seen == true ? "white" : "#eee",
                              cursor: "pointer",
                            }}
                          >
                            <ListItemIcon>
                              <CircleNotificationsIcon
                                style={{
                                  color: notification?.seen == true ? "" : "red",
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText>
                              Le bon de commande {notification?.bdcNum} est modifié
                            </ListItemText>
                            <ListItemIcon
                              onClick={() => handleDeleteClick(notification)}
                            >
                              <ClearIcon
                                style={{
                                  color: "red",
                                }}
                              />
                            </ListItemIcon>
                          </ListItem>
                          <Divider />
                        </div>
                      );
                    }
                    if (notification?.vendorId?._id === account?._id && notification?.content === 'FRN-BDC') {
                      return (
                        <div
                          key={index}
                        >
                          <ListItem
                            style={{
                              backgroundColor:
                                notification?.seen == true ? "white" : "#eee",
                              cursor: "pointer",
                            }}
                          >
                            <ListItemIcon>
                              <CircleNotificationsIcon
                                style={{
                                  color: notification?.seen == true ? "" : "red",
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText>
                              Vous avez un nouveau bon de commande {notification?.bdcNum}
                            </ListItemText>
                            <ListItemIcon
                              onClick={() => handleDeleteClick(notification)}
                            >
                              <ClearIcon
                                style={{
                                  color: "red",
                                }}
                              />
                            </ListItemIcon>
                          </ListItem>
                          <Divider />
                        </div>
                      );
                    }
                    if (notification?.vendorId?._id === account?._id && notification?.content === 'FRN-FAC-NEW') {
                      return (
                        <div
                          key={index}
                        >
                          <ListItem
                            style={{
                              backgroundColor:
                                notification?.seen == true ? "white" : "#eee",
                              cursor: "pointer",
                            }}
                          >
                            <ListItemIcon>
                              <CircleNotificationsIcon
                                style={{
                                  color: notification?.seen == true ? "" : "red",
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText>
                              Vous avez une nouvelle facture {notification?.facNum}
                            </ListItemText>
                            <ListItemIcon
                              onClick={() => handleDeleteClick(notification)}
                            >
                              <ClearIcon
                                style={{
                                  color: "red",
                                }}
                              />
                            </ListItemIcon>
                          </ListItem>
                          <Divider />
                        </div>
                      );
                    }
                    if (notification?.vendorId?._id === account?._id && notification?.content === 'FRN-FAC-PAYED') {
                      return (
                        <div
                          key={index}
                        >
                          <ListItem
                            style={{
                              backgroundColor:
                                notification?.seen == true ? "white" : "#eee",
                              cursor: "pointer",
                            }}
                          >
                            <ListItemIcon>
                              <CircleNotificationsIcon
                                style={{
                                  color: notification?.seen == true ? "" : "red",
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText>
                              La facture {notification?.facNum} est payé
                            </ListItemText>
                            <ListItemIcon
                              onClick={() => handleDeleteClick(notification)}
                            >
                              <ClearIcon
                                style={{
                                  color: "red",
                                }}
                              />
                            </ListItemIcon>
                          </ListItem>
                          <Divider />
                        </div>
                      );
                    }
                  }
                })}
              </List>
            </div>
          )
        }

        <IconButton
          // variant="outlined"
          sx={{ color: "#FE7373" }}
          size="small"
          // sx={{ padding: 0 }}
          onClick={handleLogout}
          title="Se déconnecter"
        >
          <LogoutIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
