import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import CustomDialogTitle from "components/custom-dialog-title/CustomDialogTitle.component";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import { uiActions } from "store/ui/ui.state";
import UploadAvatarForm from "./UploadAvatarForm.component";

export default function UploadAvatarDialog() {
  const dispatch = useAppDispatch();
  const uploadAvatarFormOpen = useAppSelector(
    (state) => state.ui.uploadAvatarFormOpen
  );

  const closeUploadAvatarForm = () => {
    dispatch(uiActions.setUploadAvatarFormOpen(false));
  };

  return (
    <Dialog
      open={uploadAvatarFormOpen}
      onClose={closeUploadAvatarForm}
      sx={{ "& .MuiPaper-root": { width: "500px" } }}
    >
      <CustomDialogTitle onClose={closeUploadAvatarForm} id="">
        Changer l'image du profil
      </CustomDialogTitle>
      <Divider />
      <DialogContent>
        <UploadAvatarForm closeUploadAvatarForm={closeUploadAvatarForm} />
      </DialogContent>
    </Dialog>
  );
}
