import { useState, useRef, useEffect } from "react";
import { Field, FieldArray, Formik, FormikProvider, useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { CustomTextField } from "components/custom-textfield/CustomTextField.component";

import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ClearIcon from "@mui/icons-material/Clear";
import FormHelperText from "@mui/material/FormHelperText";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { margin } from "../variables";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import BlueButton from "components/blue-button/BlueButton.component";
import GrayButton from "components/gray-button/GrayButton.component";
import React from "react";
import {
  addOrderThunk,
  getOrdersByVendorIdThunk,
  getOrdersThunk,
} from "store/orders/orders.thunk";
import Add from "@mui/icons-material/Add";
import { Supplier } from "store/suppliers/suppliers.state";
import Delete from "@mui/icons-material/Delete";
import { Autocomplete, Divider, FormControlLabel, InputLabel, Radio, RadioGroup } from "@mui/material";
import { IArticle } from "store/article/article.state";
import { defaultTodayDate } from "utils/globalVariables";

type ErrorProp = boolean | undefined;

type AddOrderFormProps = {
  handleCloseAddDialog: () => void;
};

export default function AddOrderForm(props: AddOrderFormProps) {
  const dispatch = useAppDispatch();
  const articlesList = useAppSelector((state) => state.articles.list);
  const tvaList = useAppSelector((state) => state.tvas.list);
  const suppList = useAppSelector((state) => state.suppliers.list);
  const optionsNames = suppList?.map((e: any) => ({ id: e.id, name: `${e.nom} ${e.prenom}` }))
  const ptList = useAppSelector((state) => state.paymentTerms.list);
  const perimetertList = useAppSelector((state) => state.perimeters.list);
  const sessiontList = useAppSelector((state) => state.session.list);
  const typedepensestList = useAppSelector((state) => state.typedepenses.list);
  const costsCentertList = useAppSelector((state) => state.pole.list);
  const projetList = useAppSelector((state) => state.project.projectList);
  const [FRNvALUE, setFRNvALUE] = React.useState<any>('');
  const suppListOptions: any = suppList?.map((e: any) => ({ name: `${e.nom} ${e.prenom}`, id: e.id }))
  suppListOptions.push({
    id: '', name: ''
  })
  const authenticatedUser = useAppSelector((state) => state.auth.user?.id);
  const authUser = useAppSelector((state) => state.auth.user)!;

  const accountType = useAppSelector((state) => state.auth.accountType)!;

  var sr = " ";
  const formik = useFormik({
    initialValues: {
      userRef: authenticatedUser,
      session: "",
      pole: "",
      project: "",
      typeDepense: "",
      paymentTerm: "",
      perimeter: "",
      BDC_NUM: "",
      reste: 0.0,
      GHT: 0.0,
      MTVA: 0.0,
      GTTC: 0.0,
      documentDate: `${defaultTodayDate}`,
      missionInformation: "",
      FRN: FRNvALUE as any,
      raisonSocial: sr,
      articles: [] as any[],
      prestationOuAchat: 'PRESTATION_SERVICE',
      THT: {} as any,
      TTC: {} as any,
      startDate: "",
      endDate: "",
    },
    validationSchema: Yup.object({
      pole: Yup.string().required("centre de coûts obligatoire"),
      project: Yup.string().required("Projet obligatoire"),
      typeDepense: Yup.string().required("Type de dépense obligatoire"),
      session: Yup.string().required("Périmètre du BDC obligatoire"),
      documentDate: Yup.string().required("Date obligatoire"),
      GHT: Yup.number(),
      MTVA: Yup.number(),
      GTTC: Yup.number(),
      missionInformation: Yup.string().required(
        "Information de la mission obligatoire"
      ),
      paymentTerm: Yup.string().required("Condition de paiement obligatoire"),
      perimeter: Yup.string().required("Produit obligatoire"),
      FRN: Yup.string().required("Fournisseur obligatoire"),
      raisonSocial: Yup.string(),
      startDate: Yup.string().required("Date de début bdc obligatoire"),
      endDate: Yup.string().required("Date fin bdc obligatoire"),
    }),
    validateOnChange: false,
    enableReinitialize: false,
    onSubmit: async (values) => {
      try {
        await dispatch(addOrderThunk(values as any));
        if (accountType === "vendor") {
          await dispatch(getOrdersByVendorIdThunk(authUser.id));
        } else {
          await dispatch(getOrdersThunk());
        }
      } catch (error) {
        console.log(error);
      }
      props.handleCloseAddDialog();
    },
  });

  const initQty = {} as any;
  const [quantities, setQuantities] = useState<any>(initQty);

  if (formik.getFieldProps("FRN").value) {
    sr = suppList.filter(
      (supp: Supplier) => supp.id === formik.getFieldProps("FRN").value
    )[0].socialReason;
    formik.values.raisonSocial = sr;
  }

  const calculateTHT = (index: number) => {
    const article: any = articlesList.filter(
      (art: IArticle) => art._id === formik.values.articles[index].idArticle
    )[0];

    let THT: number = 0;
    if (article) {
      THT =
        (formik.values.articles[index].qty as number) * (article.HT as number);
    }
    formik.values.articles[index].THT = Math.round((THT + Number.EPSILON) * 100) / 100;
    formik.setFieldValue(`articles.${index}.THT`, (Math.round((THT + Number.EPSILON) * 100) / 100));
    return Math.round((THT + Number.EPSILON) * 100) / 100;
  };
  // article.TTC = article.nbHeureArticle * article.HT * (article.TVA / 100);
  const calculateTCC = (index: number) => {
    let TTC: number = 0;
    const article: any = articlesList.filter(
      (art: IArticle) => art._id === formik.values.articles[index].idArticle
    )[0];
    if (article) {
      TTC =
        (formik.values.articles[index].qty as number) *
        (article.HT + (article.TVA * article.HT) / 100);
    }
    formik.values.articles[index].TTC = Math.round((TTC + Number.EPSILON) * 100) / 100;
    formik.setFieldValue(`articles.${index}.TTC`, (Math.round((TTC + Number.EPSILON) * 100) / 100));
    return Math.round((TTC + Number.EPSILON) * 100) / 100;
  };

  const calculateGHT = () => {
    const GHT = formik.values.articles.reduce((total: number, article: any) => {
      return total + (article.THT as number);
    }, 0);
    formik.values.GHT = Math.round((GHT + Number.EPSILON) * 100) / 100;
    return Math.round((GHT + Number.EPSILON) * 100) / 100;
  };

  const calculateMTVA = () => {
    const total: any[] = [];

    formik.values.articles.map((art: any, index: number) => {
      total.push(
        (((art?.HT as number) *
          articlesList.filter(
            (art: IArticle) =>
              art._id === formik.values.articles[index].idArticle
          )[0]?.TVA) as number) / 100
      );
    });

    const MTVA: any = total.reduce((total: number, T: number) => {
      return total + T;
    }, 0);
    // formik.values.MTVA = MTVA;
    formik.values.MTVA = Math.round(((formik.values.GTTC - formik.values.GHT) + Number.EPSILON) * 100) / 100;

    return Math.round(((formik.values.GTTC - formik.values.GHT) + Number.EPSILON) * 100) / 100;
  };
  const calculateGTTC = () => {
    const GTTC = formik.values.articles.reduce(
      (total: number, article: any) => {
        return total + (article.TTC as number);
      },
      0
    );
    formik.values.GTTC = Math.round((GTTC + Number.EPSILON) * 100) / 100;

    return Math.round((GTTC + Number.EPSILON) * 100) / 100;
  };
  const showFRNError = (formik.errors.FRN && formik.touched.FRN) as ErrorProp;
  const showSessionError = (formik.errors.session &&
    formik.touched.session) as ErrorProp;
  const showPoleError = (formik.errors.pole &&
    formik.touched.pole) as ErrorProp;
  const showProjetError = (formik.errors.project &&
    formik.touched.project) as ErrorProp;
  const showDependessesTypeError = (formik.errors.typeDepense &&
    formik.touched.typeDepense) as ErrorProp;
  const showPaymentTermError = (formik.errors.paymentTerm &&
    formik.touched.paymentTerm) as ErrorProp;
  const showPerimeterError = (formik.errors.perimeter &&
    formik.touched.perimeter) as ErrorProp;
  const showMissionInformationError = (formik.errors.missionInformation &&
    formik.touched.missionInformation) as ErrorProp;

  const initTHT: any = {};
  const [THTValues, setTHTValues] = useState<any>(initTHT);

  const initTTC: any = {};

  const [TTCValues, setTTCValues] = useState<any>(initTTC);

  formik.values.GHT = calculateGHT();
  formik.values.MTVA = calculateMTVA();
  formik.values.GTTC = calculateGTTC();

  useEffect(() => {

    formik.values.articles.map(
      (item: any, index: number) => (initTTC[index] = calculateTCC(index))
    );

    formik.values.articles.map(
      (item: any, index: number) => (initTHT[index] = calculateTHT(index))
    );

    // formik.values.articles.map(
    //   (item: any, index: number) => (initQty[index] = 1)
    // );
  }, [])

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <FormikProvider value={formik}>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="flex-start"
            mb={4}
          >
            <Grid item xs={6}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography component="span" fontWeight="bold">
                    Date{" "}
                    <Typography component="span" color="error">
                      *
                    </Typography>:
                  </Typography>
                </Grid>
                <Grid item>
                  {/* <input
                    {...formik.getFieldProps("documentDate")}
                    type="text"
                    value={formik.values.documentDate}
                    style={{
                      width: "8vw",
                      height: "42px",
                      border: "none",
                      paddingTop: "1%",
                      paddingLeft: "5%",
                      color: "#5E6278",
                      backgroundColor: "white",
                    }}
                  /> */}
                  <TextField
                    {...formik.getFieldProps("documentDate")}
                    type="date"
                    size="small"
                    id="start"
                    style={{
                      height: "42px",
                      marginLeft: "2%",
                      paddingTop: "7%",
                      color: "#5E6278",
                      backgroundColor: "white",
                    }}
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  {/* <Typography style={{ fontSize: "18px", fontWeight: "500" }} component="span" fontWeight="bold">
                    № BDC{" "}
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </Typography> */}
                </Grid>
                <Grid item>
                  {/* <TextField
                    {...formik.getFieldProps("BDC_NUM")}
                    type="text"
                    disabled
                    placeholder="03048"
                    fullWidth
                    style={{
                      // width: "18vw",
                      height: "42px",
                      color: "#5E6278",
                      paddingLeft: "3%",
                      paddingTop: "5%",
                      backgroundColor: "white",
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    size="small"
                    variant="standard"
                  /> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item xs={12}
            container
            direction='row'
          >
            <Grid item xs={6}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography component="span" fontWeight="bold">
                    Date début BDC{" "}
                    <Typography component="span" color="error">
                      *
                    </Typography>:
                  </Typography>
                </Grid>
                <Grid item>
                  {/* <input
                  {...formik.getFieldProps("documentDate")}
                  type="text"
                  value={formik.values.documentDate}
                  style={{
                    width: "8vw",
                    height: "42px",
                    border: "none",
                    paddingTop: "1%",
                    paddingLeft: "5%",
                    color: "#5E6278",
                    backgroundColor: "white",
                  }}
                /> */}
                  {/* <input
                    // {...formik.getFieldProps("documentDate")}
                    type="date"
                    id="start"
                    style={{
                      border: "none",
                      paddingLeft: "5%",
                    }}
                    // name="documentDate"
                  /> */}
                  <TextField
                    {...formik.getFieldProps('startDate')}
                    type="date"
                    size="small"
                    id="start"
                    fullWidth
                    style={{
                      height: "42px",
                      marginLeft: "2%",
                      paddingTop: "7%",
                      color: "#5E6278",
                      backgroundColor: "white",
                    }}
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography component="span" fontWeight="bold">
                    Date fin BDC{" "}
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item>
                  {/* <TextField
                    {...formik.getFieldProps("BDC_NUM")}
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                    placeholder="03048"
                    style={{
                      width: "18vw",
                      height: "42px",
                      color: "#5E6278",
                      paddingLeft: "3%",
                      paddingTop: "3%",
                      backgroundColor: "white",
                    }}
                    size="small"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  /> */}
                  <TextField
                    {...formik.getFieldProps('endDate')}
                    type="date"
                    placeholder="03048"
                    fullWidth
                    style={{
                      // width: "18vw",
                      height: "42px",
                      color: "#5E6278",
                      paddingLeft: "3%",
                      paddingTop: "5%",
                      backgroundColor: "white",
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    size="small"
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ borderStyle: "dashed", marginBottom: "4%" }} />
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignContent="center"
            // alignItems="center"
            mb={4}
            spacing={3}
          >
            <Grid item xs={6}>
              <Typography component="span" fontWeight="bold">
                Client{" "}
              </Typography>
              <br />
              <TextField
                // {...formik.getFieldProps("invoiceDate")}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                placeholder={`INSTITUT ARTLINE`}
                disabled
                fullWidth
                style={{
                  // width: "18vw",
                  padding: "2% 3%",
                  borderRadius: "6px",
                  marginTop: "3%",
                  height: "42px",
                  color: "black",
                  backgroundColor: "#F5F8FA",
                }}
                size="small"
                type="text"
              />{" "}
              <TextField
                // {...formik.getFieldProps("invoiceDate")}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                placeholder={`siret: 79350175000020`}
                disabled
                fullWidth
                style={{
                  // width: "18vw",
                  padding: "2% 3%",
                  marginTop: "3%",
                  height: "42px",
                  borderRadius: "6px",
                  color: "black",
                  backgroundColor: "#F5F8FA",
                }}
                size="small"
                type="text"
              />{" "}
              <TextField
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                placeholder={`117 Rue de charenton \n 75 012 Paris \n +33 09 72 63 22 86`}
                disabled
                fullWidth
                style={{
                  // width: "18vw",
                  padding: "2% 3%",
                  marginTop: "3%",
                  borderRadius: "6px",
                  color: "black",
                  backgroundColor: "#F5F8FA",
                }}
                multiline
                rows={3}
                type="text"
              />{" "}
            </Grid>
            <Grid item xs={6}>
              <Typography component="span" fontWeight="bold">
                Préstataire{" "}
              </Typography>
              <br />
              {suppList && suppListOptions && (
                <Autocomplete
                  sx={{
                    // border: "1px solid blue",
                    "& .MuiOutlinedInput-root": {
                      // border: "1px solid yellow",
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                      border: "none"
                    }
                  }}
                  {...formik.getFieldProps("FRN")}
                  options={suppListOptions}
                  value={FRNvALUE}
                  onChange={(e, value) => {
                    formik.values.FRN = value?.id || ''
                    setFRNvALUE(value?.id)
                  }}
                  getOptionLabel={(supp) => {
                    return supp?.name ? supp?.name : suppListOptions?.find((el: any) => el.id === supp)?.name || ''
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value
                  }}
                  fullWidth
                  style={{
                    // width: "18vw", 
                    marginTop: "3%",
                    marginBottom: "3%",
                    height: "52px",
                    color: "#5E6278",
                    backgroundColor: "#F5F8FA",
                  }}
                  // sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} name='FRN' label="prestataire" />}
                />
              )}
              {/* <FormControl fullWidth size="small">
                <InputLabel style={{ marginTop: "3%" }}>Nom</InputLabel>
                <Select
                  {...formik.getFieldProps("FRN")}
                  onChange={formik.handleChange}
                  error={showFRNError}
                  variant="filled"
                  disableUnderline={true}
                  fullWidth
                  style={{
                    // width: "18vw",
                    padding: "0",
                    borderRadius: "6px",
                    marginTop: "3%",
                    height: "42px",
                    color: "#5E6278",
                    backgroundColor: "#F5F8FA",
                  }}
                >
                  {suppList.map((supp) => (
                    <MenuItem key={supp.id} value={supp.id}>
                      {supp.nom} {supp.prenom}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              <FormHelperText error>
                {showFRNError && formik.errors.FRN?.toString()}
              </FormHelperText>
              <RadioGroup
                {...formik.getFieldProps('prestationOuAchat')}
                aria-labelledby="prestation-ou-achat"
                row
              >
                <FormControlLabel value="PRESTATION_SERVICE" control={<Radio />} label="Prestation de service" />
                <FormControlLabel value="ACHAT_MARCHANDISE" control={<Radio />} label="Achat de marchandise" />
              </RadioGroup>
              <TextField
                // id="outlined-basic"
                // label=" Rprendre Détails/Raison social"
                placeholder="Rprendre Détails/Raison social"
                variant="standard"
                type="text"
                disabled
                fullWidth
                InputProps={{
                  disableUnderline: true,
                }}
                value={
                  formik.values.FRN
                    ? suppList.filter(
                      (supp: Supplier) => supp.id === formik.values.FRN
                    )[0].socialReason
                    : ""
                }
                style={{
                  // width: "18vw",
                  // paddingLeft: "3%",
                  padding: "2% 3%",
                  marginTop: "3%",
                  height: "42px",
                  borderRadius: "6px",
                  color: "#5E6278",
                  backgroundColor: "#F5F8FA",
                }}
                size="small"
              />
              <TextField
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                disabled
                placeholder={`Reprendre Adresse \n Pays`}
                value={
                  formik.values.FRN
                    ? suppList.filter(
                      (supp: Supplier) => supp.id === formik.values.FRN
                    )[0].address +
                    `\n` +
                    suppList.filter(
                      (supp: Supplier) => supp.id === formik.values.FRN
                    )[0].pays
                    : ""
                }
                multiline
                fullWidth
                rows={3}
                style={{
                  // width: "18vw",
                  padding: "2% 3%",
                  marginTop: "3%",
                  marginBottom: "1%",
                  borderRadius: "6px",
                  color: "#5E6278",
                  backgroundColor: "#F5F8FA",
                }}
                type="text"
              />{" "}
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignContent="center"
              spacing={4}
              mt={1}
            >
              <Grid item xs={6}>
                <Typography component="span" fontWeight="bold" sx={{ padding: "0px 20px", }}>
                  Condition de paiement{" "}
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    // marginBottom: margin,
                    "& .MuiSelect-select": { padding: "2px 20px", },
                    padding: "2px 20px",
                  }}
                >
                  <Select
                    {...formik.getFieldProps("paymentTerm")}
                    variant="filled"
                    error={showPaymentTermError}
                    sx={{
                      color: "#5E6278",
                      fontWeight: "500",
                      fontSize: "14px",
                      background: "#F5F8FA",
                      "&:before": {
                        border: "none",
                      },
                      "&:after": {
                        border: "none",
                      },
                      height: "48px",
                    }}
                  >
                    {ptList.map((pt) => (
                      <MenuItem key={pt._id} value={pt._id}>
                        {pt.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText error sx={{ padding: "0px 20px", }}>
                  {showPaymentTermError && formik.errors.paymentTerm}
                </FormHelperText>
              </Grid>
              <Grid
                item
                xs={6}
              >
                <Typography component="span" fontWeight="bold" sx={{ padding: "0px 20px", }}>
                  Produit{" "}
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    // marginBottom: margin,
                    "& .MuiSelect-select": { padding: "2px 20px", },
                    padding: "2px 20px",
                  }}
                >
                  <Select
                    {...formik.getFieldProps("perimeter")}
                    variant="filled"
                    error={showPerimeterError}
                    sx={{
                      color: "#5E6278",
                      fontWeight: "500",
                      fontSize: "14px",
                      background: "#F5F8FA",
                      "&:before": {
                        border: "none",
                      },
                      "&:after": {
                        border: "none",
                      },
                      height: "48px",
                    }}
                  >
                    {perimetertList.map((pt) => (
                      <MenuItem key={pt._id} value={pt._id}>
                        {pt.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText error sx={{ padding: "0px 20px", }}>
                  {showPerimeterError && formik.errors.perimeter}
                </FormHelperText>
              </Grid>
              <Grid item xs={6}>
                <Typography component="span" fontWeight="bold" sx={{ padding: "0px 20px", }}>
                  Périmètre du BDC{" "}
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    // marginBottom: margin,
                    "& .MuiSelect-select": { padding: "2px 20px", },
                    padding: "2px 20px",
                  }}
                >
                  <Select
                    {...formik.getFieldProps("session")}
                    variant="filled"
                    error={showSessionError}
                    sx={{
                      color: "#5E6278",
                      fontWeight: "500",
                      fontSize: "14px",
                      background: "#F5F8FA",
                      "&:before": {
                        border: "none",
                      },
                      "&:after": {
                        border: "none",
                      },
                      height: "48px",
                    }}
                  >
                    {sessiontList.map((pt) => {
                      return (
                        <MenuItem key={pt._id} value={pt._id}>
                          {pt.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
                <FormHelperText error sx={{ padding: "0px 20px", }}>
                  {showSessionError && formik.errors.session}
                </FormHelperText>
              </Grid>
              <Grid item xs={6}>
                <Typography component="span" fontWeight="bold" sx={{ padding: "0px 20px", }}>
                  Type de dépense{" "}
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    // marginBottom: margin,
                    "& .MuiSelect-select": { padding: "10px" },
                    padding: "0px 20px",
                  }}
                >
                  <Select
                    {...formik.getFieldProps("typeDepense")}
                    variant="filled"
                    error={showDependessesTypeError}
                    sx={{
                      color: "#5E6278",
                      fontWeight: "500",
                      fontSize: "14px",
                      background: "#F5F8FA",
                      "&:before": {
                        border: "none",
                      },
                      "&:after": {
                        border: "none",
                      },
                      height: "48px",
                    }}
                  >
                    {typedepensestList.map((pt) => {
                      return (
                        <MenuItem key={pt._id} value={pt._id}>
                          {pt.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
                <FormHelperText error sx={{ padding: "0px 20px", }}>
                  {showDependessesTypeError && formik.errors.typeDepense}
                </FormHelperText>
              </Grid>
              <Grid item xs={6}>
                <Typography component="span" fontWeight="bold" sx={{ padding: "0px 20px", }}>
                  Pôle / Centre de coûts{" "}
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    // marginBottom: margin,
                    "& .MuiSelect-select": { padding: "10px" },
                    padding: "0px 20px",
                  }}
                >
                  <Select
                    {...formik.getFieldProps("pole")}
                    variant="filled"
                    error={showPoleError}
                    sx={{
                      color: "#5E6278",
                      fontWeight: "500",
                      fontSize: "14px",
                      background: "#F5F8FA",
                      "&:before": {
                        border: "none",
                      },
                      "&:after": {
                        border: "none",
                      },
                      height: "48px",
                    }}
                  >
                    {costsCentertList.map((pt) => (
                      <MenuItem key={pt._id} value={pt._id}>
                        {pt.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText error sx={{ padding: "0px 20px", }}>
                  {showPoleError && formik.errors.pole}
                </FormHelperText>
              </Grid>
              <Grid item xs={6}>
                <Typography component="span" fontWeight="bold" sx={{ padding: "0px 20px", }}>
                  Projet{" "}
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    // marginBottom: margin,
                    "& .MuiSelect-select": { padding: "10px" },
                    padding: "0px 20px",
                  }}
                >
                  <Select
                    {...formik.getFieldProps("project")}
                    variant="filled"
                    error={showPoleError}
                    sx={{
                      color: "#5E6278",
                      fontWeight: "500",
                      fontSize: "14px",
                      background: "#F5F8FA",
                      "&:before": {
                        border: "none",
                      },
                      "&:after": {
                        border: "none",
                      },
                      height: "48px",
                    }}
                  >
                    {projetList.map((pt) => (
                      <MenuItem key={pt._id} value={pt._id}>
                        {pt.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText error sx={{ padding: "0px 20px", }}>
                  {showProjetError && formik.errors.project}
                </FormHelperText>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mb={2}>
            <table style={{ width: "100%" }}>
              <thead>
                <th>
                  <td>ARTICLE</td>
                </th>
                <th>
                  <td>UO</td>
                </th>
                <th>
                  <td>coût unitaire ht</td>
                </th>
                <th>
                  <td>tva</td>
                </th>
                <th>
                  <td>total ht</td>
                </th>
                <th>
                  <td>ttc</td>
                </th>
                <th>
                  <td>action</td>
                </th>
              </thead>
              <tbody>
                <FieldArray
                  name="articles"
                  render={(arrayHelpers) => (
                    <>
                      <Button
                        startIcon={<Add />}
                        style={{ margin: "10px 3%" }}
                        variant="outlined"
                        onClick={() => {
                          arrayHelpers.push({
                            idArticle: "",
                            nomArticle: "",
                            qty: 0,
                            THT: 0,
                            TVA: 0,
                            TTC: 0,
                          });
                        }}
                      >
                        Ajouter un article
                      </Button>
                      {formik.values.articles.map((article, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <FormControl fullWidth size="small">
                                <Select
                                  {...formik.getFieldProps(
                                    `articles[${index}].idArticle`
                                  )}
                                  variant="filled"
                                  disableUnderline={true}
                                  fullWidth
                                  onBlur={(e: any) => {
                                    formik.values.articles[index].HT =
                                      articlesList.filter(
                                        (art: IArticle) =>
                                          art._id ===
                                          formik.values.articles[index].idArticle
                                      )[0]?.HT;
                                    formik.values.articles[index].TVA =
                                      articlesList.filter(
                                        (art: IArticle) =>
                                          art._id ===
                                          formik.values.articles[index].idArticle
                                      )[0]?.TVA;
                                    formik.values.articles[index].nomArticle =
                                      articlesList.filter(
                                        (art: IArticle) =>
                                          art._id ===
                                          formik.values.articles[index].idArticle
                                      )[0]?.nomArticle;
                                  }}
                                  style={{
                                    // width: "18vw",
                                    borderRadius: "6px",
                                    height: "42px",
                                    color: "#5E6278",
                                    paddingBottom: "15px",
                                    backgroundColor: "#F5F8FA",
                                  }}
                                >
                                  {articlesList.map((art) => {
                                    if (formik.values.FRN === art.fournisseur || !art.fournisseur) {
                                      return (
                                        <MenuItem key={art._id} value={art._id}>
                                          {art.nomArticle}
                                        </MenuItem>
                                      )
                                    }
                                  })}
                                </Select>
                              </FormControl>
                            </td>
                            <td>
                              <TextField
                                variant="standard"
                                {...formik.getFieldProps(
                                  `articles[${index}].qty`
                                )}
                                InputProps={{
                                  disableUnderline: true,
                                }}
                                inputProps={{ inputMode: 'decimal' }}
                                fullWidth
                                type="text"
                                style={{
                                  color: "black",
                                  borderRadius: "6px",
                                  backgroundColor: "#F5F8FA",
                                  padding: "3% 3%",
                                  height: "42px",
                                }}
                                value={quantities[index]}
                                onChange={(e) => {
                                  const value: any = e.target.value
                                  formik.values.articles[index].qty = value;
                                  formik.values.articles[index].THT =
                                    calculateTHT(index);
                                  formik.values.articles[index].TTC =
                                    calculateTCC(index);
                                  const GHT = calculateGHT();
                                  formik.values.GHT = GHT;
                                  const GTTC = calculateGTTC();
                                  formik.values.GTTC = GTTC;
                                  const MTVA = calculateMTVA();
                                  formik.values.MTVA = MTVA;
                                }}
                              />
                            </td>
                            <td>
                              <TextField
                                type="number"
                                inputProps={{ min: 0 }}
                                fullWidth
                                {...formik.getFieldProps(`articles[${index}].HT`)}
                                placeholder="0.00"
                                disabled
                                style={{
                                  color: "black",
                                  borderRadius: "6px",
                                  backgroundColor: "#F5F8FA",
                                  padding: "5% 3%",
                                  height: "42px",
                                }}
                                value={
                                  formik.values.articles[index]
                                    ? articlesList.filter(
                                      (art: IArticle) =>
                                        art._id ===
                                        formik.values.articles[index].idArticle
                                    )[0]?.HT
                                    : formik.values.articles[index].HT
                                }
                                variant="standard"
                                InputProps={{
                                  disableUnderline: true,
                                }}
                              />
                            </td>
                            <td>
                              <TextField
                                type="number"
                                disabled
                                inputProps={{ min: 0 }}
                                {...formik.getFieldProps(
                                  `articles[${index}].TVA`
                                )}
                                fullWidth
                                style={{
                                  borderRadius: "6px",
                                  color: "black",
                                  // width: "8vw",
                                  backgroundColor: "#F5F8FA",
                                  padding: "6% 3%",
                                  height: "42px",
                                }}
                                value={
                                  formik.values.articles[index]
                                    ? articlesList.filter(
                                      (art: IArticle) =>
                                        art._id ===
                                        formik.values.articles[index].idArticle
                                    )[0]?.TVA
                                    : ""
                                }
                                variant="standard"
                                InputProps={{
                                  disableUnderline: true,
                                }}
                              />
                            </td>
                            <td>
                              <TextField
                                variant="standard"
                                {...formik.getFieldProps(
                                  `articles[${index}].THT`
                                )}
                                InputProps={{
                                  disableUnderline: true,
                                }}
                                disabled
                                placeholder="0.00"
                                fullWidth
                                style={{
                                  color: "black",
                                  padding: "6% 3%",
                                  backgroundColor: "white",
                                  height: "42px",
                                }}
                                // value={THTValues[index]}
                                // value={calculateTHT(index) ?? 0}
                                type="number"
                              />
                            </td>
                            <td>
                              <TextField
                                variant="standard"
                                {...formik.getFieldProps(
                                  `articles[${index}].TTC`
                                )}
                                InputProps={{
                                  disableUnderline: true,
                                }}
                                disabled
                                placeholder="0.00"
                                // value={TTCValues[index]}
                                // value={calculateTCC(index) ?? 0}
                                fullWidth
                                style={{
                                  borderRadius: "6px",
                                  padding: "6% 3%",
                                  backgroundColor: "white",
                                  height: "42px",
                                }}
                                type="number"
                              />
                            </td>
                            <td>
                              <Button
                                size="large"
                                startIcon={<Delete color="error" />}
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                  formik.values.articles = formik.values.articles.filter((el: any) => el.idArticle !== article.idArticle)
                                  formik.values.GTTC = calculateGTTC();
                                  formik.values.GHT = calculateGHT();
                                  formik.values.MTVA = calculateMTVA();
                                }}
                              />
                            </td>
                          </tr>
                        )
                      })}
                    </>
                  )}
                />
              </tbody>
            </table>
            {/* <Divider sx={{ borderStyle: "dashed", marginBottom: "4%" }} /> */}
          </Grid>
          <Grid container direction="column" justifyContent="end">
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <Typography>Grand Total Hors taxe </Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    disabled
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    {...formik.getFieldProps(`GHT`)}
                    placeholder="0.00"
                    style={{
                      color: "black",
                      padding: "2% 3%",
                      backgroundColor: "white",
                      height: "42px",
                    }}
                    value={calculateGHT() ?? 0}
                    type="number"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <Typography>Montant TVA </Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    disabled
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    {...formik.getFieldProps(`MTVA`)}
                    placeholder="0.00"
                    style={{
                      color: "black",
                      padding: "2% 3%",
                      backgroundColor: "white",
                      height: "42px",
                    }}
                    value={calculateMTVA() ?? 0}
                    type="number"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <Typography>Grand total TTC </Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    {...formik.getFieldProps(`GTTC`)}
                    disabled
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    placeholder="0.00"
                    style={{
                      color: "black",
                      padding: "2% 3%",
                      backgroundColor: "white",
                      height: "42px",
                    }}
                    value={calculateGTTC() ?? 0}
                    type="number"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: "90px" }}>
            <Typography component="span" fontWeight="bold">
              Informations de la mission{" "}
              <Typography component="span" color="error">
                *
              </Typography>
            </Typography>
            <TextField
              {...formik.getFieldProps("missionInformation")}
              multiline
              fullWidth
              error={showMissionInformationError}
              style={{
                marginTop: "1%",
                marginBottom: margin,
                backgroundColor: "#F5F8FA",
                padding: "1%",
                borderRadius: "6px",
              }}
              rows={3}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
            <FormHelperText error>
              {showMissionInformationError && formik.errors.missionInformation}
            </FormHelperText>
          </Grid>

          {/* ==================================================================== */}

          <div style={{ position: "absolute", bottom: "0", left: "0", background: "white", right: "0", display: "flex", padding: "10px", justifyContent: "center", alignItems: "center" }} >
            <GrayButton style={{ marginRight: "10px" }} onClick={props.handleCloseAddDialog}>
              Annuler
            </GrayButton>
            <BlueButton disabled={formik.values.startDate?.trim().length === 0 || formik.values.endDate?.trim().length === 0} style={{ marginLeft: "10px" }} type="submit">Soumettre</BlueButton>
          </div>
        </FormikProvider>
      </form>
      {/* {JSON.stringify(
        suppList.filter(
          (supp: any) => supp.id === formik.getFieldProps("FRN").value
        )[0].socialReason
      )} */}
    </div>
  );
}
