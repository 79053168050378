import { RefObject, useState } from "react";

export default function useImageBase64Encoder(
  imageInputRef: RefObject<HTMLInputElement>
) {
  const [base64Image, setBase64Image] = useState("");

  const handleUpload = () => {
    const file = imageInputRef.current!.files![0];
    const reader = new FileReader();
    reader.onloadend = () => {
      // Use a regex to remove data url part
      const base64String = (reader.result! as string)
        .replace("data:", "")
        .replace(/^.+,/, "");

      setBase64Image(base64String);
    //   console.log(base64String);
      // Logs wL2dvYWwgbW9yZ...
    };
    reader.readAsDataURL(file);
  };

  const handleRemoveImage = () => {
    setBase64Image("")
  }

  return {
    base64Image,
    handleUpload,
    handleRemoveImage
  };
}
