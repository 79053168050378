import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import { useFormik } from "formik";
import * as Yup from "yup";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import BlueButton from "components/blue-button/BlueButton.component";

// import { uiActions } from "store/ui/ui.state";
import { editMySupplierProfileThunk } from "store/suppliers/suppliers.thunk";
import { CustomTextField as ITextField } from "components/custom-textfield/CustomTextField.component";
import { Supplier } from "store/suppliers/suppliers.state";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import { Link, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import AttachmentIcon from "@mui/icons-material/Attachment";
// import {margin} from "../variables"

const margin = "20px";

type ErrorProp = boolean | undefined;

export default function Documents() {
  // const dispatch = useAppDispatch();
  // const [hideTva, setHideTva] = useState<boolean>(false);
  // const civiliteList = useAppSelector((state) => state.civilite.list);

  // const selectedSupplierToEdit = useAppSelector(state => state.suppliers.selectedSupplierToEdit)!
  const supplier = useAppSelector((state) => state.auth.user) as Supplier;

  return (
    <Paper elevation={0} sx={{ marginBottom: "30px", borderRadius: "12px" }}>
      <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', padding: '30px', }}>
        <div style={{ padding: ' 0 30px 15px 0', fontWeight: 500, fontSize: '19px' }}>
          Justificatif d’activité:
        </div>
        {supplier?.ProofActivity &&
          supplier?.ProofActivity?.length > 0 ?
          supplier?.ProofActivity?.map((elm: any, index: any) => (
            <Link
              href={`${elm}`}
              target="_blank"
              key={index}
              style={{ margin: '4px 0 4px 20px', fontSize: '17px' }}
              underline="hover"
            >
              {elm.split('/uploads/')[1]}
            </Link>
          )) : (
            <span> Auccun fichier </span>
          )}
      </div>
      <Divider />
      <div style={{ display: "flex", justifyContent: 'flex-start', alignItems: 'center', width: '100%', padding: '30px', }}>
        <div style={{ padding: ' 0 30px 0 0', fontWeight: 500, fontSize: '19px' }}>
          RIB:
        </div>
        {supplier?.RIB ? (
          <Link
            href={`${supplier?.RIB}`}
            target="_blank"
            style={{ margin: '0', fontSize: '17px' }}
            underline="hover"
          >
            {supplier?.RIB.split('/uploads/')[1]}
          </Link>
        ) : (
          <span> Auccun fichier (RIB) </span>
        )}
      </div>

    </Paper >
  );
}
