import { useAppSelector } from "hooks/redux.hook";
import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function CustomToolbar() {
  const params = useParams();
  const assignedUsersNumber = useAppSelector(
    (state) => state.roles.assignedUsersObjects.length
  );

  return (
    <GridToolbarContainer sx={{paddingBottom: "15px", marginTop: "15px"}}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <Typography
                component="span"
                fontWeight="700"
                fontSize="20px"
                lineHeight="23px"
                sx={{ color: "#3F4254", marginRight: "3px" }}
              >
                Utilisateurs assignés
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                component="span"
                fontWeight="700"
                fontSize="12px"
                lineHeight="23px"
                sx={{ color: "#B5B5C3" }}
              >
                ({assignedUsersNumber})
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <GridToolbarQuickFilter 
            sx={{

              "& .MuiInput-root": {
                padding: "2px 14px",
                // width: "135px",
                borderRadius: "6px",
                background: "#F5F8FA",
              },
              "& input": {
                padding: 0,
                fontWeight: "700",
                fontSize: "12px",
                lineHeight: "14px",
                color: "#5E6278",
                "&::placeholder": {
                  fontWeight: "700",
                  fontSize: "12px",
                  lineHeight: "14px",
                  color: "#5E6278",
                  // opacity: "1",
                }
              },
            }}
          />
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}
