/* eslint-disable @typescript-eslint/no-unused-vars */
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import { useAppSelector, useAppDispatch } from "hooks/redux.hook";
import { Project, projectActions } from "store/project/project.state";
import { deleteProjectThunk } from "store/project/project.thunk";
import { uiActions } from "store/ui/ui.state";
import { Role } from "store/roles/roles.state";
import BlueButton from "components/blue-button/BlueButton.component";
import RedButton from "components/red-button/RedButton";

export default function ListProjet() {
  const dispatch = useAppDispatch();
  const list = useAppSelector((state) => state.project.projectList);

  const authenticatedUser = useAppSelector((state) => state.auth.user);

  const handleDelete = (id: string) => {
    // dispatch(civiliteActions.deleteCivilite(id));
    // dispatch(deleteCiviliteThunk(id));
    //dispatch(uiActions.setDeletePoleWarningOpen({ set: true, id: id }));
    dispatch(uiActions.setDeleteProjectWarningOpen({ set: true, id: id }));
  };

  const handleEdit = (id: string) => {
    dispatch(projectActions.selectProjectToEdit(id));
  };
  return (
    <>
      {list.length === 0 && "Il n'y a pas des projets"}
      <Grid container spacing="20px" alignItems="stretch">
        {list.map((project) => (
          <Grid key={project._id} item xs={12} md={6} lg={4} xl={3}>
            <Card
              sx={{ height: "100%", display: "flex", flexDirection: "column" }}
              elevation={0}
            >
              <CardContent sx={{ flex: "1" }}>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ color: "#3F4254" }}
                >
                  {project.name}
                </Typography>
              </CardContent>
              <CardActions>
                <BlueButton
                  onClick={() => handleEdit(project._id || project.id)}
                  // sx={{ marginRight: "20px", padding: "4px 10px" }}
                  disabled={
                    !(authenticatedUser!.role as Role).managmentRules.includes(
                      "update"
                    )
                  }
                >
                  Edition
                </BlueButton>
                <RedButton
                  onClick={() => handleDelete(project._id || project.id)}
                  disabled={
                    !(authenticatedUser!.role as Role).managmentRules.includes(
                      "delete"
                    )
                  }
                >
                  Suppression
                </RedButton>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}