import { axiosInstance } from "api/axiosInstance";
import { AppDispatch } from "store/store.state";
import { Role, rolesActions } from "./roles.state";
import { NavigateFunction } from "react-router-dom";
import { feedbackMessageUIActions } from "store/feedback-message-ui/feedbackMessageUI.state";
import { addRelatedPermissions } from "utils/addRelatedPermissions"

type GetRolesResponse = {
  data: Role[];
};

export function getRolesThunk() {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.get<GetRolesResponse>("/roles", {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      });
      const roles = response.data.data;
      dispatch(rolesActions.setList(roles));
    } catch (e) {
      console.log(e);
    }
  };
}

type AddRoleResponse = {
  data: Role;
};

export function addRoleThunk(role: Omit<Role, "id" | "assignedUsers">) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.post<AddRoleResponse>(
        "/roles",
        role,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
          },
        }
      );
      const completeRole = response.data.data;
      dispatch(rolesActions.addRole(completeRole));
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Rôle ajouté avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Impossible d'ajouter un rôle, veuillez réessayer plus tard",
        })
      );
    }
  };
}

export function deleteRoleThunk(id: string, navigate?: NavigateFunction) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.delete(`/roles/${id}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      });
      // this is for comple-role-card, need to navigate out of single-role-view
      if (navigate) navigate("../");
      dispatch(rolesActions.deleteRole(id));
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Rôle supprimé avec succès",
        })
      );
    } catch (e: any) {
      if (e.response.status === 409) {
        dispatch(
          feedbackMessageUIActions.setState({
            open: true,
            message:
              "Cette donnée est utilisée, vous ne pouvez pas la supprimer",
          })
        );
      } else {
        dispatch(
          feedbackMessageUIActions.setState({
            open: true,
            message:
              "Impossible de supprimer le rôle, veuillez réessayer plus tard",
          })
        );
      }

    }
  };
}

type EditRoleResponse = {
  data: Role;
};

export function editRoleThunk(obj: {
  id: string;
  role: Omit<Role, "id" | "assignedUsers">;
}) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.put<EditRoleResponse>(
        `/roles/${obj.id}`,
        obj.role,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
          },
        }
      );
      const editedRole = response.data.data;
      dispatch(rolesActions.editRole({ id: obj.id, role: editedRole }));
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Rôle mis à jour avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message:
            "Impossible de mettre à jour le rôle, veuillez réessayer plus tard",
        })
      );
    }
  };
}


