import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CustomDialogTitle from "components/custom-dialog-title/CustomDialogTitle.component";
import BlueButton from "components/blue-button/BlueButton.component";
import GrayButton from "components/gray-button/GrayButton.component";
import Divider from "@mui/material/Divider";
import CentreCoutForm from "./CentreCoutForm.component";
import Form from "./Form.component";
import List from "./List.component";
import { getCostsThunk, getPoleThunk } from "store/pole/pole.thunk";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import { useEffect } from "react";
import { uiActions } from "store/ui/ui.state";
import { deleteCostThunk, deletePoleThunk } from "store/pole/pole.thunk";
import ListCostCenter from "./ListCostCenter";

export default function Pole() {
  const dispatch = useAppDispatch();
  const { deletePoleWarningOpen, idOfItemToDelete, deleteCostWarningOpen } = useAppSelector(
    (state) => state.ui
  );

  const closeDeletePoleWarning = () => {
    dispatch(uiActions.setDeletePoleWarningOpen({ set: false, id: "" }));
  };
  const closeDeleteCostWarning = () => {
    dispatch(uiActions.setDeleteCostWarningOpen({ set: false, id: "" }));
  };

  useEffect(() => {
    dispatch(getPoleThunk());
    dispatch(getCostsThunk())
  }, []);

  return (
    <>
      <Form />
      {/* <CentreCoutForm /> */}
      <Divider sx={{ marginBottom: "20px" }} />
      <List />
      {/* <Divider sx={{ marginBottom: "20px" }} /> */}
      {/* <ListCostCenter /> */}

      <Dialog
        open={deletePoleWarningOpen}
        onClose={closeDeletePoleWarning}
        sx={{ "& .MuiPaper-root": { width: "500px" } }}
      >
        <CustomDialogTitle onClose={closeDeletePoleWarning} id="">
          Suppression
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          Voulez-vous vraiment supprimer cette option ?
        </DialogContent>
        <DialogActions>
          <BlueButton
            onClick={() => {
              dispatch(deletePoleThunk(idOfItemToDelete));
              dispatch(
                uiActions.setDeletePoleWarningOpen({ set: false, id: "" })
              );
            }}
          >
            Oui
          </BlueButton>
          <GrayButton onClick={closeDeletePoleWarning}>No</GrayButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteCostWarningOpen}
        onClose={closeDeletePoleWarning}
        sx={{ "& .MuiPaper-root": { width: "500px" } }}
      >
        <CustomDialogTitle onClose={closeDeleteCostWarning} id="">
          Suppression
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          Voulez-vous vraiment supprimer cette option ?
        </DialogContent>
        <DialogActions>
          <BlueButton
            onClick={() => {
              dispatch(deleteCostThunk(idOfItemToDelete));
              dispatch(
                uiActions.setDeleteCostWarningOpen({ set: false, id: "" })
              );
            }}
          >
            Oui
          </BlueButton>
          <GrayButton onClick={closeDeleteCostWarning}>No</GrayButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
