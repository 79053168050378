import { axiosInstance } from "api/axiosInstance";
import { AppDispatch } from "store/store.state";
import { feedbackMessageUIActions } from "store/feedback-message-ui/feedbackMessageUI.state"
import { TypeDepense } from "./typedepenses.state";
import { depensesActions } from './typedepenses.state'


type GetDepenseResponse = {
  data: TypeDepense[]
}

export function getDepenseThunk() {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.get<GetDepenseResponse>("/typedepenses", {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      })
      const depenses = response.data.data
      dispatch(depensesActions.setList(depenses))
    } catch (e) {

    }
  }
}


export function addDepenseThunk(name: any) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.post<any>("/typedepenses", name, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      })
      const depense = response.data.data
      dispatch(depensesActions.addDepense(depense));
      dispatch(feedbackMessageUIActions.setState({ open: true, message: "Type de dépense ajouté avec succès" }))
    } catch (e) {

      dispatch(feedbackMessageUIActions.setState({ open: true, message: "Impossible d'ajouter type de dépense s'il vous plaît réessayer plus tard" }))
    }
  };
}

export function deleteDepenseThunk(id: string) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.delete(`/typedepenses/${id}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      })
      dispatch(depensesActions.deleteDepense(id));
      dispatch(feedbackMessageUIActions.setState({ open: true, message: "Type de dépense supprimée avec succès" }))
    } catch (e: any) {
      if (e.response.status === 409) {
        dispatch(
          feedbackMessageUIActions.setState({
            open: true,
            message:
              "Cette donnée est utilisée, vous ne pouvez pas la supprimer",
          })
        );
      } else {
        dispatch(feedbackMessageUIActions.setState({ open: true, message: "Impossible de supprimer type de dépense s'il vous plaît réessayer plus tard" }))

      }
    }
  };
}

export function editDepenseThunk(obj: { id: string; depense: any }) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.put<any>(`/typedepenses/${obj.id}`, obj.depense, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      })
      const editedDepense = response.data.data
      dispatch(depensesActions.editDepense({ id: obj.id, depense: editedDepense }));
      dispatch(feedbackMessageUIActions.setState({ open: true, message: "Type de dépense mis à jour avec succès" }))
      dispatch(getDepenseThunk())
    } catch (e) {

      dispatch(feedbackMessageUIActions.setState({ open: true, message: "Impossible de mettre à jour type de dépense s'il vous plaît réessayer plus tard" }))
    }
  };
}