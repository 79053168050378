import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialState = {
    open: boolean;
    message : string;
}

const initialState: InitialState = {
    open: false,
    message: ""
}

const feedbackMessageUISlice = createSlice({
    name : "feedback-message-ui",
    initialState,
    reducers: {
        setState(state, action: PayloadAction<InitialState>) {
            state.open = action.payload.open
            state.message = action.payload.message
        }
    }
})


export const feedbackMessageUIActions = feedbackMessageUISlice.actions
export const feedbackMessageUIReducer = feedbackMessageUISlice.reducer