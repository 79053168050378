import { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { ThemeProvider } from "@emotion/react";
import { PoopinsFontFamilyTheme } from "theme/theme";
// import { Link } from "react-router-dom";
import { CustomLink } from "components/custom-link/CustomLink.component";
import { HeaderListItemButton } from "./HeaderListItemButton.component";
import { SubHeaderListItemButton } from "./SubHeaderListItemButton.component";
import { RightArrow } from "./RightArrow.component";

import logo from "../../assets/logo.svg";
import dashboardTopBg from "./assets/dashboard-top-bg.svg";
import attachment from "./assets/attachment.svg";
import BarCodeRead from "./assets/barcode-read.svg";
import BoxIcon from "./assets/box.svg";
import DoubleArrowLeft from "./assets/double-arrow-left.svg";
import Layers from "./assets/layers.svg";
import Settings from "./assets/settings.svg";
import { useLocation } from "react-router-dom";

import { drawerWidth } from "./variables";

import * as ROUTES from "utils/routes";
import { useAppSelector } from "hooks/redux.hook";
import { Role } from "store/roles/roles.state";

type SideNavigationProps = {
  open: boolean;
  handleDrawerClose: () => void;
};

export default function SideNavigation(props: SideNavigationProps) {
  const { pathname } = useLocation();
  const accountType = useAppSelector((state) => state.auth.accountType);
  const authenticatedUser = useAppSelector((state) => state.auth.user);

  // const [selectedIndex, setSelectedIndex] = useState(0);
  // const handleListItemClick = (
  //   event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  //   index: number
  // ) => {
  //   setSelectedIndex(index);
  // };

  let pattern = `(^/${ROUTES.DASHBOARD}$)|(/${ROUTES.DASHBOARD}/$)`;
  const inDahsboard = new RegExp(pattern).test(pathname);
  pattern = `(^/${ROUTES.DASHBOARD}/${ROUTES.PARAMETERS})`;
  const inParameters = new RegExp(pattern).test(pathname);
  pattern = `(^/${ROUTES.DASHBOARD}/${ROUTES.PARAMETERS}/${ROUTES.USERS})`;
  const inUsers = new RegExp(pattern).test(pathname);
  pattern = `(^/${ROUTES.DASHBOARD}/${ROUTES.PARAMETERS}/${ROUTES.ROLES})`;
  const inRoles = new RegExp(pattern).test(pathname);
  pattern = `(^/${ROUTES.DASHBOARD}/${ROUTES.PARAMETERS}/${ROUTES.MANAGMENT_RULES})`;
  const inManagmentRules = new RegExp(pattern).test(pathname);
  pattern = `(^/${ROUTES.DASHBOARD}/${ROUTES.PARAMETERS}/${ROUTES.ARTICLE})`;
  const inArticle = new RegExp(pattern).test(pathname);
  pattern = `(^/${ROUTES.DASHBOARD}/${ROUTES.PARAMETERS}/${ROUTES.API_CONNECTION})`;
  const inAPIConnection = new RegExp(pattern).test(pathname);
  pattern = `(^/${ROUTES.DASHBOARD}/${ROUTES.SUPPLIERS})`;
  const inSuppliers = new RegExp(pattern).test(pathname);
  pattern = `(^/${ROUTES.DASHBOARD}/${ROUTES.SUPPLIERS}/${ROUTES.SUPPLIERS_LIST})`;
  const inSuppliersList = new RegExp(pattern).test(pathname);
  pattern = `(^/${ROUTES.DASHBOARD}/${ROUTES.PURCHASE_ORDERS})`;
  const inPurchaseOrders = new RegExp(pattern).test(pathname);
  pattern = `(^/${ROUTES.DASHBOARD}/${ROUTES.PURCHASE_ORDERS}/${ROUTES.PURCHASE_ORDERS_LIST})`;
  const inPurchaseOrdersList = new RegExp(pattern).test(pathname);
  pattern = `(^/${ROUTES.DASHBOARD}/${ROUTES.BILLS})`;
  const inBills = new RegExp(pattern).test(pathname);
  pattern = `(^/${ROUTES.DASHBOARD}/${ROUTES.BILLS}/${ROUTES.BILLS_LIST})`;
  const inBillsList = new RegExp(pattern).test(pathname);

  const role = authenticatedUser!.role as Role;
  let showTableauDeBord = false;
  let showSupplierInfos = false;
  let showParametres = false;
  let showUtilisateurs = false;
  let showRoles = false;
  let showReglesDeGestion = false;
  let showConnectionApi = false;
  let showFournisseurs = false;
  let showListeDesFournisseurs = false;
  let showBons = true;
  let showListeDesBons = true;
  let showFactures = true;
  let showListeDesFactures = true;
  if (accountType === "vendor") {
    showSupplierInfos = true;
  }

  if (accountType === "user") {
    showTableauDeBord = true;
    showParametres =
      role.userManagment.includes("read") ||
      role.roleManagment.includes("read") ||
      role.managmentRules.includes("read") ||
      role.APIConnection.includes("read");
    showUtilisateurs = role.userManagment.includes("read");
    showRoles = role.roleManagment.includes("read");
    showReglesDeGestion = role.managmentRules.includes("read");
    showConnectionApi = role.APIConnection.includes("read");
    showFournisseurs = role.supplierManagment.includes("read");
    showListeDesFournisseurs = role.supplierManagment.includes("read");
    showBons = role.orderManagment?.includes("read");
    showListeDesBons = role.orderManagment?.includes("read");
    showFactures = role.billManagment?.includes("read");
    showListeDesFactures = role.billManagment?.includes("read");
  }
  return (
    <ThemeProvider theme={PoopinsFontFamilyTheme}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#000619",
            color: "white",
          },
        }}
        variant="persistent"
        anchor="left"
        open={props.open}
      >
        {/* drawer header */}
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "2px 0",
            background: `url(${dashboardTopBg}) -50px -50px no-repeat`,
            backgroundSize: "300px",
            backgroundColor: "#000619",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
              flexGrow: 1,
              gap: "10px",
            }}
          >
            <div style={{ height: "50px" }}>
              <img src={logo} alt="Artline logo" height="100%" />
            </div>
            <Typography
              color="white"
              fontSize="10px"
              padding="0"
              lineHeight="10px"
            >
              INSTITUT <br /> ARTLINE
            </Typography>
          </Box>
          <IconButton
            onClick={props.handleDrawerClose}
            sx={{ "&:hover": { background: "rgba(255, 255, 255, 0.1)" } }}
          >
            <Avatar
              sx={{
                height: "24px",
                width: "24px",
              }}
              src={DoubleArrowLeft}
            />
          </IconButton>
        </Toolbar>
        {/* drawer header */}

        <Divider />

        <List>
          <ListItem
            disablePadding
            component={CustomLink}
            to=""
            sx={{ display: showTableauDeBord ? "block" : "none" }}
          >
            <HeaderListItemButton selected={inDahsboard}>
              <ListItemIcon>
                <Avatar
                  sx={{
                    height: "24px",
                    width: "24px",
                  }}
                  src={Layers}
                />
              </ListItemIcon>
              <ListItemText primary="Tableau de bord" />
              <RightArrow />
            </HeaderListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            component={CustomLink}
            to={`${ROUTES.PARAMETERS}/${ROUTES.USERS}`}
            sx={{ display: showParametres ? "block" : "none" }}
          >
            <HeaderListItemButton selected={inParameters}>
              <ListItemIcon>
                <Avatar
                  sx={{
                    height: "24px",
                    width: "24px",
                  }}
                  src={attachment}
                />
              </ListItemIcon>
              <ListItemText primary="Paramètres" />
              <RightArrow />
            </HeaderListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            component={CustomLink}
            to={`${ROUTES.PARAMETERS}/${ROUTES.USERS}`}
            sx={{ display: showUtilisateurs ? "block" : "none" }}
          >
            <SubHeaderListItemButton selected={inUsers}>
              <ListItemText
                sx={{
                  paddingLeft: "45px",
                  "& p": {
                    color: "#888C9F",
                  },
                }}
                inset
                secondary="- Utilisateurs"
              />
            </SubHeaderListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            component={CustomLink}
            to={`${ROUTES.PARAMETERS}/${ROUTES.ROLES}`}
            sx={{ display: showRoles ? "block" : "none" }}
          >
            <SubHeaderListItemButton selected={inRoles}>
              <ListItemText
                sx={{
                  paddingLeft: "45px",
                  "& p": {
                    color: "#888C9F",
                  },
                }}
                inset
                secondary="- Rôles"
              />
            </SubHeaderListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            component={CustomLink}
            to={`${ROUTES.PARAMETERS}/${ROUTES.MANAGMENT_RULES}`}
            sx={{ display: showReglesDeGestion ? "block" : "none" }}
          >
            <SubHeaderListItemButton selected={inManagmentRules}>
              <ListItemText
                sx={{
                  paddingLeft: "45px",
                  "& p": {
                    color: "#888C9F",
                  },
                }}
                inset
                secondary="- Règles de gestion"
              />
            </SubHeaderListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={CustomLink}
            to={`${ROUTES.PARAMETERS}/${ROUTES.ARTICLE}`}
            sx={{ display: showReglesDeGestion ? "block" : "none" }}
          >
            <SubHeaderListItemButton selected={inArticle}>
              <ListItemText
                sx={{
                  paddingLeft: "45px",
                  "& p": {
                    color: "#888C9F",
                  },
                }}
                inset
                secondary="- Articles"
              />
            </SubHeaderListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            component={CustomLink}
            to={`${ROUTES.PARAMETERS}/${ROUTES.API_CONNECTION}`}
            sx={{ display: showConnectionApi ? "block" : "none" }}
          >
            <SubHeaderListItemButton selected={inAPIConnection}>
              <ListItemIcon>
                <Avatar
                  sx={{
                    height: "24px",
                    width: "24px",
                  }}
                  src={BarCodeRead}
                />
              </ListItemIcon>
              <ListItemText
                sx={{
                  "& p": {
                    color: "#888C9F",
                  },
                }}
                secondary="Connection API"
              />
            </SubHeaderListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            component={CustomLink}
            to={`${ROUTES.SUPPLIERS}/${ROUTES.SUPPLIERS_LIST}`}
            sx={{ display: showFournisseurs ? "block" : "none" }}
          >
            <HeaderListItemButton selected={inSuppliers}>
              <ListItemText primary="Fournisseurs" />
              <RightArrow />
            </HeaderListItemButton>
          </ListItem>
          {/* <Typography
          sx={{ color: "#6C7293", textDecoration: "none" }}
          fontWeight={500}
          fontSize="11px"
          lineHeight="16px"
          marginRight="10px"
          component={Link}
          to={
            accountType === "user"
              ? ROUTES.USER_PROFILE
              : ROUTES.SUPPLIER_PROFILE
          }
        > */}
          <ListItem
            disablePadding
            component={CustomLink}
            to={
              accountType === "user"
                ? ROUTES.USER_PROFILE
                : ROUTES.SUPPLIER_PROFILE
            }
            sx={{ display: showSupplierInfos ? "block" : "none" }}
          >
            <SubHeaderListItemButton>
              <HeaderListItemButton>
                <ListItemText primary="Mes informations" />
                <RightArrow />
              </HeaderListItemButton>
              {/* <ListItemText
                sx={{
                  // paddingLeft: "45px",
                  "& p": {
                    color: "#888C9F",
                  },
                }}
                inset
                // secondary=" Mes informations"
              /> */}
            </SubHeaderListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            component={CustomLink}
            to={`${ROUTES.SUPPLIERS}/${ROUTES.SUPPLIERS_LIST}`}
            sx={{ display: showListeDesFournisseurs ? "block" : "none" }}
          >
            <SubHeaderListItemButton selected={inSuppliersList}>
              <ListItemIcon>
                <Avatar
                  sx={{
                    height: "24px",
                    width: "24px",
                  }}
                  src={Settings}
                />
              </ListItemIcon>
              <ListItemText
                sx={{
                  "& p": {
                    color: "#888C9F",
                  },
                }}
                secondary="Liste des fournisseurs"
              />
            </SubHeaderListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            component={CustomLink}
            to={`${ROUTES.PURCHASE_ORDERS}/${ROUTES.PURCHASE_ORDERS_LIST}`}
            sx={{ display: showBons ? "block" : "none" }}
          >
            <HeaderListItemButton selected={inPurchaseOrders}>
              <ListItemText primary="Bon de commandes" />
              <RightArrow />
            </HeaderListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            component={CustomLink}
            to={`${ROUTES.PURCHASE_ORDERS}/${ROUTES.PURCHASE_ORDERS_LIST}`}
            sx={{ display: showListeDesBons ? "block" : "none" }}
          >
            <SubHeaderListItemButton selected={inPurchaseOrdersList}>
              <ListItemIcon>
                <Avatar
                  sx={{
                    height: "24px",
                    width: "24px",
                  }}
                  src={BoxIcon}
                />
              </ListItemIcon>
              <ListItemText
                sx={{
                  "& p": {
                    color: "#888C9F",
                  },
                }}
                secondary="Liste des bons"
              />
            </SubHeaderListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            component={CustomLink}
            to={`${ROUTES.BILLS}/${ROUTES.BILLS_LIST}`}
            sx={{ display: showFactures ? "block" : "none" }}
          >
            <HeaderListItemButton selected={inBills}>
              <ListItemText primary="Factures" />
              <RightArrow />
            </HeaderListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            component={CustomLink}
            to={`${ROUTES.BILLS}/${ROUTES.BILLS_LIST}`}
            sx={{ display: showListeDesFactures ? "block" : "none" }}
          >
            <SubHeaderListItemButton selected={inBillsList}>
              <ListItemIcon>
                <Avatar
                  sx={{
                    height: "24px",
                    width: "24px",
                  }}
                  src={attachment}
                />
              </ListItemIcon>
              <ListItemText
                sx={{
                  "& p": {
                    color: "#888C9F",
                  },
                }}
                secondary="Liste des factures"
              />
            </SubHeaderListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </ThemeProvider>
  );
}
