import React from "react"
import ProfileCard from "./components/profile-card/ProfileCard.component"
import EditForm from "./components/edit-form/EditForm.component"
import ResetPasswordForm from "./components/reset-password-form/ResetPasswordForm.component"
import Documents from "./components/documents/Documents.component";

export default function SupplierProfile() {
    const [tabDisplay, settabDisplay] = React.useState(0);
    return (
        <div style={{ padding: "73px 67px 0 48px" }}>
            <ProfileCard settabDisplay={settabDisplay} tabDisplay={tabDisplay} />
            {tabDisplay === 0 && (
                <EditForm />
            )}
            {tabDisplay === 1 && (

                <Documents />
            )}
            {tabDisplay === 2 && (

                <ResetPasswordForm />
            )}
        </div>
    )
}