import { AppDispatch, AppThunk } from "store/store.state";
import { ordersActions, OrderStatusEnum } from "./orders.state";
import type { Order } from "./orders.state";
import { axiosInstance } from "api/axiosInstance";
import { Dispatch } from "react";
import { feedbackMessageUIActions } from "store/feedback-message-ui/feedbackMessageUI.state";

type GetOrdersResponse = {
  data: Order[];
};

export function getOrdersThunk() {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.get(
        "/purchaseorders",
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
          },
        }
      );
      const orders: Order[] = response.data.data;
      dispatch(ordersActions.setList(orders));
    } catch (e) {
      console.log(e);
    }
  };
}

export function getOrdersByVendorIdThunk(vendorId: string) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.get(
        `/purchaseorders/vendor/${vendorId}`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
          },
        }
      );
      const orders: Order[] = response.data.data;
      dispatch(ordersActions.setList(orders));
    } catch (e) {
      console.log(e);
    }
  };
}

type AddOrderResponse = {
  data: Order;
};


export function pdfOrderThunk(order: Order) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.post(
        "/purchaseorders/pdf",
        order, {
        method: 'POST',
        responseType: 'blob', //Force to receive data in a Blob Format,
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      }

      );
      const pdf = response.data.data;
      dispatch(ordersActions.pdfOrder(pdf));
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Le bon a été ajouté avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Impossible d'ajouter le bon, veuillez réessayer plus tard",
        })
      );
    }
  };
}

export function addOrderThunk(order: Order) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.post<AddOrderResponse>(
        "/purchaseorders",
        order,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
          },
        }
      );
      const completeOrder = response.data.data;
      dispatch(ordersActions.addOrder(completeOrder));
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Le bon a été ajouté avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Impossible d'ajouter le bon, veuillez réessayer plus tard",
        })
      );
    }
  };
}

export function deleteOrderThunk(id: string) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.delete(`/purchaseorders/${id}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      });
      dispatch(ordersActions.deleteOrder(id));
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Le bon a été supprimé avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message:
            "Impossible de supprimer le bon, veuillez réessayer plus tard",
        })
      );
    }
  };
}
type EditOrderParams = Order;
export function updateOrderThunk(obj: {
  id: string;
  order: EditOrderParams;
}, setOpenLoading?: Dispatch<React.SetStateAction<boolean>>) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.put(`/purchaseorders/${obj.id}`, obj.order, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      });
      dispatch(ordersActions.editOrder(obj));
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Bon de commande mis à jour avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message:
            "Impossible de mettre à jour le bon de commande, veuillez réessayer plus tard",
        })
      );
    } finally {
      if (setOpenLoading) setOpenLoading(false);
    }
  };
}


export function editStatusOrderThunk(obj: { id: string; status: OrderStatusEnum }) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.put(
        `/purchaseorders/status/${obj.id}`,
        { status: obj.status },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
          },
        }

      );
      const editedOrder = response.data.data;
      dispatch(ordersActions.editOrder({ id: obj.id, order: editedOrder }));
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Statut de bon de commande mis à jour avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message:
            "Impossible de mettre à jour une bon de commande s'il vous plaît réessayer plus tard",
        })
      );
    }
  };
}