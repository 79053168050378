import { useAppDispatch } from "hooks/redux.hook";
import { uiActions } from "store/ui/ui.state";
import { useNavigate } from "react-router-dom";
import { rolesActions } from "store/roles/roles.state";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import { RoleCardButton } from "../role-card-button/RoleCardButton.component";
import { BlueDot } from "components/blue-dot/BlueDot.component";
import type { Role } from "store/roles/roles.state";
import { Box } from "@mui/material";
import {
  makeFirstLetterCapital,
  translatePermissions,
} from "utils/stringOperations";
import { deleteRoleThunk } from "store/roles/roles.thunk";
import { useAppSelector } from "hooks/redux.hook";
import { USER_ROLES } from "utils/roles";

type RoleCardProps = {
  role: Role;
};

export default function RoleCard(props: RoleCardProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { role } = props;
  const keys = Object.keys(props.role).filter(
    (key) => key !== "name" && key !== "id"
  );

  const authenticatedUser = useAppSelector((state) => state.auth.user);

  const handleEditRole = () => {
    dispatch(rolesActions.selectRoleToEdit(role.id));
    dispatch(uiActions.setEditRoleFormOpen(true));
  };

  const handleViewRole = () => {
    navigate(role.id);
  };

  const handleDeleteRole = () => {
    // dispatch(deleteRoleThunk(role.id))
    dispatch(uiActions.setDeleteRoleWarningOpen({ set: true, id: role.id }));
    dispatch(rolesActions.selectRoleToDelete(role.id))
  };

  const capitalizedRoleName = makeFirstLetterCapital(role.name);
  return (
    <>
      <Paper sx={{ padding: "30px", overflow: "hidden", borderRadius: "6px"}} elevation={0}>
        <Typography sx={{ marginBottom: "20px", color: "#181C32", fontWeight: "700", fontSize: "20px" }}>
          {capitalizedRoleName}
        </Typography>
        <Typography
          sx={{
            fontWeight: "700",
            fontSize: "14px",
            color: "#A1A5B7",
            marginBottom: "20px",
          }}
        >
          Nombre total d'utilisateurs avec ce rôle: {role.assignedUsers.length}
        </Typography>

        <Box
          sx={{
            marginBottom: "40px",
            "& li": {
              margin: "10px 0",
            },
          }}
        >
          <ul
            style={{
              listStyle: "none",
              margin: "0",
              padding: "0",
              fontWeight: "500",
              fontSize: "13px",
              color: "#B5B5C3",
              marginBottom: "10px",
            }}
          >
            <li>
              <BlueDot /> Utilisateurs:{" "}
              {role.userManagment.length > 0
                ? translatePermissions(role.userManagment)
                : "Aucun"}
            </li>
            <li>
              <BlueDot /> Fournisseurs:{" "}
              {role.supplierManagment.length > 0
                ? translatePermissions(role.supplierManagment)
                : "Aucun"}
            </li>
            <li>
              <BlueDot /> Rôles:{" "}
              {role.roleManagment.length > 0
                ? translatePermissions(role.roleManagment)
                : "Aucun"}
            </li>
            <li>
              <BlueDot /> Règles de gestion:{" "}
              {role.managmentRules.length > 0
                ? translatePermissions(role.managmentRules)
                : "Aucun"}
            </li>
            {/* <li>
              <BlueDot /> Factures:{" "}
              {role.billManagment.length > 0
                ? translatePermissions(role.billManagment)
                : "Aucun"}
            </li>
            <li>
              <BlueDot /> Bons de command:{" "}
              {role.orderManagment.length > 0
                ? translatePermissions(role.orderManagment)
                : "Aucun"}
            </li>
            <li>
              <BlueDot /> Connection API:{" "}
              {role.APIConnection.length > 0
                ? translatePermissions(role.APIConnection)
                : "Aucun"}
            </li> */}
            <li style={{ fontStyle: "italic" }}>
              {" "}
              <BlueDot /> et 3 de plus...
            </li>
          </ul>
        </Box>

        <Grid container gap="10px">
          <Grid item>
            <RoleCardButton variant="contained" onClick={handleViewRole}>
              Afficher le rôle
            </RoleCardButton>
          </Grid>
          <Grid item>
            <RoleCardButton
              variant="contained"
              onClick={handleEditRole}
              disabled={
                !(authenticatedUser!.role as Role).roleManagment.includes(
                  "update"
                )
              }
            >
              Modifier le rôle
            </RoleCardButton>
          </Grid>
          <Grid item>
            <RoleCardButton
              variant="contained"
              onClick={handleDeleteRole}
              disabled={
                !(authenticatedUser!.role as Role).roleManagment.includes(
                  "delete"
                )
              }
            >
              Supprimer le rôle
            </RoleCardButton>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
