import { useAppSelector, useAppDispatch } from "hooks/redux.hook";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import SDataGrid from "./components/data-grid/SDataGrid.component";
import CustomDialogTitle from "components/custom-dialog-title/CustomDialogTitle.component";
import AddForm from "./components/add-form/AddForm.component";
import InviteForm from "./components/invite-form/InviteForm.component";
import EditForm from "./components/edit-form/EditForm.component";

import { uiActions } from "store/ui/ui.state";
import { getSuppliersThunk } from "store/suppliers/suppliers.thunk";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { USER_ROLES } from "utils/roles";
import DialogActions from "@mui/material/DialogActions";
import BlueButton from "components/blue-button/BlueButton.component";
import GrayButton from "components/gray-button/GrayButton.component";
import { deleteSupplierThunk } from "store/suppliers/suppliers.thunk";
import WhiteButton from "components/white-button/WhiteButton.component";
import SuppliersDataGrid from "./components/data-grid/SuppliersDataGrid";
import CustomToolbar from "./components/data-grid/CutomToolbar.component";
import SupplierToolbar from "./components/data-grid/SupplierToolbar";

export default function Suppliers() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) =>
    state.auth.user ? state.auth.user.role : ""
  );
  const {
    addSupplierFormOpen,
    inviteSupplierFormOpen,
    editSupplierFormOpen,
    deleteSupplierWarningOpen,
    idOfItemToDelete,
  } = useAppSelector((state) => state.ui);

  const closeAddSupplierForm = () => {
    dispatch(uiActions.setAddSupplierFormOpen(false));
  };
  const closeInviteSupplierForm = () => {
    dispatch(uiActions.setInviteSupplierFormOpen(false));
  };
  const closeEditSupplierForm = () => {
    dispatch(uiActions.setEditSupplierFormOpen(false));
  };

  const closeDeleteSupplierWarning = () => {
    dispatch(uiActions.setDeleteSupplierWarningOpen({ set: false, id: "" }));
  };

  return (
    <div style={{ padding: "48px 58px 0 57px " }}>
      {/* <SDataGrid /> */}
      <SupplierToolbar />

      <SuppliersDataGrid />

      <Dialog
        fullScreen
        open={addSupplierFormOpen}
        onClose={closeAddSupplierForm}
        sx={{ "& .MuiPaper-root": { height: "90vh", width: "45vw" }, "& .css-ypiqx9-MuiDialogContent-root": {
          padding: "25px 40px"
        } }}
        maxWidth="lg"
      >

        <CustomDialogTitle onClose={closeAddSupplierForm} id="">
          Ajouter un fournisseur
        </CustomDialogTitle>

        <Divider />

        <DialogContent >
          <AddForm />
        </DialogContent>

      </Dialog>

      <Dialog
        open={inviteSupplierFormOpen}
        onClose={closeInviteSupplierForm}
        sx={{ "& .MuiPaper-root": { width: "500px" } }}
      >

        <CustomDialogTitle onClose={closeInviteSupplierForm} id="">
          Invite un fournisseur
        </CustomDialogTitle>

        <Divider />

        <DialogContent>
          <InviteForm />
        </DialogContent>

      </Dialog>

      <Dialog
        fullScreen
        open={editSupplierFormOpen}
        onClose={closeEditSupplierForm}
        sx={{ "& .MuiPaper-root": { height: "90vh", width: "45vw" }, "& .css-ypiqx9-MuiDialogContent-root": {
          padding: "25px 40px"
        } }}
      >

        <CustomDialogTitle onClose={closeEditSupplierForm} id="">
          Modifier un fournisseur
        </CustomDialogTitle>

        <Divider />

        <DialogContent>
          <EditForm />
        </DialogContent>

      </Dialog>

      <Dialog
        open={deleteSupplierWarningOpen}
        onClose={closeDeleteSupplierWarning}
        sx={{ "& .MuiPaper-root": { width: "500px" } }}
      >

        <CustomDialogTitle onClose={closeDeleteSupplierWarning} id="">
          Suppression
        </CustomDialogTitle>

        <Divider />

        <DialogContent>
          Voulez-vous vraiment supprimer cet fournisseur
        </DialogContent>

        <DialogActions>

          <BlueButton
            onClick={() => {
              dispatch(deleteSupplierThunk(idOfItemToDelete));
              dispatch(
                uiActions.setDeleteSupplierWarningOpen({ set: false, id: "" })
              );
            }}
          >
            Valider
          </BlueButton>

          <WhiteButton onClick={closeDeleteSupplierWarning}>
            Annuler
          </WhiteButton>

        </DialogActions>
      </Dialog>
    </div>
  );
}
