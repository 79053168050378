import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import { useAppSelector, useAppDispatch } from "hooks/redux.hook";

import { uiActions } from "store/ui/ui.state";
import { Role } from "store/roles/roles.state";
import BlueButton from "components/blue-button/BlueButton.component";
import RedButton from "components/red-button/RedButton";
import {
  IPaymentTerm,
  paymentTermsActions,
} from "store/payment-terms/paymentTerms.state";

export default function List() {
  const dispatch = useAppDispatch();
  const list = useAppSelector((state) => state.paymentTerms.list);

  const authenticatedUser = useAppSelector((state) => state.auth.user);

  const handleDelete = (id: string) => {
    dispatch(uiActions.setDeletePaymentTermWarningOpen({ set: true, id: id }));
  };

  const handleEdit = (data: IPaymentTerm) => {
    // console.log(data);

    dispatch(paymentTermsActions.setSelectPaymentTerm(data));
  };
  return (
    <>
      {list.length === 0 && "Il n'y a pas de condition de paiement"}
      <Grid container spacing="20px" alignItems="stretch">
        {list.map((pt) => (
          <Grid key={pt._id} item xs={12} md={6} lg={4} xl={3}>
            <Card
              sx={{ height: "100%", display: "flex", flexDirection: "column" }}
              elevation={0}
            >
              <CardContent sx={{ flex: "1" }}>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{ color: "#3F4254" }}
                >
                  {pt.value}
                </Typography>
              </CardContent>
              <CardActions>
                <BlueButton
                  onClick={() => handleEdit(pt)}
                  // sx={{ marginRight: "20px", padding: "4px 10px" }}
                  disabled={
                    !(authenticatedUser!.role as Role).managmentRules.includes(
                      "update"
                    )
                  }
                >
                  Edition
                </BlueButton>
                <RedButton
                  onClick={() => {
                    handleDelete(pt._id);
                  }}
                  disabled={
                    !(authenticatedUser!.role as Role).managmentRules.includes(
                      "delete"
                    )
                  }
                >
                  Suppression
                </RedButton>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
