import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Ui = {
  addUserFormOpen: boolean;
  addOrderFormOpen: boolean;
  addInvoiceFormOpen: boolean;
  editUserFormOpen: boolean;
  addSupplierFormOpen: boolean;
  addViewFormOpen: boolean;
  inviteSupplierFormOpen: boolean;
  editSupplierFormOpen: boolean;
  editOrderFormOpen: boolean;
  editInvoiceFormOpen: boolean;
  editInvoiceStatusOpen: boolean;
  editOrderStatusOpen: boolean;
  infoOrderOpen: boolean;
  infoInvoiceOpen: boolean;
  addRoleFormOpen: boolean;
  editRoleFormOpen: boolean;
  closeAddRoleConfirmationOpen: boolean;
  closeEditRoleConfirmationOpen: boolean;
  orderPdfWarningOpen: boolean;
  viewSettingsOpen: boolean;
  invoicePdfWarningOpen: boolean;
  deleteUserWarningOpen: boolean;
  deleteTvaWarningOpen: boolean;
  deleteArticleWarningOpen: boolean;
  deletePaymentTermWarningOpen: boolean;
  deleteSupplierWarningOpen: boolean;
  deleteOrderWarningOpen: boolean;
  deleteRoleWarningOpen: boolean;
  deletePosteWarningOpen: boolean;
  deletePerimeterWarningOpen: boolean;
  deleteCiviliteWarningOpen: boolean;
  deleteVieweWarningOpen: boolean;
  deletePoleWarningOpen: boolean;
  deleteProjectWarningOpen: boolean;
  deleteInvoiceWarningOpen: boolean;
  deletePaymentDateWarningOpen: boolean;
  idOfItemToDelete: string;
  generateNewPasswordWarningOpen: boolean;
  idOfUserToGeneratePasswordFor: string;
  uploadAvatarFormOpen: boolean;
  deleteCostWarningOpen: boolean;
  deleteDepenseWarningOpen: boolean;
  deleteSessionWarningOpen: boolean;
};

const initialState: Ui = {
  addUserFormOpen: false,
  addInvoiceFormOpen: false,
  addOrderFormOpen: false,
  editUserFormOpen: false,
  addSupplierFormOpen: false,
  addViewFormOpen: false,
  inviteSupplierFormOpen: false,
  editSupplierFormOpen: false,
  orderPdfWarningOpen: false,
  invoicePdfWarningOpen: false,
  editOrderFormOpen: false,
  editInvoiceFormOpen: false,
  editInvoiceStatusOpen: false,
  editOrderStatusOpen: false,
  infoOrderOpen: false,
  infoInvoiceOpen: false,
  viewSettingsOpen: false,
  addRoleFormOpen: false,
  editRoleFormOpen: false,
  closeAddRoleConfirmationOpen: false,
  closeEditRoleConfirmationOpen: false,
  deleteUserWarningOpen: false,
  deleteSupplierWarningOpen: false,
  deleteRoleWarningOpen: false,
  deleteTvaWarningOpen: false,
  deleteArticleWarningOpen: false,
  deletePaymentTermWarningOpen: false,
  deletePosteWarningOpen: false,
  deletePerimeterWarningOpen: false,
  deleteOrderWarningOpen: false,
  deleteCiviliteWarningOpen: false,
  deletePoleWarningOpen: false,
  deleteProjectWarningOpen: false,
  deleteInvoiceWarningOpen: false,
  deletePaymentDateWarningOpen: false,
  deleteVieweWarningOpen: false,
  idOfItemToDelete: "",
  generateNewPasswordWarningOpen: false,
  idOfUserToGeneratePasswordFor: "",
  uploadAvatarFormOpen: false,
  deleteCostWarningOpen: false,
  deleteDepenseWarningOpen: false,
  deleteSessionWarningOpen: false,
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setAddUserFormOpen(state, action: PayloadAction<boolean>) {
      state.addUserFormOpen = action.payload;
    },
    setEditUserFormOpen(state, action: PayloadAction<boolean>) {
      state.editUserFormOpen = action.payload;
    },
    setAddSupplierFormOpen(state, action: PayloadAction<boolean>) {
      state.addSupplierFormOpen = action.payload;
    },
    setAddInvoiceFormOpen(state, action: PayloadAction<boolean>) {
      state.addInvoiceFormOpen = action.payload;
    },
    setInviteSupplierFormOpen(state, action: PayloadAction<boolean>) {
      state.inviteSupplierFormOpen = action.payload;
    },
    setEditSupplierFormOpen(state, action: PayloadAction<boolean>) {
      state.editSupplierFormOpen = action.payload;
    },
    setEditOrderFormOpen(state, action: PayloadAction<boolean>) {
      state.editOrderFormOpen = action.payload;
    },
    setEditInvoiceFormOpen(state, action: PayloadAction<boolean>) {
      state.editInvoiceFormOpen = action.payload;
    },
    setEditInvoiceStatusOpen(state, action: PayloadAction<boolean>) {
      state.editInvoiceStatusOpen = action.payload;
    },
    setEditOrderStatusOpen(state, action: PayloadAction<boolean>) {
      state.editOrderStatusOpen = action.payload;
    },
    setInfoOrderOpen(state, action: PayloadAction<boolean>) {
      state.infoOrderOpen = action.payload;
    },
    setViewSettingsOpen(state, action: PayloadAction<boolean>) {
      state.viewSettingsOpen = action.payload;
    },
    setInfoInvoiceOpen(state, action: PayloadAction<boolean>) {
      state.infoInvoiceOpen = action.payload;
    },
    setAddRoleFormOpen(state, action: PayloadAction<boolean>) {
      state.addRoleFormOpen = action.payload;
    },
    setEditRoleFormOpen(state, action: PayloadAction<boolean>) {
      state.editRoleFormOpen = action.payload;
    },
    setOrderPdfWarningOpen(state, action: PayloadAction<boolean>) {
      state.orderPdfWarningOpen = action.payload;
    },
    setInvoicePdfWarningOpen(state, action: PayloadAction<boolean>) {
      state.invoicePdfWarningOpen = action.payload;
    },
    setCloseAddRoleConfirmationOpen(state, action: PayloadAction<boolean>) {
      state.closeAddRoleConfirmationOpen = action.payload;
    },
    setCloseEditRoleConfirmationOpen(state, action: PayloadAction<boolean>) {
      state.closeEditRoleConfirmationOpen = action.payload;
    },
    setDeleteUserWarningOpen(
      state,
      action: PayloadAction<{ set: boolean; id: string }>
    ) {
      state.deleteUserWarningOpen = action.payload.set;
      state.idOfItemToDelete = action.payload.id;
    },
    setDeleteTvaWarningOpen(
      state,
      action: PayloadAction<{ set: boolean; id: string }>
    ) {
      state.deleteTvaWarningOpen = action.payload.set;
      state.idOfItemToDelete = action.payload.id;
    },
    setDeleteCostWarningOpen(
      state,
      action: PayloadAction<{ set: boolean; id: string }>
    ) {
      state.deleteCostWarningOpen = action.payload.set;
      state.idOfItemToDelete = action.payload.id;
    },
    setDeleteSessionWarningOpen(
      state,
      action: PayloadAction<{ set: boolean; id: string }>
    ) {
      state.deleteSessionWarningOpen = action.payload.set;
      state.idOfItemToDelete = action.payload.id;
    },
    setDeleteDepenseWarningOpen(
      state,
      action: PayloadAction<{ set: boolean; id: string }>
    ) {
      state.deleteDepenseWarningOpen = action.payload.set;
      state.idOfItemToDelete = action.payload.id;
    },
    setDeleteViewWarningOpen(
      state,
      action: PayloadAction<{ set: boolean; id: string }>
    ) {
      state.deleteVieweWarningOpen = action.payload.set;
      state.idOfItemToDelete = action.payload.id;
    },
    setDeleteArticleWarningOpen(
      state,
      action: PayloadAction<{ set: boolean; id: string }>
    ) {
      state.deleteArticleWarningOpen = action.payload.set;
      state.idOfItemToDelete = action.payload.id;
    },
    setDeletePaymentTermWarningOpen(
      state,
      action: PayloadAction<{ set: boolean; id: string }>
    ) {
      state.deletePaymentTermWarningOpen = action.payload.set;
      state.idOfItemToDelete = action.payload.id;
    },
    setDeleteSupplierWarningOpen(
      state,
      action: PayloadAction<{ set: boolean; id: string }>
    ) {
      state.deleteSupplierWarningOpen = action.payload.set;
      state.idOfItemToDelete = action.payload.id;
    },
    setDeleteOrderWarningOpen(
      state,
      action: PayloadAction<{ set: boolean; id: string }>
    ) {
      state.deleteOrderWarningOpen = action.payload.set;
      state.idOfItemToDelete = action.payload.id;
    },
    setDeleteRoleWarningOpen(
      state,
      action: PayloadAction<{ set: boolean; id: string }>
    ) {
      state.deleteRoleWarningOpen = action.payload.set;
      state.idOfItemToDelete = action.payload.id;
    },
    setDeletePosteWarningOpen(
      state,
      action: PayloadAction<{ set: boolean; id: string }>
    ) {
      state.deletePosteWarningOpen = action.payload.set;
      state.idOfItemToDelete = action.payload.id;
    },
    setDeletePerimeterWarningOpen(
      state,
      action: PayloadAction<{ set: boolean; id: string }>
    ) {
      state.deletePerimeterWarningOpen = action.payload.set;
      state.idOfItemToDelete = action.payload.id;
    },
    setDeleteCiviliteWarningOpen(
      state,
      action: PayloadAction<{ set: boolean; id: string }>
    ) {
      state.deleteCiviliteWarningOpen = action.payload.set;
      state.idOfItemToDelete = action.payload.id;
    },
    setDeletePoleWarningOpen(
      state,
      action: PayloadAction<{ set: boolean; id: string }>
    ) {
      state.deletePoleWarningOpen = action.payload.set;
      state.idOfItemToDelete = action.payload.id;
    },
    setDeleteProjectWarningOpen(
      state,
      action: PayloadAction<{ set: boolean; id: string }>
    ) {
      state.deleteProjectWarningOpen = action.payload.set;
      state.idOfItemToDelete = action.payload.id;
    },
    setDeleteInvoiceWarningOpen(
      state,
      action: PayloadAction<{ set: boolean; id: string }>
    ) {
      state.deleteInvoiceWarningOpen = action.payload.set;
      state.idOfItemToDelete = action.payload.id;
    },
    setDeletePaymentDateWarningOpen(
      state,
      action: PayloadAction<{ set: boolean; id: string }>
    ) {
      state.deletePaymentDateWarningOpen = action.payload.set;
      state.idOfItemToDelete = action.payload.id;
    },
    setIdOfItemToDelete(state, action: PayloadAction<string>) {
      state.idOfItemToDelete = action.payload;
    },
    setGenerateNewPasswordWarningOpen(
      state,
      action: PayloadAction<{ set: boolean; id: string }>
    ) {
      state.generateNewPasswordWarningOpen = action.payload.set;
      state.idOfUserToGeneratePasswordFor = action.payload.id;
    },
    setIdOfUserToGeneratePasswordFor(state, action: PayloadAction<string>) {
      state.idOfUserToGeneratePasswordFor = action.payload;
    },
    setUploadAvatarFormOpen(state, action: PayloadAction<boolean>) {
      state.uploadAvatarFormOpen = action.payload;
    },
    setAddOrderFormOpen(state, action: PayloadAction<boolean>) {
      state.addOrderFormOpen = action.payload;
    },
    setAddViewFormOpen(state, action: PayloadAction<boolean>) {
      state.addViewFormOpen = action.payload;
    },
  },
});

export const uiActions = uiSlice.actions;
export const uiReducer = uiSlice.reducer;
