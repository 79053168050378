import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Poste = {
  id: string;
  name: string;
  description: string;
  _id?: string; //<--- using this in edit/add user form
};

type InitialState = {
  selectedPosteToEdit: Poste | null;
  list: Poste[];
};

const initialState: InitialState = {
  selectedPosteToEdit: null,
  list: [],
};

const postesSlice = createSlice({
  name: "postes",
  initialState,
  reducers: {
    setList(state, action: PayloadAction<Poste[]>) {
      state.list = action.payload;
    },
    addPoste(state, action: PayloadAction<Poste>) {
      state.list.push(action.payload);
    },
    deletePoste(state, action: PayloadAction<string>) {
      // pauload: user id
      state.list = state.list.filter((poste) => poste.id !== action.payload);
    },
    editPoste(state, action: PayloadAction<{ id: string; poste: Poste }>) {
      state.list = state.list.map((poste) => {
        if (poste.id === action.payload.id) return action.payload.poste;
        return poste;
      });
    },
    selectPostetoEdit(state, action: PayloadAction<string>) {
      // payload: user id
      // NOTE: object spread doesnt deep copy objects
      state.selectedPosteToEdit = {
        ...state.list.find((poste) => poste.id === action.payload)!,
      };
    },
    clearSelectedPosteToEdit(state) {
      state.selectedPosteToEdit = null;
    },
  },
});

export const postesActions = postesSlice.actions;
export const postesReducer = postesSlice.reducer;
