import Toolbar from "@mui/material/Toolbar";
import { ReactNode } from "react";

import Main from "./Main.component";

type MainContentContainerProps = {
  open: boolean;
  children: ReactNode;
};

export default function MainContentContainer(props: MainContentContainerProps) {
  return (
    <Main open={props.open}>
      <Toolbar />
      {/* <Toolbar /> */}
      {props.children}
    </Main>
  );
}
