import { useAppDispatch } from "hooks/redux.hook";
import { uiActions } from "store/ui/ui.state";
import { rolesActions } from "store/roles/roles.state";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { RoleCardButton } from "../role-card-button/RoleCardButton.component";
import { BlueDot } from "components/blue-dot/BlueDot.component";
import type { Role } from "store/roles/roles.state";
import {
  makeFirstLetterCapital,
  translatePermissions,
} from "utils/stringOperations";
import { deleteRoleThunk } from "store/roles/roles.thunk";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks/redux.hook";
import { USER_ROLES } from "utils/roles";

type RoleCardProps = {
  role: Role;
};

export default function CompleteRoleCard(props: RoleCardProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { role } = props;
  const authenticatedUser = useAppSelector((state) => state.auth.user);

  const handleEditRole = () => {
    dispatch(rolesActions.selectRoleToEdit(role.id));
    dispatch(uiActions.setEditRoleFormOpen(true));
  };

  const handleDeleteRole = () => {
    // dispatch(deleteRoleThunk(role.id, navigate))
    dispatch(uiActions.setDeleteRoleWarningOpen({ set: true, id: role.id }));
    dispatch(rolesActions.selectRoleToDelete(role.id));
  };

  // console.log("role from complete role card ", role);

  const capitalizedRoleName = makeFirstLetterCapital(role ? role.name : "");

  return (
    <>
      <Paper
        sx={{ padding: "30px", overflow: "hidden", borderRadius: "6px" }}
        elevation={0}
      >
        <Typography
          sx={{
            marginBottom: "30px",
            color: "#181C32",
            fontWeight: "700",
            fontSize: "20px",
          }}
        >
          {capitalizedRoleName}
        </Typography>

        <Box
          sx={{
            marginBottom: "40px",
            "& li": {
              margin: "7px 0",
            },
          }}
        >
          <ul
            style={{
              listStyle: "none",
              margin: "0",
              padding: "0",
              fontWeight: "700",
              fontSize: "13px",
              color: "#B5B5C3",
              marginBottom: "20px",
            }}
          >
            <li>
              <BlueDot /> Utilisateurs:{" "}
              {role?.userManagment.length > 0
                ? translatePermissions(role?.userManagment)
                : "Aucun"}
            </li>
            <li>
              <BlueDot /> Fournisseurs:{" "}
              {role?.supplierManagment.length > 0
                ? translatePermissions(role?.supplierManagment)
                : "Aucun"}
            </li>
            <li>
              <BlueDot /> Rôles:{" "}
              {role?.roleManagment.length > 0
                ? translatePermissions(role?.roleManagment)
                : "Aucun"}
            </li>
            <li>
              <BlueDot /> Règles de gestion:{" "}
              {role?.managmentRules.length > 0
                ? translatePermissions(role?.managmentRules)
                : "Aucun"}
            </li>
            <li>
              <BlueDot /> Factures:{" "}
              {role?.billManagment.length > 0
                ? translatePermissions(role.billManagment)
                : "Aucun"}
            </li>
            <li>
              <BlueDot /> Bon de commande:{" "}
              {role?.orderManagment.length > 0
                ? translatePermissions(role.orderManagment)
                : "Aucun"}
            </li>
            <li>
              <BlueDot /> Connexion API:{" "}
              {role?.APIConnection.length > 0
                ? translatePermissions(role.APIConnection)
                : "Aucun"}
            </li>
          </ul>
        </Box>
        <Grid container gap="10px">
          <Grid item>
            <RoleCardButton
              variant="contained"
              onClick={handleEditRole}
              disabled={
                !(authenticatedUser!.role as Role).roleManagment.includes(
                  "update"
                )
              }
            >
              Modifier le rôle
            </RoleCardButton>
          </Grid>
          <Grid item>
            <RoleCardButton
              variant="contained"
              onClick={handleDeleteRole}
              disabled={
                !(authenticatedUser!.role as Role).roleManagment.includes(
                  "delete"
                )
              }
            >
              Supprimer le rôle
            </RoleCardButton>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
