import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { CustomTextField } from "components/custom-textfield/CustomTextField.component";
import Button from "@mui/material/Button";
import { useAppSelector, useAppDispatch } from "hooks/redux.hook";
import { useFormik } from "formik";
import * as Yup from "yup";
import { perimetersActions } from "store/perimeter/perimeters.state";
// import { v4 as uuidv4 } from "uuid";
import { addPerimeterThunk, editPerimeterThunk } from "store/perimeter/perimeters.thunk";
import { Role } from "store/roles/roles.state";
import BlueButton from "components/blue-button/BlueButton.component";
import GreenButton from "components/green-button/GreenButton";

type ErrorProp = boolean | undefined;

export default function Form() {
  const dispatch = useAppDispatch();
  const selectedPerimeterToEdit = useAppSelector(
    (state) => state.perimeters.selectedPerimeterToEdit
  );
  const isEditMode = selectedPerimeterToEdit !== null;
  const authenticatedUser = useAppSelector((state) => state.auth.user);

  const formik = useFormik({
    initialValues: {
      name:
        isEditMode && selectedPerimeterToEdit.name ? selectedPerimeterToEdit.name : "",
      description:
        isEditMode && selectedPerimeterToEdit.description
          ? selectedPerimeterToEdit.description
          : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Nom obligatoire"),
      description: Yup.string(),
    }),
    onSubmit: (values, helpers) => {
      // console.log(values);
      if (isEditMode) {
        // dispatch(postesActions.editPoste({ id: selectedPosteToEdit.id, poste: values as any }));
        dispatch(editPerimeterThunk({ id: selectedPerimeterToEdit.id, perimeter: values }));
        dispatch(perimetersActions.clearSelectedPerimeterToEdit());
      } else {
        // dispatch(postesActions.addPoste({id: uuidv4(), ...values}));
        dispatch(addPerimeterThunk(values));
      }
      helpers.resetForm();
    },
  });

  const showNameError = (formik.errors.name &&
    formik.touched.name) as ErrorProp;
  const showDescriptionError = (formik.errors.description &&
    formik.touched.description) as ErrorProp;

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing="12px" marginBottom="20px">
        <Grid item xs={12} md={4}>
          <Typography
            component="span"
            fontWeight="bold"
            sx={{ color: "#3F4254" }}
          >
            Nom du poste{" "}
            <Typography component="span" color="error">
              *
            </Typography>
          </Typography>
          <CustomTextField
            {...formik.getFieldProps("name")}
            error={showNameError}
            helperText={showNameError && formik.errors.name}
            placeholder="Nom du paramètre..."
            sx={{
              "& .MuiFilledInput-root": {
                background: "#fff",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            component="span"
            fontWeight="bold"
            sx={{ color: "#3F4254" }}
          >
            Description{" "}
          </Typography>
          <CustomTextField
            {...formik.getFieldProps("description")}
            error={showDescriptionError}
            helperText={showDescriptionError && formik.errors.description}
            placeholder="Description..."
            multiline
            maxRows={4}
            minRows={4}
            sx={{
              "& .MuiFilledInput-root": {
                background: "#fff",

                color: "#5E6278",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={4} alignSelf="">
          <GreenButton
            variant="contained"
            sx={{
              marginTop: "25px",
              display: isEditMode ? "none" : "inline",
              background: "#1DC894",
              boxShadow: "none",
              "&:hover": {
                background: "#1DC894",
                boxShadow: "none",
              },
            }}
            type="submit"
            disabled={
              !(authenticatedUser!.role as Role).managmentRules.includes(
                "create"
              )
            }
          >
            Ajouter
          </GreenButton>
          <BlueButton
            sx={{
              display: !isEditMode ? "none" : "inline",
              marginTop: "25px",
            }}
            variant="contained"
            type="submit"
            disabled={
              !(authenticatedUser!.role as Role).managmentRules.includes(
                "update"
              )
            }
          >
            Éditer
          </BlueButton>
        </Grid>
      </Grid>
    </form>
  );
}
