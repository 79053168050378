import { axiosInstance } from "api/axiosInstance";
import { feedbackMessageUIActions } from "store/feedback-message-ui/feedbackMessageUI.state";
import { AppDispatch } from "store/store.state";
import { ITva, tvasActions } from "./tva.state";


export function getTvasThunk() {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axiosInstance.get(
                "/tvas",
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                    },
                }
            );
            const tvas: ITva[] = response.data.data;
            dispatch(tvasActions.setList(tvas));
        } catch (e) {
            console.log(e);
        }
    };
}

export function addTvaThunk(tva: Omit<ITva, "id">) {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axiosInstance.post(
                "/tvas",
                tva,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                    },
                }
            );
            const completeTva = response.data.data;
            dispatch(tvasActions.addTva(completeTva));
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message: "Tva ajoutée avec succès",
                })
            );
        } catch (e) {
            console.log(e);
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message:
                        "Impossible d'ajouter Tva s'il vous plaît réessayer plus tard",
                })
            );
        }
    };
}

export function editTvaThunk(obj: { id: string; tva: Omit<ITva, "id"> }) {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axiosInstance.put(
                `/tvas/${obj.id}`,
                obj.tva,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                    },
                }
            );
            const editedTva = response.data.data;
            dispatch(tvasActions.editTva({ id: obj.id, tva: editedTva }));
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message: "Tva mis à jour avec succès",
                })
            );
        } catch (e) {
            console.log(e);
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message:
                        "Impossible de mettre à jour Tva s'il vous plaît réessayer plus tard",
                })
            );
        }
    };
}


export function deleteTvaThunk(id: string) {
    return async (dispatch: AppDispatch) => {
        try {
            const response: any = await axiosInstance.delete(`/tvas/${id}`, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                },
            });

            dispatch(tvasActions.deleteTva(id));
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message: "tva supprimée avec succès",
                })
            );
        } catch (e: any) {
            if (e.response.status === 409) {
                dispatch(
                    feedbackMessageUIActions.setState({
                        open: true,
                        message:
                            "Cette donnée est utilisée, vous ne pouvez pas la supprimer",
                    })
                );
            } else {
                dispatch(
                    feedbackMessageUIActions.setState({
                        open: true,
                        message:
                            "Impossible de supprimer tva s'il vous plaît réessayer plus tard",
                    })
                );
            }

        }
    };
}