import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IArticle {
    id: string;
    _id: string;
    nomArticle: string;
    TVA: number;
    HT: number;
    fournisseur: string;
};


export interface IArticles {
    selectedArticle: IArticle | null;
    list: IArticle[];
};


const initialState: IArticles = {
    selectedArticle: null,
    list: [],
};

const articlesSlice = createSlice({
    name: "articles",
    initialState: initialState,
    reducers: {
        setList(state, action: PayloadAction<IArticle[]>) {
            state.list = action.payload;
        },
        addArticle(state, action: PayloadAction<IArticle>) {
            state.list.push(action.payload);
        },
        deleteArticle(state, action: PayloadAction<string>) {
            state.list = state.list.filter((article) => article.id !== action.payload);
        },
        editArticle(state, action: PayloadAction<{ id: string; article: IArticle }>) {
            state.list = state.list.map((article) => {
                if (article.id === action.payload.id) return action.payload.article;
                return article;
            });
        },
        setSelectArticle(state, action: PayloadAction<IArticle>) {
            state.selectedArticle = action.payload
        },
        clearSelectedArticle(state) {
            state.selectedArticle = null;
        },
    },
});

export const articlesActions = articlesSlice.actions;
export const articlesReducer = articlesSlice.reducer;