import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { FieldInputProps } from "formik";
import CustomCheckbox from "components/custom-checkbox/CutomCheckbox.component"

type CheckBoxGroupProps = FieldInputProps<string[]> & { label: string };

export default function CheckBoxGroup({ label, ...props }: CheckBoxGroupProps) {
  return (
    <Grid
      container
      alignItems={{ sm: "center", xs: "flex-start" }}
      direction={{ sm: "row", xs: "column" }}
      sx={{ margin: "10px 0" }}
    >
      <Grid item flex="1" sx={{ paddingRight: "36px" }}>
        <Typography variant="body2" color="text.secondary">
          {label}
        </Typography>
      </Grid>
      <Grid item sx={{ marginRight: "5px" }}>
        <FormControlLabel
          componentsProps={{ typography: { color: "text.secondary" } }}
          control={
            <CustomCheckbox
              {...props}
              size="small"
              value="read"
              checked={props.value?.includes("read")}
              disableRipple
            />
          }
          label="Lecture"
        />
      </Grid>
      <Grid item sx={{ marginRight: "5px" }}>
        <FormControlLabel
          componentsProps={{ typography: { color: "text.secondary" } }}
          control={
            <CustomCheckbox
              {...props}
              size="small"
              value="update"
              checked={props.value?.includes("update")}
              disableRipple
            />
          }
          label="Écriture"
        />
      </Grid>
      <Grid item sx={{ marginRight: "5px" }}>
        <FormControlLabel
          componentsProps={{ typography: { color: "text.secondary" } }}
          control={
            <CustomCheckbox
              {...props}
              size="small"
              value="create"
              checked={props.value?.includes("create")}
              disableRipple
            />
          }
          label="Création"
        />
      </Grid>
      <Grid item sx={{ marginRight: "5px" }}>
        <FormControlLabel
          componentsProps={{ typography: { color: "text.secondary" } }}
          control={
            <CustomCheckbox
              {...props}
              size="small"
              value="delete"
              checked={props.value?.includes("delete")}
              disableRipple
            />
          }
          label="Suppression"
        />
      </Grid>
    </Grid>
  );
}
