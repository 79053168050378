import TextField, { TextFieldProps } from "@mui/material/TextField";
import { FilledInputProps } from "@mui/material";

export const CustomTextField = ({ sx, ...props }: TextFieldProps) => (
  <TextField
    fullWidth
    variant="filled"
    InputProps={{ disableUnderline: true } as Partial<FilledInputProps>}
    sx={{
      fontWeight: "500",
      fontSize: "14px",
      color: "#A1A5B7",
      "& .MuiFilledInput-root": {
        background: "#F5F8FA",
      },
      "& .MuiInputBase-multiline": {
        padding: "10px",
        fontWeight: "bold",
      },
      input: {
        padding: "13px 16px",
        fontWeight: "bold",
        color: "#A1A5B7",
      },
      ...sx,
    }}
    {...props}
  />
);
