import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ITva {
    id: string;
    _id: string;
    value: number,
};


export interface ITvas {
    selectedTva: ITva | null;
    list: ITva[];
};


const initialState: ITvas = {
    selectedTva: null,
    list: [],
};

const tvasSlice = createSlice({
    name: "tvas",
    initialState: initialState,
    reducers: {
        setList(state, action: PayloadAction<ITva[]>) {
            state.list = action.payload;
        },
        addTva(state, action: PayloadAction<ITva>) {
            state.list.push(action.payload);
        },
        deleteTva(state, action: PayloadAction<string>) {
            state.list = state.list.filter((tva) => tva.id !== action.payload);
        },
        editTva(state, action: PayloadAction<{ id: string; tva: ITva }>) {
            state.list = state.list.map((tva) => {
                if (tva.id === action.payload.id) return action.payload.tva;
                return tva;
            });
        },
        setSelectTva(state, action: PayloadAction<ITva>) {
            state.selectedTva = action.payload
        },
        clearSelectedTva(state) {
            state.selectedTva = null;
        },
    },
});

export const tvasActions = tvasSlice.actions;
export const tvasReducer = tvasSlice.reducer;