import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CustomDialogTitle from "components/custom-dialog-title/CustomDialogTitle.component";
import BlueButton from "components/blue-button/BlueButton.component";
import GrayButton from "components/gray-button/GrayButton.component";
import Divider from "@mui/material/Divider";
import Form from "./Form.component";
import List from "./List.component";
import { getCiviliteThunk } from "store/civilite/civilite.thunk";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import { useEffect } from "react";
import { uiActions } from "store/ui/ui.state";
import { deleteCiviliteThunk } from "store/civilite/civilite.thunk";

export default function Civilite() {
  const dispatch = useAppDispatch();
  const { deleteCiviliteWarningOpen, idOfItemToDelete } = useAppSelector(
    (state) => state.ui
  );

  const closeDeleteCiviliteWarning = () => {
    dispatch(uiActions.setDeleteCiviliteWarningOpen({ set: false, id: "" }));
  };

  useEffect(() => {
    dispatch(getCiviliteThunk());
  }, []);

  return (
    <>
      <Form />
      <Divider sx={{ marginBottom: "20px" }} />
      <List />

      <Dialog
        open={deleteCiviliteWarningOpen}
        onClose={closeDeleteCiviliteWarning}
        sx={{ "& .MuiPaper-root": { width: "500px" } }}
      >
        <CustomDialogTitle onClose={closeDeleteCiviliteWarning} id="">
          Suppression
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          Voulez-vous vraiment supprimer cette option ?
        </DialogContent>
        <DialogActions>
          <BlueButton
            onClick={() => {
              dispatch(deleteCiviliteThunk(idOfItemToDelete));
              dispatch(
                uiActions.setDeleteCiviliteWarningOpen({ set: false, id: "" })
              );
            }}
          >
            Oui
          </BlueButton>
          <GrayButton onClick={closeDeleteCiviliteWarning}>No</GrayButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
