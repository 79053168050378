import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";

import BlueButton from "components/blue-button/BlueButton.component";
import IconTextChip from "components/icon-text-chip/IconTextChip.component";
import GrayButton from "components/gray-button/GrayButton.component";
import NumberTextChip from "components/number-text-chip/NumberTextChip.component";
import CustomProgress from "components/cutom-progress/CutomProgress.component";
import Box from "@mui/material/Box";
import Duotone from "assets/Duotone.svg";
import TabButton from "components/tab-button/TabButton.component";
import { User } from "store/users/users.state";
import { Supplier } from "store/suppliers/suppliers.state";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import { calculateProfileStatus } from "utils/calculateProfileStatus";
import Position from "assets/Position.svg";
import at from "assets/at.svg";
import verified from "assets/verified.svg";
import ProfileAvatarBadge from "components/profile-avatar-badge/ProfileAvatarBadge.component";
// TODO:
// linear progress
import { uiActions } from "store/ui/ui.state";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";

export default function ProfileCard({settabDisplay, tabDisplay}) {
  const supplier = useAppSelector((state) => state.auth.user) as Supplier;
  const user = useAppSelector((state) => state.auth.user) as User;

  //   const suppliersNumber = useAppSelector(state => state.suppliers.list.length)
  const dispatch = useAppDispatch();

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          padding: "30px 30px 0 30px",
          marginBottom: "30px",
          borderRadius: "12px",
        }}
      >
        <Grid container gap="20px">
          <Grid item marginRight="10px">
            <ProfileAvatarBadge variant="dot">
              <Avatar
                title="Changer l'image du profil"
                sx={{
                  height: "160px",
                  width: "160px",
                  borderRadius: "10px",
                }}
                src={user.avatarUrl}
                onClick={() =>
                  dispatch(uiActions.setUploadAvatarFormOpen(true))
                }
              >
                <EmojiTransportationIcon sx={{ fontSize: "50px" }} />
              </Avatar>
            </ProfileAvatarBadge>
          </Grid>
          {/*<Grid item flex="1" minWidth="363px">
            <Typography
              fontWeight={700}
              fontSize="19px"
              lineHeight="22px"
              sx={{
                color: "#3F4254",
                display: "flex",
                alignItems: "center",
                gap: "7px",
              }}
            >
            {supplier.companyName}
              <Avatar src={verified} sx={{ height: "21px", width: "21px" }} />
            </Typography>
            <Box marginBottom="40px">
              <IconTextChip
                sx={{ marginRight: "36px" }}
                avatarSrc={Duotone}
                label="Fournisseur"
              />
              <IconTextChip
                sx={{ marginRight: "36px" }}
                avatarSrc={Position}
                label="Paris, 75"
              />
              <IconTextChip avatarSrc={at} label="artlineinstitut" />
            </Box>
            <NumberTextChip
              primaryText="$6000"
              secondaryText="Factures"
              sx={{ marginRight: "20px" }}
            />
            {/* <NumberTextChip primaryText={suppliersNumber.toString()} secondaryText="Fournisseurs" />
          </Grid>*/}
          {/* <Grid item>
            <Grid
              container
              justifyContent="space-between"
              height="100%"
              minHeight="100px"
              width="320px"
            >
              <Grid item>
                <GrayButton sx={{ padding: "12px 20px" }}>
                  Ajouter un Bon
                </GrayButton>
              </Grid>
              <Grid item>
                <BlueButton sx={{ padding: "12px 20px" }}>
                  Lier une facture
                </BlueButton>
              </Grid>
              <Grid item xs={12} marginLeft="12px" alignSelf="flex-end">
                <CustomProgress
                  text="Complétion Profil"
                  value={calculateProfileStatus(supplier)}
                />
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
        <Divider sx={{ margin: "20px 0 17px 0" }} />
        <TabButton isActive={tabDisplay === 0} sx={{ marginRight: "20px" }} onClick={()=> settabDisplay(0)}>
          Vue d'ensemble
        </TabButton>
        <TabButton isActive={tabDisplay === 1} sx={{ marginRight: "20px" }}  onClick={()=> settabDisplay(1)}>Documents </TabButton>
        <TabButton isActive={tabDisplay === 2} onClick={()=> settabDisplay(2)}>Réinitialiser le mot de passe </TabButton>
      </Paper>
    </>
  );
}
