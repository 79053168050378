import { Outlet, Navigate } from "react-router-dom";
import { useAppSelector } from "hooks/redux.hook";
import { USER_ROLES } from "utils/roles";
import * as ROUTES from "utils/routes";
import { Role } from "store/roles/roles.state";

export default function OrdersAccessController() {
  // TODO: extract role object from store
  // check if the roles property (which is an array of strings) includes "read" prmission

  const authenticatedUser = useAppSelector((state) => state.auth.user);

  let allowAccess = true;
  // if(authenticatedUser) {
  //   allowAccess = (authenticatedUser.role as Role).managmentRules.includes('read')
  // }

  return allowAccess ? <Outlet /> : <Navigate to={`/${ROUTES.DASHBOARD}`} />;
}
