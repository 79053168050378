import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "hooks/redux.hook";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CustomDialogTitle from "components/custom-dialog-title/CustomDialogTitle.component";
import BlueButton from "components/blue-button/BlueButton.component";
import GrayButton from "components/gray-button/GrayButton.component";
import Divider from "@mui/material/Divider";

import UDataGrid from "./components/data-grid/UDataGrid.component";
import AddUserForm from "./components/add-user-form/AddUserForm.component";
import EditUserForm from "./components/edit-user-form/EditUserForm.component";

import { uiActions } from "store/ui/ui.state";
import { getUsersThunk } from "store/users/users.thunk";
import { useNavigate } from "react-router-dom";
import { USER_ROLES } from "utils/roles";
import { getPostesThunk } from "store/postes/postes.thunk";
import { getCiviliteThunk } from "store/civilite/civilite.thunk";
import { getPoleThunk } from "store/pole/pole.thunk";
import {
  deleteUserThunk,
  generateNewPasswordForUser,
} from "store/users/users.thunk";
import WhiteButton from "components/white-button/WhiteButton.component";
import UsersDataGrid from "./components/data-grid/UsersDataGrid";
import UserToolbar from "./components/data-grid/UserToolbar";

export default function Users() {
  const navigate = useNavigate();
  // const role = useAppSelector((state) =>
  // state.auth.user ? state.auth.user.role : ""
  //);
  const dispatch = useAppDispatch();
  const {
    addUserFormOpen,
    editUserFormOpen,
    deleteUserWarningOpen,
    idOfItemToDelete,
    generateNewPasswordWarningOpen,
    idOfUserToGeneratePasswordFor,
  } = useAppSelector((state) => state.ui);

  const openAddUserForm = () => {
    dispatch(uiActions.setAddUserFormOpen(true));
  };
  const closeAddUserForm = () => {
    dispatch(uiActions.setAddUserFormOpen(false));
  };
  const openEditUserForm = () => {
    dispatch(uiActions.setEditUserFormOpen(true));
  };
  const closeEditUserForm = () => {
    dispatch(uiActions.setEditUserFormOpen(false));
  };

  const closeDeleteUserWarning = () => {
    dispatch(uiActions.setDeleteUserWarningOpen({ set: false, id: "" }));
  };

  const closeGenerateNewPasswordWarning = () => {
    dispatch(
      uiActions.setGenerateNewPasswordWarningOpen({ set: false, id: "" })
    );
  };

  return (
    <div style={{ padding: "48px 58px 0 57px " }}>
      <UserToolbar />
      <UsersDataGrid />
      <Dialog
        fullScreen
        open={addUserFormOpen}
        onClose={closeAddUserForm}
        sx={{ "& .MuiPaper-root": { height: "80vh", width: "35vw" },   "& .css-ypiqx9-MuiDialogContent-root": {
          padding: "25px 70px"
        } }}
      >
        <CustomDialogTitle onClose={closeAddUserForm} id="">
          Ajouter un utilisateur
        </CustomDialogTitle>
        <Divider />
        <DialogContent
          
        >
          <AddUserForm handleCloseAddDialog={closeAddUserForm} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={editUserFormOpen}
        onClose={closeEditUserForm}
        sx={{ "& .MuiPaper-root": { height: "80vh", width: "35vw" },   "& .css-ypiqx9-MuiDialogContent-root": {
          padding: "25px 70px"
        } }}
      >
        <CustomDialogTitle onClose={closeEditUserForm} id="">
          Modifier un utilisateur
        </CustomDialogTitle>
        <Divider />
        <DialogContent
          // sx={{
            // "& .css-ypiqx9-MuiDialogContent-root": {
            //   padding: "40px 40px"
            // }
          // }}
        >
          <EditUserForm handleCloseEditDialog={closeEditUserForm} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={deleteUserWarningOpen}
        onClose={closeDeleteUserWarning}
        sx={{ "& .MuiPaper-root": { width: "500px" } }}
      >
        <CustomDialogTitle onClose={closeDeleteUserWarning} id="">
          Suppression
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          Voulez-vous vraiment supprimer cet utilisateur?
        </DialogContent>
        <DialogActions>
          <BlueButton
            onClick={() => {
              dispatch(deleteUserThunk(idOfItemToDelete));
              dispatch(
                uiActions.setDeleteUserWarningOpen({ set: false, id: "" })
              );
            }}
          >
            Valider
          </BlueButton>
          <WhiteButton onClick={closeDeleteUserWarning}>Annuler</WhiteButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={generateNewPasswordWarningOpen}
        onClose={closeGenerateNewPasswordWarning}
        sx={{ "& .MuiPaper-root": { width: "500px" } }}
      >
        <CustomDialogTitle onClose={closeGenerateNewPasswordWarning} id="">
          Générer un nouveau mot de passe
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          Attention, cela va changer le mot de passe de cet utilisateur,
          voulez-vous continuer?
        </DialogContent>
        <DialogActions>
          <BlueButton
            onClick={() => {
              dispatch(
                generateNewPasswordForUser(idOfUserToGeneratePasswordFor)
              );
              dispatch(
                uiActions.setGenerateNewPasswordWarningOpen({
                  set: false,
                  id: "",
                })
              );
            }}
          >
            Valider
          </BlueButton>
          <WhiteButton onClick={closeGenerateNewPasswordWarning}>
            Annuler
          </WhiteButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
