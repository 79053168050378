import { axiosInstance } from "api/axiosInstance";
import {
    DataGridStateEnum,
    IDataGridState,
} from "store/datagrid-state/dataGridState.state";

let today = new Date();
const month = today.getMonth() <= 11 ? today.getMonth() + 1 : today.getMonth();
export const defaultTodayDate = month <= 9 ? `${today.getFullYear()}-0${month}-${today.getDate()}` : `${today.getFullYear()}-${month}-${today.getDate()}` ;

export const validDate = (date: string) => {
    if(date.includes("/")){
        const dateSplit = date.split("/");
        if(dateSplit[0]?.length === 1 && dateSplit[1]?.length === 1 ){
            const validateDate = `${dateSplit[2]}-0${dateSplit[1]}-0${dateSplit[0]}`;
            return validateDate;
        }
        if(dateSplit[0]?.length !== 1 && dateSplit[1]?.length !== 1 ){
            const validateDate = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;
            return validateDate;
        }
        if(dateSplit[0]?.length === 1 && dateSplit[1]?.length !== 1){
            const validateDate = `${dateSplit[2]}-${dateSplit[1]}-0${dateSplit[0]}`;
            return validateDate;
        }
        if(dateSplit[0]?.length !== 1 && dateSplit[1]?.length === 1 ){
            const validateDate = `${dateSplit[2]}-0${dateSplit[1]}-${dateSplit[0]}`;
            return validateDate;
        }
    } else {
        const dateSplit = date.split("-");
        if(dateSplit[2]?.length === 1 && dateSplit[1]?.length === 1 ){
            const validateDate = `${dateSplit[0]}-0${dateSplit[1]}-0${dateSplit[2]}`;
            return validateDate;
        }
        if(dateSplit[2]?.length !== 1 && dateSplit[1]?.length !== 1 ){
            const validateDate = `${dateSplit[0]}-${dateSplit[1]}-${dateSplit[2]}`;
            return validateDate;
        }
        if(dateSplit[2]?.length === 1 && dateSplit[1]?.length !== 1){
            const validateDate = `${dateSplit[0]}-${dateSplit[1]}-0${dateSplit[2]}`;
            return validateDate;
        }
        if(dateSplit[2]?.length !== 1 && dateSplit[1]?.length === 1 ){
            const validateDate = `${dateSplit[0]}-0${dateSplit[1]}-${dateSplit[2]}`;
            return validateDate;
        }
    }
};

export const createDefaultView = async (
    dataGridName: DataGridStateEnum,
    colState: any[]
) => {
    const defaultView = {
        dataGridName,
        viewName: "par defaut",
        colState,
        favorite: false,
    };

    try {
        const createdView: IDataGridState = await axiosInstance.post(
            "datagridstates",
            defaultView
        );
    } catch (error) {
        console.log(error);
    }
};
