import { axiosInstance } from "api/axiosInstance";
import { feedbackMessageUIActions } from "store/feedback-message-ui/feedbackMessageUI.state";
import { AppDispatch } from "store/store.state";
import { IArticle, articlesActions } from "./article.state";


export function getArticlesThunk() {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axiosInstance.get(
                "/articles",
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                    },
                },
            );
            const articles: IArticle[] = response.data.data;
            dispatch(articlesActions.setList(articles));
        } catch (e) {
            console.log(e);
        }
    };
}

export function addArticleThunk(article: Omit<IArticle, "id">) {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axiosInstance.post(
                "/articles",
                article,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                    },
                },
            );
            const completeArticle = response.data.data;
            dispatch(articlesActions.addArticle(completeArticle));
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message: "Article ajouté avec succès",
                })
            );
        } catch (e) {
            console.log(e);
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message:
                        "Impossible d'ajouter Article s'il vous plaît réessayer plus tard",
                })
            );
        }
    };
}

export function editArticleThunk(obj: { id: string; article: Omit<IArticle, "id"> }) {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axiosInstance.put(
                `/articles/${obj.id}`,
                obj.article,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                    },
                },
            );
            const editedArticle = response.data.data;
            dispatch(articlesActions.editArticle({ id: obj.id, article: editedArticle }));
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message: "Article mis à jour avec succès",
                })
            );
        } catch (e) {
            console.log(e);
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message:
                        "Impossible de mettre à jour Article s'il vous plaît réessayer plus tard",
                })
            );
        }
    };
}


export function deleteArticleThunk(id: string) {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axiosInstance.delete(`/articles/${id}`, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                },
            },);
            dispatch(articlesActions.deleteArticle(id));
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message: "Article supprimé avec succès",
                })
            );
        } catch (e: any) {
            if (e.response.status === 409) {
                dispatch(
                    feedbackMessageUIActions.setState({
                        open: true,
                        message:
                            "Cette donnée est utilisée, vous ne pouvez pas la supprimer",
                    })
                );
            } else {
                dispatch(
                    feedbackMessageUIActions.setState({
                        open: true,
                        message:
                            "Impossible de supprimer article s'il vous plaît réessayer plus tard",
                    })
                );
            }

        }
    };
}