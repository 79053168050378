import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Supplier } from "store/suppliers/suppliers.state";
import { User } from "store/users/users.state";

export interface IDataGridState {
    id: string;
    _id: string;
    dataGridName: DataGridStateEnum,
    colState: any[],
    viewName: string,
    favorite: boolean,
    user: User | Supplier
};

export enum DataGridStateEnum {
    Invoice = "Invoice",
    Order = "Order",
    Supplier = "Supplier",
    User = "User",
}


export interface IDataGridStates {
    selectedDataGridStateInvoiceToEdit: IDataGridState | null;
    selectedDataGridStateInvoiceFavorite: IDataGridState | null;
    selectedDataGridStateInvoice: IDataGridState | null;
    selectedDataGridStateInvoiceDefault: IDataGridState | null;

    selectedDataGridStateOrder: IDataGridState | null;
    selectedDataGridStateOrderFavorite: IDataGridState | null;
    selectedDataGridStatOrderDefault: IDataGridState | null;
    selectedDataGridStateOrderToEdit: IDataGridState | null;

    selectedDataGridStateSupplier: IDataGridState | null;
    selectedDataGridStateSupplierFavorite: IDataGridState | null;
    selectedDataGridStateSupplierDefault: IDataGridState | null;
    selectedDataGridStateSupplierToEdit: IDataGridState | null;

    selectedDataGridStateUser: IDataGridState | null;
    selectedDataGridStateUserFavorite: IDataGridState | null;
    selectedDataGridStatUserDefault: IDataGridState | null;
    selectedDataGridStateUserToEdit: IDataGridState | null;


    list: IDataGridState[];
};


const initialState: IDataGridStates = {
    selectedDataGridStateInvoiceToEdit: null,
    selectedDataGridStateInvoiceDefault: null,
    selectedDataGridStateInvoiceFavorite: null,
    selectedDataGridStateInvoice: null,

    selectedDataGridStateOrder: null,
    selectedDataGridStateOrderFavorite: null,
    selectedDataGridStatOrderDefault: null,
    selectedDataGridStateOrderToEdit: null,

    selectedDataGridStateSupplier: null,
    selectedDataGridStateSupplierFavorite: null,
    selectedDataGridStateSupplierDefault: null,
    selectedDataGridStateSupplierToEdit: null,

    selectedDataGridStateUser: null,
    selectedDataGridStateUserFavorite: null,
    selectedDataGridStatUserDefault: null,
    selectedDataGridStateUserToEdit: null,

    list: [],
};

const DataGridStatesSlice = createSlice({
    name: "DataGridStates",
    initialState: initialState,
    reducers: {
        setList(state, action: PayloadAction<IDataGridState[]>) {
            state.list = action.payload;
        },
        addDataGridState(state, action: PayloadAction<IDataGridState>) {
            state.list.push(action.payload);
        },
        deleteDataGridState(state, action: PayloadAction<string>) {
            state.list = state.list.filter((tva) => tva.id !== action.payload);
        },
        editDataGridState(state, action: PayloadAction<{ id: string; dataGridState: IDataGridState }>) {
            state.list = state.list.map((DataGridState) => {
                if (DataGridState.id === action.payload.id) return action.payload.dataGridState;
                return DataGridState;
            });
        },
        setSelectDataGridStateInvoiceToEdit(state, action: PayloadAction<IDataGridState>) {
            state.selectedDataGridStateInvoiceToEdit = action.payload
        },
        setSelectDataGridStateOrderToEdit(state, action: PayloadAction<IDataGridState>) {
            state.selectedDataGridStateOrderToEdit = action.payload
        },
        setSelectDataGridStateSupplierToEdit(state, action: PayloadAction<IDataGridState>) {
            state.selectedDataGridStateSupplierToEdit = action.payload
        },
        setSelectDataGridStateUserToEdit(state, action: PayloadAction<IDataGridState>) {
            state.selectedDataGridStateUserToEdit = action.payload
        },
        setSelectDataGridStateInvoiceFavorite(state, action: PayloadAction<IDataGridState>) {
            state.selectedDataGridStateInvoiceFavorite = action.payload
        },
        setSelectDataGridStateInvoiceDefault(state, action: PayloadAction<IDataGridState>) {
            state.selectedDataGridStateInvoiceDefault = action.payload
        },
        setSelectDataGridStateOrderFavorite(state, action: PayloadAction<IDataGridState>) {
            state.selectedDataGridStateOrderFavorite = action.payload
        },
        setSelectDataGridStateSupplierFavorite(state, action: PayloadAction<IDataGridState>) {
            state.selectedDataGridStateSupplierFavorite = action.payload
        },
        setSelectDataGridStateUserFavorite(state, action: PayloadAction<IDataGridState>) {
            state.selectedDataGridStateUserFavorite = action.payload
        },
        setSelectDataGridStateOrderDefault(state, action: PayloadAction<IDataGridState>) {
            state.selectedDataGridStatOrderDefault = action.payload
        },
        setSelectDataGridStateSupplierDefault(state, action: PayloadAction<IDataGridState>) {
            state.selectedDataGridStateSupplierDefault = action.payload
        },
        setSelectDataGridStateUserDefault(state, action: PayloadAction<IDataGridState>) {
            state.selectedDataGridStatUserDefault = action.payload
        },
        setSelectDataGridStateOrder(state, action: PayloadAction<IDataGridState>) {
            state.selectedDataGridStateOrder = action.payload
        },
        setSelectDataGridStateInvoice(state, action: PayloadAction<IDataGridState>) {
            state.selectedDataGridStateInvoice = action.payload
        },
        setSelectDataGridStateSupplier(state, action: PayloadAction<IDataGridState>) {
            state.selectedDataGridStateSupplier = action.payload
        },
        setSelectDataGridStateUser(state, action: PayloadAction<IDataGridState>) {
            state.selectedDataGridStateUser = action.payload
        },
        clearSelectedDataGridState(state) {
            state.selectedDataGridStateOrderToEdit = null;
            state.selectedDataGridStateInvoiceToEdit = null;
            state.selectedDataGridStateUserToEdit = null;
            state.selectedDataGridStateSupplierToEdit = null;
        },
        clearSelectedDataGridStateInvoice(state) {
            state.selectedDataGridStateInvoice = null;
        },
        clearSelectedDataGridStateOrder(state) {
            state.selectedDataGridStateOrder = null;
        },
        clearSelectedDataGridStateSupplier(state) {
            state.selectedDataGridStateSupplier = null;
        },
        clearSelectedDataGridStateUser(state) {
            state.selectedDataGridStateUser = null;
        },
    },
});

export const DataGridStatesActions = DataGridStatesSlice.actions;
export const DataGridStatesReducer = DataGridStatesSlice.reducer;