import { Typography, Grid } from "@mui/material"
import BlueButton from "components/blue-button/BlueButton.component"
import { CustomTextField } from "components/custom-textfield/CustomTextField.component"
import GreenButton from "components/green-button/GreenButton"
import { Role } from "store/roles/roles.state"
import { useAppSelector, useAppDispatch } from "hooks/redux.hook";
import {addSessionThunk, editSessionThunk} from 'store/session/session.thunk'
import { useFormik } from "formik";
import * as Yup from "yup";
import { sessionActions } from "store/session/session.state"

const Form = () => {
    const authenticatedUser = useAppSelector((state) => state.auth.user);
    const selectedSessionToEdit = useAppSelector(
      (state) => state.session.selectedSessionToEdit
    );
    const isEditMode = selectedSessionToEdit !== null;
    const dispatch = useAppDispatch()
    const formik = useFormik({
      initialValues: {
        name: isEditMode && selectedSessionToEdit.name ? selectedSessionToEdit.name : "",
      },
      enableReinitialize: true,
      validationSchema: Yup.object({
        name: Yup.string().required("Nom obligatoire"),
      }),
      onSubmit: (values, helpers) => {
        if(isEditMode){
          dispatch(editSessionThunk({ id: selectedSessionToEdit._id, session: values }))
          dispatch(sessionActions.clearSelectedSessionToEdit());
        }
        else
          dispatch(addSessionThunk(values))
        helpers.resetForm();
      }
    });


    return (<form onSubmit={formik.handleSubmit}>
        <Grid container spacing="12px" marginBottom="20px">
          <Grid item xs={12} md={4}>
            <Typography
              component="span"
              fontWeight="bold"
              sx={{ color: "#3F4254" }}
            >
              Périmètre du BDC{" "}
              <Typography component="span" color="error">
                *
              </Typography>
            </Typography>
            <CustomTextField
              {...formik.getFieldProps("name")}
              //error={showNameError}
              //helperText={showNameError && formik.errors.name}
              placeholder="Nom du paramètre..."
              sx={{
                "& .MuiFilledInput-root": {
                  background: "#fff",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <GreenButton
              variant="contained"
              sx={{
                marginTop: "25px",
                display: isEditMode ? "none" : "inline",
              }}
              type="submit"
              disabled={
                (!(authenticatedUser!.role as Role).managmentRules.includes(
                  "create"
                ) || formik.values.name.trim().length === 0)
              }
            >
              Ajouter
            </GreenButton>
            <BlueButton
              //sx={{ display: !isEditMode ? "none" : "inline", marginTop: "25px" }}
              variant="contained"
              type="submit"
              sx={{ display: !isEditMode ? "none" : "inline", marginTop: "25px" }}
              disabled={
                !(authenticatedUser!.role as Role).managmentRules.includes(
                  "update"
                )
              }
            >
              Editer
            </BlueButton>
          </Grid>
        </Grid>
      </form>)
}

export default Form