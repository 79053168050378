import ProfileCard from "./components/profile-card/ProfileCard.component"
import EditForm from "./components/edit-form/EditForm.component"
import ResetPasswordForm from "./components/reset-password-form/ResetPasswordForm.component"
import UploadAvatarForm from "./components/upload-avatar-dialog/UploadAvatarDialog.component"

export default function UserProfile() {
    return (
        <div style={{padding: "73px 67px 0 48px"}}>
            <ProfileCard/>
            <EditForm/>
            <ResetPasswordForm/>
            <UploadAvatarForm/>
        </div>
    )
}