import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";

import welcomeBg from "assets/welcome-bg.svg";
import logo from "assets/logo2.svg";

export default function Welcome() {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: `url(${welcomeBg}) left top no-repeat`,
        backgroundSize: "cover",
        backgroundColor: "#000619",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Container maxWidth="sm">
        <Paper
          sx={{
            padding: "30px",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <img src={logo} alt="Artline logo" height="120" />
          </div>
          <Typography variant="h4" fontWeight="700" align="center" gutterBottom>
            Hey BeHappy
          </Typography>
          <Typography align="center" color="text.secondary" marginBottom="20px">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Pellentesque fermentum dignissim mattis. Etiam nec vestibulum
            sapien.
          </Typography>
          <Box textAlign="center">
            <Button
              sx={{
                backgroundColor: "#FE7373",
                "&:hover": {
                  backgroundColor: "#FE7373",
                },
              }}
              variant="contained"
            >
              Se connecter
            </Button>
          </Box>
          <Typography variant="h4" gutterBottom sx={{textAlign: {
            xs: "center",
            sm: "left"
          }}}>
            What's next?
          </Typography>
          <Paper
            elevation={0}
            sx={{
              backgroundColor: "#F9F9F9",
            }}
          >
            <List>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar sx={{ background: "white", color: "#50CD89" }}>
                    1
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="h4">Vérifier votre profil</Typography>
                  }
                  secondary={
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.secondary"
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Pellentesque fermentum dignissim matti
                    </Typography>
                  }
                />
              </ListItem>

              <Divider
                sx={{
                  borderStyle: "dashed",
                }}
                variant="inset"
                component="li"
              />

              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar sx={{ background: "white", color: "#50CD89" }}>
                    2
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="h4">
                      Téléversement de vos factures
                    </Typography>
                  }
                  secondary={
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.secondary"
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Pellentesque fermentum dignissim matti
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Paper>
        </Paper>
      </Container>
    </Box>
  );
}
