const AG_GRID_LOCALE_FR = {
  contains: 'Contient',
  notContains: 'Ne contient pas',
  startsWith: 'Commence par',
  endsWith: 'Se termine par',
  // Filter Conditions
  andCondition: 'ET',
  orCondition: 'OU',
  // Filter Buttons
  applyFilter: 'Appliquer',
  resetFilter: 'Réinitialiser',
  clearFilter: 'Effacer',
  cancelFilter: 'Annuler',
  // Number Filter & Text Filter
  filterOoo: 'Filtre...',
  equals: 'Est égal à',
  notEqual: 'Non égal',
  blank: 'Vierge',
  notBlank: 'Non vierge',
  // Number Filter
  lessThan: 'Moins de',
  greaterThan: 'Supérieur à',
  lessThanOrEqual: 'Inférieur ou égal',
  greaterThanOrEqual: 'Greater than or equal',
  inRange: "Dans l'intervalle",
  inRangeStart: 'de',
  inRangeEnd: 'à',
}

export {
  AG_GRID_LOCALE_FR,
}