import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type TypeDepense = {
  name: string;
  _id: string; //<--- using this in edit/add user form 
};

type InitialState = {
  selectedDepenseToEdit: TypeDepense | null;
  list: TypeDepense[];
};

const initialState: InitialState = {
  selectedDepenseToEdit: null,
  list: [],
};

const depenseSlice = createSlice({
  name: "typedepenses",
  initialState,
  reducers: {
    setList(state, action: PayloadAction<TypeDepense[]>) {
      state.list = action.payload;
    },
    addDepense(state, action: PayloadAction<TypeDepense>) {
      state.list.push(action.payload);
    },
    deleteDepense(state, action: PayloadAction<string>) {
      // pauload: user id
      state.list = state.list.filter((depense) => depense._id !== action.payload);
    },
    editDepense(state, action: PayloadAction<{ id: string; depense: TypeDepense }>) {
      state.list = state.list.map((depense) => {
        if (depense._id === action.payload.id) return action.payload.depense;
        return depense;
      });
    },
    selectDepenseToEdit(state, action: PayloadAction<string>) {
      // payload: user id
      // NOTE: object spread doesnt deep copy objects
      state.selectedDepenseToEdit = {
        ...state.list.find((depense) => depense._id === action.payload)!,
      };
    },
    clearSelectedDepenseToEdit(state) {
      state.selectedDepenseToEdit = null;
    },
  },
});

export const depensesActions = depenseSlice.actions;
export const depensesReducer = depenseSlice.reducer;
