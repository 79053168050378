import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Order } from "store/orders/orders.state";
import { Civilite } from "store/civilite/civilite.state";
export type Supplier = {
  id: string;
  nom: string;
  prenom: string;
  civilite: any;
  email: string;
  socialReason: string;
  city: string;
  postalCode: string;
  address: string;
  siret: string;
  pays: string;
  siren: string;
  BIC: string;
  IBAN: string;
  RIB: string;
  ProofActivity: [string];
  tva: number;
  tel: string;
  mobile: string;
  status: string;
  role: string;
  purchaseOredrs?: Order[];
  //--------------------
};

export type Suppliers = {
  selectedSupplierToEdit: Supplier | null;
  list: Supplier[];
};

const initialState: Suppliers = {
  selectedSupplierToEdit: null,
  list: [],
};

const suppliersSlice = createSlice({
  name: "suppliers",
  initialState,
  reducers: {
    setList(state, action: PayloadAction<Supplier[]>) {
      state.list = action.payload;
    },
    addSupplier(state, action: PayloadAction<Supplier>) {
      state.list.push(action.payload);
    },
    deleteSupplier(state, action: PayloadAction<string>) {
      // payload: id
      state.list = state.list.filter(
        (supplier) => supplier.id !== action.payload
      );
    },
    editSupplier(
      state,
      action: PayloadAction<{ id: string; supplier: Supplier }>
    ) {
      state.list = state.list.map((supplier) => {
        if (supplier.id === action.payload.id) return action.payload.supplier;
        return supplier;
      });
    },
    editSupplierStatus(
      state,
      action: PayloadAction<{ id: string; status: "Confirmé" | "À confirmer" }>
    ) {
      state.list = state.list.map((supplier) => {
        if (supplier.id === action.payload.id) {
          return {
            ...supplier,
            status: action.payload.status,
          };
        } else return supplier;
      });
    },
    selectSupplierToEdit(state, action: PayloadAction<string>) {
      // payload: supplier id
      state.selectedSupplierToEdit = {
        ...state.list.find((supplier) => supplier.id === action.payload)!,
      };
    },
    clearSelectedSupplierToEdit(state) {
      state.selectedSupplierToEdit = null;
    },
  },
});

export const suppliersActions = suppliersSlice.actions;
export const suppliersReducer = suppliersSlice.reducer;
