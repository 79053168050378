import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Formik, Form, ErrorMessage, Field, FormikProvider } from "formik";

import { CustomTextField } from "components/custom-textfield/CustomTextField.component";
import { useAppSelector, useAppDispatch } from "hooks/redux.hook";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  addArticleThunk,
  editArticleThunk,
  getArticlesThunk,
} from "store/article/article.thunk";
import { articlesActions, IArticle } from "store/article/article.state";
import { Role } from "store/roles/roles.state";
import { useEffect } from "react";
import { Order } from "store/orders/orders.state";

type ErrorProp = boolean | undefined;

export default function ArticleForm(props) {
  const dispatch = useAppDispatch();
  const suppList = useAppSelector((state) => state.suppliers.list)!;
  const tvaList = useAppSelector((state) => state.tvas.list);
  const authenticatedUser = useAppSelector((state) => state.auth.user);
  const selectedArticle = useAppSelector(
    (state) => state.articles.selectedArticle
  )!;
  const isEditMode = selectedArticle !== null;

  const formik = useFormik({
    initialValues: {
      nomArticle:
        isEditMode && selectedArticle.nomArticle
          ? selectedArticle.nomArticle
          : "",
      HT: isEditMode && selectedArticle.HT ? selectedArticle.HT : "",
      TVA: isEditMode && selectedArticle.TVA ? selectedArticle.TVA : "",
      fournisseur: isEditMode && selectedArticle.fournisseur ? selectedArticle.fournisseur : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      nomArticle: Yup.string().required("Nom de l'article obligatoire"),
      HT: Yup.number().required("Coût unitaire HT obligatoire"),
      TVA: Yup.number().required("Tva obligatoire"),
      fournisseur: Yup.string(),
    }),
    onSubmit: (values, helpers) => {
      if (isEditMode) {
        dispatch(
          editArticleThunk({
            id: selectedArticle._id,
            article: values as IArticle,
          })
        );

        dispatch(getArticlesThunk());
        props.onSubmit()
      } else {
        dispatch(addArticleThunk(values as IArticle));
        props.onSubmit()
      }
      dispatch(articlesActions.clearSelectedArticle());

      helpers.resetForm();
    },
  });

  const showNameError = (formik.errors.nomArticle &&
    formik.touched.nomArticle) as ErrorProp;
  const showTVAError = (formik.errors.TVA && formik.touched.TVA) as ErrorProp;
  const showHTError = (formik.errors.HT && formik.touched.HT) as ErrorProp;
  const showFournisseurError = (formik.errors.fournisseur &&
    formik.touched.fournisseur) as ErrorProp;

  return (
    <div>
      <Typography variant="h3" sx={{ color: "#3F4254", marginBottom: "41px" }}>
        Articles
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <FormikProvider value={formik}>
          <Grid
            container
            direction="row"
            spacing={2}
            mb={5}
          >
            <Grid item xs={3}>
              <Typography component="span" fontWeight="bold">
                Nom de l'article {" "}
                <Typography component="span" color="error">
                  *
                </Typography></Typography>
              <Field
                render={() => (
                  <TextField
                    fullWidth
                    placeholder="Article ..."
                    {...formik.getFieldProps("nomArticle")}
                    variant="standard"
                    error={showNameError}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    type="text"
                    style={{
                      color: "black",
                      backgroundColor: "#fff",
                      padding: "1% 3%",
                      height: "42px",
                    }}
                  />
                )}
              />
              <FormHelperText error>
                {showNameError && formik.errors.nomArticle}
              </FormHelperText>
            </Grid>
            <Grid item xs={2}>
              <Typography component="span" fontWeight="bold">
                Coût unitaire HT {" "}
                <Typography component="span" color="error">
                  *
                </Typography>
              </Typography>
              <Field
                name="HT"
                render={() => (
                  <Field
                    fullWidth
                    {...formik.getFieldProps("HT")}
                    error={showHTError}
                    placeholder="0.00 €"
                    type="number"
                    style={{
                      height: "42px",
                      color: "#5E6278",
                      backgroundColor: "#F5F8FA",
                      border: "none",
                      paddingLeft: "4%",
                      width: '100%',
                    }}
                  />
                )}
              />
              <FormHelperText error>
                {showHTError && formik.errors.HT}
              </FormHelperText>
            </Grid>
            <Grid item xs={2}>
              <Typography component="span" fontWeight="bold">
                TVA {" "}
                <Typography component="span" color="error">
                  *
                </Typography>
              </Typography>

              <FormControl fullWidth error={showTVAError}>
                {/* <InputLabel id="tva">TVA</InputLabel> */}
                <Select
                  fullWidth
                  label="TVA"
                  labelId="tva"
                  id="tva-select"
                  {...formik.getFieldProps(`TVA`)}
                  disableUnderline={true}
                  sx={{
                    height: "40px",
                    fontWeight: "500",
                    padding: "4%",
                    borderRadius: "6px",
                    fontSize: "14px",
                    color: "#5E6278",
                    background: "#F5F8FA",
                    "&:before": {
                      border: "none",
                    },
                    "&:after": {
                      border: "none",
                    },
                  }}
                  variant="standard"
                >
                  {tvaList.map((tva) => (
                    <MenuItem key={tva._id} value={tva.value}>
                      {tva.value}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>
                  {showTVAError && formik.errors.TVA}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <Typography component="span" fontWeight="bold">
                Fournisseur{" "}
              </Typography>

              <FormControl
                fullWidth
              >
                <Select
                  {...formik.getFieldProps("fournisseur")}
                  onChange={formik.handleChange}
                  // label="civilitesss"
                  error={showFournisseurError}
                  disableUnderline={true}
                  fullWidth
                  sx={{
                    height: "40px",
                    fontWeight: "500",
                    padding: "4%",
                    borderRadius: "6px",
                    fontSize: "14px",
                    color: "#5E6278",
                    background: "#F5F8FA",
                    "&:before": {
                      border: "none",
                    },
                    "&:after": {
                      border: "none",
                    },
                  }}
                  variant="standard"
                >
                  {suppList.map((supp) => (
                    <MenuItem key={supp.id} value={supp.id}>
                      {supp.socialReason}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormHelperText error>
                {showFournisseurError &&
                  formik.errors.fournisseur?.toString()}
              </FormHelperText>
            </Grid>
            <Grid item xs={2} display="flex" container direction="column" alignContent="center" justifyItems="center" alignItems="center" justifyContent="center">
              <Typography fontWeight="bold">Action</Typography>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                sx={{
                  display: isEditMode ? "none" : "inline",
                }}
              >
                Ajouter
              </Button>
              <Button
                sx={{
                  display: !isEditMode ? "none" : "inline",
                }}
                variant="contained"
                type="submit"
                disabled={
                  !(authenticatedUser!.role as Role).managmentRules.includes(
                    "update"
                  )
                }
              >
                Éditer
              </Button>
            </Grid>
          </Grid>
        </FormikProvider>
      </form>
    </div>
  );
}
