import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import { SxProps } from "@mui/material";
import { Theme } from "@emotion/react";

type Props = {
  avatarSrc: string;
  label: string;
  sx?: SxProps<Theme>
};

export default function IconTextChip(props: Props) {
  return (
    <Chip
      avatar={<Avatar src={props.avatarSrc} />}
      label={props.label}
      variant="outlined"
      sx={{
        border: "none",
        "& .MuiChip-label": {
          fontSize: "14px",
          color: "#B5B5C3",
          fontWeight: 500,
          lineHeight: "16px",
          paddingRight: "0",
        },
        "& .MuiChip-avatar": {
          width: "14px",
          height: "14px",
        },
        ...props.sx,
      }}
    />
  );
}
