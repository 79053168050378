import { axiosInstance } from "api/axiosInstance";
import { AppDispatch } from "store/store.state";
import { Poste, postesActions } from "./postes.state";
import { feedbackMessageUIActions } from "store/feedback-message-ui/feedbackMessageUI.state"


type GetPostesResponse = {
  data: Poste[]
}

export function getPostesThunk() {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.get<GetPostesResponse>("/posts", {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      })
      const postes = response.data.data
      dispatch(postesActions.setList(postes))
    } catch (e) {
      console.log(e)
    }
  }
}

type AddPosteResponse = {
  data: Poste
}

export function addPosteThunk(poste: Omit<Poste, "id">) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.post<AddPosteResponse>("/posts", poste, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      })
      const completePoste = response.data.data
      dispatch(postesActions.addPoste(completePoste));
      dispatch(feedbackMessageUIActions.setState({ open: true, message: "Poste ajouté avec succès" }))
    } catch (e) {
      console.log(e)
      dispatch(feedbackMessageUIActions.setState({ open: true, message: "Impossible d'ajouter la position, veuillez réessayer plus tard" }))
    }
  };
}

export function deletePosteThunk(id: string) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.delete(`/posts/${id}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      })
      dispatch(postesActions.deletePoste(id));
      dispatch(feedbackMessageUIActions.setState({ open: true, message: "Poste supprimé avec succès" }))
    } catch (e: any) {
      if (e.response.status === 409) {
        dispatch(
          feedbackMessageUIActions.setState({
            open: true,
            message:
              "Cette donnée est utilisée, vous ne pouvez pas la supprimer",
          })
        );
      } else {
        dispatch(feedbackMessageUIActions.setState({ open: true, message: "Impossible de supprimer la position, veuillez réessayer plus tard" }))

      }
    }
  };
}

type EditPosteResponse = {
  data: Poste
}

export function editPosteThunk(obj: { id: string; poste: Omit<Poste, "id"> }) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.put<EditPosteResponse>(`/posts/${obj.id}`, obj.poste, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      })
      const editedPoste = response.data.data
      dispatch(postesActions.editPoste({ id: obj.id, poste: editedPoste }));
      dispatch(feedbackMessageUIActions.setState({ open: true, message: "Poste mise à jour avec succès" }))
    } catch (e) {
      console.log(e)
      dispatch(feedbackMessageUIActions.setState({ open: true, message: "Impossible de mettre à jour la position, veuillez réessayer plus tard" }))
    }
  };
}
