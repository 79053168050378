import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CustomDialogTitle from "components/custom-dialog-title/CustomDialogTitle.component";
import BlueButton from "components/blue-button/BlueButton.component";
import GrayButton from "components/gray-button/GrayButton.component";
import Divider from "@mui/material/Divider";

import { deletePosteThunk } from "store/postes/postes.thunk";

import { useEffect } from "react";
import Form from "./Form.component";
import List from "./List.component";
import { getPostesThunk } from "store/postes/postes.thunk";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import { uiActions } from "store/ui/ui.state";

export default function Postes() {
  const dispatch = useAppDispatch();
  const { deletePosteWarningOpen, idOfItemToDelete } = useAppSelector(
    (state) => state.ui
  );

  const closeDeletePosteWarning = () => {
    dispatch(uiActions.setDeletePosteWarningOpen({ set: false, id: "" }));
  };

  useEffect(() => {
    dispatch(getPostesThunk());
  }, []);

  return (
    <>
      <Form />
      <Divider sx={{ marginBottom: "20px" }} />
      <List />

      <Dialog
        open={deletePosteWarningOpen}
        onClose={closeDeletePosteWarning}
        sx={{ "& .MuiPaper-root": { width: "500px" } }}
      >
        <CustomDialogTitle onClose={closeDeletePosteWarning} id="">
          Suppression
        </CustomDialogTitle>
        <Divider />
        <DialogContent>Voulez-vous vraiment supprimer cette option ?</DialogContent>
        <DialogActions>
          <BlueButton
            onClick={() => {
              dispatch(deletePosteThunk(idOfItemToDelete));
              dispatch(
                uiActions.setDeletePosteWarningOpen({ set: false, id: "" })
              );
            }}
          >
            Oui
          </BlueButton>
          <GrayButton onClick={closeDeletePosteWarning}>No</GrayButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
