import React, { useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import {
  Avatar,
  Badge,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import VpnKeyIcon from "@mui/icons-material/VpnKey";

import { Role } from "store/roles/roles.state";
import Delete from "@mui/icons-material/Delete";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import EmailIcon from "@mui/icons-material/Email";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";
import { uiActions } from "store/ui/ui.state";
import { GridCheckCircleIcon } from "@mui/x-data-grid";
import CustomSideBar from "components/custom-sidebar/CustomSideBar";
import {
  DataGridStateEnum,
  DataGridStatesActions,
  IDataGridState,
} from "store/datagrid-state/dataGridState.state";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import {
  getOrdersByVendorIdThunk,
  getOrdersThunk,
} from "store/orders/orders.thunk";
import { getDatagridStateByUserIdThunk } from "store/datagrid-state/dataGridState.thunk";

import CustomDialogTitle from "components/custom-dialog-title/CustomDialogTitle.component";
import WhiteButton from "components/white-button/WhiteButton.component";
import AddView from "../add-view/AddView";
import { User, usersActions } from "store/users/users.state";
import ViewSettings from "../views-settings/ViewSettings";
import { AG_GRID_LOCALE_FR } from "utils/constants";
const UsersDataGrid = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<AgGridReact>(null);
  const rows = useAppSelector((state) => state.users.list);
  const accountType = useAppSelector((state) => state.auth.accountType)!;
  const authenticatedUser = useAppSelector((state) => state.auth.user)!;
  const selectedUser = useAppSelector(
    (state) => state.users.selectedUserToEdit
  );
  const [gridColumnApi, setGridColumnApi] = useState<any>();
  const [gridApi, setGridApi] = useState<any>();
  const [customSideBarOpen, setCustomSideBarOpen] = useState<boolean>(false);
  const statesList = useAppSelector((state) => state.dataGridStates.list);
  const selectedUserView: IDataGridState = useAppSelector(
    (state) => state.dataGridStates.selectedDataGridStateUser
  )!;
  const selectedUserFavoriteView: IDataGridState = useAppSelector(
    (state) => state.dataGridStates.selectedDataGridStateUserFavorite
  )!;
  const { addViewFormOpen, viewSettingsOpen } = useAppSelector(
    (state) => state.ui
  );
  const defaultView = {
    _id: "0",
    id: "0",
    dataGridName: DataGridStateEnum.User,
    favorite: false,
    viewName: "Par défaut",
    user: authenticatedUser,
    colState: gridRef?.current?.columnApi?.getColumnState() as any,
  };

  const [columnDefs] = useState([
    {
      headerName: "Actions",
      field: "id",
      sortable: false,
      resizable: false,
      cellRenderer: (params: any) => (
        <Grid container>
          <Grid item>
            <IconButton
              size="small"
              title="Suppression"
              sx={{ color: "#FE7373" }}
              disabled={
                params.data.id === authenticatedUser!.id ||
                !(authenticatedUser!.role as Role).userManagment.includes(
                  "delete"
                )
              }
              onClick={() => {
                dispatch(
                  uiActions.setDeleteUserWarningOpen({
                    set: true,
                    id: params.data.id,
                  })
                );
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              title="Edition"
              sx={{ color: "#00A3FF" }}
              disabled={
                // params.data.id === authenticatedUser!.id ||
                !(authenticatedUser!.role as Role).userManagment.includes(
                  "update"
                )
              }
              onClick={() => {
                dispatch(usersActions.selectUsertoEdit(params.data.id));
                dispatch(uiActions.setEditUserFormOpen(true));
              }}
            >
              <EditIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              title="Générer un nouveau mot de passe et l'envoyer par e-mail"
              sx={{ color: "#1DC894" }}
              disabled={params.data.id === authenticatedUser!.id}
              onClick={() => {
                dispatch(
                  uiActions.setGenerateNewPasswordWarningOpen({
                    set: true,
                    id: params.data.id,
                  })
                );
              }}
            >
              <VpnKeyIcon />
            </IconButton>
          </Grid>
        </Grid>
      ),
    },
    {
      field: "fullName",
      headerName: "Utilisateur",
      floatingFilter: true,
      filter: "agTextColumnFilter",

      filterParams: {
        buttons: ["apply", "clear", "cancel", "reset"],
      },
      cellRenderer: (params: any) => (
        <Grid container alignItems="center" flexWrap="nowrap" overflow="hidden">
          <Grid item marginRight="19px">
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
              // false: badge invisible, true: badge green
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: true ? "#50CD89" : "transparent",
                },
              }}
            >
              <Avatar
                src={params.data.avatarUrl}
                sx={{ width: "35px", height: "35px" }}
              />
            </Badge>
          </Grid>
          <Grid>
            <Typography
              fontWeight="700"
              fontSize="15px"
              lineHeight="18px"
              sx={{ color: "#3F4254", marginBottom: "5px", marginTop: "2px" }}
            >
              {params.data.lastName + " " + params.data.firstName}
            </Typography>
            <Typography
              fontWeight="500"
              fontSize="13px"
              lineHeight="15px"
              sx={{ color: "#B5B5C3", marginBottom: "2px" }}
            >
              {params.data.email}
            </Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: "role.name",
      headerName: "Rôle",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "clear", "cancel", "reset"],
      },
    },
    {
      field: "tel",
      headerName: "Téléphone",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "clear", "cancel", "reset"],
      },
    },
    {
      field: "civilite.name",
      headerName: "Civilité",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "clear", "cancel", "reset"],
      },
    },
    {
      field: "pole.name",
      headerName: "Pôle",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "clear", "cancel", "reset"],
      },
    },
    {
      field: "position.name",
      headerName: "Poste",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["apply", "clear", "cancel", "reset"],
      },
    },
    {
      field: "joinDate",
      headerName: "Date d'adhésion",
      floatingFilter: true,
    },
    {
      field: "lastLogin",
      headerName: "Dernière connexion",
    },
  ]);
  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      // enableRowGroup: true,
      // enablePivot: true,
      // enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);
  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useEffect(() => {
    if (accountType === "vendor") {
      dispatch(getOrdersByVendorIdThunk(authenticatedUser.id));
    } else {
      dispatch(getOrdersThunk());
    }
    getDatagridStateByUserIdThunk(authenticatedUser.id);
  }, []);

  useEffect(() => {
    if (selectedUserView?.colState.length === 0) {
      resetState();
      selectView(gridRef?.current?.columnApi?.getColumnState());
    } else {
      gridRef?.current?.columnApi?.applyColumnState({
        state: selectedUserView?.colState,
        applyOrder: true,
      });
    }

    // const favorite = statesList
    //   .filter(
    //     (view: IDataGridState) =>
    //       view.dataGridName === DataGridStateEnum.Invoice
    //   )
    //   .every((view: IDataGridState) => view.favorite === false);
    //   console.log(favorite)
    // if (favorite) {
    //   gridRef?.current?.columnApi?.resetColumnState();
    //   selectView(gridRef?.current?.columnApi?.getColumnState());
    // }
  }, [selectedUserView]);

  const onRowClicked = (e: any) => {
    const selectedRow: User = e.data;
    dispatch(usersActions.selectUsertoEdit(selectedRow.id));
  };
  const resetState = () => {
    gridRef?.current?.columnApi?.resetColumnState();
  };

  const selectView = (colState: any) => {
    gridRef?.current?.columnApi?.applyColumnState({
      state: colState,
      applyOrder: true,
    });
  };

  useEffect(() => {
    if (!addViewFormOpen) {
      selectedUserView ? selectView(selectedUserView?.colState) : resetState();
    }
  }, []);

  const closeAddViewform = () => {
    dispatch(uiActions.setAddViewFormOpen(false));
  };
  const closeSettingsViewform = () => {
    dispatch(uiActions.setViewSettingsOpen(false));
  };

  const rowStyle = { height: "50px" };
  return (
    <div style={{ width: "100%" }}>
      <Grid container direction="row" alignItems="stretch" mb={3}>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={6}>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <FormControl style={{
                  width: "30%",
                  background: "#e6f4ff",
                  borderRadius: "5px"
                }}>
                  <InputLabel
                    style={{
                      background: "#e6f4ff",
                      padding: "1px",
                      color: "black"
                    }}
                    id="demo-simple-select-label">
                    Sélectionner une vue
                  </InputLabel>
                  <Select
                    // disableUnderline={true}
                    placeholder="Sélectionner une vue"
                    sx={{
                      boxShadow: "none",
                      ".MuiOutlinedInput-notchedOutline": { border: "0 #e3d3f2", },
                    }}
                    value={selectedUserView?._id ?? defaultView._id}
                    style={{
                      height: "30px",
                      margin: "6px",
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                  >
                    <MenuItem
                      key={defaultView._id}
                      value={defaultView._id}
                      style={{ height: "40px" }}
                      onClick={() => {
                        resetState();
                        dispatch(
                          DataGridStatesActions.clearSelectedDataGridStateUser()
                        );
                      }}
                    >
                      {" "}
                      <Checkbox
                        checked={defaultView.favorite}
                        icon={<FavoriteBorder />}
                        checkedIcon={<Favorite />}
                      />
                      {defaultView.viewName}{" "}
                    </MenuItem>
                    {statesList
                      .filter(
                        (view: IDataGridState) =>
                          view.dataGridName === DataGridStateEnum.User
                      )
                      .map((view: IDataGridState) => {
                        return (
                          <MenuItem
                            key={view._id}
                            value={view._id}
                            style={{ height: "40px" }}
                            onClick={() => {
                              selectView(view?.colState);
                              dispatch(
                                DataGridStatesActions.setSelectDataGridStateUser(
                                  view
                                )
                              );
                            }}
                          >
                            {" "}
                            <Checkbox
                              checked={view.favorite}
                              icon={<FavoriteBorder />}
                              checkedIcon={<Favorite />}
                            />
                            {view.viewName}{" "}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <IconButton onClick={() => {
                  dispatch(uiActions.setViewSettingsOpen(true));
                }} aria-label="settings" color="default">
                  <SettingsIcon

                  />
                </IconButton>
                <IconButton
                  onClick={() => {
                    dispatch(uiActions.setAddViewFormOpen(true));
                  }}
                  aria-label="sauvegarder"
                  color="primary"
                >
                  <SaveIcon />
                </IconButton>
                <Button
                  sx={{
                    boxShadow: "none",
                    textTransform: "none",
                    backgroundColor: "#1DC894",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#1DC894",
                    },
                  }}
                  variant="contained"
                  onClick={() => dispatch(uiActions.setAddUserFormOpen(true))}
                  disabled={
                    !(authenticatedUser!.role as Role).userManagment.includes("create")
                  }
                  title="Ajouter un utilisateur"
                >
                  Ajouter
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={!customSideBarOpen ? 11.5 : 10}
          justifyContent="center"
          alignItems="center"
          className="ag-theme-alpine"
          mt={1}
          style={{ height: "75vh" }}
        >
          <AgGridReact
            ref={gridRef}
            localeText={AG_GRID_LOCALE_FR}
            rowData={rows}
            rowStyle={rowStyle}
            onGridReady={onGridReady}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            onRowClicked={(e) => onRowClicked(e)}
          ></AgGridReact>
        </Grid>
        <Grid item xs={!customSideBarOpen ? 0.5 : 2} style={{ height: "75vh", overflow: "hidden" }} mt={1}>
          <CustomSideBar
            gridColumnApi={gridColumnApi}
            setCustomSideBarOpen={setCustomSideBarOpen}
          />
        </Grid>
      </Grid>

      {/* add view dialog */}
      <Dialog
        fullScreen
        open={addViewFormOpen}
        onClose={closeAddViewform}
        sx={{ "& .MuiPaper-root": { height: "25vh", width: "40vw" } }}
        maxWidth="lg"
      >
        <CustomDialogTitle onClose={closeAddViewform} id="">
          Sauvgarder la vue
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          <AddView gridRef={gridRef} closeAddViewform={closeAddViewform} />
        </DialogContent>
      </Dialog>

      {/* settings view dialog */}
      <Dialog
        fullScreen
        open={viewSettingsOpen}
        onClose={closeSettingsViewform}
        sx={{ "& .MuiPaper-root": { height: "85vh", width: "30vw" } }}
        maxWidth="lg"
      >
        <CustomDialogTitle onClose={closeSettingsViewform} id="">
          Liste des vues disponible
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          <ViewSettings
            views={statesList.filter(
              (view: IDataGridState) =>
                view.dataGridName === DataGridStateEnum.User
            )}
          />
        </DialogContent>
        <DialogActions>
          <WhiteButton onClick={closeSettingsViewform}>Annuler</WhiteButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UsersDataGrid;
