import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import AddIcon from '@mui/icons-material/Add';

import addNewRole from "assets/addNewRole.png"

import { useAppDispatch } from "hooks/redux.hook";
import { uiActions } from "store/ui/ui.state";
import Avatar from "@mui/material/Avatar";

export default function AddNewRoleCard() {
  const dispatch = useAppDispatch()
  return (
    <Paper
      sx={{
        padding: "20px",
        cursor: "pointer",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "280px"
      }}
      elevation={0}
      onClick={() => dispatch(uiActions.setAddRoleFormOpen(true))}
    >
      <Avatar src={addNewRole} sx={{width: "120px", height: "120px"}} />
      <Typography
        align="center"
        variant="h4"
        sx={{
          color: "#A1A5B7",
          fontWeight: 700
        }}
      >
        Ajouter un nouveau rôle
      </Typography>
    </Paper>
  );
}
