import Button, { ButtonProps } from "@mui/material/Button";
import styled from "@emotion/styled";

// const BlueButton = styled(Button)((props) => ({
//   textTransform: "none",
//   background: "#00A3FF",
//   color: "#fff",
//   boxShadow: "none",
//   fontSize: "15px",
//   fontWeight: "700",
//   lineHeight: "18px",
//   "&:hover": {
//     background: "#00A3FF",
//     boxShadow: "none",
//   },
// }));

const BlueButton = ({ sx, ...props }: ButtonProps) => (
  <Button
    {...props}
    sx={{
      textTransform: "none",
      background: "#00A3FF",
      color: "#fff",
      boxShadow: "none",
      fontSize: "15px",
      fontWeight: "700",
      lineHeight: "18px",
      padding: "12px 20px",
      "&:hover": {
        background: "#00A3FF",
        boxShadow: "none",
      },
      ...sx
    }}
  />
);

export default BlueButton;
