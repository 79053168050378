import {useEffect} from 'react'
import { Dialog, DialogActions, DialogContent, Divider } from '@mui/material'
import BlueButton from 'components/blue-button/BlueButton.component'
import CustomDialogTitle from 'components/custom-dialog-title/CustomDialogTitle.component'
import GrayButton from 'components/gray-button/GrayButton.component'
import { useAppDispatch, useAppSelector } from 'hooks/redux.hook'
import { deleteCostThunk, getCostsThunk } from 'store/pole/pole.thunk'
import { uiActions } from 'store/ui/ui.state'
import Form from './components/Form.component'
import List from './components/List.component'
import { deleteDepenseThunk, getDepenseThunk } from 'store/typedepenses/typedepenses.thunk'

const TypeDepense = () => {
    const dispatch = useAppDispatch()
    const closeDepenseCostWarning = () => {
        dispatch(uiActions.setDeleteDepenseWarningOpen({ set: false, id: "" }));
      };
    const { idOfItemToDelete, deleteDepenseWarningOpen } = useAppSelector(
        (state) => state.ui
      );
    useEffect(() => {dispatch(getDepenseThunk())}, [])
    return (
    <>
        <Form />
        <Divider />
        <List />
        <Dialog
        open={deleteDepenseWarningOpen}
        onClose={closeDepenseCostWarning}
        sx={{ "& .MuiPaper-root": { width: "500px" } }}
      >
        <CustomDialogTitle onClose={closeDepenseCostWarning} id="">
          Suppression
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          Voulez-vous vraiment supprimer cette option ?
        </DialogContent>
        <DialogActions>
          <BlueButton
            onClick={() => {
              dispatch(deleteDepenseThunk(idOfItemToDelete));
              dispatch(
                uiActions.setDeleteDepenseWarningOpen({ set: false, id: "" })
              );
            }}
          >
            Oui
          </BlueButton>
          <GrayButton onClick={closeDepenseCostWarning}>No</GrayButton>
        </DialogActions>
      </Dialog>
    </>

    )
}

export default TypeDepense