export const rows = [
  {
    id: 1,
    name: "Poste",
    description: "Les postes de travail",
  },
  {
    id: 2,
    name: "Civilité",
    description: "Civilités",
  },
  {
    id: 3,
    name: "Pôle",
    description: "Pôles",
  },
  {
    id: 4,
    name: "TVA",
    description: "TVA",
  },
  {
    id: 5,
    name: "Condition de paiement",
    description: "Condition de paiement",
  },
  {
    id: 6,
    name: "Articles",
    description: "Articles",
  },
  // {
  //   id: 7,
  //   name: "Date de paiement",
  //   description: "Date(s) de paiement",
  // },
  {
    id: 7,
    name: "Type de dépense",
    description: "Type de dépense",
  },
  {
    id: 8,
    name: "Produit",
    description: "Produit",
  },
  {
    id: 9,
    name: "Projets",
   description: "Projet(s)",
  }, 
  {
    id: 10,
    name: "Périmètre du BDC",
    description: "Périmètre du BDC",
  }, 

];
