import { axiosInstance } from "api/axiosInstance";
import { AppDispatch } from "store/store.state";
import { INotification, notificationsActions } from "./notifications.state";



export function getNotificationsThunk() {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axiosInstance.get(
                "/notifications",
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                    },
                }
            );
            const notifications: INotification[] = response.data.data;
            dispatch(notificationsActions.setList(notifications));
        } catch (e) {
            console.log(e);
        }
    };
}

export function getNotificationsSeenThunk() {
    return async (dispatch: AppDispatch) => {
        try {
            await axiosInstance.put(
                "/notifications/all/seen",
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                    },
                }
            );
        } catch (e) {
            console.log(e);
        }
    };
}

export function deleteNotificationsThunk(id: string) {
    return async (dispatch: AppDispatch) => {
        try {
            await axiosInstance.delete(
                `/notifications/${id}`,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                    },
                }
            );
        } catch (e) {
            console.log(e);
        }
    };
}
export function getVendorNotificationsThunk(id: string) {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axiosInstance.get(
                `/notifications/getNotificationsVendor/${id}`,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                    },
                }
            );
            const notifications: INotification[] = response.data.data;
            dispatch(notificationsActions.setList(notifications));
        } catch (e) {
            console.log(e);
        }
    };
}