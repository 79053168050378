import React, { useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import { Supplier, suppliersActions } from "store/suppliers/suppliers.state";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  DialogTitle,
  Typography,
  Select,
  Box,
} from "@mui/material";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { Role } from "store/roles/roles.state";
import Delete from "@mui/icons-material/Delete";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import EmailIcon from "@mui/icons-material/Email";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";
import { uiActions } from "store/ui/ui.state";
import { GridCheckCircleIcon } from "@mui/x-data-grid";
import * as XLSX from "xlsx";
import {
  sendInvitationEmailThunk,
  updateSupplierStatusThunk,
} from "store/suppliers/suppliers.thunk";
import CustomSideBar from "components/custom-sidebar/CustomSideBar";
import {
  DataGridStateEnum,
  DataGridStatesActions,
  IDataGridState,
} from "store/datagrid-state/dataGridState.state";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import {
  getOrdersByVendorIdThunk,
  getOrdersThunk,
} from "store/orders/orders.thunk";
import { getDatagridStateByUserIdThunk } from "store/datagrid-state/dataGridState.thunk";

import CustomDialogTitle from "components/custom-dialog-title/CustomDialogTitle.component";
import ViewSettings from "../views-settings/ViewSettings";
import WhiteButton from "components/white-button/WhiteButton.component";
import AddView from "../add-view/AddView";
import { AG_GRID_LOCALE_FR } from "utils/constants";
const SuppliersDataGrid = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<AgGridReact>(null);
  const rows = useAppSelector((state) => state.suppliers.list);

  const accountType = useAppSelector((state) => state.auth.accountType)!;
  const authenticatedUser = useAppSelector((state) => state.auth.user)!;
  const selectedSpplier = useAppSelector(
    (state) => state.suppliers.selectedSupplierToEdit
  );
  const [gridColumnApi, setGridColumnApi] = useState<any>();
  const [gridApi, setGridApi] = useState<any>();
  const [proofActivityOpen, setProofActivityOpen] = useState(false);
  const [customSideBarOpen, setCustomSideBarOpen] = useState<boolean>(false);
  const statesList = useAppSelector((state) => state.dataGridStates.list);
  const selectedSupplierView: IDataGridState = useAppSelector(
    (state) => state.dataGridStates.selectedDataGridStateSupplier
  )!;
  const selectedSupplierFavoriteView: IDataGridState = useAppSelector(
    (state) => state.dataGridStates.selectedDataGridStateSupplierFavorite
  )!;
  const { addViewFormOpen, viewSettingsOpen } = useAppSelector(
    (state) => state.ui
  );
  const defaultView = {
    _id: "0",
    id: "0",
    dataGridName: DataGridStateEnum.Supplier,
    favorite: false,
    viewName: "Par défaut",
    user: authenticatedUser,
    colState: gridRef?.current?.columnApi?.getColumnState() as any,
  };

  const [columnDefs, setColumnDefs] = useState(
    [
      {
        headerName: "Actions",
        field: "id",
        sortable: false,
        resizable: false,
        cellRenderer: (params: any) => (
          <Grid container alignItems="center" height="100%">
            <Grid item>
              <IconButton
                title="Suppression"
                sx={{ color: "#FE7373" }}
                size="small"
                disabled={
                  !(authenticatedUser!.role as Role).supplierManagment.includes(
                    "delete"
                  )
                }
                onClick={() => {
                  dispatch(
                    uiActions.setDeleteSupplierWarningOpen({
                      set: true,
                      id: params.data.id,
                    })
                  );
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                title="Edition"
                sx={{ color: "#00A3FF" }}
                size="small"
                disabled={
                  !(authenticatedUser!.role as Role).supplierManagment.includes(
                    "update"
                  )
                }
                onClick={() => {
                  dispatch(suppliersActions.selectSupplierToEdit(params.data.id));
                  dispatch(uiActions.setEditSupplierFormOpen(true));
                }}
              >
                <EditIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                title="Inviter"
                sx={{ color: "#FE7373" }}
                size="small"
                disabled={params.data?.status === "Confirmé"}
                onClick={() => dispatch(sendInvitationEmailThunk(params.data.id))}
              >
                <EmailIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                title={
                  params.data?.status === "À confirmer"
                    ? "Confirmé"
                    : "Dé-confirmer"
                }
                sx={{
                  color:
                    params.data?.status === "À confirmer" ? "#FE7373" : "#1DC894",
                }}
                size="small"
                onClick={() => {
                  dispatch(
                    updateSupplierStatusThunk(
                      params.data.id,
                      params.data?.status === "À confirmer"
                        ? "Confirmé"
                        : "À confirmer"
                    )
                  );
                }}
              >
                {params.row?.status === "À confirmer" ? (
                  <GridCheckCircleIcon />
                ) : (
                  <UnpublishedIcon />
                )}
              </IconButton>
            </Grid>
          </Grid>
        ),
      },
      {
        field: "nom",
        headerName: "Nom",
        floatingFilter: true,
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["apply", "clear", "cancel", "reset"],
        },
      },
      {
        field: "prenom",
        headerName: "Prénom",
        floatingFilter: true,
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["apply", "clear", "cancel", "reset"],
        },
      },
      {
        field: "civilite.name",
        headerName: "Civilité",
        floatingFilter: true,
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["apply", "clear", "cancel", "reset"],
        },
      },
      {
        field: "email",
        headerName: "E-mail",
        floatingFilter: true,
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["apply", "clear", "cancel", "reset"],
        },
      },
      {
        field: "socialReason",
        headerName: "Raison Sociale",
        floatingFilter: true,
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["apply", "clear", "cancel", "reset"],
        },
      },
      {
        field: "city",
        headerName: "Ville",
        floatingFilter: true,
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["apply", "clear", "cancel", "reset"],
        },
      },
      {
        field: "postalCode",
        headerName: "Code postal",
        floatingFilter: true,
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["apply", "clear", "cancel", "reset"],
        },
      },
      {
        field: "address",
        headerName: "Adresse",
        floatingFilter: true,
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["apply", "clear", "cancel", "reset"],
        },
      },
      {
        field: "siret",
        headerName: "SIRET",
        floatingFilter: true,
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["apply", "clear", "cancel", "reset"],
        },
      },
      {
        field: "siren",
        headerName: "SIREN",
        floatingFilter: true,
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["apply", "clear", "cancel", "reset"],
        },
      },
      {
        field: "pays",
        headerName: "Pays",
        floatingFilter: true,
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["apply", "clear", "cancel", "reset"],
        },
      },
      {
        field: "tva",
        headerName: "Num TVA",
        floatingFilter: true,
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["apply", "clear", "cancel", "reset"],
        },
      },
      {
        field: "tel",
        headerName: "Numéro Tél",
        floatingFilter: true,
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["apply", "clear", "cancel", "reset"],
        },
      },
      {
        field: "mobile",
        headerName: "Numéro Mobile",
        floatingFilter: true,
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["apply", "clear", "cancel", "reset"],
        },
      },
      {
        field: "status",
        headerName: "Status",
        cellRenderer: (params: any) => (
          <Box
            // variant="contained"
            sx={{
              fontSize: "12px",
              fontWeight: "700",
              textTransform: "none",
              borderRadius: "6px",
              boxShadow: "none",
              color: params.data.status === "À confirmer" ? "#F1BC00" : "#41F172",
              background:
                params.data.status === "À confirmer" ? "#FFF8DD" : "#D2FFE7",
              textAlign: "center"
              // "&:hover": {
              //   background:
              //     params.data.status === "À confirmer" ? "#FFF8DD" : "#D2FFE7",
              //   boxShadow: "none",
              // },
            }}
          >
            {params.data.status}
          </Box>
        ),
      },
      {
        field: "purchaseOrders",
        headerName: "Bons de commande",
        floatingFilter: true,
        filter: "agNumberColumnFilter",
        filterParams: {
          buttons: ["apply", "clear", "cancel", "reset"],
        },
        cellRenderer: (params: any) => (
          <span>{params.data.purchaseOrders?.length}</span>
        ),
      },
      {
        field: "IBAN",
        headerName: "IBAN",
        floatingFilter: true,
        filter: "agTextColumnFilter",
      },
      {
        field: "ProofActivity",
        headerName: "Justificatif d’activité",
        cellRenderer: (params: any) => (
          <IconButton
            color="primary"
            aria-label="Justificatif d’activité"
            component="label"
            onClick={() => {
              dispatch(suppliersActions.selectSupplierToEdit(params.data));
              setProofActivityOpen(true);
            }}
          >
            <AttachmentIcon />
          </IconButton>
        ),
      },
      {
        field: "RIB",
        headerName: "RIB",
        cellRenderer: (params: any) => (
          <span>
            {" "}
            <Link href={`${params.data.RIB}`} target="_blank" underline="hover">
              {"RIB"}
            </Link>
          </span>
        ),
      },
      {
        field: "BIC",
        headerName: "BIC",
        floatingFilter: true,
        filter: "agNumberColumnFilter",
        filterParams: {
          buttons: ["apply", "clear", "cancel", "reset"],
        },
      },
    ]);
  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);
  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useEffect(() => {
    if (accountType === "vendor") {
      dispatch(getOrdersByVendorIdThunk(authenticatedUser.id));
    } else {
      dispatch(getOrdersThunk());
    }
    getDatagridStateByUserIdThunk(authenticatedUser.id);
  }, []);

  useEffect(() => {
    if (selectedSupplierView?.colState?.length === 0) {
      resetState();
      selectView(gridRef?.current?.columnApi?.getColumnState());
    } else {
      gridRef?.current?.columnApi?.applyColumnState({
        state: selectedSupplierView?.colState,
        applyOrder: true,
      });
    }

    // const favorite = statesList
    //   .filter(
    //     (view: IDataGridState) =>
    //       view.dataGridName === DataGridStateEnum.Invoice
    //   )
    //   .every((view: IDataGridState) => view.favorite === false);
    //   console.log(favorite)
    // if (favorite) {
    //   gridRef?.current?.columnApi?.resetColumnState();
    //   selectView(gridRef?.current?.columnApi?.getColumnState());
    // }
  }, [selectedSupplierView]);

  const onRowClicked = (e: any) => {
    const selectedRow: Supplier = e.data;
    dispatch(suppliersActions.selectSupplierToEdit(selectedRow.id));
  };
  const resetState = () => {
    gridRef?.current?.columnApi?.resetColumnState();
  };

  const selectView = (colState: any) => {
    gridRef?.current?.columnApi?.applyColumnState({
      state: colState,
      applyOrder: true,
    });
  };

  useEffect(() => {
    if (!addViewFormOpen) {
      selectView(selectedSupplierView?.colState ?? defaultView.colState);
    }
  }, []);


  const closeAddViewform = () => {
    dispatch(uiActions.setAddViewFormOpen(false));
  };
  const closeSettingsViewform = () => {
    dispatch(uiActions.setViewSettingsOpen(false));
  };
  const downloadExcel = (data: any) => {
    let newData: any[] = [];
    data.forEach((row: any, index: number) => {
      const dt: any = {}
      Object.keys(row).map((key: any) => {
        if (key !== 'role' && key !== 'id' && key !== '__v' && key !== '_id') {
          let dd: any = columnDefs.find((elm: any) => elm.field === key)?.headerName || key
          if (dd === 'civilite') {
            dd = 'Civilité'
          }
          if (dd === 'IsValidated') {
            dd = 'validation'
          }

          if (dd === 'Civilité') {
            dt[dd] = row[key]?.name
          }
          else if (dd === 'Bons de commande') {
            dt[dd] = row[key]?.length
          }
          else if (dd === 'validation') {
            dt[dd] = row[key]?.IsValidated ? 'validé' : 'Pas encore validé'
          }
          else if (dd === 'Justificatif d’activité') {
            let fileAll = ''
            row[key]?.forEach((file: any, index: any) => {
              if (index === 0) {
                fileAll = file

              } else {
                fileAll = fileAll + ', ' + file
              }
            })
            dt[dd] = fileAll
          } else {
            dt[dd] = row[key]
          }
        }
      });
      newData[index] = { ...dt };
    });
    // newData.map((elem: any) => {
    // elem.civilite = elem?.civilite?.name;
    // elem.IsValidated = elem?.IsValidated ? 'validé' : 'Pas encore validé';
    // delete elem.__v;
    // delete elem.id;
    // delete elem._id;
    // delete elem.role;
    // });
    const worksheet = XLSX.utils.json_to_sheet(newData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "liste_Fournisseurs.xlsx", { compression: true });
  };
  return (
    <div style={{ width: "100%" }}>
      <Grid container direction="row" alignItems="stretch" mb={3}>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={4}></Grid>
            <Grid item xs={8}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <FormControl style={{
                  width: "30%",
                  background: "#e6f4ff",
                  borderRadius: "5px"
                }}>
                  <InputLabel style={{
                    background: "#e6f4ff",
                    padding: "1px",
                    color: "black"
                  }} id="demo-simple-select-label">
                    Sélectionner une vue
                  </InputLabel>
                  <Select
                    // disableUnderline={true}
                    placeholder="Sélectionner une vue"
                    sx={{
                      boxShadow: "none",
                      ".MuiOutlinedInput-notchedOutline": { border: "0 #e3d3f2" },
                    }}
                    value={selectedSupplierView?._id ?? defaultView._id}
                    style={{
                      height: "30px",
                      margin: "6px",
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                  >
                    <MenuItem
                      key={defaultView._id}
                      value={defaultView._id}
                      style={{ height: "40px" }}
                      onClick={() => {
                        resetState();
                        dispatch(
                          DataGridStatesActions.clearSelectedDataGridStateSupplier()
                        );
                      }}
                    >
                      {" "}
                      <Checkbox
                        checked={defaultView.favorite}
                        icon={<FavoriteBorder />}
                        checkedIcon={<Favorite />}
                      />
                      {defaultView.viewName}{" "}
                    </MenuItem>
                    {statesList
                      .filter(
                        (view: IDataGridState) =>
                          view.dataGridName === DataGridStateEnum.Supplier
                      )
                      .map((view: IDataGridState) => {
                        return (
                          <MenuItem
                            key={view._id}
                            value={view._id}
                            style={{ height: "40px" }}
                            onClick={() => {
                              selectView(view?.colState);
                              dispatch(
                                DataGridStatesActions.setSelectDataGridStateSupplier(
                                  view
                                )
                              );
                            }}
                          >
                            {" "}
                            <Checkbox
                              checked={view.favorite}
                              icon={<FavoriteBorder />}
                              checkedIcon={<Favorite />}
                            />
                            {view.viewName}{" "}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <IconButton onClick={() => {
                  dispatch(uiActions.setViewSettingsOpen(true));
                }} aria-label="settings" color="default">
                  <SettingsIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    dispatch(uiActions.setAddViewFormOpen(true));
                  }}
                  aria-label="sauvegarder"
                  color="primary"
                >
                  <SaveIcon />
                </IconButton>
                <Button
                  sx={{
                    boxShadow: "none",
                    marginRight: "17px",

                    textTransform: "none",
                    backgroundColor: "#1DC894",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#1DC894",
                    },
                  }}
                  variant="contained"
                  onClick={() => dispatch(uiActions.setInviteSupplierFormOpen(true))}
                  disabled={
                    !(authenticatedUser!.role as Role).supplierManagment.includes(
                      "create"
                    )
                  }
                  title="Inviter un fournisseur"
                >
                  Inviter
                </Button>
                <Button
                  sx={{
                    boxShadow: "none",
                    textTransform: "none",
                    backgroundColor: "#1DC894",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#1DC894",
                    },
                  }}
                  variant="contained"
                  onClick={() => dispatch(uiActions.setAddSupplierFormOpen(true))}
                  disabled={
                    !(authenticatedUser!.role as Role).supplierManagment.includes(
                      "create"
                    )
                  }
                  title="Ajouter un fournisseur"
                >
                  Ajouter
                </Button>
                <Button
                  sx={{
                    margin: "0 0 0 10px",
                    boxShadow: "none",
                    backgroundColor: "#1DC894",
                    textTransform: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#1DC894",
                    },
                  }}
                  variant="contained"
                  endIcon={<FileDownloadIcon />}
                  onClick={() => downloadExcel(rows)}
                >
                  Excel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={!customSideBarOpen ? 11.5 : 10}
          justifyContent="center"
          alignItems="center"
          className="ag-theme-alpine"
          mt={1}
          style={{ height: "75vh" }}
        >
          <AgGridReact
            ref={gridRef}
            localeText={AG_GRID_LOCALE_FR}
            rowData={rows}
            onGridReady={onGridReady}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            onRowClicked={(e) => onRowClicked(e)}
            enableCellTextSelection
          ></AgGridReact>
        </Grid>
        <Grid style={{ height: "75vh", overflow: "hidden" }} item xs={!customSideBarOpen ? 0.5 : 2} mt={1}>
          <CustomSideBar
            gridColumnApi={gridColumnApi}
            setCustomSideBarOpen={setCustomSideBarOpen}
          />
        </Grid>
      </Grid>

      {/* add view dialog */}
      <Dialog
        fullScreen
        open={addViewFormOpen}
        onClose={closeAddViewform}
        sx={{ "& .MuiPaper-root": { height: "25vh", width: "40vw" } }}
        maxWidth="lg"
      >
        <CustomDialogTitle onClose={closeAddViewform} id="">
          Sauvgarder la vue
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          <AddView gridRef={gridRef} closeAddViewform={closeAddViewform} />
        </DialogContent>
      </Dialog>

      {/* settings view dialog */}
      <Dialog
        fullScreen
        open={viewSettingsOpen}
        onClose={closeSettingsViewform}
        sx={{ "& .MuiPaper-root": { height: "85vh", width: "30vw" } }}
        maxWidth="lg"
      >
        <CustomDialogTitle onClose={closeSettingsViewform} id="">
          Liste des vues disponible
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          <ViewSettings
            views={statesList.filter(
              (view: IDataGridState) =>
                view.dataGridName === DataGridStateEnum.Supplier
            )}
          />
        </DialogContent>
        <DialogActions>
          <WhiteButton onClick={closeSettingsViewform}>Annuler</WhiteButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={proofActivityOpen}
        onClose={() => setProofActivityOpen(false)}
        sx={{
          width: "40vw",
          height: "40vh",
          margin: "auto",
        }}
      >
        <DialogTitle> Justificatif d’activité </DialogTitle>
        <DialogContent>
          {selectedSpplier?.ProofActivity &&
            selectedSpplier?.ProofActivity?.length > 0 ? (
            <List>
              {selectedSpplier?.ProofActivity?.map(
                (file: any, index: number) => {
                  return (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <AttachmentIcon />
                      </ListItemIcon>
                      <ListItemText>
                        <Link
                          href={`${file}`}
                          target="_blank"
                          underline="hover"
                        >
                          {" "}
                          {/* {`Ficher - ${index + 1}`} */}
                          {file?.split('/uploads/')[1]?.split('.pdf')[0]}
                          {" "}
                        </Link>
                      </ListItemText>
                    </ListItem>
                  );
                }
              )}
            </List>
          ) : (
            <span> Aucun fichier </span>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setProofActivityOpen(false)}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SuppliersDataGrid;
