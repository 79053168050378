import { useAppSelector } from "hooks/redux.hook";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { User } from "store/users/users.state";
import { AgGridReact } from "ag-grid-react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FC, useState } from "react";
import { Order } from "store/orders/orders.state";
import { Pole } from "store/pole/pole.state";
import { Supplier } from "store/suppliers/suppliers.state";
import { IArticle } from "store/article/article.state";

const OrderPdf = () => {
  const selectedOrderToEdit: any = useAppSelector(
    (state) => state.orders.selectedOrderToEdit
  )!;
  const articlesList: IArticle[] = useAppSelector(
    (state) => state.articles.list
  )!;
  const [rowData] = useState(selectedOrderToEdit.articles);

  const [columnDefs] = useState([
    { field: "nomArticle", headerName: "Nom de l'article" },
    { field: "HT", headerName: "Coût unitaire HT" },
    { field: "nbHeureArticle", headerName: "Quantité" },
    { field: "TVA" },
    { field: "TTC" },
  ]);

  return (
    <div id="orderInfos">
      <Grid
        container
        direction="row"
        pt={7}
        justifyContent="space-between"
        alignItems="start"
        style={{
          borderTop: "10px solid #283592",
          width: "65vw",
          margin: "5% auto",
        }}
      >
        <Grid item xs={3}>
          <Typography variant="h4" color="text.secondary">
            Client
          </Typography>
          <List>
            {" "}
            <ListItem>
              <ListItemText
                style={{
                  color: "#6d65ea",
                  textTransform: "uppercase",
                }}
              >
                <Typography variant="h3">INSTITUT ARTLINE</Typography>
              </ListItemText>
            </ListItem>{" "}
            <ListItem>
              <ListItemText> 117 Rue de charenton</ListItemText>
            </ListItem>{" "}
            <ListItem>
              <ListItemText> 75 012 Paris</ListItemText>
            </ListItem>{" "}
            <ListItem>
              <ListItemText> +33 09 72 63 22 86</ListItemText>
            </ListItem>{" "}
            <ListItem>
              <ListItemText> Siret : 79350175000020</ListItemText>
            </ListItem>{" "}
          </List>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h4" color="text.secondary">
            Préstataire
          </Typography>
          <List>
            {" "}
            <ListItem>
              <ListItemText>
                <b>
                  {" "}
                  {(selectedOrderToEdit.FRN as Supplier)?.nom}{" "}
                  {(selectedOrderToEdit.FRN as Supplier)?.prenom}
                </b>
              </ListItemText>
            </ListItem>{" "}
            <ListItem>
              <ListItemText>
                {" "}
                {(selectedOrderToEdit.FRN as Supplier)?.socialReason}{" "}
              </ListItemText>
            </ListItem>{" "}
            <ListItem>
              <ListItemText>
                {" "}
                {(selectedOrderToEdit.FRN as Supplier)?.siret}{" "}
              </ListItemText>
            </ListItem>{" "}
            {/* <ListItem>
              <ListItemText>
                {" "}
                {(selectedOrderToEdit.FRN as Supplier).tel}{" "}
                {(selectedOrderToEdit.FRN as Supplier).mobile}{" "}
              </ListItemText>
            </ListItem>{" "} */}
            <ListItem>
              <ListItemText>
                {" "}
                {(selectedOrderToEdit.FRN as Supplier)?.address}{" "}
              </ListItemText>
            </ListItem>{" "}
            <ListItem>
              <ListItemText>
                {" "}
                {(selectedOrderToEdit.FRN as Supplier)?.postalCode}
                {"  "}
                {(selectedOrderToEdit.FRN as Supplier)?.city}{" "}
              </ListItemText>
            </ListItem>{" "}
            <ListItem>
              <ListItemText>
                {" "}
                {(selectedOrderToEdit.FRN as Supplier)?.pays}{" "}
              </ListItemText>
            </ListItem>{" "}
          </List>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        style={{
          width: "65vw",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "2%",
        }}
      >
        <Typography
          variant="h2"
          style={{
            color: "#283592",
            fontSize: "60px",
            margin: "1% 0",
            fontWeight: "bold",
          }}
        >
          {" "}
          Bon de commande
        </Typography>
        <Typography
          variant="h4"
          mb={2}
          style={{
            color: "#092e53",
            fontSize: "25px",
            borderBottom: "3px solid #ead1dc",
            paddingBottom: ".7%",
            width: "60vw",
          }}
        >
          {" "}
          Informations Générales
        </Typography>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid
              item
              xs={3}
              style={{
                backgroundColor: "#f3f3f3",
                padding: "1%",
                fontWeight: "bold",
                borderRight: "5px solid #ffe599",
              }}
            >
              <Typography>N° BDC</Typography>{" "}
            </Grid>
            <Grid item style={{ padding: "1%" }}>
              {" "}
              <Typography>{selectedOrderToEdit?.BDC_NUM}</Typography>{" "}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid
              item
              xs={3}
              style={{
                backgroundColor: "#f3f3f3",
                padding: "1%",
                fontWeight: "bold",
                borderRight: "5px solid #ffe599",
              }}
            >
              <Typography>Produit</Typography>{" "}
            </Grid>
            <Grid item style={{ padding: "1%" }}>
              {" "}
              <Typography>{selectedOrderToEdit?.perimeter?.name}</Typography>{" "}
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid
              xs={3}
              item
              style={{
                backgroundColor: "#f3f3f3",
                padding: "1%",
                fontWeight: "bold",
              }}
            >
              <Typography>Date BDC </Typography>{" "}
            </Grid>
            <Grid item style={{ padding: "1%" }}>
              <Typography> {selectedOrderToEdit?.documentDate} </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid
              xs={3}
              item
              style={{
                backgroundColor: "#f3f3f3",
                padding: "1%",
                fontWeight: "bold",
              }}
            >
              <Typography>Date début BDC / Date fin BDC </Typography>{" "}
            </Grid>
            <Grid item style={{ padding: "1%" }}>
              <Typography> {selectedOrderToEdit?.startDate} / {selectedOrderToEdit?.endDate} </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid
              xs={3}
              item
              style={{
                backgroundColor: "#f3f3f3",
                padding: "1%",
                fontWeight: "bold",
              }}
            >
              <Typography> Projet </Typography>{" "}
            </Grid>
            <Grid item style={{ padding: "1%" }}>
              <Typography> {selectedOrderToEdit?.project?.name} </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid
              xs={3}
              item
              style={{
                backgroundColor: "#f3f3f3",
                padding: "1%",
                fontWeight: "bold",
              }}
            >
              <Typography> Périmètre du BDC </Typography>{" "}
            </Grid>
            <Grid item style={{ padding: "1%" }}>
              <Typography> {selectedOrderToEdit?.session?.name} </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid
              xs={3}
              item
              style={{
                backgroundColor: "#f3f3f3",
                padding: "1%",
                fontWeight: "bold",
              }}
            >
              <Typography> Pôle / Centre de coûts </Typography>{" "}
            </Grid>
            <Grid item style={{ padding: "1%" }}>
              <Typography> {selectedOrderToEdit?.pole?.name} </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        style={{
          width: "65vw",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "2%",
        }}
      >
        <Typography
          variant="h4"
          mb={2}
          style={{
            color: "#092e53",
            fontSize: "25px",
            borderBottom: "3px solid #ead1dc",
            paddingBottom: ".7%",
            width: "60vw",
          }}
        >
          {" "}
          Informations de la mission
        </Typography>
        <Grid
          container
          direction="row"
          style={{ backgroundColor: "#f3f3f3", padding: "1%" }}
        >
          <Grid item xs={6}>
            {/* <Grid container direction="row" spacing={2} mb={1}>
              <Grid item xs={2}>
                {" "}
                <Typography style={{ fontWeight: "bold", fontSize: "17px" }}>
                  {" "}
                  Session{" "}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                {" "}
                <Typography> 2209 </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} mb={1}>
              <Grid item xs={2}>
                {" "}
                <Typography style={{ fontWeight: "bold", fontSize: "17px" }}>
                  {" "}
                  Cursus{" "}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                {" "}
                <Typography> I3D/VFX - Infographie 3D/VFX </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} mb={1}>
              <Grid item xs={2}>
                {" "}
                <Typography style={{ fontWeight: "bold", fontSize: "17px" }}>
                  {" "}
                  Niveau{" "}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                {" "}
                <Typography> B3 </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} mb={1}>
              <Grid item xs={2}>
                {" "}
                <Typography style={{ fontWeight: "bold", fontSize: "17px" }}>
                  {" "}
                  UV{" "}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                {" "}
                <Typography> B3 UV 02 Projet Concept Art </Typography>
              </Grid>
            </Grid> */}
            <Typography variant="body1">
              {selectedOrderToEdit.missionInformation}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        style={{
          width: "65vw",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "5%",
        }}
      >
        <Typography variant="h3" mb={3} style={{ color: "#2944b8" }}>
          {" "}
          Conditions de paiement
        </Typography>
        <Typography
          variant="body1"
          style={{ color: "#70787c", fontSize: "20px" }}
        >
          {" "}
          {selectedOrderToEdit.paymentTerm
            ? selectedOrderToEdit.paymentTerm.value
            : "pas de condition de paiement"}
        </Typography>
      </Grid>

      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        style={{
          width: "65vw",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "2%",
        }}
      >
        <Grid item xs={12}>
          <table style={{ width: "60vw" }}>
            <thead>
              <tr>
                <th></th>
                <th>Nom de l'article</th>
                <th>Coût unitaire HT</th>
                <th>Quantité</th>
                <th>Montant TTC</th>
              </tr>
            </thead>
            <tbody>
              {selectedOrderToEdit.articles?.map((article: any, index: number) => {
                return (
                  <tr key={index}>
                    <td></td>
                    <td>
                      {
                        articlesList.filter(
                          (art: IArticle) => art._id === article.idArticle
                        )[0]?.nomArticle
                      }
                    </td>
                    <td> {article.HT} €</td>
                    <td> {article.qty} </td>
                    <td> {article.TTC} €</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            mt={2}
          >
            <Grid item xs={3}>
              <Typography>
                {" "}
                <b style={{ color: "red" }}>Total</b>{" "}
                {selectedOrderToEdit.articles?.length} article(s)
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>
                {" "}
                <b style={{ color: "red" }}>Total TTC</b>{" "}
                {selectedOrderToEdit.GTTC} €
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        textAlign="center"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: "70px" }}
      >
        <Grid item xs={6}>
          <Typography variant="body1">
            INSTITUT ARTLINE – L’ÉCOLE EN LIGNE DE LA CRÉATION NUMÉRIQUE 117 rue
            de Charenton, 75012 PARIS | institutartline.com | +33 (0)1 86 95 23
            31 | admission@institutartline.com Ét. d’enseignement supérieur
            privé à distance, déclaré au Rectorat de l’académie de Paris, soumis
            au contrôle pédagogique de l’État | N° formation continue : 11 75
            51293 75 - Sarl au capital de 11 000 € - RCS Paris 793 501 750 - APE
            8559BD
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderPdf;
