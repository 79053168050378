import { Button, Grid, TextField, Typography } from "@mui/material";
import WhiteButton from "components/white-button/WhiteButton.component";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import React from "react";
import {
  DataGridStateEnum,
  DataGridStatesActions,
  IDataGridState,
} from "store/datagrid-state/dataGridState.state";
import {
  addDatagridStateThunk,
  editDataGridStateThunk,
  getDatagridStateByNameThunk,
  getDatagridStateByUserIdThunk,
  getDatagridStatesThunk,
} from "store/datagrid-state/dataGridState.thunk";
import { uiActions } from "store/ui/ui.state";
interface IAddViewProps {
  gridRef: any;
  closeAddViewform: any;
}

const AddView: React.FC<IAddViewProps> = ({ gridRef, closeAddViewform }) => {
  const dispatch = useAppDispatch();
  const selectedView: IDataGridState = useAppSelector(
    (state) => state.dataGridStates.selectedDataGridStateUserToEdit
  )!;

  const authUser = useAppSelector((state) => state.auth.user)!;

  const formik = useFormik({
    initialValues: {
      dataGridName: selectedView
        ? selectedView.dataGridName
        : DataGridStateEnum.User,
      colState: selectedView
        ? selectedView.colState
        : gridRef?.current?.columnApi?.getColumnState(),
      viewName: selectedView ? selectedView.viewName : "",
      user: authUser.id,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      // console.log(values);
      try {
        if (selectedView) {
          await dispatch(
            editDataGridStateThunk({
              id: selectedView._id,
              datagridstate: values as any,
            })
          );
        } else {
          await dispatch(addDatagridStateThunk(values as any));
        }
      } catch (error) {
        console.log(error);
      }
      dispatch(uiActions.setAddViewFormOpen(false));
      await dispatch(getDatagridStateByUserIdThunk(authUser.id));
      await dispatch(getDatagridStateByNameThunk(values.viewName, authUser.id));
      dispatch(DataGridStatesActions.clearSelectedDataGridState());
    },
  });
  return (
    <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
      <TextField
        fullWidth
        placeholder="Entrer le nom de votre vue "
        {...formik.getFieldProps("viewName")}
      />
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <WhiteButton
          onClick={() => {
            dispatch(DataGridStatesActions.clearSelectedDataGridState());
            closeAddViewform();
          }}
        >
          Annuler
        </WhiteButton>
        <Button type="submit">
          {" "}
          {selectedView ? "Editer" : "Sauvgarder"}{" "}
        </Button>
      </Grid>
    </form>
  );
};

export default AddView;
