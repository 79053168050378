import { Card, CardContent, Typography, CardActions } from "@mui/material";
import { Grid } from "@mui/material";
import BlueButton from "components/blue-button/BlueButton.component";
import RedButton from "components/red-button/RedButton";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import { useEffect } from "react";
import { Role } from "store/roles/roles.state";
import { sessionActions } from "store/session/session.state";
import { uiActions } from "store/ui/ui.state";

const List = () => {
    const dispatch = useAppDispatch();
    const list = useAppSelector((state) => state.session.list);
    const authenticatedUser = useAppSelector((state) => state.auth.user);

    const handleDelete = (id: string) => {
        // dispatch(civiliteActions.deleteCivilite(id));
        // dispatch(deleteCiviliteThunk(id));
        // console.log(id)
        dispatch(uiActions.setDeleteSessionWarningOpen({ set: true, id: id }));
      };
    const handleEdit = (id: string) => {
        dispatch(sessionActions.selectSessionToEdit(id));
      };
    // useEffect(()=> {console.log(list)})
    
    return (
        <>
        {list.length === 0 && "Il n'y a pas de sessions"}
        <Grid container spacing="20px" alignItems="stretch">
          {list.map((session) => (
            <Grid key={session._id} item xs={12} md={6} lg={4} xl={3}>
              <Card
                sx={{ height: "100%", display: "flex", flexDirection: "column" }}
                elevation={0}
              >
                <CardContent sx={{ flex: "1" }}>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{ color: "#3F4254" }}
                  >
                    {session.name}
                  </Typography>
                </CardContent>
                <CardActions>
                  <BlueButton
                    onClick={() => handleEdit(session?._id)}
                    // sx={{ marginRight: "20px", padding: "4px 10px" }}
                    disabled={
                      !(authenticatedUser!.role as Role).managmentRules.includes(
                        "update"
                      )
                    }
                  >
                    Edition
                  </BlueButton>
                  <RedButton
                    onClick={() => handleDelete(session._id)}
                    disabled={
                      !(authenticatedUser!.role as Role).managmentRules.includes(
                        "delete"
                      )
                    }
                  >
                    Suppression
                  </RedButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </>
    )
}

export default List