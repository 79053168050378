import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { uiActions } from "store/ui/ui.state";
import { Role } from "store/roles/roles.state";

const SupplierToolbar = () => {
  const suppliers = useAppSelector((state) => state.suppliers.list);
  const authenticatedUser = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={0}
      mb={1}
      style={{ marginBottom: "40px" }}
    >
      <Typography variant="h4" color="initial">
        Fournisseurs ({suppliers.length})
      </Typography>
      <Grid item>
        {/* <Button
          sx={{
            boxShadow: "none",
            marginRight: "17px",

            textTransform: "none",
            backgroundColor: "#1DC894",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "#1DC894",
            },
          }}
          variant="contained"
          onClick={() => dispatch(uiActions.setInviteSupplierFormOpen(true))}
          disabled={
            !(authenticatedUser!.role as Role).supplierManagment.includes(
              "create"
            )
          }
          title="Inviter un fournisseur"
        >
          Inviter
        </Button>
        <Button
          sx={{
            boxShadow: "none",
            textTransform: "none",
            backgroundColor: "#1DC894",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "#1DC894",
            },
          }}
          variant="contained"
          onClick={() => dispatch(uiActions.setAddSupplierFormOpen(true))}
          disabled={
            !(authenticatedUser!.role as Role).supplierManagment.includes(
              "create"
            )
          }
          title="Ajouter un fournisseur"
        >
          Ajouter
        </Button> */}
      </Grid>
    </Grid>
  );
};

export default SupplierToolbar;
