import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import { useAppSelector, useAppDispatch } from "hooks/redux.hook";
import { Civilite, civiliteActions } from "store/civilite/civilite.state";
import { deleteCiviliteThunk } from "store/civilite/civilite.thunk";
import { uiActions } from "store/ui/ui.state";
import { Role } from "store/roles/roles.state";
import BlueButton from "components/blue-button/BlueButton.component";
import RedButton from "components/red-button/RedButton";
import { ITva, tvasActions } from "store/tva/tva.state";
import { useEffect } from "react";
import { getTvasThunk } from "store/tva/tva.thunk";
import { getPaymentDatesThunk } from "store/payment-dates/paymentDates.thunk";
import {
  IPaymentDate,
  paymentDatesActions,
} from "store/payment-dates/paymentDates.state";

export default function List() {
  const dispatch = useAppDispatch();
  const list = useAppSelector((state) => state.paymentDates.list);

  const authenticatedUser = useAppSelector((state) => state.auth.user);

  const handleDelete = (id: string) => {
    dispatch(uiActions.setDeletePaymentDateWarningOpen({ set: true, id: id }));
  };

  useEffect(() => {
    dispatch(getPaymentDatesThunk());
  }, []);

  const handleEdit = (data: IPaymentDate) => {
    dispatch(paymentDatesActions.setSelectPaymentDate(data));
  };
  return (
    <>
      {list.length === 0 && "Il n'y a pas de Date de paiement"}
      <Grid container spacing="20px" alignItems="stretch">
        {list.map((pd) => (
          <Grid key={pd.id} item xs={12} md={6} lg={4} xl={3}>
            <Card
              sx={{ height: "100%", display: "flex", flexDirection: "column" }}
              elevation={0}
            >
              <CardContent sx={{ flex: "1" }}>
                <Typography variant="subtitle1">Date de paiement</Typography>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ color: "#3F4254" }}
                >
                  Le {pd.value} du mois
                </Typography>
              </CardContent>
              <CardActions>
                <BlueButton
                  onClick={() => handleEdit(pd)}
                  // sx={{ marginRight: "20px", padding: "4px 10px" }}
                  disabled={
                    !(authenticatedUser!.role as Role).managmentRules.includes(
                      "update"
                    )
                  }
                >
                  Edition
                </BlueButton>
                <RedButton
                  onClick={() => {
                    handleDelete(pd._id);
                  }}
                  disabled={
                    !(authenticatedUser!.role as Role).managmentRules.includes(
                      "delete"
                    )
                  }
                >
                  Suppression
                </RedButton>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
