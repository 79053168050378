import { AppDispatch, AppThunk } from "store/store.state";
import { usersActions } from "./users.state";
import type { User } from "./users.state";
import { axiosInstance } from "api/axiosInstance";
import { NavigateFunction } from "react-router-dom";
import * as ROUTES from "utils/routes";
import { feedbackMessageUIActions } from "store/feedback-message-ui/feedbackMessageUI.state";
import { rolesActions } from "store/roles/roles.state";
import { authActions } from "store/auth/auth.state";
import { setUserThunk } from "store/auth/auth.thunk";

type GetUsersResponse = {
  data: User[];
};

export function getUsersThunk() {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.get<GetUsersResponse>("/users", {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      });
      const data = response.data.data;
      const users: User[] = response.data.data;
      dispatch(usersActions.setList(users));
    } catch (e) {
      console.log(e);
    }
  };
}

type AddUserResponse = {
  data: User;
};

export function addUserThunk(user: User, image: File) {
  return async (dispatch: AppDispatch) => {
    try {
      const formData = new FormData();
      formData.set("firstName", user.firstName);
      formData.set("lastName", user.lastName);
      formData.set("email", user.email);
      formData.set("tel", user.tel);
      formData.set("civilite", user.civilite as any);
      formData.set("pole", user.pole as any);
      formData.set("role", user.role as any);
      formData.set("position", user.position as any);
      formData.set("avatar", image);

      const response = await axiosInstance.post<AddUserResponse>(
        "/auth/user/register",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
          },
        }
      );
      const completeUser = response.data.data;
      dispatch(usersActions.addUser(completeUser));
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "L'utilisateur a été ajouté avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message:
            "Impossible d'ajouter l'utilisateur, veuillez réessayer plus tard",
        })
      );
    }
  };
}

export function deleteUserThunk(id: string) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.delete(`/users/${id}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      });
      dispatch(usersActions.deleteUser(id));
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "L'utilisateur a été supprimé avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message:
            "Impossible de supprimer l'utilisateur, veuillez réessayer plus tard",
        })
      );
    }
  };
}

type EditUserResponse = {
  data: User;
};

type EditUserParams = Omit<User, "id" | "avatarUrl" | "joinDate" | "lastLogin">;

// editing a user in the dashboard
export function editUserThunk(
  obj: { id: string; user: EditUserParams },
  image: File
) {
  return async (dispatch: AppDispatch) => {
    try {
      const formData = new FormData();
      formData.set("firstName", obj.user.firstName);
      formData.set("lastName", obj.user.lastName);
      formData.set("email", obj.user.email);
      formData.set("tel", obj.user.tel);
      formData.set("civilite", obj.user.civilite as any);
      formData.set("pole", obj.user.pole as any);
      formData.set("position", obj.user.position as any);
      formData.set("role", obj.user.role as any);
      formData.set("avatar", image);
      const response = await axiosInstance.put<EditUserResponse>(
        `/users/${obj.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
          },
        }
      );
      const editedUser = response.data.data;
      dispatch(usersActions.editUser({ id: obj.id, user: editedUser }));
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "L'utilisateur a été mis à jour avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message:
            "Impossible de mettre à jour l'utilisateur, veuillez réessayer plus tard",
        })
      );
    }
  };
}

// editing my user in the profile page
export function editMyUserProfileThunk(
  obj: { id: string; user: EditUserParams },
  image?: File
) {
  return async (dispatch: AppDispatch) => {
    try {
      const formData = new FormData();
      formData.set("firstName", obj.user.firstName);
      formData.set("lastName", obj.user.lastName);
      formData.set("email", obj.user.email);
      formData.set("role", (obj.user as any).role);
      formData.set("tel", obj.user.tel);
      formData.set("civilite", obj.user.civilite as any);
      formData.set("pole", obj.user.pole as any);
      formData.set("position", obj.user.position as any);
      if (image) formData.set("avatar", image);
      const response = await axiosInstance.put<EditUserResponse>(
        `/users/${obj.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
          },
        }
      );
      const editedUser = response.data.data;
      // edit user in auth store
      localStorage.setItem("user", JSON.stringify(editedUser));
      dispatch(authActions.setUser(editedUser));
      // edit user in usersstore
      dispatch(usersActions.editUser({ id: obj.id, user: editedUser }));
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "L'utilisateur a été mis à jour avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message:
            "Impossible de mettre à jour l'utilisateur, veuillez réessayer plus tard",
        })
      );
    }
  };
}

// forgot password ==================================================================
// send email to reset password
export function forgotPasswordThunk(
  email: string,
  setShowError: React.Dispatch<React.SetStateAction<boolean>>
): AppThunk {
  return async (dispatch) => {
    try {
      await axiosInstance.post("/auth/user/forgotpassword", {
        email,
      },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
          },
        });
      setShowError(false);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "L'e-mail a été envoyé avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      setShowError(true);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message:
            "Impossible d'envoyer un e-mail, veuillez réessayer plus tard",
        })
      );
    }
  };
}

export function resetPasswordThunk(
  password: string,
  confirmPassword: string,
  token: string,
  setShowError: React.Dispatch<React.SetStateAction<boolean>>,
  navigate: NavigateFunction
): AppThunk {
  return async (dispatch, getState) => {
    try {
      // const token = getState().auth.token
      await axiosInstance.put(`/auth/user/resetpassword/${token}`, {
        password,
        confirmPassword,
      }, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      });
      setShowError(false);
      navigate(`/${ROUTES.LOGIN_USER}`);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Le mot de passe a été réinitialisé avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      setShowError(true);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message:
            "Impossible de réinitialiser le mot de passe, veuillez réessayer plus tard",
        })
      );
    }
  };
}
// forgot password ==================================================================

// reset password from profile=======================================================
export function resetPasswordFromProfileThunk(
  oldPassword: string,
  newPassword: string,
  confirmNewPassword: string
): AppThunk {
  return async (dispatch, getState) => {
    try {
      const userId = getState().auth.user!.id;
      await axiosInstance.put(`/users/changepassword/${userId}`, {
        oldPassword,
        newPassword,
        confirmNewPassword,
      }, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      });
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Le mot de passe a été réinitialisé avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message:
            "Impossible de réinitialiser le mot de passe, veuillez réessayer plus tard",
        })
      );
    }
  };
}
// reset password from profile=======================================================

type GetUsersAssignedToARoleResponse = {
  data: User[];
};
// get all users assigned to a role
export function getUsersAssignedToARole(roleId: string): AppThunk {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get<GetUsersAssignedToARoleResponse>(
        `/users/role/${roleId}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      }
      );
      dispatch(
        rolesActions.setAssignedUsersOfARole({
          roleId,
          users: response.data.data,
        })
      );
    } catch (e) {
      console.log(e);
    }
  };
}

// remove a role for a user, in other words give him a default empty roles with all permission tables empty
export function removeRoleFromUserThunk(
  userId: string,
  roleId: string
): AppThunk {
  return async (dispatch) => {
    try {
      await axiosInstance.put(`/roles/discharge/user/${userId}`, {
        roleId,
      }, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      });
      dispatch(rolesActions.removeAssignedUser(userId));
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Rôle supprimé pour cet utilisateur avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Impossible de supprimer le rôle de cet utilisateur",
        })
      );
    }
  };
}

export function generateNewPasswordForUser(id: string): AppThunk {
  return async (dispatch) => {
    try {
      await axiosInstance.post(`/auth/user/invite`, {
        userId: id,
      }, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      });
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message:
            "Un nouveau mot de passe généré et envoyé à l'utilisateur avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message:
            "Impossible de générer le mot de passe, veuillez réessayer plus tard",
        })
      );
    }
  };
}

export function uploadAvatarThunk(userId: string, avatar: File): AppThunk {
  return async (dispatch, getState) => {
    try {
      const formData = new FormData();
      formData.set("avatar", avatar);
      const response = await axiosInstance.post<{ data: string }>(
        `/users/upload/${userId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
          },
        }
      );
      const authenticatedUser = getState().auth.user as User;
      dispatch(
        setUserThunk({ ...authenticatedUser, avatarUrl: response.data.data })
      ); // we need to save it in local storage too
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message: "Image de profil modifiée avec succès",
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        feedbackMessageUIActions.setState({
          open: true,
          message:
            "Impossible de changer l'image du profil, veuillez réessayer plus tard",
        })
      );
    }
  };
}
