import { AppThunk } from "store/store.state";
import { User } from "store/users/users.state";
import { AccountType, authActions } from "./auth.state";
import { axiosInstance } from "api/axiosInstance";

export function setTokenThunk(token: string): AppThunk {
  return (dispatch) => {
    localStorage.setItem("token", token);
    dispatch(authActions.setToken(token));
  };
}

export function setUserThunk(user: User): AppThunk {
  return (dispatch) => {
    localStorage.setItem("user", JSON.stringify(user));
    dispatch(authActions.setUser(user));
  };
}

// this is login
export function setUserAndTokenThunk(
  user: User,
  token: string,
  accountType: AccountType
): AppThunk {
  return (dispatch) => {
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("token", token);
    localStorage.setItem("accountType", accountType)
    dispatch(authActions.setUser(user));
    dispatch(authActions.setToken(token));
    dispatch(authActions.setAccountType(accountType))
  };
}

export function logoutThunk(): AppThunk {
  return async (dispatch, getState) => {
    try {
      await axiosInstance.post(
        `/auth/${getState().auth.accountType}/logout`, undefined,
        {
          headers: {
            authorization: `Bearer ${getState().auth.token}`,
          },
        }
      );
      // TODO: add a logout request to server
      dispatch(authActions.setUser(null));
      dispatch(authActions.setToken(""));
      dispatch(authActions.setAccountType(""));

      // NOTE: this removes all keys! maybe there are some keys you need to keep
      localStorage.clear();
    } catch (e) {
      console.log(e);
    }
  };
}
