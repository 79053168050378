import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { CustomTextField } from "components/custom-textfield/CustomTextField.component";
import { useAppSelector, useAppDispatch } from "hooks/redux.hook";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Role } from "store/roles/roles.state";
import BlueButton from "components/blue-button/BlueButton.component";
import GreenButton from "components/green-button/GreenButton";
import {
  addPaymentTermThunk,
  editPaymentTermThunk,
  getPaymentTermssThunk,
} from "store/payment-terms/paymentTerms.thunk";
import {
  IPaymentTerm,
  paymentTermsActions,
} from "store/payment-terms/paymentTerms.state";

type ErrorProp = boolean | undefined;

export default function Form() {
  const dispatch = useAppDispatch();
  const selectedPaymentTerm = useAppSelector(
    (state) => state.paymentTerms.selectedPaymentTerm
  );
  const isEditMode = selectedPaymentTerm !== null;

  const authenticatedUser = useAppSelector((state) => state.auth.user);

  const formik = useFormik({
    initialValues: {
      value:
        isEditMode && selectedPaymentTerm.value
          ? selectedPaymentTerm.value
          : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      value: Yup.string().required("Condition de paiement obligatoire"),
    }),
    onSubmit: async (values, helpers) => {
      // console.log(values);
      if (isEditMode) {
        // console.log(selectedPaymentTerm);

        dispatch(
          editPaymentTermThunk({
            id: selectedPaymentTerm._id,
            pt: values as IPaymentTerm,
          })
        );

        await dispatch(getPaymentTermssThunk());
        dispatch(paymentTermsActions.clearSelectedPaymentTerm());
      } else {
        await dispatch(addPaymentTermThunk(values as IPaymentTerm));
      }
      helpers.resetForm();
    },
  });

  const showValueError = (formik.errors.value &&
    formik.touched.value) as ErrorProp;
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing="12px" marginBottom="20px">
        <Grid item xs={12} md={4}>
          <Typography
            component="span"
            fontWeight="bold"
            sx={{ color: "#3F4254" }}
          >
            Condition de paiement{" "}
            <Typography component="span" color="error">
              *
            </Typography>
          </Typography>
          <CustomTextField
            {...formik.getFieldProps("value")}
            error={showValueError}
            type="text"
            helperText={showValueError && formik.errors.value}
            placeholder=" Condition de paiement"
            sx={{
              "& .MuiFilledInput-root": {
                background: "#fff",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <GreenButton
            variant="contained"
            sx={{
              marginTop: "25px",
              display: isEditMode ? "none" : "inline",
            }}
            type="submit"
            disabled={
              !(authenticatedUser!.role as Role).managmentRules.includes(
                "create"
              )
            }
          >
            Ajouter
          </GreenButton>
          <BlueButton
            sx={{ display: !isEditMode ? "none" : "inline", marginTop: "25px" }}
            variant="contained"
            type="submit"
            disabled={
              !(authenticatedUser!.role as Role).managmentRules.includes(
                "update"
              )
            }
          >
            Editer
          </BlueButton>
        </Grid>
      </Grid>
    </form>
  );
}
