import { useState, useRef, useEffect } from "react";
import { Field, FieldArray, Formik, FormikProvider, useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { CustomTextField } from "components/custom-textfield/CustomTextField.component";

import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Autocomplete from '@mui/material/Autocomplete';

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import BlueButton from "components/blue-button/BlueButton.component";
import GrayButton from "components/gray-button/GrayButton.component";
import {
  addOrderThunk,
  getOrdersByVendorIdThunk,
  getOrdersThunk,
  updateOrderThunk,
} from "store/orders/orders.thunk";
import Add from "@mui/icons-material/Add";

import React from "react";
import { Supplier } from "store/suppliers/suppliers.state";
import Delete from "@mui/icons-material/Delete";
import { margin } from "pages/users/components/variables";
import { Divider, FormControlLabel, InputLabel, Radio, RadioGroup } from "@mui/material";
import ReactQuill from "react-quill";
import { ordersActions } from "store/orders/orders.state";
import { IArticle } from "store/article/article.state";
import { validDate } from "utils/globalVariables";

type EditOrderFormProps = {
  handleCloseEditDialog: () => void;
};

const EditOrderForm2 = (props: EditOrderFormProps) => {
  const dispatch = useAppDispatch();
  const poleList = useAppSelector((state) => state.pole.list);
  const suppList = useAppSelector((state) => state.suppliers.list);
  const tvaList = useAppSelector((state) => state.tvas.list);
  const ptList = useAppSelector((state) => state.paymentTerms.list);
  const sessiontList = useAppSelector((state) => state.session.list);
  const projetList = useAppSelector((state) => state.project.projectList);
  const perimetertList = useAppSelector((state) => state.perimeters.list);
  const typedepensestList = useAppSelector((state) => state.typedepenses.list);
  const costsCentertList = useAppSelector((state) => state.pole.list);
  const authenticatedUser = useAppSelector((state) => state.auth.user?.id);
  const accountType = useAppSelector((state) => state.auth.accountType)!;
  const authUser = useAppSelector((state) => state.auth.user)!;
  const suppListOptions: any = suppList?.map((e: any) => ({ name: `${e.nom} ${e.prenom}`, id: e.id }))
  const selectedOrderToEdit = useAppSelector(
    (state) => state.orders.selectedOrderToEdit
  )!;
  const articlesList = useAppSelector((state) => state.articles.list);
  var sr = " ";
  const formik = useFormik({
    initialValues: {
      userRef: authenticatedUser,
      session: selectedOrderToEdit?.session?._id,
      pole: selectedOrderToEdit?.pole?._id,
      project: selectedOrderToEdit?.project?._id,
      typeDepense: selectedOrderToEdit?.typeDepense?._id,
      paymentTerm: selectedOrderToEdit.paymentTerm?._id,
      perimeter: selectedOrderToEdit?.perimeter?._id,
      BDC_NUM: selectedOrderToEdit.BDC_NUM,
      reste: selectedOrderToEdit.reste,
      GHT: selectedOrderToEdit.GHT,
      MTVA: selectedOrderToEdit.MTVA,
      GTTC: selectedOrderToEdit.GTTC,
      documentDate: validDate(selectedOrderToEdit.documentDate),
      missionInformation: selectedOrderToEdit.missionInformation,
      FRN: selectedOrderToEdit.FRN?.id,
      raisonSocial: selectedOrderToEdit.raisonSocial,
      articles: selectedOrderToEdit.articles,
      prestationOuAchat: selectedOrderToEdit.prestationOuAchat,
      THT: {} as any,
      TTC: {} as any,
      startDate: selectedOrderToEdit.startDate,
      endDate: selectedOrderToEdit.endDate,
    },
    validationSchema: Yup.object({
      pole: Yup.string().required("Centre de coûts obligatoire"),
      typeDepense: Yup.string().required("Type de dépense obligatoire"),
      session: Yup.string().required("Périmètre du BDC obligatoire"),
      documentDate: Yup.string().required("Date obligatoire"),
      GHT: Yup.number(),
      MTVA: Yup.number(),
      GTTC: Yup.number(),
      paymentTerm: Yup.string().required("Condition de paiement obligatoire"),
      perimeter: Yup.string().required("Produit obligatoire"),
      // pole: Yup.string().required("Désignation obligatoire"),
      FRN: Yup.string().required("Préstataire obligatoire"),
      raisonSocial: Yup.string(),
      startDate: Yup.string().required("Date de début bdc obligatoire"),
      endDate: Yup.string().required("Date fin bdc obligatoire"),
    }),
    enableReinitialize: false,
    onSubmit: async (values: any) => {
      await dispatch(
        updateOrderThunk({
          id: selectedOrderToEdit._id,
          order: values as any,
        })
      );
      if (accountType === "vendor") {
        await dispatch(getOrdersByVendorIdThunk(authUser.id));
      } else {
        await dispatch(getOrdersThunk());
      }
      props.handleCloseEditDialog();
    },
  });

  const initQty = {} as any;

  // if (formik.getFieldProps("FRN").value) {
  //   sr = suppList.filter(
  //     (supp: Supplier) => supp.id === formik.getFieldProps("FRN").value
  //   )[0].socialReason;
  //   formik.values.raisonSocial = sr;
  // }
  const calculateTHT = (index: number) => {
    let THT: number = 0;
    const article = formik.values.articles[index];
    THT =
      (article.qty as number) *
      (articlesList.filter((art: any) => art._id === article.idArticle)[0]
        ?.HT as number);

    formik.setFieldValue(`articles.${index}.THT`, (Math.round((THT + Number.EPSILON) * 100) / 100));

    return Math.round((THT + Number.EPSILON) * 100) / 100;
  };
  // article.TTC = article.nbHeureArticle * article.HT * (article.TVA / 100);
  const calculateTCC = (index: number) => {
    let TTC: number = 0;
    const article: any = formik.values.articles[index];
    article
      ? (TTC =
        (article.qty as number) *
        (articlesList.filter((art: any) => art._id === article.idArticle)[0]
          ?.HT +
          (articlesList.filter((art: any) => art._id === article.idArticle)[0]
            ?.TVA *
            articlesList.filter(
              (art: any) => art._id === article.idArticle
            )[0]?.HT) /
          100))
      : (TTC = 0);
    formik.setFieldValue(`articles.${index}.TTC`, (Math.round((TTC + Number.EPSILON) * 100) / 100));

    return Math.round((TTC + Number.EPSILON) * 100) / 100;
  };
  const calculateGHT = () => {
    const GHT = Object.values(formik.values.articles).reduce(
      (total: number, article: any) => {
        return total + (article.THT as number);
      },
      0
    );
    formik.values.GHT = Math.round((GHT + Number.EPSILON) * 100) / 100;
    return Math.round((GHT + Number.EPSILON) * 100) / 100;
  };
  const calculateMTVA = () => {
    const MTVA: number = formik.values.articles.reduce(
      (total: number, article: any) => {
        const value = (article.HT * article.TVA) / 100;
        return total + value;
      },
      0
    );

    formik.values.MTVA = Math.round(((formik.values.GTTC - formik.values.GHT) + Number.EPSILON) * 100) / 100;
    return Math.round(((formik.values.GTTC - formik.values.GHT) + Number.EPSILON) * 100) / 100;
  };
  const calculateGTTC = () => {
    const GTTC = Object.values(formik.values.articles).reduce(
      (total: number, article: any) => {
        return total + (article.TTC as number);
      },
      0
    );
    formik.values.GTTC = Math.round((GTTC + Number.EPSILON) * 100) / 100;
    return Math.round((GTTC + Number.EPSILON) * 100) / 100;
  };
  // formik.handleChange = (event: any) => {
  //   if (event.target.name.split(".")[1] === "HT") {
  //     calculateTHT(event.target.name.split(".")[0][9]);
  //     formik.values.articles[event.target.name.split(".")[0][9]].HT =
  //       event.target.value;
  //   }
  // };

  const [quantities, setQuantities] = useState<any>(initQty);

  const initTHT: any = {};

  const [THTValues, setTHTValues] = useState<any>(initTHT);

  const initTTC: any = {};

  const [TTCValues, setTTCValues] = useState<any>(initTTC);

  formik.values.GHT = calculateGHT();
  formik.values.MTVA = calculateMTVA();
  formik.values.GTTC = calculateGTTC();

  useEffect(() => {

    formik.values.articles.map(
      (item: any, index: number) => (initTTC[index] = calculateTCC(index))
    );

    formik.values.articles.map(
      (item: any, index: number) => (initTHT[index] = calculateTHT(index))
    );

    // formik.values.articles.map(
    //   (item: any, index: number) => (initQty[index] = 1)
    // );
  }, [])

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <FormikProvider value={formik}>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="flex-start"
            mb={4}
          >
            <Grid item xs={6}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography component="span" fontWeight="bold">
                    Date{" "}
                    <Typography component="span" color="error">
                      *
                    </Typography>:
                  </Typography>
                </Grid>
                <Grid item>
                  {/* <input
                  {...formik.getFieldProps("documentDate")}
                  type="text"
                  value={formik.values.documentDate}
                  style={{
                    width: "8vw",
                    height: "42px",
                    border: "none",
                    paddingTop: "1%",
                    paddingLeft: "5%",
                    color: "#5E6278",
                    backgroundColor: "white",
                  }}
                /> */}
                  {/* <input
                    // {...formik.getFieldProps("documentDate")}
                    type="date"
                    id="start"
                    style={{
                      border: "none",
                      paddingLeft: "5%",
                    }}
                    // name="documentDate"
                  /> */}
                  <TextField
                    {...formik.getFieldProps("documentDate")}
                    type="date"
                    size="small"
                    id="start"
                    fullWidth
                    style={{
                      height: "42px",
                      marginLeft: "2%",
                      paddingTop: "7%",
                      color: "#5E6278",
                      backgroundColor: "white",
                    }}
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography style={{ fontSize: "18px", fontWeight: "500" }} component="span" fontWeight="bold">
                    № BDC{" "}
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item>
                  {/* <TextField
                    {...formik.getFieldProps("BDC_NUM")}
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                    placeholder="03048"
                    style={{
                      width: "18vw",
                      height: "42px",
                      color: "#5E6278",
                      paddingLeft: "3%",
                      paddingTop: "3%",
                      backgroundColor: "white",
                    }}
                    size="small"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  /> */}
                  <TextField
                    {...formik.getFieldProps("BDC_NUM")}
                    type="text"
                    disabled
                    placeholder="03048"
                    fullWidth
                    style={{
                      // width: "18vw",
                      height: "42px",
                      color: "#5E6278",
                      paddingLeft: "3%",
                      paddingTop: "5%",
                      backgroundColor: "white",
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    size="small"
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item xs={12}
            container
            direction='row'
          >
            <Grid item xs={6}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography component="span" fontWeight="bold">
                    Date début BDC{" "}
                    <Typography component="span" color="error">
                      *
                    </Typography>:
                  </Typography>
                </Grid>
                <Grid item>
                  {/* <input
                  {...formik.getFieldProps("documentDate")}
                  type="text"
                  value={formik.values.documentDate}
                  style={{
                    width: "8vw",
                    height: "42px",
                    border: "none",
                    paddingTop: "1%",
                    paddingLeft: "5%",
                    color: "#5E6278",
                    backgroundColor: "white",
                  }}
                /> */}
                  {/* <input
                    // {...formik.getFieldProps("documentDate")}
                    type="date"
                    id="start"
                    style={{
                      border: "none",
                      paddingLeft: "5%",
                    }}
                    // name="documentDate"
                  /> */}
                  <TextField
                    {...formik.getFieldProps('startDate')}
                    type="date"
                    size="small"
                    id="start"
                    fullWidth
                    style={{
                      height: "42px",
                      marginLeft: "2%",
                      paddingTop: "7%",
                      color: "#5E6278",
                      backgroundColor: "white",
                    }}
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography component="span" fontWeight="bold">
                    Date fin BDC{" "}
                    <Typography component="span" color="error">
                      *
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item>
                  {/* <TextField
                    {...formik.getFieldProps("BDC_NUM")}
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                    placeholder="03048"
                    style={{
                      width: "18vw",
                      height: "42px",
                      color: "#5E6278",
                      paddingLeft: "3%",
                      paddingTop: "3%",
                      backgroundColor: "white",
                    }}
                    size="small"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  /> */}
                  <TextField
                    {...formik.getFieldProps('endDate')}
                    type="date"
                    placeholder="03048"
                    fullWidth
                    style={{
                      // width: "18vw",
                      height: "42px",
                      color: "#5E6278",
                      paddingLeft: "3%",
                      paddingTop: "5%",
                      backgroundColor: "white",
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    size="small"
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ borderStyle: "dashed", marginBottom: "4%" }} />
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignContent="center"
            // alignItems="center"
            mb={4}
            spacing={3}
          >
            <Grid item xs={6}>
              <Typography component="span" fontWeight="bold">
                Client{" "}
              </Typography>
              <br />
              <TextField
                // {...formik.getFieldProps("invoiceDate")}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                placeholder={`INSTITUT ARTLINE`}
                disabled
                fullWidth
                style={{
                  // width: "18vw",
                  padding: "2% 3%",
                  borderRadius: "6px",
                  marginTop: "3%",
                  height: "42px",
                  color: "black",
                  backgroundColor: "#F5F8FA",
                }}
                size="small"
                type="text"
              />{" "}
              <TextField
                // {...formik.getFieldProps("invoiceDate")}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                placeholder={`siret: 79350175000020`}
                disabled
                fullWidth
                style={{
                  // width: "18vw",
                  padding: "2% 3%",
                  marginTop: "3%",
                  height: "42px",
                  borderRadius: "6px",
                  color: "black",
                  backgroundColor: "#F5F8FA",
                }}
                size="small"
                type="text"
              />{" "}
              <TextField
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                placeholder={`117 Rue de charenton \n 75 012 Paris \n +33 09 72 63 22 86`}
                disabled
                fullWidth
                style={{
                  // width: "18vw",
                  padding: "2% 3%",
                  marginTop: "3%",
                  borderRadius: "6px",
                  color: "black",
                  backgroundColor: "#F5F8FA",
                }}
                multiline
                rows={3}
                type="text"
              />{" "}
            </Grid>
            <Grid item xs={6}>
              <Typography component="span" fontWeight="bold">
                Préstataire{" "}
              </Typography>
              <br />
              {suppList && suppListOptions && (
                <Autocomplete
                  sx={{
                    // border: "1px solid blue",
                    "& .MuiOutlinedInput-root": {
                      // border: "1px solid yellow",
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                      border: "none"
                    }
                  }}
                  {...formik.getFieldProps("FRN")}
                  options={suppListOptions}
                  onChange={(e, value: any) => {
                    formik.values.FRN = value.id
                  }}
                  getOptionLabel={(supp) => {
                    return supp?.name ? supp?.name : suppListOptions?.find((el: any) => el.id === supp).name
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value
                  }}
                  fullWidth
                  style={{
                    // width: "18vw", 
                    marginTop: "3%",
                    marginBottom: "3%",
                    height: "52px",
                    color: "#5E6278",
                    backgroundColor: "#F5F8FA",
                  }}
                  // sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} name='FRN' />}
                />
              )}
              <RadioGroup
                {...formik.getFieldProps('prestationOuAchat')}
                aria-labelledby="prestation-ou-achat" row
              //defaultValue={'PRESTATION_SERVICE'}
              >
                <FormControlLabel value="PRESTATION_SERVICE" control={<Radio />} label="Prestation de service" />
                <FormControlLabel value="ACHAT_MARCHANDISE" control={<Radio />} label="Achat de marchandise" />
              </RadioGroup>
              {/* <FormControl fullWidth size="small">
              <InputLabel style={{ marginTop: "3%" }}>Nom</InputLabel>
                <Select
                  {...formik.getFieldProps("FRN")}
                  onChange={formik.handleChange}
                  // error={showFRNError}
                  variant="filled"
                  disableUnderline={true}
                  fullWidth
                  style={{
                    // width: "18vw", 
                    padding: "0",
                    borderRadius: "6px",
                    marginTop: "3%",
                    height: "42px",
                    color: "#5E6278",
                    backgroundColor: "#F5F8FA",
                  }}
                >
                  {suppList.map((supp) => (
                    <MenuItem key={supp.id} value={supp.id}>
                      {supp.nom} {supp.prenom}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              <TextField
                placeholder="Rprendre Détails/Raison social"
                variant="standard"
                type="text"
                disabled
                fullWidth
                InputProps={{
                  disableUnderline: true,
                }}
                value={
                  formik.values.FRN
                    ? suppList.filter(
                      (supp: Supplier) => supp.id === formik.values.FRN
                    )[0].socialReason
                    : ""
                }
                style={{
                  // width: "18vw",
                  // paddingLeft: "3%",
                  padding: "2% 3%",
                  marginTop: "3%",
                  height: "42px",
                  borderRadius: "6px",
                  color: "#5E6278",
                  backgroundColor: "#F5F8FA",
                }}
                size="small"
              />
              <TextField
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                disabled
                placeholder={`Reprendre Adresse \n Pays`}
                value={
                  formik.values.FRN
                    ? suppList.filter(
                      (supp: Supplier) => supp.id === formik.values.FRN
                    )[0].address +
                    `\n` +
                    suppList.filter(
                      (supp: Supplier) => supp.id === formik.values.FRN
                    )[0].pays
                    : ""
                }
                multiline
                fullWidth
                rows={3}
                style={{
                  // width: "18vw",
                  padding: "2% 3%",
                  marginTop: "3%",
                  borderRadius: "6px",
                  color: "#5E6278",
                  backgroundColor: "#F5F8FA",
                }}
                type="text"
              />{" "}
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignContent="center"
              spacing={3}
              mt={1}
            >
              <Grid item xs={6}>
                <Typography component="span" fontWeight="bold" sx={{ padding: "0px 20px", }}>
                  Condition de paiement{" "}
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    marginBottom: margin,
                    "& .MuiSelect-select": { padding: "2px 20px", },
                    padding: "2px 20px",
                  }}
                >
                  <Select
                    {...formik.getFieldProps("paymentTerm")}
                    variant="filled"
                    sx={{
                      color: "#5E6278",
                      fontWeight: "500",
                      fontSize: "14px",
                      background: "#F5F8FA",
                      "&:before": {
                        border: "none",
                      },
                      "&:after": {
                        border: "none",
                      },
                      height: "48px",
                    }}
                  >
                    {ptList.map((pt) => (
                      <MenuItem key={pt._id} value={pt._id}>
                        {pt.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography component="span" fontWeight="bold" sx={{ padding: "0px 20px", }}>
                  Produit{" "}
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    marginBottom: margin,
                    "& .MuiSelect-select": { padding: "2px 20px", },
                    padding: "2px 20px",
                  }}
                >
                  <Select
                    {...formik.getFieldProps("perimeter")}
                    variant="filled"
                    // error={showPerimeterError}
                    sx={{
                      color: "#5E6278",
                      fontWeight: "500",
                      fontSize: "14px",
                      background: "#F5F8FA",
                      "&:before": {
                        border: "none",
                      },
                      "&:after": {
                        border: "none",
                      },
                      height: "48px",
                    }}
                  >
                    {perimetertList.map((pt) => (
                      <MenuItem key={pt._id} value={pt._id}>
                        {pt.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography component="span" fontWeight="bold" sx={{ padding: "0px 20px", }}>
                  Périmètre du BDC{" "}
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    marginBottom: margin,
                    "& .MuiSelect-select": { padding: "2px 20px", },
                    padding: "2px 20px",
                  }}
                >
                  <Select
                    {...formik.getFieldProps("session")}
                    variant="filled"
                    sx={{
                      color: "#5E6278",
                      fontWeight: "500",
                      fontSize: "14px",
                      background: "#F5F8FA",
                      "&:before": {
                        border: "none",
                      },
                      "&:after": {
                        border: "none",
                      },
                      height: "48px",
                    }}
                  >
                    {sessiontList.map((pt) => {
                      return (
                        <MenuItem key={pt._id} value={pt._id}>
                          {pt.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography component="span" fontWeight="bold" sx={{ padding: "0px 20px", }}>
                  Type de dépense{" "}
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    // marginBottom: margin,
                    "& .MuiSelect-select": { padding: "10px" },
                    padding: "0px 20px",
                  }}
                >
                  <Select
                    {...formik.getFieldProps("typeDepense")}
                    variant="filled"
                    sx={{
                      color: "#5E6278",
                      fontWeight: "500",
                      fontSize: "14px",
                      background: "#F5F8FA",
                      "&:before": {
                        border: "none",
                      },
                      "&:after": {
                        border: "none",
                      },
                      height: "48px",
                    }}
                  >
                    {typedepensestList.map((pt) => {
                      return (
                        <MenuItem key={pt._id} value={pt._id}>
                          {pt.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography component="span" fontWeight="bold" sx={{ padding: "0px 20px", }}>
                  Pôle / Centre de coûts{" "}
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
                <FormControl
                  fullWidth

                  size="small"
                  sx={{
                    marginBottom: margin,
                    "& .MuiSelect-select": { padding: "10px" },
                    padding: "0px 20px",
                  }}
                >
                  <Select
                    {...formik.getFieldProps("pole")}
                    variant="filled"
                    sx={{
                      color: "#5E6278",
                      fontWeight: "500",
                      fontSize: "14px",
                      background: "#F5F8FA",
                      "&:before": {
                        border: "none",
                      },
                      "&:after": {
                        border: "none",
                      },
                      height: "48px",
                    }}
                  >
                    {costsCentertList.map((pt) => {
                      return (
                        <MenuItem key={pt._id} value={pt._id}>
                          {pt.description}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography component="span" fontWeight="bold" sx={{ padding: "0px 20px", }}>
                  Projet{" "}
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    // marginBottom: margin,
                    "& .MuiSelect-select": { padding: "10px" },
                    padding: "0px 20px",
                  }}
                >
                  <Select
                    {...formik.getFieldProps("project")}
                    variant="filled"
                    // error={showCostsCenterError}
                    sx={{
                      color: "#5E6278",
                      fontWeight: "500",
                      fontSize: "14px",
                      background: "#F5F8FA",
                      "&:before": {
                        border: "none",
                      },
                      "&:after": {
                        border: "none",
                      },
                      height: "48px",
                    }}
                  >
                    {projetList.map((pt) => {
                      return (
                        <MenuItem key={pt._id} value={pt._id}>
                          {pt.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          {/* {JSON.stringify(formik.values.articles, null, 4)} */}
          <Grid item xs={12} mb={2}>
            <table style={{ width: "100%" }}>
              <thead>
                <th>
                  <tr>ARTICLE</tr>
                </th>
                <th>
                  <tr>UO</tr>
                </th>
                <th>
                  <tr>coût unitaire ht</tr>
                </th>
                <th>
                  <tr>tva</tr>
                </th>
                <th>
                  <tr>Total ht</tr>
                </th>
                <th>
                  <tr>ttc</tr>
                </th>
                <th>
                  <td>action</td>
                </th>
              </thead>
              <tbody>
                <FieldArray
                  name="articles"
                  render={(arrayHelpers) => (
                    <>
                      <Button
                        startIcon={<Add />}
                        style={{ margin: "10px 3%" }}
                        variant="outlined"
                        onClick={() => {
                          arrayHelpers.push({
                            idArticle: "",
                            qty: 1,
                            HT: 0,
                            TVA: 0,
                            THT: 0,
                            TTC: 0,
                          });
                          // setQuantities({
                          //   ...quantities,
                          //   [formik.values.articles.length]: 1,
                          // });
                        }}
                      >
                        Ajouter un article
                      </Button>
                      {formik.values.articles.map((article, index) => (
                        <tr>
                          <td>
                            <FormControl fullWidth size="small">
                              <Select
                                {...formik.getFieldProps(
                                  `articles[${index}].idArticle`
                                )}
                                variant="filled"
                                disableUnderline={true}
                                onChange={(e) => {
                                  const article = articlesList.filter(
                                    (art: any) => art._id === e.target.value
                                  )[0];
                                  formik.values.articles[index].idArticle =
                                    e.target.value;
                                  const THT = calculateTHT(index);
                                  const TTC = calculateTCC(index);
                                  formik.values.articles[index].HT = article.HT;
                                  formik.values.articles[index].TVA =
                                    article.TVA;
                                  formik.values.articles[index].THT = THT;
                                  formik.values.articles[index].TTC = TTC;
                                  formik.values.GHT = calculateGHT();
                                  formik.values.MTVA = calculateMTVA();
                                  formik.values.GTTC = calculateGTTC();
                                }}
                                fullWidth
                                style={{
                                  // width: "18vw",
                                  borderRadius: "6px",
                                  height: "42px",
                                  color: "#5E6278",
                                  paddingBottom: "15px",
                                  backgroundColor: "#F5F8FA",
                                }}
                              >
                                {articlesList.map((art) => (
                                  <MenuItem key={art._id} value={art._id}>
                                    {art.nomArticle}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </td>
                          <td>
                            <Field
                              render={() => {
                                return (
                                  <TextField
                                    variant="standard"
                                    {...formik.getFieldProps(
                                      `articles[${index}].qty`
                                    )}
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    inputProps={{ inputMode: 'decimal' }}
                                    fullWidth
                                    type="text"
                                    defaultValue={quantities[index]}
                                    onChange={(e) => {
                                      const value: any = e.target.value;
                                      formik.values.articles[index].qty = value;
                                      formik.values.articles[index].THT =
                                        calculateTHT(index);
                                      formik.values.articles[index].TTC =
                                        calculateTCC(index);
                                      const GHT = calculateGHT();
                                      formik.values.GHT = GHT;
                                      const GTTC = calculateGTTC();
                                      formik.values.GTTC = GTTC;
                                      const MTVA = calculateMTVA();
                                      formik.values.MTVA = MTVA;
                                    }}
                                    style={{
                                      color: "black",
                                      backgroundColor: "#F5F8FA",
                                      padding: "3% 3%",
                                      borderRadius: "6px",
                                      height: "42px",
                                    }}
                                  />
                                )
                              }}
                            />
                          </td>
                          <td>
                            <Field
                              render={() => {
                                return (
                                  <TextField
                                    type="number"
                                    placeholder="0.00"
                                    {...formik.getFieldProps(`articles[${index}].HT`)}
                                    disabled
                                    style={{
                                      color: "black",
                                      backgroundColor: "#F5F8FA",
                                      padding: "5% 3%",
                                      borderRadius: "6px",
                                      height: "42px",
                                    }}
                                    variant="standard"
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                  />
                                )
                              }}
                            />
                          </td>
                          <td>
                            <Field
                              render={() => (
                                <TextField
                                  type="number"
                                  {...formik.getFieldProps(
                                    `articles[${index}].TVA`
                                  )}
                                  disabled
                                  fullWidth
                                  style={{
                                    color: "black",
                                    // width: "8vw",
                                    backgroundColor: "#F5F8FA",
                                    borderRadius: "6px",
                                    padding: "6% 3%",
                                    height: "42px",
                                  }}
                                  variant="standard"
                                  InputProps={{
                                    disableUnderline: true,
                                  }}
                                />
                              )}
                            />
                          </td>
                          <td>
                            <Field
                              render={() => {
                                return (
                                  <TextField
                                    variant="standard"
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    {...formik.getFieldProps(
                                      `articles[${index}].THT`
                                    )}
                                    // value={()=> {
                                    //   console.log(calculateTHT(index))
                                    //   return calculateTHT(index);
                                    // }}
                                    // defaultValue={()=> {
                                    //   console.log(calculateTHT(index))
                                    //   return calculateTHT(index);
                                    // }}
                                    disabled

                                    placeholder="0.00"
                                    style={{
                                      color: "black",
                                      padding: "6% 3%",
                                      borderRadius: "6px",
                                      backgroundColor: "white",
                                      height: "42px",
                                    }}
                                    type="number"
                                  />
                                )
                              }}
                            />
                          </td>
                          <td>
                            <Field
                              render={() => (
                                <TextField
                                  variant="standard"
                                  {...formik.getFieldProps(
                                    `articles[${index}].TTC`
                                  )}
                                  InputProps={{
                                    disableUnderline: true,
                                  }}
                                  disabled
                                  fullWidth
                                  placeholder="0.00"
                                  // value={
                                  //   formik.values.articles[index]?.TTC ??
                                  //   calculateTCC(index)
                                  // }
                                  style={{
                                    borderRadius: "6px",
                                    padding: "6% 3%",
                                    backgroundColor: "white",
                                    height: "42px",
                                  }}
                                  type="number"
                                />
                              )}
                            />
                          </td>
                          <td>
                            <Button
                              size="large"
                              startIcon={<Delete color="error" />}
                              onClick={() => {
                                arrayHelpers.remove(index);
                                formik.values.articles = formik.values.articles.filter((el: any) => el.idArticle !== article.idArticle)
                                formik.values.GTTC = calculateGTTC();
                                formik.values.GHT = calculateGHT();
                                formik.values.MTVA = calculateMTVA();
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                />
              </tbody>
            </table>
          </Grid>
          <Grid container direction="column" justifyContent="end">
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <Typography>Grand Total Hors taxe </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Field
                    render={() => (
                      <TextField
                        disabled
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        {...formik.getFieldProps(`GHT`)}
                        placeholder="0.00"
                        style={{
                          color: "black",
                          padding: "2% 3%",
                          backgroundColor: "white",
                          height: "42px",
                        }}
                        // value={calculateGHT()}
                        type="number"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <Typography>Montant TVA </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Field
                    render={() => (
                      <TextField
                        disabled
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        {...formik.getFieldProps(`MTVA`)}
                        placeholder="0"
                        style={{
                          color: "black",
                          padding: "2% 3%",
                          backgroundColor: "white",
                          height: "42px",
                        }}
                        // value={calculateMTVA()}
                        type="number"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <Typography>Grand total TTC </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Field
                    render={() => (
                      <TextField
                        disabled
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        {...formik.getFieldProps(`GTTC`)}
                        placeholder="0.00"
                        style={{
                          color: "black",
                          padding: "2% 3%",
                          backgroundColor: "white",
                          height: "42px",
                        }}
                        // value={calculateGTTC()}
                        type="number"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: "90px" }}>
            <Typography component="span" fontWeight="bold">
              Informations de la mission{" "}
              <Typography component="span" color="error">
                *
              </Typography>
            </Typography>
            <TextField
              {...formik.getFieldProps("missionInformation")}
              multiline
              fullWidth
              style={{
                marginTop: "1%",
                marginBottom: margin,
                backgroundColor: "#F5F8FA",
                padding: "1%",
                borderRadius: "6px",
              }}
              rows={3}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            // defaultValue={formik.values.missionInformation}
            />
          </Grid>
          {/* ==================================================================== */}
          <div style={{ position: "absolute", bottom: "0", left: "0", background: "white", right: "0", display: "flex", padding: "10px", justifyContent: "center", alignItems: "center" }} >
            <GrayButton style={{ marginRight: "10px" }} onClick={props.handleCloseEditDialog}>
              Annuler
            </GrayButton>
            <BlueButton
              style={{ marginLeft: "10px" }}
              type="submit"
              // disabled={formik.values.startDate?.trim().length === 0 || formik.values.endDate?.trim().length === 0}
            >Soumettre</BlueButton>
          </div>
        </FormikProvider>
      </form>
      {/* {JSON.stringify(
      suppList.filter(
        (supp: any) => supp.id === formik.getFieldProps("FRN").value
      )[0].socialReason
    )} */}
    </div>
  );
};

export default EditOrderForm2;
