import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CustomDialogTitle from "components/custom-dialog-title/CustomDialogTitle.component";
import BlueButton from "components/blue-button/BlueButton.component";
import GrayButton from "components/gray-button/GrayButton.component";
import Divider from "@mui/material/Divider";
import Form from "./Form.component";
import { getProjectsThunk} from "store/project/project.thunk";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import { useEffect } from "react";
import { uiActions } from "store/ui/ui.state";
import { deleteProjectThunk } from "store/project/project.thunk";
import ListProjet from "./ListProjet";

export default function Pole() {
  const dispatch = useAppDispatch();
  const { deleteProjectWarningOpen, idOfItemToDelete } = useAppSelector(
    (state) => state.ui
  );

  const closeDeleteProjectWarning = () => {
    dispatch(uiActions.setDeleteProjectWarningOpen({ set: false, id: "" }));
  };
  useEffect(() => {
    dispatch(getProjectsThunk());
  }, []);

  return (
    <>
      <Form />
      <Divider sx={{ marginBottom: "20px" }} />
      <ListProjet />

      <Dialog
        open={deleteProjectWarningOpen}
        onClose={closeDeleteProjectWarning}
        sx={{ "& .MuiPaper-root": { width: "500px" } }}
      >
        <CustomDialogTitle onClose={closeDeleteProjectWarning} id="">
          Suppression
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          Voulez-vous vraiment supprimer cette projet ?
        </DialogContent>
        <DialogActions>
          <BlueButton
            onClick={() => {
              dispatch(deleteProjectThunk(idOfItemToDelete));
              dispatch(
                uiActions.setDeleteProjectWarningOpen({ set: false, id: "" })
              );
            }}
          >
            Oui
          </BlueButton>
          <GrayButton onClick={closeDeleteProjectWarning}>No</GrayButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
