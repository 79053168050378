import { Field, FormikProvider, useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import * as Yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import {
  addInvoiceThunk,
  getInvoicesByVendorIdThunk,
  getInvoicesThunk,
} from "store/invoices/invoice.thunk";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
// import Box from "@mui/material/Box";
import BlueButton from "components/blue-button/BlueButton.component";
import GrayButton from "components/gray-button/GrayButton.component";
import { defaultTodayDate } from "utils/globalVariables";
// import { Supplier } from "store/suppliers/suppliers.state";
// import { ITva } from "store/tva/tva.state";
import { Order } from "store/orders/orders.state";
// import InvoiceStatus from "../invoice-status/InvoiceStatus";
import { InvoiceStatusEnum } from "store/invoices/invoice.state";
import { width } from "@mui/system";

interface IAddInvoiceFormProps {
  handleCloseAddDialog: () => void;
}
type ErrorProp = boolean | undefined;

const AddInvoice: React.FC<IAddInvoiceFormProps> = ({
  handleCloseAddDialog,
}) => {
  const dispatch = useAppDispatch();
  const suppList = useAppSelector((state) => state.suppliers.list)!;
  const orderList = useAppSelector((state) => state.orders.list)!;
  const tvaList = useAppSelector((state) => state.tvas.list)!;
  const accountType = useAppSelector((state) => state.auth.accountType)!;
  const authenticatedUser: any = useAppSelector((state) => state.auth.user)!;
  const [invoiceInputValue, setInvoiceInputValue] = useState<any>({});
  const invoiceInputRef = useRef<HTMLInputElement>(null);
  const [showRestError, setShowRestError] = useState<boolean>(false);
  const [showFileError, setShowFileError] = useState<boolean>(false);
  const [showPayedBDCError, setShowPayedBDCError] = useState<boolean>(false);
  const [hideTvaTtc, setHideTvaTtc] = useState<boolean>(true)

  const calculateTTC = () => {
    const TTC =
      (formik.values.amount as number) +
      (0 *
        (formik.values.amount as number)) /
      100;
    formik.values.TTC = TTC;
    return Math.round((TTC + Number.EPSILON) * 100) / 100;
  };
  const calculateReste = () => {
    const MBDC = orderList.filter(
      (item: any) =>
        item._id === formik.values.purchaseOrder
    )[0]?.GTTC ?? 0
    const reste = orderList.filter(
      (item: any) =>
        item._id === formik.values.purchaseOrder
    )[0]?.reste ?? 0
    if (reste !== 0) {
      const resteCalculted = reste - calculateTTC()
      formik.values.invoiceRest = resteCalculted
      return Math.round((resteCalculted + Number.EPSILON) * 100) / 100;
    } else {
      // const resteCalculted = MBDC - calculateTTC()
      // formik.values.invoiceRest = resteCalculted
      return 0;
    }
  };

  const noTva = tvaList?.find((t) => t.value === 0)?._id

  const formik: any = useFormik({
    initialValues: {
      invoiceDate: `${defaultTodayDate}`,
      purchaseOrder: "" as any,
      fournisseur: accountType === "vendor" ? authenticatedUser : "",
      amount: 0,
      invoiceRest: 0,
      status: InvoiceStatusEnum.Créée,
      invoiceName: "",
      // TVA: `${noTva}`,
      TTC: 0,
    },
    validationSchema: Yup.object({
      invoiceDate: Yup.string().required("Date obligatoire"),
      purchaseOrder: Yup.string(),
      // fournisseur: Yup.string().required("Fournisseur obligatoire"),
      amount: Yup.number().min(1, 'Montant doit être supérieur à zéro')
        .required("Montant obligatoire"),
      status: Yup.string().required("Status obligatoire"),
      invoiceName: Yup.string().required("Nom obligatoire"),
      // TVA: Yup.string(),
    }),
    onSubmit: async (values) => {
      // console.log('T:')
      const RESTE = orderList.filter(
        (order: Order) => order._id === values.purchaseOrder
      )[0].reste;
      if (RESTE !== 0) {
        if (Object.keys(invoiceInputValue).length === 0) {
          setShowFileError(true);
          return;
        }
        values.fournisseur = orderList.filter(
          (order: Order) => order._id === values.purchaseOrder
        )[0].FRN.id;

        if (values.invoiceRest < 0) {
          setShowRestError(true);
          return;
        }

        try {
          await dispatch(
            addInvoiceThunk(values as any, invoiceInputRef.current!.files![0])
          );
          handleCloseAddDialog();

          if (accountType === "vendor") {
            await dispatch(getInvoicesByVendorIdThunk(authenticatedUser.id));
          } else {
            await dispatch(getInvoicesThunk());
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setShowPayedBDCError(true)
      }
    },
  });

  // const showTVAError = (formik.errors.TVA && formik.touched.TVA) as ErrorProp;
  const showAmountError = (formik.errors.amount &&
    formik.touched.amount) as ErrorProp;
  const showFournisseurError = (formik.errors.fournisseur &&
    formik.touched.fournisseur) as ErrorProp;
  const showInvoiceDateError = (formik.errors.invoiceDate &&
    formik.touched.invoiceDate) as ErrorProp;
  const showInvoiceNameError = (formik.errors.invoiceName &&
    formik.touched.invoiceName) as ErrorProp;
  const showPurchaseOrderError = (formik.errors.purchaseOrder &&
    formik.touched.purchaseOrder) as ErrorProp;

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormikProvider value={formik}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={4}
          style={{ width: "40vw", margin: "3% auto" }}
        >
          <Grid item xs={12}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={12}>
                <Typography component="span" fontWeight="bold">
                  Nom du document{" "}
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  {...formik.getFieldProps("invoiceName")}
                  type="text"
                  error={showInvoiceNameError}
                  style={{
                    width: "37vw",
                    height: "42px",
                    color: "#5E6278",
                    fontSize: "15px",
                    fontWeight: "500",
                    padding: "0 3%",
                    backgroundColor: "#F5F8FA",
                    border: "none",
                  }}
                />
                <FormHelperText error>
                  {showInvoiceNameError && formik.errors.invoiceName}{" "}
                </FormHelperText>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ width: "38vw" }}
            >
              <Grid item xs={6}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  style={{ width: "18vw" }}
                >
                  <Grid item xs={12}>
                    <Typography component="span" fontWeight="bold">
                      Date de facture{" "}
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      {...formik.getFieldProps("invoiceDate")}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      error={showInvoiceDateError}
                      style={{
                        width: "18vw",
                        padding: "3%",
                        height: "42px",
                        color: "#5E6278",
                        backgroundColor: "#F5F8FA",
                      }}
                      size="small"
                      type="date"
                    />{" "}
                    <FormHelperText error>
                      {showInvoiceDateError && formik.errors.invoiceDate}{" "}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  style={{ width: "18vw" }}
                >
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                    >
                      <Grid item xs={12}>
                        <Typography component="span" fontWeight="bold">
                          Fournisseur{" "}
                          {/* <Typography component="span" color="error">
                            *
                          </Typography> */}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {accountType === "vendor" ? (
                          <>
                            <Field
                              render={() => {
                                <TextField
                                  fullWidth
                                  label="Fournisseur"
                                  style={{ width: "18vw", height: "42px" }}
                                  {...formik.getFieldProps("fournisseur")}
                                  type="text"
                                />;
                              }}
                            />
                            <TextField
                              {...formik.getFieldProps("fournisseur")}
                              placeholder="Fournisseur"
                              disabled
                              style={{ width: "18vw", height: "42px" }}
                              value={`${authenticatedUser.nom} ${authenticatedUser.prenom}`}
                              size="small"
                              type="text"
                            />
                          </>
                        ) : (
                          <>
                            <FormControl
                              size="small"
                              sx={{
                                "& .MuiSelect-select": { padding: "10px" },
                              }}
                            >
                              <Select
                                {...formik.getFieldProps("fournisseur")}
                                onChange={formik.handleChange}
                                // label="civilitesss"
                                error={showFournisseurError}
                                variant="filled"
                                value={
                                  formik?.values?.purchaseOrder
                                    ? orderList.filter(
                                      (item: Order) =>
                                        item?._id ===
                                        formik.values?.purchaseOrder
                                    )[0]?.FRN?.id
                                    : ""
                                }
                                sx={{
                                  color: "#5E6278",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  width: "18vw",
                                  background: "#F5F8FA",
                                  "&:before": {
                                    border: "none",
                                  },
                                  "&:after": {
                                    border: "none",
                                  },
                                }}
                              >
                                {suppList.map((supp) => (
                                  <MenuItem key={supp.id} value={supp.id}>
                                    {supp.socialReason}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormHelperText error>
                              {showFournisseurError &&
                                formik.errors.fournisseur?.toString()}
                            </FormHelperText>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ width: "38vw" }}
            >
              <Grid item xs={6}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Grid item xs={12}>
                    <Typography component="span" fontWeight="bold">
                      Numéro de bon de commande associé {" "}
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl size="small">
                      <Select
                        {...formik.getFieldProps("purchaseOrder")}
                        onChange={formik.handleChange}
                        variant="standard"
                        error={showPurchaseOrderError}
                        sx={{
                          width: "18vw",
                          height: "40px",
                          fontWeight: "500",
                          padding: "4%",
                          borderRadius: "6px",
                          fontSize: "14px",
                          color: "#5E6278",
                          background: "#F5F8FA",
                          "&:before": {
                            border: "none",
                          },
                          "&:after": {
                            border: "none",
                          },
                        }}
                      >
                        {orderList
                          .filter((item) => item.status === "Actif")
                          .map((order) => (
                            <MenuItem key={order._id} value={order._id}>
                              {order.BDC_NUM}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText error>
                        {showPurchaseOrderError &&
                          formik.errors.purchaseOrder?.toString()}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid>
                  <Grid item xs={12}>
                    <Typography component="span" fontWeight="bold">
                      Montant{" "}
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      type="number"
                      error={showAmountError}
                      style={{
                        width: "18vw",
                        height: "42px",
                        color: "#5E6278",
                        backgroundColor: "#F5F8FA",
                        border: "none",
                        paddingLeft: "4%",
                      }}
                      {...formik.getFieldProps("amount")}
                    />
                    <FormHelperText error>
                      {showAmountError && formik.errors.amount}{" "}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ width: "38vw" }}
            >
              <Grid item xs={6}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Grid item xs={12}>
                    <Typography component="span" fontWeight="bold">
                      Téléverser la Facture{" "}
                      <Typography component="span" color="error">
                        *
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" component="label">
                      <KeyboardArrowDownIcon />
                      <input
                        hidden
                        ref={invoiceInputRef}
                        type="file"
                        onChange={() =>
                          setInvoiceInputValue({ ...invoiceInputRef })
                        }
                      />
                    </Button>
                    <br />
                    {showFileError === true && (
                      <FormHelperText error>
                        Ce champ est obligatoire
                      </FormHelperText>
                    )}
                    <span>
                      {invoiceInputValue.current?.value.split("\\")[
                        invoiceInputValue.current?.value.split("\\").length - 1
                      ] ?? ""}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid>
                  <Grid item xs={12}>
                    <Typography component="span" fontWeight="bold">
                      Montant BDC (en € TTC){" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      disabled
                      type="number"
                      value={
                        orderList.filter(
                          (item: any) =>
                            item._id === formik.values.purchaseOrder
                        )[0]?.GTTC ?? 0
                      }
                      style={{
                        width: "18vw",
                        height: "42px",
                        color: "#5E6278",
                        backgroundColor: "rgb(226 232 240)",
                        border: "none",
                        paddingLeft: "4%",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} >
              </Grid>
              {/* empty elements serves to adjust */}
              <div style={{ margin: '10px 0' }}>&#x9;</div>
              <Grid item xs={6} ></Grid>
              <Grid item xs={6} ></Grid>
              <Grid item xs={6} >
                <Grid>
                  <Grid item xs={12}>
                    <Typography component="span" fontWeight="bold">
                      Reste à facturer{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      disabled
                      type="number"
                      value={calculateReste() ?? 0}
                      // value={orderList.filter(
                      //     (item: any) =>
                      //       item._id === formik.values.purchaseOrder
                      //   )[0]?.reste ?? 0}
                      style={{
                        width: "18vw",
                        height: "42px",
                        color: "#5E6278",
                        backgroundColor: "rgb(226 232 240)",
                        border: "none",
                        paddingLeft: "4%",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ width: "38vw" }}
            >

              {/* {!hideTvaTtc && (
                <Grid item xs={6}>
                  <Grid>
                    <Grid item xs={12}>
                      <Typography component="span" fontWeight="bold">
                        TVA{" "}
                        <Typography component="span" color="error">
                          *
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl size="small">
                        <Select
                          {...formik.getFieldProps("TVA")}
                          onChange={formik.handleChange}
                          variant="standard"
                          error={showTVAError}
                          sx={{
                            width: "18vw",
                            height: "40px",
                            fontWeight: "500",
                            padding: "4%",
                            borderRadius: "6px",
                            fontSize: "14px",
                            color: "#5E6278",
                            background: "#F5F8FA",
                            "&:before": {
                              border: "none",
                            },
                            "&:after": {
                              border: "none",
                            },
                          }}
                        >
                          {tvaList.map((tva) => (
                            <MenuItem key={tva._id} value={tva._id} selected={+tva.value === 0}>
                              {tva.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormHelperText error>
                        {showTVAError && formik.errors.TVA}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>
              )} */}

            </Grid>
          </Grid>
          {!hideTvaTtc && (
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                style={{ width: "37vw", marginBottom: "60px" }}
              >
                <Grid item xs={6}>
                  <Grid>
                    <Grid item xs={12}>
                      <Typography component="span" fontWeight="bold">
                        Montant TTC{" "}
                        <Typography component="span" color="error">
                          *
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        type="number"
                        {...formik.getFieldProps("TTC")}
                        value={calculateTTC() ?? 0}
                        style={{
                          width: "18vw",
                          height: "42px",
                          color: "#5E6278",
                          backgroundColor: "#F5F8FA",
                          border: "none",
                          paddingLeft: "4%",
                        }}
                      />
                      <FormHelperText error>
                        {showRestError && (
                          <span>
                            {" "}
                            Le total de votre facture ne peut pas être supérieur
                            au reste à facturer{" "}
                          </span>
                        )}{" "}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          <div style={{ position: "absolute", bottom: "0", left: "0", background: "white", right: "0", display: "flex", padding: "10px", justifyContent: "center", alignItems: "center" }} >
            <GrayButton style={{ marginRight: "40px" }} onClick={handleCloseAddDialog}>Annuler</GrayButton>

            <BlueButton style={{ marginLeft: "40px" }} variant="contained" sx={{ textTransform: "none" }} type="submit">Soumettre</BlueButton>
          </div>
        </Grid>
      </FormikProvider >
    </form >
  );
};

export default AddInvoice;
