import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Supplier } from "store/suppliers/suppliers.state";
import {User} from "store/users/users.state"

export type AccountType = "user" | "vendor" | "" 

type Auth = {
    accountType: AccountType;
    user: User | Supplier | null;
    // supplier : Supplier | null;
    token: string;
}

const initialState: Auth = {
    accountType: localStorage.getItem("accountType") !== "undefined" ? localStorage.getItem("accountType") as AccountType : "", 
    user: localStorage.getItem("user") !== "undefined" ? JSON.parse(localStorage.getItem("user")!) : null,
    token: localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : "" 
}

// console.log("state", initialState)

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<User | null>) {
            state.user = action.payload
        },
        setToken(state, action: PayloadAction<string>) {
            state.token = action.payload
        },
        setAccountType(state, action: PayloadAction<AccountType>) {
            state.accountType = action.payload
        },
        updateUserAvatarUrl(state, action: PayloadAction<string>) {
            (state.user as User).avatarUrl = action.payload
        }
    }
})

export const authActions = authSlice.actions
export const authReducer = authSlice.reducer