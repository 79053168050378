import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import {
  DataGridStateEnum,
  DataGridStatesActions,
  IDataGridState,
} from "store/datagrid-state/dataGridState.state";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import { uiActions } from "store/ui/ui.state";
import {
  deleteDatagridStateThunk,
  favoriteDatagridStateThunk,
  getDatagridStateByUserIdThunk,
  getDatagridStatesThunk,
} from "store/datagrid-state/dataGridState.thunk";
import CustomDialogTitle from "components/custom-dialog-title/CustomDialogTitle.component";
import WhiteButton from "components/white-button/WhiteButton.component";
import BlueButton from "components/blue-button/BlueButton.component";

interface IViewSettingsProps {
  views: IDataGridState[];
}

const ViewSettings: React.FC<IViewSettingsProps> = ({ views }) => {
  const dispatch = useAppDispatch();
  const selectedView = useAppSelector(
    (state) => state.dataGridStates.selectedDataGridStateUserToEdit
  )!;
  const authUser = useAppSelector((state) => state.auth.user)!;
  const defaultView = {
    _id: "0",
    id: "0",
    dataGridName: DataGridStateEnum.User,
    favorite: false,
    viewName: "Par défaut",
  };

  const { deleteVieweWarningOpen } = useAppSelector((state) => state.ui);
  const closeDeleteViewDialog = () => {
    dispatch(uiActions.setDeleteViewWarningOpen({ set: false, id: "" }));
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={10}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Checkbox
              checked={defaultView.favorite}
              icon={<FavoriteBorder />}
              checkedIcon={<Favorite />}
            />
            <Typography> {defaultView.viewName} </Typography>
          </Grid>
        </Grid>
      </Grid>
      {views.map((view: IDataGridState) => {
        return (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={8}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Checkbox
                  checked={view.favorite}
                  icon={<FavoriteBorder />}
                  checkedIcon={<Favorite />}
                  onClick={async () => {
                    try {
                      await dispatch(favoriteDatagridStateThunk(view._id));
                      await dispatch(
                        getDatagridStateByUserIdThunk(authUser.id)
                      );
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                />
                <Typography> {view.viewName} </Typography>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                <IconButton aria-label="editer" color="primary">
                  <EditIcon
                    onClick={() => {
                      dispatch(
                        DataGridStatesActions.setSelectDataGridStateUserToEdit(
                          view
                        )
                      );
                      dispatch(uiActions.setAddViewFormOpen(true));
                    }}
                  />
                </IconButton>
                <IconButton aria-label="supprimer" color="error">
                  <DeleteIcon
                    onClick={() => {
                      dispatch(
                        DataGridStatesActions.setSelectDataGridStateUserToEdit(
                          view
                        )
                      );
                      dispatch(
                        uiActions.setDeleteViewWarningOpen({
                          set: true,
                          id: view._id,
                        })
                      );
                    }}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        );
      })}

      {/* delete view dialog */}
      <Dialog
        fullScreen
        open={deleteVieweWarningOpen}
        onClose={closeDeleteViewDialog}
        sx={{ "& .MuiPaper-root": { height: "25vh", width: "40vw" } }}
        maxWidth="lg"
      >
        <CustomDialogTitle onClose={closeDeleteViewDialog} id="">
          Supprimer la vue
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          Voulez-vous vraiment supprimer cette option ?
        </DialogContent>
        <DialogActions>
          <WhiteButton
            onClick={() => {
              dispatch(DataGridStatesActions.clearSelectedDataGridState());
              closeDeleteViewDialog();
            }}
          >
            Annuler
          </WhiteButton>
          <BlueButton
            onClick={async () => {
              try {
                await dispatch(deleteDatagridStateThunk(selectedView._id));
                await dispatch(getDatagridStateByUserIdThunk(authUser.id));
              } catch (error) {
                console.log(error);
              }
              dispatch(DataGridStatesActions.clearSelectedDataGridState());
              closeDeleteViewDialog();
            }}
          >
            Supprimer
          </BlueButton>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default ViewSettings;
