import { axiosInstance } from "api/axiosInstance";
import { feedbackMessageUIActions } from "store/feedback-message-ui/feedbackMessageUI.state";
import { AppDispatch } from "store/store.state";
import { IPaymentTerm, paymentTermsActions } from "./paymentTerms.state";


export function getPaymentTermssThunk() {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axiosInstance.get(
                "/paymentterms",
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                    },
                }
            );
            const pts: IPaymentTerm[] = response.data.data;
            dispatch(paymentTermsActions.setList(pts));
        } catch (e) {
            console.log(e);
        }
    };
}

export function addPaymentTermThunk(pt: Omit<IPaymentTerm, "id">) {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axiosInstance.post(
                "/paymentterms",
                pt,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                    },
                }
            );
            const completePt = response.data.data;
            dispatch(paymentTermsActions.addPaymentTerm(completePt));
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message: "Condition de paiement ajoutée avec succès",
                })
            );
        } catch (e) {
            console.log(e);
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message:
                        "Impossible d'ajouter une Condition de paiement s'il vous plaît réessayer plus tard",
                })
            );
        }
    };
}

export function editPaymentTermThunk(obj: { id: string; pt: Omit<IPaymentTerm, "id"> }) {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axiosInstance.put(
                `/paymentterms/${obj.id}`,
                obj.pt,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                    },
                }
            );
            const editedPt = response.data.data;
            dispatch(paymentTermsActions.editPaymentTerm({ id: obj.id, pt: editedPt }));
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message: "Condition de paiement mis à jour avec succès",
                })
            );
        } catch (e) {
            console.log(e);
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message:
                        "Impossible de mettre à jour condition de paiement s'il vous plaît réessayer plus tard",
                })
            );
        }
    };
}


export function deletePaymentTermThunk(id: string) {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axiosInstance.delete(`/paymentterms/${id}`, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
                },
            });
            dispatch(paymentTermsActions.deletePaymentTerm(id));
            dispatch(
                feedbackMessageUIActions.setState({
                    open: true,
                    message: "Condition de paiement supprimée avec succès",
                })
            );
        } catch (e: any) {
            if (e.response.status === 409) {
                dispatch(
                    feedbackMessageUIActions.setState({
                        open: true,
                        message:
                            "Cette donnée est utilisée, vous ne pouvez pas la supprimer",
                    })
                );
            } else {
                dispatch(
                    feedbackMessageUIActions.setState({
                        open: true,
                        message:
                            "Impossible de supprimer condition de paiement s'il vous plaît réessayer plus tard",
                    })
                );
            }

        }
    };
}