import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import UsersList from "../users-list/UsersList.component";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { useState } from "react";

export default function Header() {
  const params = useParams();
  const [usersListOpen, setUsersListOpen] = useState(false);
  return (
    <>
      <Grid
        container
        alignItems="flex-end"
        justifyContent="space-between"
        sx={{ marginBottom: "40px" }}
      >
        <Grid item>
          {/* <Typography variant="h6">Rôles</Typography>
          <Breadcrumbs>
            <Typography variant="caption">Paramètres</Typography>
            <Typography variant="caption">Rôles</Typography>
            <Typography variant="caption" color="text.primary">
              {params.id ? "Voire le role" : "Liste des rôles"}
            </Typography>
          </Breadcrumbs> */}
        </Grid>
        <Grid item>
          <Button
            sx={{
              backgroundColor: "#1DC894",
              "&:hover": {
                backgroundColor: "#1DC894",
              },
            }}
            variant="contained"
            onClick={() => setUsersListOpen(true)}
          >
            Affecter un utilisateur
          </Button>
        </Grid>
      </Grid>

      <Dialog open={usersListOpen} onClose={() => setUsersListOpen(false)}>
        <DialogContent>
          <UsersList
            handleClose={() => {
              setUsersListOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
