import { useState, useRef, FormEvent } from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";

import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";

// import useImageBase64Encoder from "hooks/useImageBase64Ecoder";
import BlueButton from "components/blue-button/BlueButton.component";
import GrayButton from "components/gray-button/GrayButton.component";
import { uiActions } from "store/ui/ui.state";
import { User } from "store/users/users.state";

import {uploadAvatarThunk} from "store/users/users.thunk"


type UploadAvatarFormProps = {
    closeUploadAvatarForm: () => void;
};

export default function UploadAvatarForm(props: UploadAvatarFormProps) {
  const dispatch = useAppDispatch();
  const authenticatedUser = useAppSelector(state => state.auth.user as User)
  const [mediaUrl, setMediaUrl] = useState(authenticatedUser.avatarUrl);
  const imageInputRef = useRef<HTMLInputElement>(null);

  const removeChosenAvatar = () => {
    imageInputRef.current!.value = "";
    setMediaUrl("");
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    dispatch(uploadAvatarThunk(authenticatedUser.id, imageInputRef.current!.files![0]))
    dispatch(uiActions.setUploadAvatarFormOpen(false))
  }


  return (
    <form onSubmit={handleSubmit}>
      <Typography component="span" fontWeight="bold">
        Avatar
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box
          position="relative"
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="120px"
          marginBottom="30px"
        >
          <Avatar
            src={mediaUrl}
            sx={{
              borderRadius: "0",
              width: "100%",
              height: "120px",
              zIndex: 1,
              boxShadow: 5,
              padding: "3px",
            }}
          />
          <IconButton
            sx={{
              boxShadow: 5,
              position: "absolute",
              bottom: "-10px",
              right: "-10px",
              zIndex: 2,
              background: "white",
              padding: "2px",
            }}
            onClick={removeChosenAvatar}
          >
            <ClearIcon sx={{ fontSize: "20px" }} />
          </IconButton>

          <div
            style={{
              position: "absolute",
              top: "-10px",
              right: "-10px",
              width: "20px",
              height: "20px",
            }}
          >
            <input
              ref={imageInputRef}
              type="file"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 0,
                height: "100%",
                width: "100%",
                zIndex: 2,
                cursor: "pointer",
              }}
              onChange={(e) => {
                setMediaUrl(URL.createObjectURL(e.target.files![0]));
              }}
            />
            <IconButton
              sx={{
                boxShadow: 5,
                position: "absolute",
                top: "0px",
                right: "0px",
                zIndex: 1,
                background: "white",
                padding: "2px",
              }}
              onClick={removeChosenAvatar}
            >
              <EditIcon sx={{ fontSize: "20px" }} />
            </IconButton>
          </div>
        </Box>

        <Typography
          display="block"
          variant="caption"
          align="center"
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          Types de fichiers autorisés: png, jpg, jpeg
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center" gap="10px" marginTop="20px">
        <GrayButton
          onClick={props.closeUploadAvatarForm}
        >
          Annuler
        </GrayButton>
        <BlueButton
          disabled={imageInputRef.current?.files?.length === 0}
          variant="contained"
          type="submit"
          sx={{ textTransform: "none" }}
        >
          Soumettre
        </BlueButton>
      </Box>
    </form>
  );
}
