import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Session {
    _id: string,
    name: string
}
type InitialState = {
    selectedSessionToEdit: Session | null;
    list: Session[];
  };
  
  const initialState: InitialState = {
    selectedSessionToEdit: null,
    list: [],
  };

const sessionSlice = createSlice({
    name: "session",
    initialState,
    reducers: {
      setList(state, action: PayloadAction<Session[]>) {
        state.list = action.payload;
      },
      addSession(state, action: PayloadAction<Session>) {
        state.list.push(action.payload);
      },
      deleteSession(state, action: PayloadAction<string>) {
        // pauload: user id
        state.list = state.list.filter((session) => session._id !== action.payload);
      },
      editSession(state, action: PayloadAction<{ id: string; session: Session }>) {
        state.list = state.list.map((session) => {
          if (session._id === action.payload.id) return action.payload.session;
          return session;
        });
      },
      selectSessionToEdit(state, action: PayloadAction<string>) {
        // payload: user id
        // NOTE: object spread doesnt deep copy objects
        state.selectedSessionToEdit = {
          ...state.list.find((session) => session._id === action.payload)!,
        };
      },
      clearSelectedSessionToEdit(state) {
        state.selectedSessionToEdit = null;
      },
    },
  });
  
  export const sessionActions = sessionSlice.actions;
  export const sessionReducer = sessionSlice.reducer;
  