import { useAppSelector, useAppDispatch } from "hooks/redux.hook";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { Role } from "store/roles/roles.state";
import { uiActions } from "store/ui/ui.state";

interface ICustomToolbarProps {
  downloadExcel: any;
}

export const CustomToolbar: React.FC<ICustomToolbarProps> = ({
  downloadExcel,
}) => {
  // const ordersNumber = useAppSelector((state) => state.orders.list.length);
  // const ordersList = useAppSelector((state) => state.orders.list);
  const authenticatedUser = useAppSelector((state) => state.auth.user);
  const accountType = useAppSelector((state) => state.auth.accountType);
  const dispatch = useAppDispatch();

  return (
    <div>
      <Grid container alignItems="center" justifyContent="space-between">
        {/* <Grid item xs={12}>
              <Typography
                component="span"
                fontWeight="500"
                fontSize="20px"
                lineHeight="23px"
                sx={{ color: "#181C32" }}
              >
                Bons de commande ({ordersNumber})
              </Typography>
        </Grid> */}
        <Grid item xs={12}>
          {accountType === "user" &&
            (authenticatedUser?.role as Role).orderManagment.includes(
              "create"
            ) && (
              <Button
                sx={{
                  margin:"",
                  boxShadow: "none",
                  backgroundColor: "#1DC894",
                  textTransform: "none",
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "#1DC894",
                  },
                }}
                variant="contained"
                title="Ajouter un bon"
                onClick={() => dispatch(uiActions.setAddOrderFormOpen(true))}
                //</Grid>disabled={
                //    !(authenticatedUser!.role as Role).userManagment.includes(
                //</Grid>      "create"
                //     )
                //   }
              >
                Ajouter un bon
              </Button>
            )}
          {/* <Button
            sx={{
              margin:"0 0 0 10px",
              boxShadow: "none",
              backgroundColor: "#1DC894",
              textTransform: "none",
              "&:hover": {
                boxShadow: "none",
                backgroundColor: "#1DC894",
              },
            }}
            variant="contained"
            title="Exporter"
            onClick={() => downloadExcel(ordersList)}
          >
            Exporter
          </Button> */}
        </Grid>
      </Grid>
    </div>
  );
};
