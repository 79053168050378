import { axiosInstance } from "api/axiosInstance";
import { AppDispatch } from "store/store.state";
import { Civilite, civiliteActions } from "./civilite.state";
import { feedbackMessageUIActions } from "store/feedback-message-ui/feedbackMessageUI.state"


type GetCiviliteResponse = {
  data: Civilite[]
}

export function getCiviliteThunk() {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.get<GetCiviliteResponse>("/civilities")
      const civilite = response.data.data
      dispatch(civiliteActions.setList(civilite))
    } catch (e) {
      console.log(e)
    }
  }
}

type AddCiviliteResponse = {
  data: Civilite
}

export function addCiviliteThunk(civilite: Omit<Civilite, "id">) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.post<AddCiviliteResponse>("/civilities", civilite, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      },)
      const completeCivilite = response.data.data
      dispatch(civiliteActions.addCivilite(completeCivilite));
      dispatch(feedbackMessageUIActions.setState({ open: true, message: "Civilité ajouté avec succès" }))
    } catch (e) {
      console.log(e)
      dispatch(feedbackMessageUIActions.setState({ open: true, message: "Impossible d'ajouter civilité s'il vous plaît réessayer plus tard" }))
    }
  };
}

export function deleteCiviliteThunk(id: string) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.delete(`/civilities/${id}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      },)
      dispatch(civiliteActions.deleteCivilite(id));
      dispatch(feedbackMessageUIActions.setState({ open: true, message: "Civilité supprimée avec succès" }))
    } catch (e: any) {
      if (e.response.status === 409) {
        dispatch(
          feedbackMessageUIActions.setState({
            open: true,
            message:
              "Cette donnée est utilisée, vous ne pouvez pas la supprimer",
          })
        );
      } else {
        dispatch(feedbackMessageUIActions.setState({ open: true, message: "Impossible de supprimer civilité s'il vous plaît réessayer plus tard" }))

      }
    }
  };
}

type EditCiviliteResponse = {
  data: Civilite
}

export function editCiviliteThunk(obj: { id: string; civilite: Omit<Civilite, "id"> }) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await axiosInstance.put<EditCiviliteResponse>(`/civilities/${obj.id}`, obj.civilite, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token") !== "undefined" ? localStorage.getItem("token")! : ""}`,
        },
      },)
      const editedCivilite = response.data.data
      dispatch(civiliteActions.editCivilite({ id: obj.id, civilite: editedCivilite }));
      dispatch(feedbackMessageUIActions.setState({ open: true, message: "Civilité mis à jour avec succès" }))
    } catch (e) {
      dispatch(feedbackMessageUIActions.setState({ open: true, message: "Impossible de mettre à jour civilité s'il vous plaît réessayer plus tard" }))
    }
  };
}
