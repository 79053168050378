import { useAppSelector } from "hooks/redux.hook";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import React, { useState } from "react";
import { User } from "store/users/users.state";
import { Supplier } from "store/suppliers/suppliers.state";
import { AgGridReact } from "ag-grid-react";
import { Button } from "@mui/material";
import { IArticle } from "store/article/article.state";
import { AG_GRID_LOCALE_FR } from "utils/constants";

const OrderInfos = () => {
  const selectedOrderToEdit: any = useAppSelector(
    (state) => state.orders.selectedOrderToEdit
  )!;
  const articlesList: IArticle[] = useAppSelector(
    (state) => state.articles.list
  )!;

  const [rowData] = useState(selectedOrderToEdit.articles);
  // console.log(selectedOrderToEdit);

  const [columnDefs] = useState([
    {
      field: "nomArticle",
      headerName: "Nom de l'article",
      cellRenderer: (params: any) => (
        <span>
          {" "}
          {
            articlesList.filter(
              (art: any) => art._id === params.data.idArticle
            )[0].nomArticle
          }
        </span>
      ),
    },
    { field: "HT", headerName: "Coût unitaire HT",
    cellRenderer: (params: any) => (
      <span>
        {" "}
        {
          articlesList.filter(
            (art: any) => art._id === params.data.idArticle
          )[0].HT
        } €
      </span>
    ),
  },
    {
      field: "qty",
      headerName: "Quantité",
    },
    { field: "TVA" },
    {
      field: "TTC",
      cellRenderer: (params: any) => <span> {params.data.TTC} </span>,
    },
  ]);
  const exportOrderPdf = () => {
    const input: any = document.getElementById("orderInfos");
    html2canvas(input, { logging: true, useCORS: true }).then((canvas) => {
      const imgWidth = 208;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL("img/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save(
        `${selectedOrderToEdit.BDC_NUM}-${
          selectedOrderToEdit.documentDate
        }-${new Date().getSeconds()}.pdf`
      );
    });
  };

  return (
    <div id="orderInfos">
      <Grid
        item
        textAlign="center"
        xs={12}
        style={{
          backgroundColor: "#0a0b31",
          color: "whitesmoke",
          padding: "1%",
        }}
      >
        <Typography variant="h2">
          {" "}
          N° BDC: {selectedOrderToEdit?.BDC_NUM}{" "}
        </Typography>
        <Typography variant="subtitle1">
          {" "}
          Date: {selectedOrderToEdit?.documentDate}{" "}
        </Typography>
      </Grid>
      <Grid
        container
        direction="row"
        spacing={5}
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item xs={3}>
          <Typography variant="h4" color="text.secondary">
            Client
          </Typography>
          <List>
            {" "}
            <ListItem divider={true}>
              <ListItemText>
                {" "}
                {(selectedOrderToEdit.userRef as User).firstName}{" "}
                {(selectedOrderToEdit.userRef as User).lastName}
              </ListItemText>
            </ListItem>{" "}
            <ListItem divider={true}>
              <ListItemText>
                {" "}
                {(selectedOrderToEdit.userRef as User).tel}{" "}
              </ListItemText>
            </ListItem>{" "}
          </List>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h4" color="text.secondary">
            Préstataire
          </Typography>
          <List>
            {" "}
            <ListItem divider={true}>
              <ListItemText>
                {" "}
                {(selectedOrderToEdit.FRN as Supplier).nom}{" "}
                {(selectedOrderToEdit.FRN as Supplier).prenom}
              </ListItemText>
            </ListItem>{" "}
            <ListItem divider={true}>
              <ListItemText>
                {" "}
                siret: {(selectedOrderToEdit.FRN as Supplier).siret}{" "}
              </ListItemText>
            </ListItem>{" "}
            <ListItem divider={true}>
              <ListItemText>
                {" "}
                {(selectedOrderToEdit.FRN as Supplier).tel}{" "}
                {(selectedOrderToEdit.FRN as Supplier).mobile}{" "}
              </ListItemText>
            </ListItem>{" "}
            <ListItem divider={true}>
              <ListItemText>
                {" "}
                {(selectedOrderToEdit.FRN as Supplier).address}{" "}
              </ListItemText>
            </ListItem>{" "}
            <ListItem divider={true}>
              <ListItemText>
                {" "}
                {(selectedOrderToEdit.FRN as Supplier).postalCode}{" "}
                {(selectedOrderToEdit.FRN as Supplier).city}{" "}
              </ListItemText>
            </ListItem>{" "}
            <ListItem divider={true}>
              <ListItemText>
                {" "}
                {(selectedOrderToEdit.FRN as Supplier).pays}{" "}
              </ListItemText>
            </ListItem>{" "}
          </List>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={8}>
          <div
            className="ag-theme-alpine"
            style={{ height: "30vh", width: "55vw" }}
          >
            <AgGridReact
              rowData={rowData}
              localeText={AG_GRID_LOCALE_FR}
              columnDefs={columnDefs}
            ></AgGridReact>
          </div>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h4">
            TOTAL: {selectedOrderToEdit.total}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderInfos;
