import Button from "@mui/material/Button";
import styled from "@emotion/styled";

export const RoleCardButton = styled(Button)((props) => ({
  textTransform: "none",
  background: props.theme.palette.background.default,
  color: props.theme.palette.text.secondary,
  boxShadow: "none",
  "&:hover": {
    background: props.theme.palette.background.default,
    boxShadow: "none",
  },
}));


