import { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import * as Yup from "yup";
import { CustomTextField } from "components/custom-textfield/CustomTextField.component";

import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ClearIcon from "@mui/icons-material/Clear";
import FormHelperText from "@mui/material/FormHelperText";
import EditIcon from "@mui/icons-material/Edit";

import RadioLabel from "../radio-label/RadioLabel.component";
import { DashedDivider } from "../dashed-divider/DottedDivider.component";
import { addUserThunk } from "store/users/users.thunk";
import { User } from "store/users/users.state";
import { USER_ROLES } from "utils/roles";
import { margin } from "../variables";
import { getRolesThunk } from "store/roles/roles.thunk";

import useImageBase64Encoder from "hooks/useImageBase64Ecoder";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import BlueButton from "components/blue-button/BlueButton.component";
import GrayButton from "components/gray-button/GrayButton.component";
import CustomRadioButton from "components/custom-radio-button/CutomRadioButton.component";
import { array, boolean } from "yup/lib/locale";
import React from "react";

type ErrorProp = boolean | undefined;

type AddUserFormProps = {
  handleCloseAddDialog: () => void;
  isRoleSelected?: boolean;
  roleId?: number | null;
};

export default function AddUserForm(props: AddUserFormProps) {
  const dispatch = useAppDispatch();
  const postesList = useAppSelector((state) => state.postes.list);
  const rolesList = useAppSelector((state) => state.roles.list);
  const civiliteList = useAppSelector((state) => state.civilite.list);
  const poleList = useAppSelector((state) => state.pole.list);
  const [mediaUrl, setMediaUrl] = useState("");
  const [suppEmail, setSuppEmail] = React.useState("");

  const imageInputRef = useRef<HTMLInputElement>(null);
  const { base64Image, handleUpload, handleRemoveImage } =
    useImageBase64Encoder(imageInputRef);
  const formik = useFormik({
    initialValues: {
      civilite: "",
      firstName: "",
      lastName: "",
      email: suppEmail,
      pole: "",
      tel: "",
      position: "",
      role: props.roleId,
    },
    validationSchema: Yup.object({
      civilite: Yup.string().required("Civilité obligatoire"),
      firstName: Yup.string()
        .min(2, "Trop Court!")
        .max(30, "Trop Long!")
        .required("Prénom obligatoire"),
      lastName: Yup.string()
        .min(2, "Trop Court!")
        .max(30, "Trop Long!")
        .required("Nom obligatoire"),
      email: Yup.string()
        .email("Mauvais format d'e-mail")
        .required("E-mail obligatoire"),
      // .test("artlinemail", "@institutartline.com only", (value) => {
      //   const email_content = value?.split("@");
      //   return email_content?.[1] === "institutartline.com";
      // }),
      pole: Yup.string().required("Pole obligatoire"),
      tel: Yup.string().length(10),
      position: Yup.string().required("Poste obligatoire"),
      role: Yup.string().required("Rôle bligatoire"),
    }),
    onSubmit: (values) => {
      // console.log(values);
      dispatch(addUserThunk(values as any, imageInputRef.current!.files![0]));
      props.handleCloseAddDialog();
      // console.log(base64Image);
    },
  });

  const showCiviliteError = (formik.errors.civilite &&
    formik.touched.civilite) as ErrorProp;
  const showFirstNameError = (formik.errors.firstName &&
    formik.touched.firstName) as ErrorProp;
  const showLastNameError = (formik.errors.lastName &&
    formik.touched.lastName) as ErrorProp;
  const showEmailError = (formik.errors.email &&
    formik.touched.email) as ErrorProp;
  const showPoleError = (formik.errors.pole &&
    formik.touched.pole) as ErrorProp;
  const showTelError = (formik.errors.tel && formik.touched.tel) as ErrorProp;

  const showPositionError = (formik.errors.position &&
    formik.touched.position) as ErrorProp;
  const showRoleError = (formik.errors.role &&
    formik.touched.role) as ErrorProp;
  const removeChosenAvatar = () => {
    imageInputRef.current!.value = "";
    handleRemoveImage();
    setMediaUrl("");
  };

  useEffect(() => {
    dispatch(getRolesThunk());
    // console.log(poleList);
    // console.log(postesList);
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography component="span" fontWeight="bold">
        Avatar
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box
          position="relative"
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="120px"
          marginBottom="10px"
        >
          <Avatar
            src={mediaUrl}
            sx={{
              borderRadius: "50%",
              width: "100%",
              height: "120px",
              zIndex: 1,
              boxShadow: 5,
            }}
          />
          <IconButton
            sx={{
              boxShadow: 5,
              position: "absolute",
              bottom: "-10px",
              right: "-10px",
              zIndex: 2,
              background: "white",
              padding: "2px",
            }}
            onClick={removeChosenAvatar}
          >
            <ClearIcon sx={{ fontSize: "20px" }} />
          </IconButton>

          <div
            style={{
              position: "absolute",
              top: "-10px",
              right: "-10px",
              width: "20px",
              height: "20px",
            }}
          >
            <input
              ref={imageInputRef}
              type="file"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 0,
                height: "100%",
                width: "100%",
                zIndex: 2,
                cursor: "pointer",
              }}
              onChange={(e) => {
                handleUpload();
                setMediaUrl(URL.createObjectURL(e.target.files![0]));
              }}
            />
            <IconButton
              sx={{
                boxShadow: 5,
                position: "absolute",
                top: "0px",
                right: "0px",
                zIndex: 1,
                background: "white",
                padding: "2px",
              }}
              onClick={removeChosenAvatar}
            >
              <EditIcon sx={{ fontSize: "20px" }} />
            </IconButton>
          </div>
        </Box>

        <Typography
          display="block"
          variant="caption"
          align="center"
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          Types de fichiers autorisés: png, jpg, jpeg
        </Typography>
      </Box>
      <Typography component="span" fontWeight="bold">
        Civilité{" "}
        <Typography component="span" color="error">
          *
        </Typography>
      </Typography>
      <FormControl
        fullWidth
        size="small"
        error={showCiviliteError}
        sx={{
          marginBottom: margin,
          "& .MuiSelect-select": { padding: "10px" },
        }}
      >
        <Select
          placeholder="hahahha"
          {...formik.getFieldProps("civilite")}
          // label="civilitesss"
          variant="filled"
          sx={{
            color: "#5E6278",
            fontWeight: "500",
            fontSize: "14px",
            background: "#F5F8FA",
            "&:before": {
              border: "none",
            },
            "&:after": {
              border: "none",
            },
          }}
        >
          {civiliteList.map((c) => (
            <MenuItem key={c.id} value={c.id}>
              {c.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>
          {showCiviliteError && formik.errors.civilite}
        </FormHelperText>
      </FormControl>
      <Typography component="span" fontWeight="bold">
        Nom{" "}
        <Typography component="span" color="error">
          *
        </Typography>
      </Typography>
      <CustomTextField
        {...formik.getFieldProps("lastName")}
        error={showLastNameError}
        helperText={showLastNameError && formik.errors.lastName}
        placeholder="Nom"
        fullWidth
        size="small"
        sx={{ marginBottom: margin }}
        inputProps={{ style: { textTransform: "uppercase" } }}
      />
      <Typography component="span" fontWeight="bold">
        Prénom{" "}
        <Typography component="span" color="error">
          *
        </Typography>
      </Typography>
      <CustomTextField
        inputProps={{ style: { textTransform: "capitalize" } }}
        {...formik.getFieldProps("firstName")}
        error={showFirstNameError}
        helperText={showFirstNameError && formik.errors.firstName}
        placeholder="Prénom"
        fullWidth
        size="small"
        sx={{ marginBottom: margin }}
      />
      <Typography component="span" fontWeight="bold">
        E-mail{" "}
        <Typography component="span" color="error">
          *
        </Typography>
      </Typography>
      <CustomTextField
        {...formik.getFieldProps("email")}
        error={showEmailError}
        helperText={showEmailError && formik.errors.email}
        placeholder="E-mail : example@institutartline.com"
        fullWidth
        size="small"
        sx={{ marginBottom: margin }}
      />
      <Typography component="span" fontWeight="bold">
        Téléphone{" "}
      </Typography>
      <CustomTextField
        {...formik.getFieldProps("tel")}
        error={showTelError}
        helperText={showTelError && formik.errors.tel}
        placeholder="Numéro de téléphone"
        fullWidth
        size="small"
        sx={{ marginBottom: margin }}
        inputProps={{ maxLength: 10 }}
        onChange={(e) => {
          const value = e.target.value.replace(/\D/g, "");
          formik.setValues({
            ...formik.values,
            tel: value,
          });
        }}
      />
      <Typography component="span" fontWeight="bold">
        Pôle{" "}
        <Typography component="span" color="error">
          *
        </Typography>
      </Typography>
      <FormControl
        fullWidth
        size="small"
        error={showPoleError}
        sx={{
          marginBottom: margin,
          "& .MuiSelect-select": { padding: "10px" },
        }}
      >
        <Select
          {...formik.getFieldProps("pole")}
          // label="civilitesss"
          variant="filled"
          sx={{
            color: "#5E6278",
            fontWeight: "500",
            fontSize: "14px",
            background: "#F5F8FA",
            "&:before": {
              border: "none",
            },
            "&:after": {
              border: "none",
            },
          }}
        >
          {poleList.map((pole) => (
            <MenuItem key={pole.id} value={pole.id}>
              {pole.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>
          {showPoleError && formik.errors.pole}
        </FormHelperText>
      </FormControl>
      <Typography component="span" fontWeight="bold">
        Poste{" "}
        <Typography component="span" color="error">
          *
        </Typography>
      </Typography>

      <FormControl
        fullWidth
        size="small"
        error={showPositionError}
        sx={{
          marginBottom: margin,
          "& .MuiSelect-select": { padding: "10px" },
        }}
      >
        <Select
          {...formik.getFieldProps("position")}
          // label="civilitesss"
          variant="filled"
          sx={{
            color: "#5E6278",
            fontWeight: "500",
            fontSize: "14px",
            background: "#F5F8FA",
            "&:before": {
              border: "none",
            },
            "&:after": {
              border: "none",
            },
          }}
        >
          {postesList.map((c) => (
            <MenuItem key={c.id} value={c.id}>
              {c.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>
          {showPositionError && formik.errors.position}
        </FormHelperText>
      </FormControl>

      <Typography component="span" fontWeight="bold">
        Rôle{" "}
        <Typography component="span" color="error">
          *
        </Typography>
      </Typography>
      <FormControl
        fullWidth
        size="small"
        error={showRoleError}
        sx={{
          marginBottom: margin,
          "& .MuiSelect-select": { padding: "10px" },
        }}
      >
        <Select
          disabled={props.isRoleSelected}
          {...formik.getFieldProps("role")}
          // label="civilitesss"
          variant="filled"
          sx={{
            marginBottom: "40px",
            color: "#5E6278",
            fontWeight: "500",
            fontSize: "14px",
            background: "#F5F8FA",
            "&:before": {
              border: "none",
            },
            "&:after": {
              border: "none",
            },
          }}
        >
          {rolesList.map((role) => (
            <MenuItem key={role.id} value={role.id}>
              {role.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>
          {showRoleError && formik.errors.role}
        </FormHelperText>
      </FormControl>

      <div style={{ position: "absolute", bottom: "0", left: "0", background: "white", right: "0", display: "flex", padding: "10px", justifyContent: "center", alignItems: "center" }} >
        <GrayButton style={{ marginRight: "10px" }} onClick={props.handleCloseAddDialog}>Annuler</GrayButton>
        <BlueButton style={{ marginLeft: "10px" }} sx={{ textTransform: "none" }} variant="contained" type="submit">Soumettre</BlueButton>
      </div>
    </form>
  );
}
