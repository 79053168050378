// this function will receive a role object
// and apply these rules to permission tables:
// create ==> read and update, if there is create permisssion add also read and update
// update ==> read, if there is update permission add also read
// only read
// delete is not related to other

import { Role } from "store/roles/roles.state";

type Params = Omit<Role, "id" | "assignedUsers">;

export function addRelatedPermissions(role: Params) {
  const result = role;

  if (result.userManagment.includes("create")) {
    if (!result.userManagment.includes("read"))
      result.userManagment.push("read");
    if (!result.userManagment.includes("update"))
      result.userManagment.push("update");
  }
  if (result.userManagment.includes("update")) {
    if (!result.userManagment.includes("read"))
      result.userManagment.push("read");
  }
// ===============
  if (result.roleManagment.includes("create")) {
    if (!result.roleManagment.includes("read"))
      result.roleManagment.push("read");
    if (!result.roleManagment.includes("update"))
      result.roleManagment.push("update");
  }
  if (result.roleManagment.includes("update")) {
    if (!result.roleManagment.includes("read"))
      result.roleManagment.push("read");
  }

// ===============
  if (result.supplierManagment.includes("create")) {
    if (!result.supplierManagment.includes("read"))
      result.supplierManagment.push("read");
    if (!result.supplierManagment.includes("update"))
      result.supplierManagment.push("update");
  }
  if (result.supplierManagment.includes("update")) {
    if (!result.supplierManagment.includes("read"))
      result.supplierManagment.push("read");
  }

// ===============
  if (result.managmentRules.includes("create")) {
    if (!result.managmentRules.includes("read"))
      result.managmentRules.push("read");
    if (!result.managmentRules.includes("update"))
      result.managmentRules.push("update");
  }
  if (result.managmentRules.includes("update")) {
    if (!result.managmentRules.includes("read"))
      result.managmentRules.push("read");
  }

// ===============
  if (result.billManagment.includes("create")) {
    if (!result.billManagment.includes("read"))
      result.billManagment.push("read");
    if (!result.billManagment.includes("update"))
      result.billManagment.push("update");
  }
  if (result.billManagment.includes("update")) {
    if (!result.billManagment.includes("read"))
      result.billManagment.push("read");
  }

// ===============
  if (result.orderManagment.includes("create")) {
    if (!result.orderManagment.includes("read"))
      result.orderManagment.push("read");
    if (!result.orderManagment.includes("update"))
      result.orderManagment.push("update");
  }
  if (result.orderManagment.includes("update")) {
    if (!result.orderManagment.includes("read"))
      result.orderManagment.push("read");
  }

  // ===============
  if (result.APIConnection.includes("create")) {
    if (!result.APIConnection.includes("read"))
      result.APIConnection.push("read");
    if (!result.APIConnection.includes("update"))
      result.APIConnection.push("update");
  }
  if (result.APIConnection.includes("update")) {
    if (!result.APIConnection.includes("read"))
      result.APIConnection.push("read");
  }

  return result;
}
