import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { orange } from "@mui/material/colors";

import { useAppSelector, useAppDispatch } from "hooks/redux.hook";
import { uiActions } from "store/ui/ui.state";
import BlueButton from "components/blue-button/BlueButton.component";
import WhiteButton from "components/white-button/WhiteButton.component";

export default function CloseEditRoleConfirmation() {
  const dispatch = useAppDispatch();
  const closeEditRoleConfirmationOpen = useAppSelector(
    (state) => state.ui.closeEditRoleConfirmationOpen
  );

  const handleCloseConfirmationDialog = () => {
    dispatch(uiActions.setCloseEditRoleConfirmationOpen(false));
  };

  const handleCloseEditRoleForm = () => {
    dispatch(uiActions.setCloseEditRoleConfirmationOpen(false));
    dispatch(uiActions.setEditRoleFormOpen(false));
  };

  return (
    <Dialog open={closeEditRoleConfirmationOpen}>
      <DialogContent>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <ErrorOutlineIcon fontSize="large" sx={{ color: orange[500] }} />
          </Grid>
          <Grid item>
            <Typography sx={{ color: (theme) => theme.palette.text.secondary }}>
            Attention, les modifications apportées ne seront pas enregistrées. Voulez-vous continuer ?
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <BlueButton onClick={handleCloseEditRoleForm}>
        Valider
        </BlueButton>
        <WhiteButton
          onClick={handleCloseConfirmationDialog}
        >
          Annuler
        </WhiteButton>
      </DialogActions>
    </Dialog>
  );
}
