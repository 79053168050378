import styled from "@emotion/styled";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import checked from "./checked.svg";

const radius = 7;
const scale = 22.75;

const CustomUncheckedCheckboxIcon = styled("span")({
  borderRadius: radius,
  width: scale,
  height: scale,
  background: "#eff2f5",
  border: 1,
  display: "inline-block",
});

const CustomCheckedCheckboxIcon = styled("span")({
  borderRadius: radius,
  width: scale,
  height: scale,
  backgroundColor: "#009ef7",
  backgroundImage: `url('${checked}')`,
  backgroundSize: `${0.8 * scale}px`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  border: 1,
  display: "inline-block",
});

export default function CustomCheckbox({sx, ...props}: CheckboxProps) {
  let scale = 22.75; // default scale
  if (props.size === "small") {
    scale = 20.14;
  }
  return (
    <Checkbox
      checkedIcon={<CustomCheckedCheckboxIcon />}
      icon={<CustomUncheckedCheckboxIcon />}
      sx={{
        "& span": { height: scale, width: scale, backgroundSize: 0.7 * scale },
        ...sx
      }}
      {...props}
    />
  );
}
