import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as ROUTES from "utils/routes";
import { useAppDispatch } from "hooks/redux.hook";
import { useFormik } from "formik";
import * as Yup from "yup";
import BlueButton from "components/blue-button/BlueButton.component";
import Container from "@mui/material/Container";

import { resetPasswordThunk as resetPasswordThunkSupplier } from "store/suppliers/suppliers.thunk";
import { resetPasswordThunk as resetPasswordThunkUser } from "store/users/users.thunk";

import pattern1 from "assets/pattern1.svg";
import rlb from "assets/rectangle-left-bottom.svg";
import rrt from "assets/rectangle-right-top.svg";
import crb from "assets/circle-right-bottom.svg";
import clt from "assets/circle-left-top.svg";

export default function ResetPassword() {
  const { type, token } = useParams();
  const [showError, setShowError] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(10, "Trop court")
        .required("Mot de pass obligatoire"),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Les mots de passe doivent correspondre"
      ),
    }),

    onSubmit: (values, helpers) => {
      // console.log(values);
      const accountType = type!.toLowerCase();
      if (accountType === "utilisateur") {
        dispatch(
          resetPasswordThunkUser(
            values.password,
            values.confirmPassword,
            token as string,
            setShowError,
            navigate
          )
        );
      } else {
        dispatch(
          resetPasswordThunkSupplier(
            values.password,
            values.confirmPassword,
            token as string,
            setShowError,
            navigate
          )
        );
      }
      helpers.resetForm();
    },
  });

  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          background: `
              url(${clt}) left 10% no-repeat,
              url(${rlb}) left bottom no-repeat, 
              url(${rrt}) 50% top no-repeat,
              url(${crb}) 50% bottom no-repeat,
              url(${pattern1}) top left  no-repeat
              `,
          // backgroundSize: "7% auto, 80%, 10% auto, 20% auto, 15% auto",
          backgroundSize: "7% ,10% , 20% , 15%, cover ",
          backgroundColor: "#000619",
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Container maxWidth="sm">
          <Paper
            sx={{
              padding: "40px",
              borderRadius: "25px 5px",
              margin: "15px",
            }}
          >
            <Typography
              sx={{ marginBottom: showError ? 0 : "50px" }}
              variant="h4"
              //   fontWeight="700"
              align="center"
            >
              Veuillez entrer votre nouveau mot de passe
            </Typography>

            {showError && (
              <Typography variant="body2" align="center" color="error">
                Error: {errorText && errorText}
              </Typography>
            )}

            <form onSubmit={formik.handleSubmit}>
              <TextField
                {...formik.getFieldProps("password")}
                error={
                  (formik.errors.password && formik.touched.password) as
                    | boolean
                    | undefined
                }
                helperText={
                  formik.errors.password &&
                  formik.touched.password &&
                  formik.errors.password
                }
                sx={{
                  marginBottom: "10px",
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#000619",
                  },
                  "& .MuiInputBase-root:after": {
                    border: "none",
                  },
                }}
                label="Mot de passe"
                variant="standard"
                placeholder="Mot de passe"
                fullWidth
                type="password"
              />

              <TextField
                {...formik.getFieldProps("confirmPassword")}
                error={
                  (formik.errors.confirmPassword &&
                    formik.touched.confirmPassword) as boolean | undefined
                }
                helperText={
                  formik.errors.confirmPassword &&
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                sx={{
                  marginBottom: "30px",
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#000619",
                  },
                  "& .MuiInputBase-root:after": {
                    border: "none",
                  },
                }}
                label="Confirmer ot de passe"
                variant="standard"
                placeholder="Confirmer mot de passe"
                fullWidth
                type="password"
              />

              <BlueButton type="submit" sx={{padding: "12px 20px"}}>Soumettre</BlueButton>
            </form>
          </Paper>
        </Container>
      </Box>
    </>
  );
}
