import { Outlet, Navigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import * as ROUTES from "utils/routes";
import { useEffect } from "react";
import { axiosInstance } from "api/axiosInstance";
import { User } from "store/users/users.state";
import { setUserThunk } from "store/auth/auth.thunk";
import { log } from "console";
type userDataResponseType = {
  success: boolean;
  data: User;
};

export default function DashboardAccessController() {
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.auth.token);
  const accountType = useAppSelector((state) => state.auth.accountType);

  useEffect(() => {
    if (!token) return;
    axiosInstance
      .get<userDataResponseType>(`/auth/${accountType}/me`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((result) => {
        return result.data;
      })
      .then(({ data }) => {
        dispatch(setUserThunk(data));
        localStorage.setItem("user", JSON.stringify(data));
      });
  }, []);
  // console.log(accountType);

  // TODO: anyone can use console to add token to localStorage
  // so, make a request with token in useEffect, if status 200 allow access

  return token ? <Outlet /> : <Navigate to={`/${ROUTES.HOME}`} />;
}
