import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
} from "@mui/material";
import BlueButton from "components/blue-button/BlueButton.component";
import CustomDialogTitle from "components/custom-dialog-title/CustomDialogTitle.component";
import WhiteButton from "components/white-button/WhiteButton.component";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import jsPDF from "jspdf";
import React, { useEffect } from "react";
import {
  deleteInvoiceThunk,
  getInvoicesByVendorIdThunk,
  getInvoicesThunk,
} from "store/invoices/invoice.thunk";
import { uiActions } from "store/ui/ui.state";
import AddInvoice from "./components/add-invoice-form/AddInvoice";
import IDataGrid from "./components/data-grid/IDataGrid";
import EditInvoice from "./components/edit-invoice-form/EditInvoice";
import InvoiceInfos from "./components/invoice-infos/InvoiceInfos";
import html2canvas from "html2canvas";
import {
  getOrdersByVendorIdThunk,
  getOrdersThunk,
} from "store/orders/orders.thunk";
import InvoiceStatus from "./components/invoice-status/InvoiceStatus";

const Invoices = () => {
  const dispatch = useAppDispatch();
  const {
    deleteInvoiceWarningOpen,
    infoInvoiceOpen,
    invoicePdfWarningOpen,
    idOfItemToDelete,
    addInvoiceFormOpen,
    editInvoiceFormOpen,
    editInvoiceStatusOpen,
  } = useAppSelector((state) => state.ui);
  const selectedInvoice = useAppSelector(
    (state) => state.invoices.selectedInvoice
  )!;
  const accountType = useAppSelector((state) => state.auth.accountType)!;
  const authUser = useAppSelector((state) => state.auth.user)!;
  useEffect(() => {
    if (accountType === "vendor") {
      dispatch(getOrdersByVendorIdThunk(authUser.id));
      dispatch(getInvoicesByVendorIdThunk(authUser.id));
    } else {
      dispatch(getInvoicesThunk());
      dispatch(getOrdersThunk());
    }
  }, [deleteInvoiceWarningOpen, infoInvoiceOpen, invoicePdfWarningOpen, idOfItemToDelete, addInvoiceFormOpen, editInvoiceFormOpen,]);
  const closeAddInvoiceform = () => {
    dispatch(uiActions.setAddInvoiceFormOpen(false));
  };
  const closeDeleteInvoiceWarning = () => {
    dispatch(uiActions.setDeleteInvoiceWarningOpen({ set: false, id: "" }));
  };
  const closeEditInvoiceWarning = () => {
    dispatch(uiActions.setEditInvoiceFormOpen(false));
  };
  const closeEditInvoiceStatusOpen = () => {
    dispatch(uiActions.setEditInvoiceStatusOpen(false));
  };
  const closeInfosInvoiceWarning = () => {
    dispatch(uiActions.setInfoInvoiceOpen(false));
  };
  const closePdfInvoiceWarning = () => {
    dispatch(uiActions.setInvoicePdfWarningOpen(false));
  };

  const exportInvoicePdf = () => {
    const input: any = document.getElementById("invoiceInfos");
    html2canvas(input, { logging: true, useCORS: true }).then((canvas) => {
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL("img/png");
      const pdf = new jsPDF("portrait", "mm", "a4");
      pdf.addImage(imgData, "PNG", 0, 25, imgWidth, imgHeight);
      pdf.save(
        `Facture-${selectedInvoice.invoiceDate}-${new Date().getSeconds()}.pdf`
      );
    });
  };

  return (
    <div style={{ padding: "0px 40px 0 40px ", height: 'calc(100% - 200px)', }}>
      <IDataGrid />

      {/* add dialog */}
      <Dialog
        open={addInvoiceFormOpen}
        onClose={closeAddInvoiceform}
        sx={{
          "& .MuiPaper-root": { height: "90vh", width: "45vw" }, "& .css-ypiqx9-MuiDialogContent-root": {
            padding: "20px 40px 40px 20px"
          }
        }}
        maxWidth="lg"
      >
        <CustomDialogTitle onClose={closeAddInvoiceform} id="">
          Ajouter une facture
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          <AddInvoice handleCloseAddDialog={closeAddInvoiceform} />
        </DialogContent>
      </Dialog>

      {/* infos dialog */}
      <Dialog
        open={infoInvoiceOpen}
        onClose={closeInfosInvoiceWarning}
        sx={{ "& .MuiPaper-root": { height: "95vh", width: "95vw" } }}
        maxWidth="lg"
      >
        <CustomDialogTitle onClose={closeInfosInvoiceWarning} id="">
          facture
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          <InvoiceInfos handleCloseEditDialog={closeInfosInvoiceWarning} />
        </DialogContent>
      </Dialog>

      {/* status dialog */}
      <Dialog
        fullScreen
        open={editInvoiceStatusOpen}
        onClose={closeEditInvoiceStatusOpen}
        sx={{ "& .MuiPaper-root": { height: "45vh", width: "40vw" } }}
        maxWidth="lg"
      >
        <CustomDialogTitle onClose={closeEditInvoiceStatusOpen} id="">
          Statut de facture
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          <InvoiceStatus handleCloseEditStatus={closeEditInvoiceStatusOpen} />
        </DialogContent>
      </Dialog>

      {/* version pdf */}
      <Dialog
        fullScreen
        open={invoicePdfWarningOpen}
        onClose={closePdfInvoiceWarning}
        sx={{ "& .MuiPaper-root": { height: "95vh", width: "90vw" } }}
      >
        <CustomDialogTitle onClose={closePdfInvoiceWarning} id="">
          Téléchargement
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          <InvoiceInfos handleCloseEditDialog={closePdfInvoiceWarning} />
        </DialogContent>
        <Divider />
        <DialogActions>
          <BlueButton
            onClick={() => {
              exportInvoicePdf();
              dispatch(uiActions.setInvoicePdfWarningOpen(false));
            }}
          >
            Télécharger
          </BlueButton>
          <WhiteButton onClick={closePdfInvoiceWarning}>Annuler</WhiteButton>
        </DialogActions>
      </Dialog>

      {/* edit dialog */}
      <Dialog
        fullScreen
        open={editInvoiceFormOpen}
        onClose={closeEditInvoiceWarning}
        sx={{
          "& .MuiPaper-root": { height: "90vh", width: "45vw" }, "& .css-ypiqx9-MuiDialogContent-root": {
            padding: "20px 40px 40px 20px"
          }
        }}
        maxWidth="lg"
      >
        <CustomDialogTitle onClose={closeEditInvoiceWarning} id="">
          Editer une facture
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          <EditInvoice handleCloseEditDialog={closeEditInvoiceWarning} />
        </DialogContent>
      </Dialog>

      {/* delete dialog */}
      <Dialog
        open={deleteInvoiceWarningOpen}
        onClose={closeDeleteInvoiceWarning}
        sx={{ "& .MuiPaper-root": { width: "500px" } }}
      >
        <CustomDialogTitle onClose={closeDeleteInvoiceWarning} id="">
          Suppression
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          Voulez-vous vraiment supprimer cette facture?
        </DialogContent>
        <DialogActions>
          <BlueButton
            onClick={async () => {
              try {
                await dispatch(deleteInvoiceThunk(idOfItemToDelete));
                dispatch(
                  uiActions.setDeleteInvoiceWarningOpen({ set: false, id: "" })
                );
                await dispatch(getInvoicesThunk());
              } catch (error) {
                console.log(error);
              }
            }}
          >
            Valider
          </BlueButton>
          <WhiteButton onClick={closeDeleteInvoiceWarning}>Annuler</WhiteButton>
        </DialogActions>
      </Dialog>

      {/* change status dialog */}
      {/* <Dialog
        fullScreen
        open={editInvoiceFormOpen}
        onClose={closeEditInvoiceWarning}
        sx={{ "& .MuiPaper-root": { height: "75vh", width: "55vw" } }}
        maxWidth="lg"
      >
        <CustomDialogTitle onClose={closeEditInvoiceWarning} id="">
          Editer une facture
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          <EditInvoice handleCloseEditDialog={closeEditInvoiceWarning} />
        </DialogContent>
      </Dialog> */}
    </div>
  );
};

export default Invoices;
