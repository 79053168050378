// export const LOGIN = "login";
// export const SIGNUP = "signup";
// export const WELCOME = "welcome";
// export const DASHBOARD = "dashboard";
// export const USERS = "users";
// export const ROLES = "roles";
// export const SUPPLIERS = "suppliers";
// export const SUPPLIERS_LIST = "suppliers-list";
// export const MANAGMENT_RULES = "managment-rules";
// export const API_CONNECTION = "API-connection";
// export const PARAMETERS = "parameters"
// export const PURCHASE_ORDERS = "purchase-orders"
// export const PURCHASE_ORDERS_LIST = "purchase-orders-list"
// export const BILLS = "bills"
// export const BILLS_LIST = "bills-list"

// Note: french characters doesnt work in the path property of the Route component
export const LOGIN = "connexion";
export const LOGIN_SUPPLIER = `${LOGIN}/fournisseur`;
export const LOGIN_USER = `${LOGIN}/utilisateur`;
export const SIGNUP = "inscription";
export const WELCOME = "bienvenue";
export const DASHBOARD = "tableau-de-bord";
export const USERS = "utilisateurs";
export const ROLES = "roles";
export const SUPPLIERS = "fournisseurs";
export const SUPPLIERS_LIST = "liste-fournisseurs";
export const MANAGMENT_RULES = "regles-de-gestion";
export const API_CONNECTION = "Connexion-API";
export const PARAMETERS = "parametres";
export const PURCHASE_ORDERS = "bon-de-commandes";
export const PURCHASE_ORDERS_LIST = "liste-des-bons-de-commande";
export const BILLS = "factures";
export const BILLS_LIST = "liste-des-factures";
export const HOME = "accueil";
export const POSTES = "postes";
export const PERIMETER = "perimeters";
export const CIVILITE = "civilite";
export const TVA = "tva";
export const TYPE_DEPENSE = "type-de-depense";
export const SESSION = "session"
export const POLE = "pole";
export const ARTICLE = "article";
export const PAYMENTTERM = "condition-de-paiement";
export const PAYMENTDATE = "date-de-paiement";
export const PROJECT = "project";
export const USER_PROFILE = "profil-utilisateur";
export const SUPPLIER_PROFILE = "profil-fournisseur";
export const FORGOT_PASSWORD = "mot-de-passe-oublie";
export const RESET_PASSWORD = "reinitialiser-le-mot-de-passe";
