import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { CustomTextField } from "components/custom-textfield/CustomTextField.component";
import { useAppSelector, useAppDispatch } from "hooks/redux.hook";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Role } from "store/roles/roles.state";
import BlueButton from "components/blue-button/BlueButton.component";
import GreenButton from "components/green-button/GreenButton";
import { addTvaThunk, editTvaThunk, getTvasThunk } from "store/tva/tva.thunk";
import { ITva, tvasActions } from "store/tva/tva.state";

type ErrorProp = boolean | undefined;

export default function Form() {
  const dispatch = useAppDispatch();
  const selectedTvaToEdit = useAppSelector((state) => state.tvas.selectedTva);
  const isEditMode = selectedTvaToEdit !== null;

  const authenticatedUser = useAppSelector((state) => state.auth.user);

  const formik = useFormik({
    initialValues: {
      value:
        isEditMode && selectedTvaToEdit.value ? selectedTvaToEdit.value : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      value: Yup.number().required("Tva obligatoire"),
    }),
    onSubmit: (values, helpers) => {
      // console.log(values);
      if (isEditMode) {
        // console.log("ajouter");

        dispatch(
          editTvaThunk({ id: selectedTvaToEdit._id, tva: values as ITva })
        );

        dispatch(getTvasThunk());
        dispatch(tvasActions.clearSelectedTva());
      } else {
        dispatch(addTvaThunk(values as ITva));
        // console.log("ajouter");
      }
      helpers.resetForm();
    },
  });

  const showValueError = (formik.errors.value &&
    formik.touched.value) as ErrorProp;
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing="12px" marginBottom="20px">
        <Grid item xs={12} md={4}>
          <Typography
            component="span"
            fontWeight="bold"
            sx={{ color: "#3F4254" }}
          >
            Num Tva{" "}
            <Typography component="span" color="error">
              *
            </Typography>
          </Typography>
          <CustomTextField
            {...formik.getFieldProps("value")}
            error={showValueError}
            type="number"
            helperText={showValueError && formik.errors.value}
            placeholder="Numéro TVA"
            sx={{
              "& .MuiFilledInput-root": {
                background: "#fff",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <GreenButton
            variant="contained"
            sx={{
              marginTop: "25px",
              display: isEditMode ? "none" : "inline",
            }}
            type="submit"
            disabled={
              !(authenticatedUser!.role as Role).managmentRules.includes(
                "create"
              )
            }
          >
            Ajouter
          </GreenButton>
          <BlueButton
            sx={{ display: !isEditMode ? "none" : "inline", marginTop: "25px" }}
            variant="contained"
            type="submit"
            disabled={
              !(authenticatedUser!.role as Role).managmentRules.includes(
                "update"
              )
            }
          >
            Editer
          </BlueButton>
        </Grid>
      </Grid>
    </form>
  );
}
