import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import { useFormik } from "formik";
import * as Yup from "yup";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import BlueButton from "components/blue-button/BlueButton.component";

// import { uiActions } from "store/ui/ui.state";
import { editSupplierThunk } from "store/suppliers/suppliers.thunk";
import { CustomTextField as ITextField } from "components/custom-textfield/CustomTextField.component";
import { Supplier } from "store/suppliers/suppliers.state";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { resetPasswordThunk } from "store/users/users.thunk";
import { resetPasswordFromProfileThunk } from "store/users/users.thunk";

const margin = "20px";

type ErrorProp = boolean | undefined;

export default function ResetPasswordForm() {
  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Ancien mot de passe obligatoire"),
      newPassword: Yup.string()
        .required("Nouveau mot de passe obligatoire")
        .min(10, "Trop court"),
      confirmNewPassword: Yup.string().oneOf(
        [Yup.ref("newPassword"), null],
        "Les mots de passe doivent correspondre"
      ),
    }),
    onSubmit: (values, helpers) => {
      console.log(values);
      dispatch(
        resetPasswordFromProfileThunk(
          values.oldPassword,
          values.newPassword,
          values.confirmNewPassword
        )
      );
      helpers.resetForm();
    },
  });

  const showOldPasswordError = (formik.errors.oldPassword &&
    formik.touched.oldPassword) as ErrorProp;
  const showNewPasswordError = (formik.errors.newPassword &&
    formik.touched.newPassword) as ErrorProp;
  const showConfirmNewPasswordError = (formik.errors.confirmNewPassword &&
    formik.touched.confirmNewPassword) as ErrorProp;
  return (
    <Paper elevation={0} sx={{ borderRadius: "12px", }}>
      <Typography fontWeight={700} fontSize="19px" sx={{ color: "#3F4254" }} lineHeight="23px" padding="22px 0 19px 30px">
        Réinitialiser le mot de passe
      </Typography>
      <Divider />
      <form onSubmit={formik.handleSubmit} style={{padding: "30px 30px 20px 30px"}}>
        <Grid container>
          <Grid item flex="1">
            <Typography
              fontWeight="500"
              fontSize="15px"
              lineHeight="18px"
              sx={{ color: "#3F4254" }}
            >
              Ancien mot de passe
            </Typography>
          </Grid>
          <Grid item width="450px">
            <ITextField
              type="password"
              {...formik.getFieldProps("oldPassword")}
              error={showOldPasswordError}
              helperText={showOldPasswordError && formik.errors.oldPassword}
              sx={{ marginBottom: margin }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item flex="1">
            <Typography
              fontWeight="500"
              fontSize="15px"
              lineHeight="18px"
              sx={{ color: "#3F4254" }}
            >
              Nouveau mot de passe
            </Typography>
          </Grid>
          <Grid item width="450px">
            <ITextField
              type="password"
              {...formik.getFieldProps("newPassword")}
              error={showNewPasswordError}
              helperText={showNewPasswordError && formik.errors.newPassword}
              sx={{ marginBottom: margin }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item flex="1">
            <Typography
              fontWeight="500"
              fontSize="15px"
              lineHeight="18px"
              sx={{ color: "#3F4254" }}
            >
              Confirmer le nouveau mot de passe
            </Typography>
          </Grid>
          <Grid item width="450px">
            <ITextField
              type="password"
              {...formik.getFieldProps("confirmNewPassword")}
              error={showConfirmNewPasswordError}
              helperText={
                showConfirmNewPasswordError && formik.errors.confirmNewPassword
              }
              sx={{ marginBottom: margin }}
            />
          </Grid>
        </Grid>
        <Box
          display="flex"
          justifyContent="flex-end"
          gap="10px"
          marginTop="20px"
        >
          <BlueButton
            variant="contained"
            type="submit"
            sx={{ textTransform: "none", padding: "12px 20px" }}
          >
            modifier le mot de passe
          </BlueButton>
        </Box>
      </form>
    </Paper>
  );
}
