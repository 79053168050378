/* eslint-disable no-loop-func */
import Delete from "@mui/icons-material/Delete";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";
import FolderZipIcon from '@mui/icons-material/FolderZip';
import * as XLSX from "xlsx";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { AgGridReact } from "ag-grid-react";
import { useAppDispatch, useAppSelector } from "hooks/redux.hook";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { IInvoice, invoicesActions } from "store/invoices/invoice.state";
import { CustomToolbar } from "./CutomToolbar.component";
import { uiActions } from "store/ui/ui.state";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { Role } from "store/roles/roles.state";
import CustomSideBar from "components/custom-sidebar/CustomSideBar";
import "./style.css";
import {
  DataGridStateEnum,
  DataGridStatesActions,
  IDataGridState,
} from "store/datagrid-state/dataGridState.state";
import {
  addDatagridStateThunk,
  getDatagridStateByUserIdThunk,
  getDatagridStatesThunk,
} from "store/datagrid-state/dataGridState.thunk";
import CustomDialogTitle from "components/custom-dialog-title/CustomDialogTitle.component";
import AddView from "../add-view/AddView";
import WhiteButton from "components/white-button/WhiteButton.component";
import ViewSettings from "../views-settings/ViewSettings";
import InvoiceInfosAll from "../invoice-infos/InvoiceInfosAll";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import PDFMerger from 'pdf-merger-js/browser'
import { AG_GRID_LOCALE_FR } from "utils/constants";


// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

const IDataGrid = () => {
  const dispatch = useAppDispatch();
  const { addViewFormOpen, viewSettingsOpen } = useAppSelector(
    (state) => state.ui
  );
  const invoicesData = useAppSelector((state) => state.invoices.list);
  const gridRef = useRef<AgGridReact>(null);
  const groupDisplayType = "groupRows";
  const authenticatedUser = useAppSelector((state) => state.auth.user)!;
  const accountType = useAppSelector((state) => state.auth.accountType);
  const articles = useAppSelector((state) => state.articles.list)!;
  const [downloadAll, setdownloadAll] = React.useState(false);
  const [downloadAllMerged, setdownloadAllMerged] = React.useState(false);
  // const [downloadAllLoading, setdownloadAllLoading] = React.useState(false);
  const statesList = useAppSelector((state) => state.dataGridStates.list);

  const selectedInvoiceView: IDataGridState = useAppSelector(
    (state) => state.dataGridStates.selectedDataGridStateInvoice
  )!;
  const [selectedView, setSelectedView] =
    useState<IDataGridState>(selectedInvoiceView);

  useEffect(() => {
    getDatagridStateByUserIdThunk(authenticatedUser.id);
  }, []);

  const defaultView = {
    _id: "0",
    id: "0",
    dataGridName: DataGridStateEnum.Invoice,
    favorite: false,
    viewName: "Par défaut",
    user: authenticatedUser,
    colState: gridRef?.current?.columnApi?.getColumnState() as any,
  };
   useEffect(() => {
    setSelectedView({ ...selectedInvoiceView });
    if (selectedInvoiceView?.colState.length === 0) {
      resetState();
      // setSelectedView({ ...defaultView });
      selectView(gridRef?.current?.columnApi?.getColumnState());
    } else {
      gridRef?.current?.columnApi?.applyColumnState({
        state: selectedInvoiceView?.colState,
        applyOrder: true,
      });
    }
    // const favorite = statesList
    //   .filter(
    //     (view: IDataGridState) =>
    //       view.dataGridName === DataGridStateEnum.Invoice
    //   )
    //   .every((view: IDataGridState) => view.favorite === false);

    // if (favorite) {
    //   setSelectedView({ ...defaultView });
    //   gridRef?.current?.columnApi?.resetColumnState();
    //   selectView(gridRef?.current?.columnApi?.getColumnState());
    // }
  }, [selectedInvoiceView]);
  const [gridApi, setGridApi] = useState<any>()!;
  const [gridColumnApi, setGridColumnApi] = useState<any>();
  const [customSideBarOpen, setCustomSideBarOpen] = useState<boolean>(false);
  const defaultColDef = useMemo(() => {
    return {
      minWidth: 100,
      // allow every column to be aggregated
      // enableValue: true,
      // allow every column to be grouped
      // enableRowGroup: true,
      // allow every column to be pivoted
      // enablePivot: true,
      resizable: true,
      sortable: true,
      filter: true,
    };
  }, []);
  const popupParent = useMemo<HTMLElement | null>(() => {
    return document.body;
  }, []);

  // possible options: 'never', 'always', 'onlyWhenGrouping'
  const calculateReste = (params: any) => {
    const resteCalculted: any = (params?.data?.purchaseOrder?.GTTC - params?.data?.amount) || ''
    return resteCalculted;
  }

  const rowClassRules = React.useMemo<any>(() => ({
    'ag-row-invoice-volante': (params: any) => !params?.data?.purchaseOrder,
  }), [])
  const rowStyle: any = {
    fontFamily: "Roboto",
    fontWeigth: 700,
    fontSize: "14px",
    lineHeigth: "17.58px",
    color: "#3F4254",
  };
  const closeAddViewform = () => {
    dispatch(uiActions.setAddViewFormOpen(false));
  };
  const closeSettingsViewform = () => {
    dispatch(uiActions.setViewSettingsOpen(false));
  };
  // display each row grouping in group rows

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const selectView = (colState: any) => {
    gridRef?.current?.columnApi?.applyColumnState({
      state: colState,
      applyOrder: true,
    });
  };

  useEffect(() => {
    if (!addViewFormOpen) {
      selectView(selectedInvoiceView?.colState ?? defaultView.colState);
    }
  }, [])

  const resetState = () => {
    gridRef?.current?.columnApi.resetColumnState();
  };

  const changeStatus = (data: any) => {
    if (
      (authenticatedUser?.role as Role)?.name === "Administrateur" &&
      (authenticatedUser?.role as Role)?.billManagment.includes("update")
    ) {
      dispatch(invoicesActions.setSelectInvoice(data));
      dispatch(uiActions.setEditInvoiceStatusOpen(true));
    }
  };
  const deleteRow = (id: string) => {
    dispatch(
      uiActions.setDeleteInvoiceWarningOpen({
        set: true,
        id: id,
      })
    );
  };
  const InvoicePdf = (data: IInvoice) => {
    dispatch(invoicesActions.setSelectInvoice(data));
    dispatch(uiActions.setInvoicePdfWarningOpen(true));
  };

  const updateRow = (data: IInvoice) => {
    dispatch(invoicesActions.setSelectInvoice(data));
    dispatch(uiActions.setEditInvoiceFormOpen(true));
  };
  // const InvoiceInfos = (data: IInvoice) => {
  //   dispatch(invoicesActions.setSelectInvoice(data));
  //   dispatch(uiActions.setInfoInvoiceOpen(true));
  // };
  const columnTypes = useMemo(() => {
    return {
      numberColumn: { width: 130, filter: "agNumberColumnFilter" },
      medalColumn: { width: 100, columnGroupShow: "open", filter: false },
      nonEditableColumn: { editable: false },
      dateColumn: {
        // specify we want to use the date filter
        filter: "agDateColumnFilter",
        // add extra parameters for the date filter
        filterParams: {
          // provide comparator function
          comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
            // In the example application, dates are stored as dd/mm/yyyy
            // We create a Date object for comparison against the filter date
            const dateParts = cellValue.split("/");
            const day = Number(dateParts[0]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[2]);
            const cellDate = new Date(year, month, day);
            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
        },
      },
    };
  }, []);

  const [columnDefs] = useState([
    {
      headerName: "Actions",
      field: "id",
      cellRenderer: (params: any) => (
        <Grid>
          {!(accountType === "vendor") && (
            <IconButton
              title="Supprimer"
              onClick={() => deleteRow(params.data._id)}
              disabled={
                !(authenticatedUser?.role as Role)?.billManagment?.includes(
                  "delete"
                )
              }
              color="error"
              size="small"
            >
              <Delete />
            </IconButton>
          )}

          {""}
          {!(accountType === "vendor") && (
            <IconButton
              onClick={() => updateRow(params.data)}
              title="Editer"
              disabled={
                !(authenticatedUser?.role as Role)?.billManagment?.includes(
                  "update"
                )
              }
              size="small"
              sx={{ color: "#00A3FF" }}
            >
              <EditIcon />
            </IconButton>
          )}
          <IconButton
            title="Version PDF "
            sx={{ color: "#f24446" }}
            size="small"
            onClick={() => InvoicePdf(params.data)}
          >
            <PictureAsPdfIcon />
          </IconButton>
          {/* <IconButton
            title="Infos"
            size="small"
            color="info"
            disabled={
              accountType === "user" &&
              !(authenticatedUser?.role as Role)?.billManagment?.includes(
                "read"
              )
            }
            onClick={() => InvoiceInfos(params.data)}
          >
            <InfoIcon />
          </IconButton> */}
        </Grid>
      ),
    },
    {
      field: "invoiceDate",
      headerName: "Date de facture",
      floatingFilter: true,
      filter: "agDateColumnFilter",
      sortable: true,
      type: ["dateColumn", "nonEditableColumn"],
      // cellRendererFramework: (params: any) => (
      //   <span>{`${params.data.invoiceDate.split("-")[2]}/${
      //     params.data.invoiceDate.split("-")[1]
      //   }/${params.data.invoiceDate.split("-")[0]}`}</span>
      // ),
    },
    {
      field: "dateDeControle",
      headerName: "Date de contrôle",
      type: ["dateColumn", "nonEditableColumn"],
      floatingFilter: true,
      filter: "agDateColumnFilter",
      sortable: true,
      cellRenderer: (params: any) => (
        <span> {params.data.dateDeControle} </span>
      ),
    },
    {
      field: "dateDeReception",
      headerName: "Date de réception",
      type: ["dateColumn", "nonEditableColumn"],
      floatingFilter: true,
      filter: "agDateColumnFilter",
      sortable: true,
      cellRenderer: (params: any) => (
        <span> {params.data.dateDeReception} </span>
      ),
    },
    {
      field: "dateDePaiementPrevisionnel",
      headerName: "Date de paiement prévisionnel",
      type: ["dateColumn", "nonEditableColumn"],
      floatingFilter: true,
      filter: "agDateColumnFilter",
      sortable: true,
      cellRenderer: (params: any) => (
        <span> {params.data.dateDePaiementPrevisionnel} </span>
      ),
    },
    {
      field: "dateDePaiementReel",
      headerName: "Date de paiement réel",
      type: ["dateColumn", "nonEditableColumn"],
      floatingFilter: true,
      filter: "agDateColumnFilter",
      sortable: true,
      cellRenderer: (params: any) => (
        <span> {params.data.dateDePaiementReel} </span>
      ),
    },
    {
      field: "FAC_NUM",
      headerName: "Numéro facture",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true,
    },
    {
      headerName: "Facture",
      field: "invoiceName",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true,
    },
    {
      headerName: "Fournisseur",
      field: "fournisseur.nom",
      floatingFilter: true,
      // rowGroup: true,
      filter: "agTextColumnFilter",
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      // rowGroup: true,
      cellRenderer: (params: any) => (
        <>
          <Button
            onClick={() => {
              changeStatus(params.data)
            }}
            variant={
              params.data.status === "Créée" ? "outlined" : "contained"
            }
            size="small"
            style={{ textTransform: "capitalize" }}
            sx={{
              color:
                params.data.status === "Validée"
                  ? "#42D9C7"
                  : params.data.status === "Réceptionnée"
                    ? "#42D9C7"
                    : params.data.status === "En paiement"
                      ? "#42D9C7"
                      : params.data.status === "Payée"
                        ? "#42D9C7"
                        : "#42D9C7",
            }}
          >
            {" "}
            {params.data.status} {" "}
          </Button >
        </>
      ),
    },
    {
      field: "purchaseOrder.BDC_NUM",
      headerName: "Numéro Bon",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true,
    },
    // {
    //   field: "amount",
    //   headerName: "Montant Facture",
    //   floatingFilter: true,
    //   filter: "agNumberColumnFilter",
    //   sortable: true,
    //   cellRendererFramework: (params: any) => (
    //     <span>{params.data.amount} €</span>
    //   ),
    // },
    {
      field: "amount",
      headerName: "Montant facture HT",
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      sortable: true,
      cellRenderer: (params: any) => (
        <span> {params.data.amount} €</span>
      ),
    },
    {
      field: "purchaseOrder.reste",
      headerName: "Reste à payer",
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      sortable: true,
      cellRenderer: (params: any) => (
        <span>{params?.data?.invoiceRest} € </span>
      ),
    },
    {
      field: "purchaseOrder.GHT",
      headerName: "Montant BDC HT",
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      sortable: true,
      cellRenderer: (params: any) => (
        <span>{params.data.purchaseOrder?.GHT ? params.data.purchaseOrder?.GHT + ' €' : ''} </span>

      ),
    },
    {
      field: "purchaseOrder.MTVA",
      headerName: "T. TVA",
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      sortable: true,
      cellRenderer: (params: any) => (
        <span>{params.data.purchaseOrder?.MTVA ? params.data.purchaseOrder?.MTVA + ' €' : ''} </span>
      ),
    },
    {
      field: "purchaseOrder.GTTC",
      headerName: "Montant BDC TTC",
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      type: "numberColumn",
      sortable: true,
      cellRenderer: (params: any) => (
        <span>{params.data.purchaseOrder?.GTTC ? params.data.purchaseOrder?.GTTC + ' €' : ''} </span>
      ),
    },
    // {
    //   field: "TVA",
    //   headerName: "TVA Facture",
    //   floatingFilter: true,
    //   filter: "agNumberColumnFilter",
    //   sortable: true,
    //   cellRendererFramework: (params: any) => (
    //     <span> {params.data.TVA?.value} </span>
    //   ),
    // },
    {
      field: "TTC",
      headerName: "Montant facture TTC",
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      sortable: true,
      cellRenderer: (params: any) => (
        <span> {params.data.TTC} €</span>
      ),
    },
    {
      field: "invoiceDocUrl",
      headerName: "Facture cliente",
      cellRenderer: (params: any) => (
        <span>
          {" "}
          <Link
            href={`${params.data.invoiceDocUrl}`}
            target="_blank"
            underline="hover"
          >
            {"facture jointe"}
          </Link>
        </span>
      ),
    },
  ]);

  const downloadExcel = (data: any) => {
    // let newData: any[] = [];
    // data.forEach((row: any, index: number) => {
    //   const dt: any = {}
    //   Object.keys(row).map((key: any) => {
    //     if (key !== '__v' && key !== '_id' && key !== 'modificationDate') {
    //       let dd: any = columnDefs?.find((elm: any) => elm.field === key)?.headerName || key

    //       if (dd === 'fournisseur') {
    //         dd = 'Fournisseur'
    //       }
    //       if (dd === 'purchaseOrder') {
    //         dd = 'Bons de commande'
    //       }

    //       if (dd === 'Fournisseur') {
    //         dt[dd] = row[key] ? `${row[key].nom} ${row[key].prenom}` : ''
    //       }
    //       else if (dd === 'TVA Facture') {
    //         dt[dd] = row[key]?.value
    //       }
    //       else if (dd === 'Bons de commande') {
    //         if (row[key]) {
    //           dt['Reste à payer'] = (row[key].GTTC - row['amount']) || 0
    //           dt['Grand T. HT'] = row[key].GHT
    //           dt['T. TVA'] = row[key].MTVA
    //           dt['Grand T. TTC'] = row[key].GTTC
    //           dt['Order ID'] = row[key]._id
    //           dt['Numéro Bon'] = row[key].BDC_NUM

    //         } else {
    //           dt['Reste à payer'] = ''
    //           dt['Grand T. HT'] = ''
    //           dt['T. TVA'] = ''
    //           dt['Grand T. TTC'] = ''
    //           dt['Order ID'] = ''
    //           dt['Numéro Bon'] = ''
    //           dt['Numéro Bon'] = ''

    //         }
    //       } else {
    //         dt[dd] = row[key]
    //       }
    //     }
    //   })
    //   newData[index] = { ...dt };
    // });
    // const columnDefsoo = gridRef.current?.columnApi?.getColumns()
    // console.log(' 🚀  => columnDefs', columnDefsoo)
    // // let newDataFinal: any[] = [];
    // // const dts: any = []
    // // columnDefs.map((elem: any) => {
    // //   const dt: any = {}
    // //   newData.map((row: any) => {
    // //     Object.keys(row).map((key: any) => {
    // //       if (elem?.headerName === key) {
    // //         console.log(' 🚀  => key', key)
    // //         // dts.push(row)
    // //       //   // dt[key] = row[key]
    // //       }
    // //     })
    // //     // const ddt = newData.find((el: any) => el?.)
    // //     // console.log(' 🚀  => elem', elem)
    // //   });
    // //   // console.log(' 🚀  => dt', dt)
    // // });
    // // console.log(' 🚀  => dts', dts)

    // const worksheet = XLSX.utils.json_to_sheet(dts);
    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // XLSX.writeFile(workbook, "liste_Factures.xlsx", { compression: true });

    const cols: any = []
    const colswithHeader: any = []
    const datas: any[] = []
    const columnDefs: any = gridRef?.current?.columnApi?.getColumns()

    columnDefs.forEach((col: any) => {
      if (col?.colId !== '0') {
        if (col?.colId !== 'id') {
          if (col?.colId !== 'modificationDate') {
            if (col?.colId !== '__v') {
              if (col?.visible) {
                cols.push(col?.colId)
                colswithHeader.push({
                  field: col?.colId,
                  headerName: col?.userProvidedColDef?.headerName,
                  // type: col?.userProvidedColDef?.filter === 'agDateColumnFilter' ? 'date' : 'notDate',
                })
              }
            }
          }
        }
      }
    })
    const rowCount: any = gridRef?.current?.api?.getDisplayedRowCount()
    const lastGridIndex = rowCount - 1
    const currentPage: any = gridRef.current?.api.paginationGetCurrentPage()
    const pageSize: any = gridRef?.current?.api?.paginationGetPageSize()
    const startPageIndex: any = currentPage * pageSize
    let endPageIndex: any = (currentPage + 1) * pageSize - 1

    if (endPageIndex > lastGridIndex) {
      endPageIndex = lastGridIndex
    }
    for (let i = startPageIndex; i <= endPageIndex; i++) {
      const rowNode = gridRef.current?.api.getDisplayedRowAtIndex(i)
      const dt: any = {}
      cols?.forEach((cl: any) => {
        const fl = colswithHeader.find((elm: any) => elm.field === cl)
        // console.log(' 🚀  => fl.headerName', fl.headerName)
        if (fl.headerName === 'Fournisseur') {
          if (rowNode?.data?.fournisseur) {
            dt['Fournisseur'] = `${rowNode?.data?.fournisseur?.nom} ${rowNode?.data?.fournisseur?.prenom}`
          } else {
            dt['Fournisseur'] = ''
          }
        } else if (fl.headerName === 'TVA Facture') {
          // console.log(' 🚀  => rowNode?.data?.TVA', rowNode?.data?.TVA)
          dt[fl.headerName] = rowNode?.data?.TVA ? rowNode?.data?.TVA?.value : ''
        } else if (fl.headerName === 'Reste à payer') {
          dt[fl.headerName] = rowNode?.data?.purchaseOrder ? (rowNode?.data?.purchaseOrder.GTTC - rowNode?.data?.amount) : ''
        } else if (fl.headerName === 'Montant BDC HT') {
          dt[fl.headerName] = rowNode?.data?.purchaseOrder ? rowNode?.data?.purchaseOrder.GHT : ''
        } else if (fl.headerName === 'T. TVA') {
          dt[fl.headerName] = rowNode?.data?.purchaseOrder ? rowNode?.data?.purchaseOrder.MTVA : ''
        } else if (fl.headerName === 'Montant BDC TTC') {
          dt[fl.headerName] = rowNode?.data?.purchaseOrder ? rowNode?.data?.purchaseOrder.GTTC : ''
        } else if (fl.headerName === 'Order ID') {
          dt[fl.headerName] = rowNode?.data?.purchaseOrder ? rowNode?.data?.purchaseOrder._id : ''
        } else if (fl.headerName === 'Numéro Bon') {
          dt[fl.headerName] = rowNode?.data?.purchaseOrder ? rowNode?.data?.purchaseOrder.BDC_NUM : ''
        } else {
          dt[fl.headerName] = rowNode?.data[cl]
        }
      })
      datas.push(dt)
    }
    const worksheet = XLSX.utils.json_to_sheet(datas);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "liste_Factures.xlsx", { compression: true });
  };

  const onBtnExport = () => {
    gridApi?.exportDataAsCsv();
  };

  const exportInvoicePdf = async () => {
    if (downloadAll && invoicesData.length) {
      var zip = new JSZip();
      await invoicesData?.forEach(async (el: any, ind: any) => {
        const idComponent = 'invoiceInfosAll' + (ind + 1)
        const input: any = document.getElementById(idComponent);
        html2canvas(input, { logging: true, useCORS: true }).then(async (canvas) => {
          const imgWidth = 210;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          const imgData = canvas.toDataURL("img/png");
          const pdf = new jsPDF("portrait", "mm", "a4");
          await pdf.addImage(imgData, "PNG", 0, 25, imgWidth, imgHeight);
          const fileData = new Blob([pdf.output('blob')], {
            type: 'application/pdf',
          })
          const fileName = `Facture-${ind + 1}_${el.invoiceDate.replaceAll('/', '-')}.pdf`
          // input.remove()
          await zip.file(fileName, fileData);
          if (ind === (invoicesData.length - 1)) {
            await zip.generateAsync({
              type: 'blob',
            })
              .then((blob) => {
                saveAs(blob, `Factures_${new Date().getDay()}/${new Date().getMonth()}/${new Date().getFullYear()}.zip`)
                setdownloadAll(false)
              })
          }
        });
      })
    }
  }
  const exportInvoicePdfMerged = async () => {
    if (downloadAllMerged && invoicesData.length) {
      const merger = new PDFMerger()
      await invoicesData?.forEach(async (el: any, ind: any) => {
        const idComponent = 'invoiceInfosAll' + (ind + 1)
        const input: any = document.getElementById(idComponent);
        html2canvas(input, { logging: true, useCORS: true }).then(async (canvas) => {
          const imgWidth = 210;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          const imgData = canvas.toDataURL("img/png");
          const pdf = new jsPDF("portrait", "mm", "a4");
          await pdf.addImage(imgData, "PNG", 0, 25, imgWidth, imgHeight);
          const fileData = new Blob([pdf.output('blob')], {
            type: 'application/pdf',
          })
          await merger.add(fileData)
          // input.remove()
          const fileName = `Factures_${new Date().getDay()}/${new Date().getMonth()}/${new Date().getFullYear()}-Fusionné`
          if (ind === (invoicesData.length - 1)) {
            await merger.save(fileName)
            setdownloadAllMerged(false)
          }
        });
      })
    }
  }
  // display each row grouping in group rows
  return (
    <div>
      <Grid container direction="row">
        <Grid item xs={12}>
          {/* hada howa ndir fih pdfs */}
          <CustomToolbar exportXLSX={downloadExcel} invoicesData={invoicesData} exportCSV={onBtnExport} />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={6}>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >

                <FormControl style={{
                  width: "40%", background: "#e6f4ff",
                  borderRadius: "5px"
                }}>
                  <InputLabel style={{
                    background: "#e6f4ff",
                    padding: "1px",
                    color: "black"
                  }} id="demo-simple-select-label">
                    Sélectionner une vue
                  </InputLabel>
                  <Select
                    // disableUnderline={true}
                    placeholder="Sélectionner une vue"
                    sx={{
                      boxShadow: "none",
                      ".MuiOutlinedInput-notchedOutline": { border: "0 #e3d3f2", },
                    }}
                    value={selectedInvoiceView?._id ?? defaultView._id}
                    style={{
                      height: "30px",
                      margin: "6px",
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                  >
                    <MenuItem
                      key={defaultView._id}
                      value={defaultView._id}
                      style={{ height: "40px" }}
                      onClick={() => {
                        resetState();
                        dispatch(
                          DataGridStatesActions.clearSelectedDataGridStateInvoice()
                        );
                        // const resetView =
                        //   gridRef?.current?.columnApi?.getColumnState();
                        // selectView(resetView);
                        // setSelectedView({ ...defaultView });
                      }}
                    >
                      {" "}
                      <Checkbox
                        checked={defaultView.favorite}
                        icon={<FavoriteBorder />}
                        checkedIcon={<Favorite />}
                      />
                      {defaultView.viewName}{" "}
                    </MenuItem>
                    {statesList
                      .filter(
                        (view: IDataGridState) =>
                          view.dataGridName === DataGridStateEnum.Invoice
                      )
                      .map((view: IDataGridState) => {
                        return (
                          <MenuItem
                            key={view._id}
                            value={view._id}
                            style={{ height: "40px" }}
                            onClick={() => {
                              selectView(view.colState);
                              dispatch(
                                DataGridStatesActions.setSelectDataGridStateInvoice(
                                  view
                                )
                              );
                            }}
                          >
                            {" "}
                            <Checkbox
                              checked={view.favorite}
                              icon={<FavoriteBorder />}
                              checkedIcon={<Favorite />}
                            />
                            {view.viewName}{" "}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <IconButton onClick={() => {
                  dispatch(uiActions.setViewSettingsOpen(true));
                }} aria-label="settings" color="default">
                  <SettingsIcon

                  />
                </IconButton>
                <IconButton onClick={() => {
                  dispatch(uiActions.setAddViewFormOpen(true));
                }} aria-label="sauvegarder" color="primary">
                  <SaveIcon />
                </IconButton>
                <div style={{ display: 'flex', alignItems: 'flex-center', margin: '10px 0' }}>
                  <Button
                    sx={{
                      boxShadow: "none",
                      border: '1px solid #c8531d',
                      color: 'white',
                      backgroundColor: "#c8531d",
                      textTransform: "none",
                      "&:hover": {
                        boxShadow: "none",
                        color: '#c8531d',
                        border: '1px solid #c8531d',
                        backgroundColor: "white",
                      },
                      "&:disabled": {
                        boxShadow: "none",
                        color: '#c0c0c0',
                        border: '1px solid #c0c0c0',
                        backgroundColor: "white",
                      },
                    }}
                    disabled={downloadAll || downloadAllMerged}
                    variant="outlined"
                    title="Télécharger tout les PDF"
                    onClick={() => {
                      setdownloadAll(true)
                    }}
                    startIcon={downloadAll ? <CircularProgress size={18} /> : <FolderZipIcon />}
                  >
                    Télécharger tout
                  </Button>
                  <Button
                    sx={{
                      boxShadow: "none",
                      border: '1px solid #c8531d',
                      color: 'white',
                      marginLeft: '10px',
                      backgroundColor: "#c8531d",
                      textTransform: "none",
                      "&:hover": {
                        boxShadow: "none",
                        color: '#c8531d',
                        border: '1px solid #c8531d',
                        backgroundColor: "white",
                      },
                      "&:disabled": {
                        boxShadow: "none",
                        color: '#c0c0c0',
                        border: '1px solid #c0c0c0',
                        backgroundColor: "white",
                      },
                    }}
                    variant="outlined"
                    disabled={downloadAll || downloadAllMerged}
                    title="Télécharger tout les PDF (Fusionné)"
                    startIcon={downloadAllMerged ? <CircularProgress size={18} /> : <PictureAsPdfIcon />}
                    onClick={() => {
                      setdownloadAllMerged(true)
                    }}
                  >
                    Télécharger tout (Fusionné)
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
        <Grid
          item
          xs={!customSideBarOpen ? 11.5 : 10}
          justifyContent="center"
          alignItems="center"
          className="ag-theme-alpine"
          style={{ height: "70vh" }}
        >
          <AgGridReact
            ref={gridRef}
            localeText={AG_GRID_LOCALE_FR}
            rowData={invoicesData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            rowStyle={rowStyle}
            enableCellTextSelection
            suppressExcelExport={true}
            popupParent={popupParent}
            groupDisplayType={groupDisplayType}
            onGridReady={onGridReady}
            columnTypes={columnTypes}
            rowClassRules={rowClassRules}
            onRowClicked={(e: any) => {
              dispatch(invoicesActions.setSelectInvoice(e.data));
            }}
          ></AgGridReact>
        </Grid>
        <Grid style={{ height: "70vh", overflow: "hidden" }} item xs={!customSideBarOpen ? 0.5 : 2}>
          <CustomSideBar
            gridColumnApi={gridColumnApi}
            setCustomSideBarOpen={setCustomSideBarOpen}
          />
        </Grid>
        {downloadAll && invoicesData.length && (
          invoicesData?.map((el: any, ind: any) => (
            <InvoiceInfosAll idComponent={'invoiceInfosAll' + (ind + 1)} idComponentFinal={'invoiceInfosAll' + invoicesData.length} selectedInvoice={el} key={ind} exportInvoicePdf={exportInvoicePdf} />
          )
          ))}
        {downloadAllMerged && invoicesData.length && (
          invoicesData?.map((el: any, ind: any) => (
            <InvoiceInfosAll idComponent={'invoiceInfosAll' + (ind + 1)} idComponentFinal={'invoiceInfosAll' + invoicesData.length} selectedInvoice={el} key={ind} exportInvoicePdf={exportInvoicePdfMerged} />
          )
          ))}
      </Grid>

      {/* add view dialog */}
      <Dialog
        fullScreen
        open={addViewFormOpen}
        onClose={closeAddViewform}
        sx={{ "& .MuiPaper-root": { height: "25vh", width: "40vw" } }}
        maxWidth="lg"
      >
        <CustomDialogTitle onClose={closeAddViewform} id="">
          Sauvgarder la vue
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          <AddView gridRef={gridRef} closeAddViewform={closeAddViewform} />
        </DialogContent>
      </Dialog>

      {/* settings view dialog */}
      <Dialog
        fullScreen
        open={viewSettingsOpen}
        onClose={closeSettingsViewform}
        sx={{ "& .MuiPaper-root": { height: "85vh", width: "30vw" } }}
        maxWidth="lg"
      >
        <CustomDialogTitle onClose={closeSettingsViewform} id="">
          Liste des vues disponible
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          <ViewSettings
            views={statesList.filter(
              (view: IDataGridState) =>
                view.dataGridName === DataGridStateEnum.Invoice
            )}
          />
        </DialogContent>
        <DialogActions>
          <WhiteButton onClick={closeSettingsViewform}>Annuler</WhiteButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default IDataGrid;

// const values: any = {};
// values.dataGridName = DataGridStateEnum.Invoice;
// values.colState = gridRef?.current?.columnApi.getColumnState();
// dispatch(addDatagridStateThunk(values));
